import * as THREE from 'three';
import { DistancePMI } from '../PMI/distancePMI';

export class ChoiceObject {

    //フィールド変数（プロパティ）
    public objects: number[][] = new Array(2);
    public information = new Array(2);
    public distanceVector: THREE.Vector3[] = new Array(2);

    //コンストラクタ
    constructor() { }

    //メソッド
    //オブジェクトを選ぶメソッド
    public choose(object: number[], type: string, id: number) {
        if (!this.objects[0]) {
            this.information[0] = { type: type, id: id };
            this.objects[0] = object;
        } else {
            //2番目のオブジェクトがある場合は、1番目に上書き
            if (this.objects[1]) {
                this.information[0] = this.information[1];
                this.objects[0] = this.objects[1];
            }
            //2番目のオブジェクトとして選択
            this.information[1] = { type: type, id: id };
            this.objects[1] = object;
        }
    }

    //選択したオブジェクトの情報を返す関数（距離がある場合はそれに相当するベクトルをdistanceVectorに与える）
    public informationOfChoice(duplicateVerticesList: number[]): string {
        let infoText = '';

        //各選択オブジェクトの情報
        if (this.information[0]) {
            for (let n = 0; n < 2; n++) {
                //順番
                if (n === 0) infoText += ' First picked';
                else infoText += '\n Second picked';
                //タイプ
                infoText += ' ' + this.information[n].type + '\n';
                //頂点の場合
                if (this.information[n].type === 'vertex') {
                    //IDリスト
                    let pointsIDlist = '  ID : ';
                    for (let i = 0; i < duplicateVerticesList.length; i++) {
                        if (this.information[n].id === i) {
                            pointsIDlist += i + '';
                        } else if (this.information[n].id === duplicateVerticesList[i]) {
                            pointsIDlist += ', ' + i + '';
                        }
                    }
                    infoText += pointsIDlist + '\n';
                    //頂点座標
                    let COP = this.objects[n];
                    infoText += '  x : ' + COP[0] + '\n  y : ' + COP[1] + '\n  z : ' + COP[2] + '\n';
                } else if (this.information[n].type === 'edge') {
                    infoText += '  ID : ' + this.information[n].id + '\n';
                    infoText += '  length : ' + this.lengthOf(this.objects[n]) + '\n';
                } else {
                    infoText += '  ID : ' + this.information[n].id + '\n'
                }
                //2番目がなければ追記を終了
                if (!this.information[1]) break;
            }
        } else {
            infoText = ' no picked object'
        }

        //2つ選んでいる場合の情報
        if (this.information[1]) {
            const distance = new DistancePMI;
            this.distanceVector = [];
            //{vertex, vertex}：距離と方向ベクトル情報を表示
            if (this.information.filter(object => object.type === 'vertex').length === 2) {
                let measureVector = new THREE.Vector3().subVectors(
                    new THREE.Vector3().fromArray(this.objects[1]),
                    new THREE.Vector3().fromArray(this.objects[0])
                );
                infoText += '\n distance : ' + measureVector.length() + '\n';
                let dirVec = new THREE.Vector3().copy(measureVector).normalize();
                infoText += ' direction vector\n  x : ' + dirVec.x + '\n  y : ' + dirVec.y + '\n  z : ' + dirVec.z + '\n';
                this.distanceVector = [
                    new THREE.Vector3().fromArray(this.objects[0]),
                    new THREE.Vector3().fromArray(this.objects[1])
                ];
            }
            //{vertex, edge(resp.face)}：距離と差分の軸方向成分を表示
            else if (this.information.filter(object => object.type === 'vertex').length === 1) {
                let measureVector = new THREE.Vector3();
                let targetObjects: number[][] = Array(2);
                let other = 1;
                if (this.information[0].type === 'vertex') targetObjects = this.objects;
                else {
                    targetObjects[0] = this.objects[1];
                    targetObjects[1] = this.objects[0];
                    other = 0;
                };
                if (this.information[other].type === 'edge')
                    measureVector = distance.fromVertexToEdge(targetObjects[0], targetObjects[1]);
                else if (this.information[other].type === 'face')
                    measureVector = distance.fromVertexToFace(targetObjects[0], targetObjects[1]);
                infoText += '\n distance : ' + measureVector.length()
                    + '\n  dx : ' + measureVector.x + '\n  dy : ' + measureVector.y + '\n  dz : ' + measureVector.z + '';
                this.distanceVector = [
                    new THREE.Vector3().fromArray(targetObjects[0]),
                    new THREE.Vector3().fromArray(targetObjects[0]).add(measureVector)
                ];
            }
            //{edge, edge}：距離と差分を表示
            else if (this.information.filter(object => object.type === 'edge').length === 2) {
                let distanceData = distance.betweenEdges(this.objects[0], this.objects[1]);
                let measureVector = new THREE.Vector3().subVectors(
                    distanceData.end, distanceData.start
                );
                infoText += '\n distance : ' + distanceData.distance + '\n';
                infoText += '\n  dx : ' + measureVector.x + '\n  dy : ' + measureVector.y + '\n  dz : ' + measureVector.z + '\n';
                this.distanceVector = [distanceData.start, distanceData.end];
            }
            //{edge, face}：距離と差分を表示
            else if (this.information.filter(object => object.type === 'edge').length === 1) {
                let targetObjects: number[][] = Array(2);
                if (this.information[0].type === 'edge') targetObjects = this.objects;
                else {
                    targetObjects[0] = this.objects[1];
                    targetObjects[1] = this.objects[0];
                };
                let distanceData = distance.fromEdgeToFace(targetObjects[0], targetObjects[1]);
                let measureVector = new THREE.Vector3().subVectors(
                    distanceData.end, distanceData.start
                );
                infoText += '\n distance : ' + distanceData.distance + '\n';
                infoText += '\n  dx : ' + measureVector.x + '\n  dy : ' + measureVector.y + '\n  dz : ' + measureVector.z + '\n';
                this.distanceVector = [distanceData.start, distanceData.end];
            }
            //{face, face}：距離と差分を表示
            else {
                let distanceData = distance.betweenFaces(this.objects[0], this.objects[1]);
                let measureVector = new THREE.Vector3().subVectors(
                    distanceData.end, distanceData.start
                );
                infoText += '\n distance : ' + distanceData.distance + '\n';
                infoText += '\n  dx : ' + measureVector.x + '\n  dy : ' + measureVector.y + '\n  dz : ' + measureVector.z + '\n';
                this.distanceVector = [distanceData.start, distanceData.end];
            }
        }
        return infoText;
    }

    //辺の長さを得るメソッド（移動予定）
    public lengthOf(edgeLines: number[]): number {
        let lengthSum = 0;
        for (let n = 0; n < edgeLines.length / 3 - 1; n++) {
            let initial = new THREE.Vector3(edgeLines[n * 3], edgeLines[n * 3 + 1], edgeLines[n * 3 + 2]);
            let terminal = new THREE.Vector3(edgeLines[n * 3 + 3], edgeLines[n * 3 + 4], edgeLines[n * 3 + 5]);
            lengthSum += initial.sub(terminal).length();
        }
        return lengthSum;
    }
}