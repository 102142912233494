import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/trialBalanceSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';

interface Props {
  selectModelCondition: MyIF.selectModelingConditionType;
}

const TrialCalcConditionArea: React.FC<Props> = React.memo(({ selectModelCondition }) => {
  return (
    <section className='trialBalanceSpecEditFormAccordionContent'>
      <input id='toggle3' type='checkbox' className='trialBalanceSpecEditFormToggle' />
      <label className='trialBalanceSpecEditFormToggleLabel' htmlFor='toggle3'>
        試算条件
      </label>

      <div className='trialBalanceSpecEditFormToggleContentBlock'>
        <div className='trialBalanceSpecEditFormToggleContent'>
          <div className='trialBalanceSpecEditFormInputBlock'>
            <label htmlFor='form3-1'>造形材料</label>
            <div className='fixedValue'>{selectModelCondition.materialValue}</div>
          </div>
        </div>

        <div className='trialBalanceSpecEditFormToggleContent'>
          <div className='trialBalanceSpecEditFormInputBlock'>
            <label htmlFor='form3-2'>造形方式</label>
            <div className='fixedValue'>{selectModelCondition.methodValue}</div>
          </div>
        </div>

        <div className='trialBalanceSpecEditFormToggleContent'>
          <div className='trialBalanceSpecEditFormInputBlock'>
            <label htmlFor='form3-3'>
              3Dプリンタ機種
              <p>
                金属材料のSDSシートは
                <a
                  href='https://www.orixrentec.jp/3dprinter/download/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  こちら
                </a>
              </p>
            </label>
            <div className='fixedValue'>{selectModelCondition.printerValue}</div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default TrialCalcConditionArea;
