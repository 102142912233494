import * as React from 'react';
import { gavadonView } from 'gavadon';
import * as MyIF from '../../common/Interface';
import TopImage from '../../assets/image/viewer/3d-ctrl-view-top.png';
import ButtomImage from '../../assets/image/viewer/3d-ctrl-view-bottom.png';
import LeftImage from '../../assets/image/viewer/3d-ctrl-view-left-side.png';
import RightImage from '../../assets/image/viewer/3d-ctrl-view-right-side.png';
import FrontImage from '../../assets/image/viewer/3d-ctrl-view-front.png';
import BackImage from '../../assets/image/viewer/3d-ctrl-view-back.png';
import FitImage from '../../assets/image/viewer/3d-ctrl-fit.png';
import ZoomImage from '../../assets/image/viewer/3d-ctrl-zoom.png';
import PanImage from '../../assets/image/viewer/3d-ctrl-pan.png';
import RotateImage from '../../assets/image/viewer/3d-ctrl-rotate.png';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import QuestionPopper from '../popper/QuestionPopper';
import QEXP from '../../common/QuestionExp.json';
import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const ToggleButton = styled(MuiToggleButton)({
  '&': {
    color: '#ff8f22',
    backgroundColor: '#ffffff',
  },
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
  '&.Mui-selected': {
    color: '#ffffff',
    backgroundColor: '#ff8f22',
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#FFBB7C',
  },
});

type captureType = 'VIEW' | 'PDF' | 'NORMAL';
type cameraModeType = 'camera' | 'model';

export type gavadonFuncHandle = {
  getCapture(captureSize?: captureType): Promise<string>;
  getCamera(): MyIF.camera;
  boudingBoxView(boundingBox: MyIF.boundingBoxInfo, boundingBatchNumber: number): Promise<void>;
  getRotate(): MyIF.rotate;
  setRotate(rotate: MyIF.rotate): void;
  setCamera(rotate: MyIF.rotate): Promise<void>;
  setCameraMode(mode: cameraModeType): void;
  reloadGround(): void;
  getFrImageData(limitDegree: number): {
    imageSize: number;
    sliceNum: number;
  };
  showBasePlate(x: number, y: number): void;
  showBasePlateInit(): void;
  getBoudingBoxSize(): { x: number; y: number; z: number };
  switchToggle(on: boolean): void;
  getBoundingBoxSizeWithEuler(euler: { x: number, y: number, z: number }): { x: number; y: number; z: number };
};

const MODEL_COLOR = '#6088C6';
const BOX_COLOR = '#515356';
const COLOR_BASE_PLATE = '#696969';
const COLOR_BACK_GROUND = '#f5f5f5';
const GAVADON_LIGHT = {
  ambient: {
    intensity: 1,
  },
  directional: {
    intensity: 0.7,
  },
  hemisphere: {
    intensity: 0.5,
  },
};

interface Props {
  initializeSidebar?: () => void;
  RecommendCalcExecute?: () => Promise<void>;
  handleCalcCancel?: () => void;
  isCalcCancel?: boolean;
  recommendCalcRunning?: boolean;
  eulerFlg?: boolean;
  boundingBox?: MyIF.boundingBoxInfo;
  boundingBatchNumber?: number;
  modelBlob?: Blob;
  modelViewInfo?: MyIF.modelViewInfo;
  setModelViewInfo?: React.Dispatch<React.SetStateAction<MyIF.modelViewInfo | undefined>>;
  GetCalcTime?(): void;
  GetBasePlateSize?():
    | {
        x: number;
        y: number;
      }
    | undefined;
  SetCheckBoudingBoxSize?(): void;
  setRecommendCalcRunning?:React.Dispatch<React.SetStateAction< boolean >>;
}

const GavadonComponent = React.forwardRef<gavadonFuncHandle, Props>(
  (
    {
      eulerFlg,
      boundingBox,
      boundingBatchNumber,
      modelViewInfo,
      setModelViewInfo,
      GetCalcTime,
      GetBasePlateSize,
      SetCheckBoudingBoxSize,
      RecommendCalcExecute,
      initializeSidebar,
      handleCalcCancel,
      isCalcCancel,
      setRecommendCalcRunning
    },
    ref
  ) => {
    const { setProgressShow } = useProgress();
    const app = React.useRef<HTMLDivElement>(document.createElement('div'));
    const viewer = React.useRef<gavadonView>(null!);

    /**
     * Blob読み込み
     * @param blob
     * @returns
     */
    const loadBuffer = (blob: Blob): Promise<FileReader> => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(blob);
      return new Promise((resolve) => {
        reader.onload = () => resolve(reader);
      });
    };

    /**
     * モデル表示
     * @param modelBlob
     * @returns
     */
    const showModel = async (modelBlob: Blob, rotate?: MyIF.rotate): Promise<void> => {
      console.log('モデル表示');
      viewer.current.setModel();
      const reader = await loadBuffer(modelBlob);
      const res = reader.result ? reader.result : '';
      const promise = viewer.current.setModel(res, 'stl', {
        showAxis: true,
        showBasePlate: false,
        isGround: true,
        defaultRotation: rotate,
        basePlateColor: COLOR_BASE_PLATE,
        opacity: 0.7,
        modelColor: {
          face: MODEL_COLOR,
        },
        lights: GAVADON_LIGHT,
        metalness: 0.5,
      });
      return promise;
    };

    /**
     * サポート材追加
     * @param supportBlob
     */
    const showSupportModel = async (supportBlob: Blob): Promise<void> => {
      console.log('サポートモデル表示', supportBlob);
      viewer.current.clearSubModels();
      const reader = await loadBuffer(supportBlob);
      const res = reader.result ? reader.result : '';
      const promise = viewer.current.addModel(res, 'msgpack', {
        modelColor: {
          edge: '#F6ADC6',
        },
      });
      return promise;
    };

    /**
     * オンパーツサポート材追加
     * @param supportBlob
     */
    const showOnPartsSupportModel = async (blob: Blob): Promise<void> => {
      console.log('オンパーツサポートモデル表示', blob);
      const reader = await loadBuffer(blob);
      const res = reader.result ? reader.result : '';
      const promise = viewer.current.addModel(res, 'msgpack', {
        modelColor: {
          edge: '#960CFF',
        },
      });
      return promise;
    };

    /**
     * 断面積急増個所ハイライト
     */
    const showCrossSection = async (blob: Blob): Promise<void> => {
      console.log('断面積急増個所ハイライト', blob);
      const reader = await loadBuffer(blob);
      const res = reader.result ? reader.result : '';
      const promise = viewer.current.addModel(res, 'msgpack');
      return promise;
    };

    /**
     * バウンディングボックス表示
     * @param boundingBox
     * @param boundingBatchNumber
     * @returns
     */
    const showBoudingBox = (
      boundingBox: MyIF.boundingBoxInfo,
      boundingBatchNumber: number
    ): Promise<void> => {
      console.log('バウンディングボックス表示', boundingBox, boundingBatchNumber);
      viewer.current.setModel();
      if (boundingBox.batchList.length > boundingBatchNumber) {
        interface gavadonBb {
          inputData?: any;
          dataType?: string;
          coords: { x: number; y: number; z: number; rot?: number }[];
          size?: { x: number; y: number; z: number };
          opacity?: number;
          roughness?: number;
          metalness?: number;
          modelColor?: {
            edge?: string;
            face?: string;
          };
          modelRotation?: { x: number; y: number; z: number };
          boxColor?: string;
        }
        let input: gavadonBb[] = [];
        boundingBox.batchList[boundingBatchNumber].modelList.forEach((element) => {
          input.push({
            inputData: element.modelData,
            dataType: 'stl',
            coords: element.coords,
            size: element.size,
            boxColor: BOX_COLOR,
            modelColor: {
              face: MODEL_COLOR,
            },
            opacity: 0.7,
            metalness: 0.5,
            modelRotation: element.rotate,
          });
        });
        const promise = viewer.current.setMultiModels(input, {
          showAxis: true,
          showBasePlate: true,
          isGround: true,
          basePlateColor: COLOR_BASE_PLATE,
          boxOpacity: 0.3,
          lights: GAVADON_LIGHT,
          basePlateWidth: boundingBox.basePlateX,
          basePlateHeight: boundingBox.basePlateY,
          isFitCorner: true,
          modifiedValue: 1,
        });
        return promise;
      } else {
        return new Promise((resolve) => resolve());
      }
    };

    /**
     * 初期表示
     */
    React.useEffect(() => {
      console.log('★effect');
      if (app.current.getElementsByTagName('canvas').length === 0) {
        console.log('★gavadon instance create');
        viewer.current = new gavadonView(app.current);
        viewer.current.setBackGroundColor(COLOR_BACK_GROUND);
        viewer.current.animationLoop();
      }
      return () => {
        console.log('★removeEffect');
        viewer.current.dispose();
        (viewer.current as gavadonView | undefined) = undefined;
        console.log(viewer.current);
      };
    }, []);

    /**
     * modelBlobに変化あったときはモデル表示
     */
    React.useEffect(() => {
      (async () => {
        if (modelViewInfo?.modelBlob) {
          console.log('Gavadon modelViewInfo', modelViewInfo);
          setProgressShow(true);
          await showModel(modelViewInfo.modelBlob, modelViewInfo.rotate);

          // サポート材あれば表示
          if (modelViewInfo?.supportBlob) {
            await showSupportModel(modelViewInfo.supportBlob);
          }

          // オンパーツサポート材あれば表示
          if (modelViewInfo.onPartsSupportBlob) {
            await showOnPartsSupportModel(modelViewInfo.onPartsSupportBlob);
          }

          if (modelViewInfo.crossSectionalNgBlob) {
            await showCrossSection(modelViewInfo.crossSectionalNgBlob);
          }

          // ベースプレートを表示
          if (GetBasePlateSize) {
            const size = GetBasePlateSize();
            if (size) {
              console.log('showBasePlate');
              viewer.current.showBasePlate(true, COLOR_BASE_PLATE, size.x, size.y);
            } else {
              const size = viewer.current.getBoundingBoxSize();
              viewer.current.showBasePlate(true, COLOR_BASE_PLATE, size.x * 1.2, size.y * 1.2);
            }
          }

          viewer.current.rotationFromEuler(modelViewInfo?.rotate);

          GetCalcTime && GetCalcTime();

          SetCheckBoudingBoxSize && SetCheckBoudingBoxSize();

          setProgressShow(false);
          firstRef.current = false;
        } else {
          viewer.current.setModel();
        }
      })();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelViewInfo?.modelBlob, modelViewInfo?.supportBlob, modelViewInfo?.crossSectionalNgBlob]);

    /**
     * boudingBoxに変化あったときはバウンディングボックス表示
     */
    React.useEffect(() => {
      if (boundingBox && boundingBatchNumber !== undefined) {
        setProgressShow(true);
        showBoudingBox(boundingBox, boundingBatchNumber).then(() => {
          setProgressShow(false);
        });
      } else {
        viewer.current.setModel();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boundingBox, boundingBatchNumber]);

    /**
     * 外部参照用
     */
    React.useImperativeHandle(ref, () => ({
      /**
       * キャプチャ取得
       * @param captureType
       * @returns
       */
      async getCapture(captureSize: captureType = 'NORMAL') {
        const WIDTH = captureSize === 'VIEW' ? 600 : captureSize === 'PDF' ? 800 : 100;
        const HEIGHT = captureSize === 'VIEW' ? 450 : captureSize === 'PDF' ? 600 : 75;
        let capture: string = '';
        console.log('キャプチャ取得');
        capture = await viewer.current.captureScreen(WIDTH, HEIGHT);
        return capture;
      },

      getCamera(): MyIF.camera {
        return viewer.current.getModelRotationAxis();
      },

      async boudingBoxView(
        boundingBox: MyIF.boundingBoxInfo,
        boundingBatchNumber: number
      ): Promise<void> {
        return showBoudingBox(boundingBox, boundingBatchNumber);
      },

      getRotate(): MyIF.rotate {
        return viewer.current.getModelRotation();
      },

      setRotate(rotate: MyIF.rotate) {
        viewer.current.rotationFromEuler(rotate);
        viewer.current.showBasePlate(true, COLOR_BASE_PLATE);
        setRotateX(RadianToDegree(rotate.x).toString());
        setRotateY(RadianToDegree(rotate.y).toString());
        setRotateZ(RadianToDegree(rotate.z).toString());
        viewer.current.clearSubModels();
      },

      setCamera(rotate: MyIF.rotate) {
        const promise = viewer.current.fromEuler(rotate);
        console.log(promise);
        return promise;
      },

      setCameraMode(mode: cameraModeType) {
        setCameraMode(mode);
      },

      reloadGround() {
        console.log('reloadGround');
        viewer.current.reloadGround();
      },

      getFrImageData(limitDegree: number) {
        const result = viewer.current.getFRImageData(0.5, limitDegree, 0.4);
        return {
          imageSize: result.imageSide,
          sliceNum: result.numOfImages,
        };
      },

      showBasePlate(x: number, y: number) {
        console.log('ベースプレート更新', x, y);
        viewer.current.showBasePlate(true, COLOR_BASE_PLATE, x, y);
      },

      showBasePlateInit() {
        const size = viewer.current.getBoundingBoxSize();
        viewer.current.showBasePlate(true, COLOR_BASE_PLATE, size.x * 1.2, size.y * 1.2);
      },

      getBoudingBoxSize() {
        return viewer.current.getBoundingBoxSize();
      },

      getBoundingBoxSizeWithEuler(euler: { x: number, y: number, z: number }) {
        return viewer.current.getBoundingBoxSizeWithEuler(euler);
      },

      switchToggle(on: boolean) {
        setIsOn(on);
        setChecked(on);
      }
    }));

    /**
     * メニュー画面クリック
     * @param event
     */
    const handlePanelClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      switch (event.currentTarget.id) {
        case 'panelTop':
          viewer.current.fromTop();
          break;
        case 'panelBottom':
          viewer.current.fromBottom();
          break;
        case 'panelLeft':
          viewer.current.fromLeft();
          break;
        case 'panelRight':
          viewer.current.fromRight();
          break;
        case 'panelFront':
          viewer.current.fromFront();
          break;
        case 'panelBack':
          viewer.current.fromBack();
          break;
        case 'panelFit':
          viewer.current.fit();
          break;
        case 'panelRotate':
          setMove('rotate');
          break;
        case 'panelPan':
          setMove('pan');
          break;
        case 'panelZoom':
          setMove('zoom');
          break;
      }
    };

    const DegreeToRadian = (degree: number) => {
      return degree * (Math.PI / 180);
    };
    const RadianToDegree = (radian: number) => {
      return Math.round((radian * (180 / Math.PI) + Number.EPSILON) * 100) / 100;
    };

    // 画面に表示する角度
    const [rotateX, setRotateX] = React.useState(
      modelViewInfo?.rotate.x ? RadianToDegree(modelViewInfo?.rotate.x).toString() : '0'
    );
    const [rotateY, setRotateY] = React.useState(
      modelViewInfo?.rotate.y ? RadianToDegree(modelViewInfo?.rotate.y).toString() : '0'
    );
    const [rotateZ, setRotateZ] = React.useState(
      modelViewInfo?.rotate.z ? RadianToDegree(modelViewInfo?.rotate.z).toString() : '0'
    );

    React.useEffect(() => {
      setRotateX(
        modelViewInfo?.rotate.x ? RadianToDegree(modelViewInfo?.rotate.x).toString() : '0'
      );
      setRotateY(
        modelViewInfo?.rotate.y ? RadianToDegree(modelViewInfo?.rotate.y).toString() : '0'
      );
      setRotateZ(
        modelViewInfo?.rotate.z ? RadianToDegree(modelViewInfo?.rotate.z).toString() : '0'
      );
    }, [modelViewInfo?.rotate.x]);

    /**
     * 回転情報更新＆サポート材消すかチェック
     */
    const updateRotate = () => {
      const rotate = viewer.current.getModelRotation();
      if (modelViewInfo && JSON.stringify(rotate) !== JSON.stringify(modelViewInfo.rotate)) {
        // 差異がある場合は動いているため、サポート材を消す
        viewer.current.clearSubModels();

        // 回転情報を更新
        if (setModelViewInfo) {
          setModelViewInfo({ ...modelViewInfo, rotate: rotate });
        }
      }
    };

    // 角度のテキストボックスに変化あった場合はモデルを回転
    //ここにサポート材最小モデルの角度を取得した時の条件追加
    const ChangeRotation = (dir: string, value: string) => {
      if (setRecommendCalcRunning) {
        setRecommendCalcRunning(false);
      }
      let numValue = Number(value);
      if (Number.isFinite(numValue)) {
        let rotate = {
          x: DegreeToRadian(Number(rotateX)),
          y: DegreeToRadian(Number(rotateY)),
          z: DegreeToRadian(Number(rotateZ)),
        };
        switch (dir) {
          case 'X':
            setRotateX(value);
            rotate.x = DegreeToRadian(numValue);
            break;
          case 'Y':
            setRotateY(value);
            rotate.y = DegreeToRadian(numValue);
            break;
          case 'Z':
            setRotateZ(value);
            rotate.z = DegreeToRadian(numValue);
            break;
        }

        viewer.current.rotationFromEuler(rotate);
        viewer.current.showBasePlate(true, COLOR_BASE_PLATE);
        SetCheckBoudingBoxSize && SetCheckBoudingBoxSize();

        // 回転情報を更新＆サポート材消すかチェック
        updateRotate();
      }
    };

    // 角度に変化あった場合は、サポート材を消すかチェック
    const firstRef = React.useRef(true);
    React.useEffect(() => {
      if (firstRef.current) {
        return;
      }
      updateRotate();
      // eslint-disable-next-line react-hooks/exhaustive-deps

      // //トグルスイッチON状態で値入力するとOFF
      // if(isOn === true && firstRef){
      //   toggleSwitch();
      //   setIsOn(false);
      // };
    }, [rotateX, rotateY, rotateZ]);

    // カメラモード
    const [cameraMode, setCameraMode] = React.useState<cameraModeType>('camera');

    // 左クリック動作モード
    type moveType = 'rotate' | 'pan' | 'zoom';
    const [move, setMove] = React.useState<moveType>('rotate');

    React.useEffect(() => {
      console.log('cameraMode', cameraMode);
      viewer.current.changeControl(cameraMode, move);
    }, [cameraMode, move]);

    React.useEffect(() => {
      console.log(modelViewInfo?.image);
    }, [modelViewInfo?.image]);

    const ChangeRotationValue = (
      setFunc: React.Dispatch<React.SetStateAction<string>>,
      value: string
    ) => {
      if (value === '') {
        setFunc(value);
      } else {
        if (Number.isFinite(Number(value))) {
          // 数値に変換できる場合は、小数点以下の桁数チェック
          const commaIndex = value.indexOf('.') + 1;
          if (commaIndex === 0) {
            setFunc(value);
          } else {
            if (value.substring(commaIndex).length <= 2) {
              console.log(value);
              setFunc(value);
            }
          }
        } else {
          // 数値に変換できない場合は、有効文字かチェック
          const invalidChars = ['-', '+'];
          if (invalidChars.indexOf(value) !== -1) {
            setFunc(value);
          }
        }
      }
    };

    const [isMouseDown, setIsMouseDown] = React.useState(false);

    const [isDisplayed, setIsDisplayed] = useState(false);

    const handleAreaClick = () => {
      setIsDisplayed(true);
    };

    //トグルスイッチ
    const [switchChecked, setChecked] = useState(false);
    const toggleSwitch = () => {
      setChecked(!switchChecked);
    };

    const [isOn, setIsOn] = useState(false);

    const handleToggle = () => {
      // OFFに切り替え
      if (isOn && switchChecked) {
        console.log('スイッチOFF');
        toggleSwitch();
        setIsOn(false);
        if(initializeSidebar){
          initializeSidebar();
          viewer.current.clearSubModels();
        }
      } else{
         // ONに切り替え
         console.log('スイッチON');
         if(RecommendCalcExecute){
          RecommendCalcExecute();
         }
         toggleSwitch();
         setIsOn(true);
        }
      }

    return (
      <React.Fragment>
        {modelViewInfo && (modelViewInfo.modelBlob === undefined && modelViewInfo.image) === '' && (
          <Skeleton variant='rectangular' animation={false} width={'100%'} height={'100%'} />
        )}
        <img
          src={modelViewInfo && modelViewInfo.image}
          alt=''
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 100,
            visibility:
              modelViewInfo && modelViewInfo.modelBlob === undefined && modelViewInfo.image !== ''
                ? 'visible'
                : 'hidden',
          }}
        />
        <div
          onClick={handleAreaClick}
          id='gavadonApp'
          ref={app}
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 1,
            visibility:
              modelViewInfo && modelViewInfo.modelBlob !== undefined
                ? 'visible'
                : boundingBox
                ? 'visible'
                : 'hidden',
          }}
          onMouseDown={() => {
            setIsMouseDown(true);
          }}
          onMouseMove={() => {
            if (isMouseDown && cameraMode === 'model') {
              if (setRecommendCalcRunning) {
                setRecommendCalcRunning(false);
              }
              //トグルスイッチON状態で値入力するとOFF
              if(isOn === true && firstRef){
                toggleSwitch();
                setIsOn(false);
              };
              const rotate = viewer.current.getModelRotation();
              setRotateX(RadianToDegree(rotate.x).toString());
              setRotateY(RadianToDegree(rotate.y).toString());
              setRotateZ(RadianToDegree(rotate.z).toString());
            }
          }}
          onMouseUp={() => {
            viewer.current.showBasePlate(true, COLOR_BASE_PLATE);
            SetCheckBoudingBoxSize && SetCheckBoudingBoxSize();
            setIsMouseDown(false);
          }}
          onMouseLeave={() => {
            setIsMouseDown(false);
          }}
        >
          {/* メニュー */}
          <div id='panel' className='GavadonAreaUI'>
            {cameraMode === 'camera' && (
              <React.Fragment>
                <button id='panelRotate' onClick={handlePanelClick}>
                  <img src={RotateImage} alt='rotate' />
                </button>
                <button id='panelPan' onClick={handlePanelClick}>
                  <img src={PanImage} alt='pan' />
                </button>
                <button id='panelZoom' onClick={handlePanelClick}>
                  <img src={ZoomImage} alt='zoom' />
                </button>
                <button id='panelFit' onClick={handlePanelClick} style={{ marginRight: '10px' }}>
                  <img src={FitImage} alt='fit' />
                </button>
              </React.Fragment>
            )}
            <button id='panelFront' onClick={handlePanelClick}>
              <img src={FrontImage} alt='front' />
            </button>
            <button id='panelBack' onClick={handlePanelClick}>
              <img src={BackImage} alt='back' />
            </button>
            <button id='panelLeft' onClick={handlePanelClick}>
              <img src={LeftImage} alt='left' />
            </button>
            <button id='panelRight' onClick={handlePanelClick}>
              <img src={RightImage} alt='right' />
            </button>
            <button id='panelTop' onClick={handlePanelClick}>
              <img src={TopImage} alt='top' />
            </button>
            <button id='panelBottom' onClick={handlePanelClick}>
              <img src={ButtomImage} alt='buttom' />
            </button>
          </div>

          {/* オイラー角 */}
          {eulerFlg && (
            <div id='euler' className='GavadonEulerUI'>
              <div className='Title'>マニュアル設定</div>
              <ToggleButtonGroup
                color='primary'
                value={cameraMode}
                exclusive
                onChange={(event, newValue) => {
                  setCameraMode(cameraMode === 'camera' ? 'model' : 'camera');
                }}
                aria-label='Platform'
                size='small'
                style={{ height: '30px', marginLeft: 'auto', marginRight: 'auto' }}
              >
                <ToggleButton value='model'>モデル</ToggleButton>
                <ToggleButton value='camera'>カメラ</ToggleButton>
              </ToggleButtonGroup>

              <div className='Title' style={{ marginTop: '5px' }}>
                回転角度
              </div>
              <div className='Rotate'>
                <div>X軸</div>
                <input
                  type={'text'}
                  value={rotateX}
                  onChange={(event) => {
                    ChangeRotationValue(setRotateX, event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      ChangeRotation('X', event.currentTarget.value);
                      if(isOn === true && firstRef){
                        toggleSwitch();
                        setIsOn(false);
                      };
                    }
                  }}
                  onBlur={(event) => {
                    ChangeRotation('X', event.currentTarget.value);
                  }}
                />
              </div>
              <div className='Rotate'>
                <div>Y軸</div>
                <input
                  type={'text'}
                  value={rotateY}
                  onChange={(event) => {
                    ChangeRotationValue(setRotateY, event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      ChangeRotation('Y', event.currentTarget.value);
                      if(isOn === true && firstRef){
                        toggleSwitch();
                        setIsOn(false);
                      };
                    }
                  }}
                  onBlur={(event) => {
                    ChangeRotation('Y', event.currentTarget.value);
                  }}
                />
              </div>
              <div className='Rotate'>
                <div>Z軸</div>
                <input
                  type={'text'}
                  value={rotateZ}
                  onChange={(event) => {
                    ChangeRotationValue(setRotateZ, event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      ChangeRotation('Z', event.currentTarget.value);
                      if(isOn === true && firstRef){
                        toggleSwitch();
                        setIsOn(false);
                      };
                    }
                  }}
                  onBlur={(event) => {
                    ChangeRotation('Z', event.currentTarget.value);
                  }}
                />
              </div>
            </div>
          )}

           {/* おすすめ姿勢の簡易設定 */}
           {eulerFlg && isDisplayed &&(
               <div className="GavadonEulerUI1 show">おすすめ姿勢の<br/>簡易設定
                <QuestionPopper value={QEXP.ModelNumberEdit['MOLDING_SIMPLESETUP']} />
                  <div className='themeSwitch'>
                    <div>
                      OFF
                      <FormControlLabel
                        control={<Switch checked={switchChecked} onChange={handleToggle} />}
                        label="" style={{ marginLeft: '3px', marginRight: '3px' }}/>
                      ON
                    </div>
                 </div>
              </div>
           )}
        </div>
      </React.Fragment>
    );
  }
);

export default React.memo(GavadonComponent);
