import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import CustomPopper from './CustomPopper';

interface Props {
  value: string;
}

const QuestionPopper: React.FC<Props> = React.memo(({ value }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      <button onClick={handleClick} className='formHelpInfomationLink button-style'></button>
      <CustomPopper anchorEl={anchorEl} handleClose={handleClose}>
        <div className='formHelpInfomationDetail2'>
          {value
            .replaceAll('<br>', '\n')
            .replaceAll('\\n', '\n')
            .split('\n')
            .map((tmpValue, index) => {
              return (
                <p key={index} className='formHelpInfomationDetailPara'>
                  {tmpValue}
                </p>
              );
            })}
        </div>
      </CustomPopper>
    </span>
  );
});

export default QuestionPopper;
