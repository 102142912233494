import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useDropzone } from 'react-dropzone';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import UploadImage from '../../assets/image/common/illust_cloud_blue.svg';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';
import { FileController } from '../../common/FileController';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { useNavigate } from 'react-router-dom';
import { CachedS3ContentProvider } from '../../common/CachedS3ContentProvider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

interface Props {
  readOnly?: boolean;
  fileName: string | undefined;
  setFileName: React.Dispatch<React.SetStateAction<string | undefined>>;
  modelViewInfo: MyIF.modelViewInfo;
  setModelViewInfo?: (model: MyIF.modelViewInfo) => void;
  setThreeDFileSize?: React.Dispatch<React.SetStateAction<number | undefined>>;
  initData?: () => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ThreeDModelInputArea: React.FC<Props> = React.memo(
  ({
    readOnly,
    fileName,
    setFileName,
    modelViewInfo,
    setModelViewInfo,
    setThreeDFileSize,
    initData,
  }) => {
    const { setAlertShow, setMessageList } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();

    // ファイルドラッグ＆ドロップ
    const onDrop = React.useCallback((files: File[]) => {
      if (files.length > 0) {
        if (files[0].size > 157286400) {
          setMessageList([
            'ファイルサイズオーバーです。',
            'ファイルサイズは150MB以下でアップロードしてください。',
          ]);
          setAlertShow(true);
        } else {
          setFileName(undefined);
          initData && initData();

          const modelFIle = files[0];
          if (modelFIle.name.split('.').pop()?.toLowerCase() === 'stl') {
            fileUploadSTL(modelFIle);
          } else {
            fileUploadSTEP(modelFIle);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { fileRejections, getRootProps, getInputProps } = useDropzone({
      onDrop,
      maxFiles: 1,
      multiple: false,
      accept: { 'application/stl': ['.stl', '.stp', '.step'] },
    });

    /**
     * Gzip圧縮
     * @param fileData
     * @param fileName
     * @returns
     */
    const getGzipFile = async (fileData: File, fileName: string) => {
      return await new FileController().GetCompressionGzip(fileData, fileName);
    };

    /**
     * アップロードURL取得
     * @param uploadFile
     * @returns
     */
    const getFileUploadURL = async (uploadFile: File, fileName: string) => {
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetThreeDUploadURL(fileName, uploadFile.size);
      if (!result) {
        console.error('アップロードURL取得失敗');
        setSnackbarOpen(false);
      }
      return result;
    };

    /**
     * ファイルアップロード
     * @param uploadFile
     */
    const putFileUpload = async (url: string, uploadFile: File) => {
      const result = await new FileController().FileUploadURL(
        url,
        'application/gzip',
        uploadFile,
        'gzip',
        setProgress
      );
      if (!result) {
        console.error('ファイルアップロード失敗');
        setSnackbarOpen(false);
      }
      return result;
    };

    /**
     * ファイルアップロード完了通知
     * @param id
     * @returns
     */
    const postUploadFinished = async (id: string) => {
      return await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).PostThreeDDataUploadFinished(id);
    };

    /**
     * Frが終わったかポーリングしてチェック
     * @param id
     * @returns
     */
    const checkPolling = async (id: string) => {
      const sleep = () => new Promise((resolve) => setTimeout(resolve, 5000));

      let loopCount = 1;
      while (true) {
        console.log('Create ViewerModel check count:', loopCount);

        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetThreeDModelPath(id);
        if (result !== null) {
          if (result.modelPath !== '') {
            return result;
          } else {
            await sleep();
            loopCount += 1;
          }
        } else {
          setSnackbarOpen(false);
          break;
        }
      }
    };

    /**
     * STEPファイルアップロード
     * @param uploadFile S
     */
    const fileUploadSTEP = async (uploadFile: File) => {
      setProgressShow(true);

      // アップロード中であることを示すダイアログを表示
      setProgress(0);
      setSnackbarOpen(true);

      // 圧縮
      const gzip = await getGzipFile(uploadFile, 'model.step');
      // アップロードURL取得
      const resultUrl = await getFileUploadURL(gzip, uploadFile.name);
      if (resultUrl) {
        const threeDID = resultUrl.id;
        const uploadURL = resultUrl.url;

        // アップロード
        const resultUpload = await putFileUpload(uploadURL, gzip);
        if (resultUpload) {
          // アップロード完了通知
          const resultFinished = await postUploadFinished(threeDID);
          if (resultFinished) {
            // Frが終わったかチェック
            const resultModelPath = await checkPolling(threeDID);
            if (resultModelPath) {
              if (setModelViewInfo) {
                // 後方互換性で、拡張子がgzの場合のみ解凍
                const blob = await new CachedS3ContentProvider().GetContent(
                  resultModelPath.bucket,
                  resultModelPath.modelPath
                );

                setModelViewInfo({
                  ...modelViewInfo,
                  modelMpacKey: resultModelPath.modelPath,
                  bucketName: resultModelPath.bucket,
                  modelBlob: blob,
                  id: threeDID,
                });
              }
              setFileName(uploadFile.name);
              setThreeDFileSize && setThreeDFileSize(uploadFile.size);
            }
          }
        }
      }
      setProgressShow(false);
    };

    /**
     * STLファイルアップロード
     * @param uploadFile
     */
    const fileUploadSTL = async (uploadFile: File) => {
      // ビューワーに表示
      if (setModelViewInfo) {
        setModelViewInfo({
          ...modelViewInfo,
          id: '',
          modelBlob: uploadFile,
          rotate: { x: 0, y: 0, z: 0 },
        });
      }
      setFileName(uploadFile.name);
      setThreeDFileSize && setThreeDFileSize(uploadFile.size);

      // アップロード中であることを示すダイアログを表示
      setProgress(0);
      setSnackbarOpen(true);

      // 圧縮
      const gzip = await getGzipFile(uploadFile, 'model.stl');
      console.log('gzip', gzip);
      // アップロードURL取得
      const resultUrl = await getFileUploadURL(gzip, uploadFile.name);
      if (resultUrl) {
        const threeDID = resultUrl.id;
        const uploadURL = resultUrl.url;

        // アップロード
        const resultUpload = await putFileUpload(uploadURL, gzip);
        if (resultUpload) {
          // アップロード完了通知
          const resultFinished = await postUploadFinished(threeDID);
          if (resultFinished) {
            // Frが終わったかチェック
            const resultModelPath = await checkPolling(threeDID);
            if (resultModelPath) {
              if (setModelViewInfo) {
                setModelViewInfo({
                  ...modelViewInfo,
                  modelBlob: uploadFile,
                  modelMpacKey: resultModelPath.modelPath,
                  bucketName: resultModelPath.bucket,
                  id: threeDID,
                  rotate: { x: 0, y: 0, z: 0 },
                });
              }

              // DBに保存
              await new CachedS3ContentProvider().SetContent(
                resultModelPath.bucket + '/' + resultModelPath.modelPath,
                uploadFile
              );
            }
          }
        }
      }
    };

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const SnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };

    const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
      <div key={index}>
        {errors.map((_, index) => (
          <p
            key={index}
            className='modelNoSpecEditFormFileUploadedLinkDisalbed'
            style={{ color: 'red' }}
          >
            {file.name}は許可された拡張子ではありません
          </p>
        ))}
      </div>
    ));

    return (
      <section className='modelNoSpecEditFormAccordionContent'>
        <input id='toggle2' type='checkbox' className='modelNoSpecEditFormToggle'></input>
        <label className='modelNoSpecEditFormToggleLabel' htmlFor='toggle2'>
          3Dデータ（必須）
          <QuestionPopper value={QEXP.ModelNumberEdit['3D_FILE']} />
        </label>
        <div className='modelNoSpecEditFormToggleContentBlock'>
          <div className='modelNoSpecEditFormToggleContent'>
            <div className='modelNoSpecEditFormFileUploadBlock'>
              {readOnly !== true && (
                <div className='modelNoSpecEditFormFileUploadArea' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className='modelNoSpecEditFormFileUploadAreaPara1'>
                    <img
                      src={UploadImage}
                      alt=''
                      className='modelNoSpecEditFormFileUploadAreaImage'
                    ></img>
                    <br />
                    ファイルをドラッグ＆ドロップしてください
                  </p>
                  <p className='modelNoSpecEditFormFileUploadAreaPara2'>
                    ファイルを選択する場合は
                    <button className='modelNoSpecEditFormFileUploadAreaLink button-style-trans'>
                      クリック
                    </button>
                    してください
                  </p>
                </div>
              )}

              {!readOnly && (
                <div
                  style={{
                    fontSize: '12px',
                    color: 'red',
                  }}
                >
                  ※単位はmmである必要があります。<br></br>
                  　アップロードできるファイル形式はSTL、またはSTEPで、<br></br>
                  　150MBまでアップロード可能です。<br></br>
                  　アップロード時間はお客さまの通信環境により変化します。
                </div>
              )}
              {fileRejectionItems}
              {readOnly ? (
                <div className='modelNoSpecEditFormFileUploadedName'>
                  <div className='modelNoSpecEditFormFileUploadedLinkDisalbed'>{fileName}</div>
                </div>
              ) : (
                <div className='modelNoSpecEditFormFileUploadedName'>
                  {setModelViewInfo && fileName && (
                    <button
                      className='modelNoSpecEditFormFileUploadedLink button-style'
                      onClick={() => {
                        setModelViewInfo({
                          ...modelViewInfo,
                          modelMpacKey: '',
                          modelBlob: undefined,
                        });
                        setFileName(undefined);
                      }}
                    >
                      {fileName}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={modelViewInfo.id ? 3000 : undefined}
          onClose={SnackbarClose}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 20000 }}
        >
          {modelViewInfo.id ? (
            <Alert severity='success' onClose={SnackbarClose} sx={{ backgroundColor: '#ff8f22' }}>
              3Dファイルのアップロードが完了しました。
            </Alert>
          ) : progress === 100 && fileName?.split('.').pop()?.toLowerCase() !== 'stl' ? (
            <Alert severity='info' onClose={SnackbarClose} sx={{ backgroundColor: '#1c6ecd' }}>
              <div>3Dモデルを読み込み中です...</div>
            </Alert>
          ) : (
            <Alert severity='info' onClose={SnackbarClose} sx={{ backgroundColor: '#1c6ecd' }}>
              <div>
                3Dファイルをアップロード中です...{progress}%
                <LinearProgress variant='determinate' value={progress} color='inherit' />
              </div>
            </Alert>
          )}
        </Snackbar>
      </section>
    );
  }
);

export default ThreeDModelInputArea;
