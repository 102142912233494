import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';

interface Props {
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalMessage: React.FC<Props> = React.memo(({ show, setIsModalOpen }) => {
  if (show) {
    return (
      <section className='modalWindowWrapper modalRemindMessage'>
        <div className='modalWindowBlock'>
          <div className='modalWindowInner modalWindowNoHeader'>
            <div className='modalWindowContents'>
              <h1 className='modalRemindMessageTitle'>サムネイル保存</h1>
              <p className='modalRemindMessagePara'>
                現在の3D Viewerの向きでサムネイル画像が保存されますがよろしいですか？
              </p>

              <div className='modalRemindMessageBtnBlock'>
                <button
                  className='btnWhiteBaseGray'
                  type='button'
                  name='button'
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  キャンセル
                </button>
                <button className='btnBlue' type='button' name='button'>
                  保存する
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
});

export default ModalMessage;
