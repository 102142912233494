import * as React from 'react';
import { APIController } from '../../common/API';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useProgress } from '../../provider/progress/ProgressProvider';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientLogin.css';
import { viewLoginType } from '../../pages/users/Login';
import { useNavigate } from 'react-router-dom';
import QuestionPopper from '../popper/QuestionPopper';
import QEXP from '../../common/QuestionExp.json';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewLoginType>>;
  mailAddress: string;
  setMailAddress: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordReset: React.FC<Props> = React.memo(
  ({ setViewSelect, mailAddress, setMailAddress }) => {
    const navigate = useNavigate();
    const { setAlertShow, setMessageList } = useAlert();
    const { setProgressShow } = useProgress();
    const [lastName, setLastName] = React.useState('');
    const [firstName, setFirstName] = React.useState('');

    const sendOneTime = async () => {
      setProgressShow(true);
      const checkResult = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).PutOneTimePassword(lastName, firstName, mailAddress);
      if (checkResult) {
        if (checkResult.length > 0) {
          setMessageList(checkResult);
          setAlertShow(true);
        } else {
          setViewSelect('OneTime');
        }
      }
      setProgressShow(false);
    };

    function resetCaretPosition() {
      const container = document.createElement('div'); // 編集不可の要素を作成
      container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
      container.style.opacity = '0'; // 編集不可の要素を非表示にする
      container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
      container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
      container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
      document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

      container.focus(); // 編集不可の要素にフォーカスを移動する
      window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

      // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
      container.style.display = 'none';
      container.style.display = '';

      container.blur(); // 編集不可の要素からフォーカスを外す
      document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
    }

    React.useEffect(() => {
      // resetCaretPosition関数を使用して、キャレット位置をリセットする
      resetCaretPosition();
    }, []);

    return (
      <div className='mainContentsBlock noFlowBar'>
        <div className='clientRegistrationForm'>
          <div className='pageTitleBlock'>
            <div className='pageTitleInner'>
              <div className='pageTitleWithBtn'>
                <h1 className='pageTitle'>パスワードのリセット</h1>
              </div>
            </div>
          </div>

          <div className='pageTitleDetailTextInput'>
            <p className='pageTitleDetailText' style={{ marginBottom: '10px' }}>
              ご登録いただいたメールアドレスを入力してください
              <QuestionPopper value={QEXP.PasswordReset.MAIL_ADDRESS} />
            </p>
            <div className='passwodResetInputBlock'>
              <input
                className='passwodResetInputTelMail'
                type='text'
                id='email'
                name='email'
                placeholder='メールアドレス'
                defaultValue={mailAddress}
                onChange={(event) => {
                  setMailAddress(event.target.value);
                }}
              />
            </div>
            <p className='pageTitleDetailText' style={{ marginBottom: '10px' }}>
              氏名を入力してください
              <QuestionPopper value={QEXP.PasswordReset.NAME} />
            </p>
            <div className='passwodResetInputBlock'>
              <input
                className='passwodResetInput'
                type='text'
                name='lname'
                id='lname'
                defaultValue={lastName}
                placeholder='氏名（姓）'
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
              <input
                className='passwodResetInput'
                type='text'
                name='fname'
                id='fname'
                defaultValue={firstName}
                placeholder='氏名（名）'
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </div>
          </div>

          <div className='clientpasswordResetButtonArea'>
            <button
              className='btnCancel'
              type='button'
              name='button'
              onClick={() => {
                setViewSelect('Login');
              }}
            >
              キャンセル
            </button>
            <button
              className='btnOrangeCommit'
              type='button'
              name='button'
              onClick={() => {
                sendOneTime();
              }}
            >
              ワンタイムパスワード発行
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default PasswordReset;
