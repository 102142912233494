import * as THREE from 'three';
import * as constant from '../Constant';
import { Calculate } from './Calculate';
import { GavadonData } from './GavadonData';
import { Simplex } from './Simplex';

//stlファイルから変換されたtessalted対応
//※むしろ、vertices情報しか得ていない
export class Face extends Simplex{

    //フィールド変数
    
    //コンストラクタ
    constructor(data: GavadonData, option?: {color?: THREE.ColorRepresentation}){
        const calculate = new Calculate();
        //頂点データ
        const vertices = calculate.roundArrayOf(data.face_data.points, constant.DIGIT);
        //データの準備
        let faceList = new Array();
        let collisions = new Array();

        //進捗確認ログ用
        // const total = data.face_data.faces.length;
        // console.log('triangles:', total);

        var attribute = new THREE.BufferAttribute(new Float32Array(vertices), 3);
        const geometry = new THREE.BufferGeometry().setAttribute('position', attribute);
        geometry.computeVertexNormals();
        // geometry.computeBoundingBox();
        // geometry.center();
        // if(!!geometry.boundingSphere) geometry.translate(geometry.boundingSphere.center.x, geometry.boundingSphere.center.y, geometry.boundingSphere.center.z);
        const allFaces = new THREE.Mesh(
            geometry,
            new THREE.MeshStandardMaterial({
                color: option?.color === undefined ? constant.FACE_COLOR : option.color,
                visible: false,
                roughness: 0.5,
                metalness: 0.5,
                // side: THREE.DoubleSide
            })
        );
        //内部透過用
        // const allFaces = new THREE.Mesh(
        //     geometry,
        //     new THREE.MeshStandardMaterial({ color: constant.FACE_COLOR, visible: false, roughness: 0.5, metalness: 0.5, flatShading: true, transparent: true, opacity: 0.8 })
        // );
        if (allFaces.material instanceof THREE.Material) allFaces.material.needsUpdate = true;
        
        //フィールド変数への代入
        super(
            vertices,
            faceList,
            allFaces,
            collisions
        );

        geometry.dispose();
    }

    //メソッド
}