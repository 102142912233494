import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import ConfirmMessage from '../message/ConfirmMessage';
import { Alert, AlertTitle, IconButton, Popover } from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import FsLightbox from 'fslightbox-react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import QuestionPopper from '../popper/QuestionPopper';
import QEXP from '../../common/QuestionExp.json';

interface Props {
  readOnly?: boolean;
  materialTypeList: MyIF.materialType[];
  selectModelCondition: MyIF.selectModelingConditionType;
  setSelectModelCondition: React.Dispatch<React.SetStateAction<MyIF.selectModelingConditionType>>;
  threeDFileName: string | undefined;
  CalcExecute?: () => Promise<void>;
  calcFlg: boolean;
  modelViewInfo?: MyIF.modelViewInfo;
  setIsModalModelingPosi?: React.Dispatch<React.SetStateAction<boolean>>;
  modelSizeOverMessage?: string | undefined;
  modelSizeOverImg?: string;
}

const ModelingConditionsArea: React.FC<Props> = React.memo(
  ({
    readOnly,
    materialTypeList,
    selectModelCondition,
    setSelectModelCondition,
    threeDFileName,
    CalcExecute,
    calcFlg,
    modelViewInfo,
    setIsModalModelingPosi,
    modelSizeOverMessage,
    modelSizeOverImg,
  }) => {
    // 試算クリアモーダル制御
    const [isCalcClearMessage, setIsCalcClearMessage] = React.useState(false);
    const handleChangeCancel = () => {
      setIsCalcClearMessage(false);
    };
    const handleChangeOK = () => {
      setIsCalcClearMessage(false);
      setIsSelectModal(true);
    };

    const [isSelectModal, setIsSelectModal] = React.useState(false);

    interface Data {
      modelingMaterialId: number;
      materialValue: string;
      sozaiValue: string;
      size: string;
      printer: string;
      method: string;
    }

    function createData(
      modelingMaterialId: number,
      materialValue: string,
      sozaiValue: string,
      size: string,
      printer: string,
      method: string
    ): Data {
      return {
        modelingMaterialId,
        materialValue,
        sozaiValue,
        size,
        printer,
        method,
      };
    }
    const [selectRowsData, setSelectRowsData] = React.useState<Data[]>([]);

    React.useEffect(() => {
      let tmpList: Data[] = [];
      for (let ii = 0; ii < materialTypeList.length; ii++) {
        for (let jj = 0; jj < materialTypeList[ii].methodList.length; jj++) {
          for (let kk = 0; kk < materialTypeList[ii].methodList[jj].printerList.length; kk++) {
            tmpList.push(
              createData(
                materialTypeList[ii].modelingMaterialId,
                materialTypeList[ii].materialValue,
                materialTypeList[ii].sozaiValue,
                materialTypeList[ii].methodList[jj].printerList[kk].maximumBuildSizeWidth +
                  '×' +
                  materialTypeList[ii].methodList[jj].printerList[kk].maximumBuildSizeDepth +
                  '×' +
                  materialTypeList[ii].methodList[jj].printerList[kk].maximumBuildSizeHeight,
                materialTypeList[ii].methodList[jj].printerList[kk].name,
                materialTypeList[ii].methodList[jj].methodValue
              )
            );
          }
        }
      }
      setSelectRowsData(tmpList);
    }, [materialTypeList]);

    const SelectTable = () => {
      interface HeadCell {
        id: keyof Data;
        label: string;
        width: string;
      }
      const headCells: readonly HeadCell[] = [
        {
          id: 'sozaiValue',
          label: '素材',
          width: '100px',
        },
        {
          id: 'materialValue',
          label: '造形材料',
          width: '100px',
        },
        {
          id: 'size',
          label: '最大造形サイズ\n（W×D×Hmm）',
          width: '150px',
        },
        {
          id: 'printer',
          label: 'プリンタ',
          width: '100px',
        },
        {
          id: 'method',
          label: '造形方式',
          width: '200px',
        },
      ];

      return (
        <div style={{ margin: '10px' }}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        style={{
                          backgroundColor: '#e6eef8',
                          borderBottom: '2px solid #1c6ecd',
                          fontWeight: 'bold',
                          whiteSpace: 'pre-line',
                        }}
                        // width={headCell.width}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                    <TableCell
                      style={{ backgroundColor: '#e6eef8', borderBottom: '2px solid #1c6ecd' }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectRowsData.map((row, index) => {
                    const labelId = 'select-table-' + index;
                    return (
                      <TableRow
                        key={labelId}
                        className='tableRow'
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectModelCondition({
                            modelingMaterialId: row.modelingMaterialId,
                            materialValue: row.materialValue,
                            methodValue: row.method,
                            printerValue: row.printer,
                          });
                          setIsSelectModal(false);
                        }}
                      >
                        <TableCell>{row.materialValue}</TableCell>
                        <TableCell>{row.sozaiValue}</TableCell>
                        <TableCell>{row.size}</TableCell>
                        <TableCell>{row.printer}</TableCell>
                        <TableCell>{row.method}</TableCell>
                        <TableCell>
                          <button
                            className='modelNumberListTableItemCheckbtn'
                            onClick={() => {
                              setSelectModelCondition({
                                modelingMaterialId: row.modelingMaterialId,
                                materialValue: row.materialValue,
                                methodValue: row.method,
                                printerValue: row.printer,
                              });
                              setIsSelectModal(false);
                            }}
                          >
                            選択
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      );
    };

    const SelectDialog = () => (
      <Dialog open={isSelectModal} maxWidth={'lg'}>
        <DialogContent>
          <SelectTable></SelectTable>
        </DialogContent>
        <DialogActions>
          <div className='modalRemindMessageBtnBlock' style={{ marginTop: '-20px' }}>
            <button
              className='btnWhiteBaseGray'
              type='button'
              name='button'
              onClick={() => {
                setIsSelectModal(false);
              }}
            >
              閉じる
            </button>
          </div>
        </DialogActions>
      </Dialog>
    );

    const [questionMaterialList, setQuestionMaterialList] = React.useState<
      {
        value: string;
        memo: string;
        image: string;
      }[]
    >([]);

    React.useEffect(() => {
      let keyList: { [key: string]: boolean } = {};
      const viewList: {
        value: string;
        memo: string;
        image: string;
      }[] = [];
      materialTypeList.forEach((materialType) => {
        const value = materialType.materialValue + '\n' + materialType.sozaiValue;
        if (!(value in keyList)) {
          viewList.push({
            value,
            memo: materialType.materialMemo,
            image: materialType.materialImage,
          });
          keyList[value] = true;
        }
      });
      setQuestionMaterialList(viewList);
    }, [materialTypeList]);

    const [isShowQuestionMaterial, setIsShowQuestionMaterial] = React.useState(false);
    const QuestionMaterialModalImage = ({ imagePath }: { imagePath: string }) => {
      const [isLightBoxOpen, setIsLightBoxOpen] = React.useState(false);

      return (
        <React.Fragment>
          <img
            style={{ width: '200px', cursor: 'pointer' }}
            src={`${process.env.PUBLIC_URL}/MaterialImage/${imagePath}`}
            alt=''
            onClick={() => setIsLightBoxOpen(!isLightBoxOpen)}
          ></img>
          <FsLightbox
            toggler={isLightBoxOpen}
            type='image'
            sources={[`${process.env.PUBLIC_URL}/MaterialImage/${imagePath}`]}
          ></FsLightbox>
        </React.Fragment>
      );
    };
    const QuestionMaterialModal = () => (
      <Dialog open={isShowQuestionMaterial} maxWidth={'lg'} fullWidth>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead className='tableHeader'>
              <TableRow>
                <TableCell align='center' colSpan={3}>
                  材料のご紹介
                  <button
                    className='modalCloseButton'
                    onClick={() => {
                      setIsShowQuestionMaterial(false);
                    }}
                  >
                    閉じる
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='tableBodyBorder'>
              {questionMaterialList.map((quotationMaterial, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      align='center'
                      sx={{ whiteSpace: 'pre-line', wordBreak: 'keep-all' }}
                    >
                      {quotationMaterial.value}
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'pre-line' }}>
                      {quotationMaterial.memo.replaceAll('\\n', '\n')}
                    </TableCell>
                    <TableCell>
                      <QuestionMaterialModalImage imagePath={quotationMaterial.image} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    );

    const QuestionMethodPrinterTableData: {
      method?: string;
      methodRow?: number;
      printer1: string;
      printer2: string;
      size1: string;
      size2: string;
      size3: string;
      comment: string;
      group?: string;
      groupRow?: number;
      material: { value?: string; row?: number }[];
    }[] = [
      {
        method: 'PBF（粉末床溶融結合）',
        methodRow: 3,
        printer1: 'BLT',
        printer2: 'S400',
        size1: '407×257×440',
        size2: '400×250×400',
        size3: '0.02～0.1',
        comment: '金属の大型造形が可能',
        group: '金属',
        groupRow: 3,
        material: [
          { value: 'ステンレス鋼\nSUS316L', row: 3 },
          { value: 'アルミニウム合金\nAlSi10Mg', row: 3 },
          { value: '' },
          { value: '' },
        ],
      },
      {
        printer1: 'BLT',
        printer2: 'S320',
        size1: '257×257×425',
        size2: '250×250×400',
        size3: '0.02～0.1',
        comment: '高さが必要な造形に対応',
        material: [{}, {}, { value: '' }, { value: '' }],
      },
      {
        printer1: 'EOS',
        printer2: 'M290',
        size1: '250×250×290',
        size2: '247x247x280',
        size3: '0.02～0.05',
        comment: '金属プリンターのスタンダード',
        material: [
          {},
          {},
          { value: 'マルエージング鋼\nMS1' },
          { value: 'ニッケル基超合金\n（インコネル） IN718' },
        ],
      },
      {
        method: 'FDM（熱融解積層法）',
        printer1: 'BigRep',
        printer2: 'STUDIO G2',
        size1: '500×1000×500',
        size2: '480×980×495',
        size3: '0.05～0.1',
        comment: '高強度かつ大型の造形が可能',
        group: '樹脂',
        groupRow: 3,
        material: [
          { value: 'PLA樹脂\nBigRep PLA' },
          { value: 'PLA樹脂\nBigRep PRO-HT' },
          {
            value: 'カーボンコンポジット樹脂\nBigRep HI-TEMP CF',
          },
          { value: '' },
        ],
      },
      {
        method: 'SLA（光造形方式）',
        printer1: 'Zrapid',
        printer2: 'iSLA880',
        size1: '800×800×550',
        size2: '720×720×300',
        size3: '0.07~0.12',
        comment: '高精細で滑らかな表面仕上がり、\n模型など外観を重視する用途に',
        material: [
          { value: 'アクリル系紫外線硬化樹脂\nABSライク（白）' },
          { value: 'アクリル系紫外線硬化樹脂\nABSライク（透明）' },
          { value: '' },
          { value: '' },
        ],
      },
      {
        method: 'SLS（粉末積層焼結）',
        printer1: 'Sinterit',
        printer2: 'LISA PRO',
        size1: '160×110×250',
        size2: '130×90×250',
        size3: '0.075～0.175',
        comment: 'サポートレスで高精度かつ安価な造形が可能',
        material: [
          { value: 'ナイロン12\nPA12（グレー）' },
          { value: '' },
          { value: '' },
          { value: '' },
        ],
      },
    ];
    const [isShowQuestionMethod, setIsShowQuestionMethod] = React.useState(false);
    const QuestionMethodModal = () => (
      <Dialog open={isShowQuestionMethod} maxWidth={'lg'} fullWidth>
        <div style={{ fontWeight: 'bold', marginTop: '35px', marginLeft: '20px' }}>
          {'造形方式×3Dプリンター×材料　組み合わせ対応表'}
        </div>
        <button
          className='modalCloseButton'
          onClick={() => {
            setIsShowQuestionMethod(false);
          }}
        >
          閉じる
        </button>
        <div style={{ margin: '0px 20px 20px 20px' }}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead className='tableHeader tableHeaderBorder'>
                <TableRow>
                  <TableCell align='center' style={{ backgroundColor: '#E2EFDA' }}>
                    造形方式
                  </TableCell>
                  <TableCell align='center'>{'プリンター\nメーカー名'}</TableCell>
                  <TableCell align='center'>{'プリンター\n機種名'}</TableCell>
                  <TableCell align='center'>造形ステージサイズ（W×D×Hmm）</TableCell>
                  <TableCell align='center'>
                    実際の造形で使用可能な造形容積(W×D×Ｈmm)特異点を除く
                  </TableCell>
                  <TableCell align='center'>積層厚(mm)</TableCell>
                  <TableCell align='center'>特長</TableCell>
                  <TableCell align='center' style={{ backgroundColor: '#FFF2CC' }}>
                    材料分類
                  </TableCell>
                  <TableCell colSpan={4} align='center' style={{ backgroundColor: '#FFF2CC' }}>
                    使用可能材料
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='tableBodyBorder tableBodyBorderWordBreak'>
                {QuestionMethodPrinterTableData.map((tableData, index) => {
                  return (
                    <TableRow key={index}>
                      {tableData.method && (
                        <TableCell rowSpan={tableData.methodRow}>{tableData.method}</TableCell>
                      )}
                      <TableCell>{tableData.printer1}</TableCell>
                      <TableCell>{tableData.printer2}</TableCell>
                      <TableCell>{tableData.size1}</TableCell>
                      <TableCell>{tableData.size2}</TableCell>
                      <TableCell>{tableData.size3}</TableCell>
                      <TableCell>{tableData.comment}</TableCell>
                      {tableData.group && (
                        <TableCell rowSpan={tableData.groupRow}>{tableData.group}</TableCell>
                      )}
                      {tableData.material.map((material, index2) => {
                        return (
                          material.value !== undefined && (
                            <TableCell key={index2} rowSpan={material.row} align='center'>
                              {material.value}
                            </TableCell>
                          )
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Dialog>
    );

    const [isShowQuestionPrinter, setIsShowQuestionPrinter] = React.useState(false);
    const QuestionMethodPrinter = () => (
      <Dialog open={isShowQuestionPrinter} maxWidth={'lg'} fullWidth>
        <div style={{ fontWeight: 'bold', marginTop: '35px', marginLeft: '20px' }}>
          {'3Dプリンター× 造形方式×材料　組み合わせ対応表'}
        </div>
        <button
          className='modalCloseButton'
          onClick={() => {
            setIsShowQuestionPrinter(false);
          }}
        >
          閉じる
        </button>
        <div style={{ margin: '0px 20px 20px 20px' }}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead className='tableHeader tableHeaderBorder'>
                <TableRow>
                  <TableCell align='center'>{'プリンター\nメーカー名'}</TableCell>
                  <TableCell align='center'>{'プリンター\n機種名'}</TableCell>
                  <TableCell align='center'>造形ステージサイズ（W×D×Hmm）</TableCell>
                  <TableCell align='center'>
                    実際の造形で使用可能な造形容積(W×D×Ｈmm)特異点を除く
                  </TableCell>
                  <TableCell align='center'>積層厚(mm)</TableCell>
                  <TableCell align='center'>特長</TableCell>
                  <TableCell align='center' style={{ backgroundColor: '#E2EFDA' }}>
                    造形方式
                  </TableCell>
                  <TableCell align='center' style={{ backgroundColor: '#FFF2CC' }}>
                    材料分類
                  </TableCell>
                  <TableCell colSpan={4} align='center' style={{ backgroundColor: '#FFF2CC' }}>
                    使用可能材料
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='tableBodyBorder tableBodyBorderWordBreak'>
                {QuestionMethodPrinterTableData.map((tableData, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{tableData.printer1}</TableCell>
                      <TableCell>{tableData.printer2}</TableCell>
                      <TableCell>{tableData.size1}</TableCell>
                      <TableCell>{tableData.size2}</TableCell>
                      <TableCell>{tableData.size3}</TableCell>
                      <TableCell>{tableData.comment}</TableCell>
                      {tableData.method && (
                        <TableCell rowSpan={tableData.methodRow}>{tableData.method}</TableCell>
                      )}
                      {tableData.group && (
                        <TableCell rowSpan={tableData.groupRow}>{tableData.group}</TableCell>
                      )}
                      {tableData.material.map((material, index2) => {
                        return (
                          material.value !== undefined && (
                            <TableCell key={index2} rowSpan={material.row} align='center'>
                              {material.value}
                            </TableCell>
                          )
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Dialog>
    );

    const NowSelectTable = () => (
      <div style={{ margin: '0px 10px 0px 10px' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className='tableHeaderNoWeight'>
              <TableRow>
                <TableCell>
                  造形材料
                  <button
                    className='formHelpInfomationLink button-style formHelpInfomationLinkBlink'
                    onClick={() => setIsShowQuestionMaterial(true)}
                  ></button>
                  <QuestionMaterialModal />
                </TableCell>
                <TableCell>
                  造形方式
                  <button
                    className='formHelpInfomationLink button-style formHelpInfomationLinkBlink'
                    onClick={() => setIsShowQuestionMethod(true)}
                  ></button>
                  <QuestionMethodModal />
                </TableCell>
                <TableCell sx={{ minWidth: '180px' }}>
                  プリンタ
                  <button
                    className='formHelpInfomationLink button-style formHelpInfomationLinkBlink'
                    onClick={() => setIsShowQuestionPrinter(true)}
                  ></button>
                  <QuestionMethodPrinter />
                  <p className='tableHeaderComment'>
                    金属材料のSDSシートは
                    <a
                      href='https://www.orixrentec.jp/3dprinter/download/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      こちら
                    </a>
                  </p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {selectModelCondition.materialValue === ''
                    ? '未選択'
                    : selectModelCondition.materialValue}
                </TableCell>
                <TableCell>
                  {selectModelCondition.methodValue === ''
                    ? '未選択'
                    : selectModelCondition.methodValue}
                </TableCell>
                <TableCell>
                  {selectModelCondition.printerValue === ''
                    ? '未選択'
                    : selectModelCondition.printerValue}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const popoverHandleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const popoverOpen = Boolean(anchorEl);

    return (
      <section className='modelNoSpecEditFormAccordionContent'>
        <input id='toggle3' type='checkbox' className='modelNoSpecEditFormToggle'></input>
        <label className='modelNoSpecEditFormToggleLabel' htmlFor='toggle3'>
          造形条件（必須）
        </label>

        <div className='modelNoSpecEditFormToggleContentBlock'>
          <div style={{ textAlign: 'center', display: 'flex', flexFlow: 'column' }}>
            {!readOnly && (
              <button
                type='button'
                name='button'
                className='btnOrangeCommitWhite'
                onClick={() => {
                  if (calcFlg) {
                    setIsSelectModal(true);
                  } else {
                    setIsCalcClearMessage(true);
                  }
                }}
                disabled={modelViewInfo?.modelBlob ? false : true}
                style={{ margin: '0px auto 5px', minWidth: '160px' }}
              >
                造形条件選択
              </button>
            )}

            {/* 現在選択中テーブル */}
            <NowSelectTable></NowSelectTable>

            {setIsModalModelingPosi && (
              <button
                type='button'
                name='button'
                className='btnOrangeEdit'
                onClick={() => {
                  setIsModalModelingPosi(true);
                }}
                disabled={
                  modelViewInfo?.modelBlob
                    ? selectModelCondition.printerValue === ''
                      ? true
                      : false
                    : true
                }
                style={{ margin: '10px auto 10px', minWidth: '160px' }}
              >
                「マニュアル姿勢設定」<br />
                造形姿勢編集
              </button>
            )}

            {CalcExecute && (
              <div>
                <button
                  className='btnOrangeCommitWhite_molding'
                  type='button'
                  name='button'
                  onClick={() => {
                    CalcExecute();
                  }}
                  disabled={
                    modelViewInfo?.id
                      ? selectModelCondition.printerValue === ''
                        ? true
                        : false ||
                          !calcFlg ||
                          (modelSizeOverMessage !== '' && modelSizeOverMessage !== undefined)
                      : true
                  }
                  style={{ margin: '0px auto 0px 20px', minWidth: '160px' }}
                >
                 「オート姿勢設定」<br />
                  造形姿勢<br />
                  お任せで試算
                </button>
                <QuestionPopper value={QEXP.ModelNumberEdit['MOLDING_POSTURE']} />
              </div>
            )}
          </div>
          {modelSizeOverMessage && (
            <Alert severity='error' style={{ marginTop: '10px' }}>
              <AlertTitle>Error</AlertTitle>
              {modelSizeOverMessage}
              <span>
                <IconButton onClick={popoverHandleClick}>
                  <QuestionMarkIcon className='questionIcon' fontSize='small' />
                </IconButton>
                <Popover
                  open={popoverOpen}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  onClose={() => setAnchorEl(null)}
                  PaperProps={{
                    style: {
                      padding: '10px',
                    },
                  }}
                >
                  <div style={{ display: 'flex', flexFlow: 'column' }}>
                    <img
                      style={{ maxWidth: '400px', margin: '0 auto' }}
                      src={modelSizeOverImg}
                    ></img>
                    <div style={{ fontSize: '12px', whiteSpace: 'pre-line' }}>
                      {
                        '3Dモデルのサイズが造形可能範囲を超えています。\n図の外枠が3Dプリンターのカタログ表記にある造形チャンバーの大きさ、\n内枠が実際のプリントで使える範囲です。'
                      }
                    </div>
                  </div>
                </Popover>
              </span>
            </Alert>
          )}
        </div>

        <ConfirmMessage
          show={isCalcClearMessage}
          title={'注意'}
          message={'試算結果がクリアされますが、よろしいですか？'}
          handleOK={handleChangeOK}
          handleCancel={handleChangeCancel}
        ></ConfirmMessage>

        <SelectDialog></SelectDialog>
      </section>
    );
  }
);

export default ModelingConditionsArea;
