import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import ModelingResultAreaTitle from './ModelingResultAreaTitle';
import ModelingResultAreaContent from './ModelingResultAreaContent';

interface Props {
  isModelingJudge: number;
  modelingResultList: MyIF.modelingResultType[] | undefined;
}

const ModelingResultArea: React.FC<Props> = React.memo(
  ({ isModelingJudge, modelingResultList }) => {
    console.log('isModelingJudge', isModelingJudge);
    return (
      <section className='modelNoSpecEditFormAccordionContent'>
        <input id='toggle4' type='checkbox' className='modelNoSpecEditFormToggle'></input>
        <label className='modelNoSpecEditFormToggleLabel' htmlFor='toggle4'>
          造形可否判定結果
          <ModelingResultAreaTitle isModelingJudge={isModelingJudge} />
        </label>
        <ModelingResultAreaContent modelingResultList={modelingResultList} />
      </section>
    );
  }
);

export default ModelingResultArea;
