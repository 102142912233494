import * as React from 'react';
import { useAlert } from '../../provider/alert/AlertProvider';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import Modal from '@mui/material/Modal';

const AlertMessage = React.memo(() => {
  const { alertShow, setAlertShow, messageList, setMessageList } = useAlert();
  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={alertShow}
    >
      <div className='modalWindowBlock modalRemindMessage' style={{ minWidth: '500px' }}>
        <div className='modalWindowInner modalWindowNoHeader'>
          <div className='modalWindowContents'>
            <h1 className='modalRemindMessageTitle'>エラー</h1>
            {messageList.length === 0 ? (
              <p className='modalRemindMessagePara'>エラーが発生しました。やり直してください。</p>
            ) : (
              <div className='modalRemindMessagePara'>
                {messageList.map((value, index) => {
                  return (
                    <div key={index}>
                      {value}
                      <br />
                    </div>
                  );
                })}
              </div>
            )}

            <div className='modalRemindMessageBtnBlock'>
              <button
                className='btnWhiteBaseGray'
                type='button'
                name='button'
                onClick={() => {
                  setAlertShow(false);
                  setMessageList([]);
                }}
              >
                閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default AlertMessage;
