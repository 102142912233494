import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import { Modal } from '@mui/material';

interface Props {
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  memo: string;
  image: string;
  subTitle: string;
}

const ModalMaterialDetails: React.FC<Props> = React.memo(
  ({ show, setIsModalOpen, title, memo, image, subTitle }) => {
    return (
      <Modal open={show}>
        <section className='modalWindowWrapper modelNoSpecEditModalMaterialDetail'>
          <div className='modalWindowBlock'>
            <div className='modalWindowInner modalWindowHasHeader'>
              <div className='modalWindowHeader'>
                <h1 className='modalWindowHeaderTitle'>材料詳細</h1>
                <button
                  className='modalWindowHeaderCloseLink button-style'
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  閉じる
                </button>
              </div>
              <div className='modalWindowContents'>
                <div className='modelNoSpecEditModalMaterialDetailContents'>
                  <h2 className='modelNoSpecEditModalMaterialDetailTitle'>{title}</h2>
                  <div className='modelNoSpecEditModalMaterialDetailBlock'>
                    <div
                      className='modelNoSpecEditModalMaterialDetailImage'
                      style={{ width: '60%' }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/MaterialImage/${image}`}
                        alt=''
                        style={{ width: '100%' }}
                      ></img>
                    </div>
                    <div className='modelNoSpecEditModalMaterialDetails'>
                      <h3 className='modelNoSpecEditModalMaterialDetailSubTitle'>{subTitle}</h3>
                      <p className='modelNoSpecEditModalMaterialDetailPara'>
                        {memo.replaceAll('\\n', '\n')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    );
  }
);

export default ModalMaterialDetails;
