import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import QEXP from '../../common/QuestionExp.json';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientRegistrationForm.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import FlowArea from '../flow/FlowArea';
import { viewSelectType } from '../../pages/requestQuotation/RequestQuotation';
import CommonContactEditTable from '../contactPerson/ContactEditTable';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { useNavigate } from 'react-router-dom';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewSelectType>>;
  userInfo: MyIF.userInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<MyIF.userInfo>>;
  setContactChangeFlg: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuotationDestEdit: React.FC<Props> = React.memo(
  ({ setViewSelect, userInfo, setUserInfo, setContactChangeFlg }) => {
    const { setAlertShow, setMessageList } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();
    const [tmpUserInfo, setTmpUserInfo] = React.useState<MyIF.userInfo>(userInfo);

    /**
     * ユーザー情報入力チェック
     */
    const checkUserInfo = async () => {
      setProgressShow(true);
      // 入力チェック
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetContactAddressCheck(tmpUserInfo);
      if (result) {
        const checkResult: string[] = result.checkResult;
        console.log(checkResult);
        if (checkResult.length === 0) {
          setUserInfo(tmpUserInfo);
          setContactChangeFlg(true);
          setViewSelect('Main');
        } else {
          setMessageList(checkResult);
          setAlertShow(true);
        }
      }
      setProgressShow(false);
    };

    return (
      <div>
        <FlowArea active={3}></FlowArea>
        <div className='mainContentsBlock' style={{ marginBottom: '20px' }}>
          <div className='clientRegistrationForm'>
            <div className='pageTitleBlock'>
              <div className='pageTitleInner'>
                <div className='pageTitleWithBtn'>
                  <h1 className='pageTitle'>送付先情報変更</h1>
                </div>
              </div>
            </div>

            <div className='pageTitleDetailText'>
              <p>
                下記の連絡先情報は、個別案件に紐づいて一時的に連絡先を変更する場合に追記、編集を行って下さい。
                <br />
                恒久的に連絡先を変更される場合は、画面右上のお客様登録名をクリックし、「プロフィール管理」画面にて編集してください。
                <br />
                <span className='attentionRedText'>※</span>マークは入力必須項目です。
              </p>
            </div>

            <CommonContactEditTable
              userInfo={tmpUserInfo}
              setUserInfo={setTmpUserInfo}
              QEXP={QEXP.QUOTATION}
            ></CommonContactEditTable>

            <div className='clientInformationRegistrationButtonArea'>
              <button
                className='btnBack'
                type='button'
                name='button'
                onClick={() => {
                  setViewSelect('Main');
                }}
              >
                戻る
              </button>
              <button
                className='btnOrangeCommit'
                type='button'
                name='button'
                onClick={() => {
                  checkUserInfo();
                }}
              >
                変更する
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default QuotationDestEdit;
