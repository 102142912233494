import { createContext, useState, useContext, useCallback } from 'react';

export const ProgressContext = createContext(
  {} as {
    progressShow: boolean;
    setProgressShow(bool: boolean): void;
  }
);

export const ProgressProvider = (props: any) => {
  const [progressShow, setProgressShowCore] = useState(false);
  let progressNum = 0;
  function setProgressShow(bool: boolean) {
    if (bool === true) {
      progressNum = progressNum + 1;
    } else {
      progressNum = progressNum - 1;
    }
    if (progressNum === 0) {
      setProgressShowCore(false);
    } else if (progressNum > 0) {
      setProgressShowCore(true);
    } else {
      console.error('プログレスリング整合性不正');
    }
  }

  return (
    <ProgressContext.Provider value={{ progressShow, setProgressShow }}>
      {props.children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => useContext(ProgressContext);
