import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/quotationRequest.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import { Dialog } from '@mui/material';

interface Props {
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalSafeHref: React.FC<Props> = React.memo(({ show, setIsModalOpen }) => {
  return <Dialog open={show} maxWidth={'lg'}>
    <div className='modalWindowHeader'>
      <button
        className='modalWindowHeaderCloseLink button-style'
        onClick={() => {
          setIsModalOpen(false);
        }}
      >
        閉じる
      </button>
    </div>
    <div style={{ margin: 24, textAlign: 'center' }}>
      <p>
        3D-FABsのご利用の際に機密保持契約の締結が必要な場合は
        <br />
        下記「お問い合わせ」にて、その旨ご連絡ください
        <br />
        担当者から個別にご連絡いたします
        <br />
        <a href='https://www.orixrentec.jp/3dprinter/inquiry/?category=5' target='_blank' rel='noopener noreferrer'
           style={{ color: '#1c6ecd', fontWeight: 'bold' }}>
          オリックス・レンテック | お問い合わせ | オリックス・レンテックの3Dプリンター事業 | ORIX Rentec Corporation
        </a>
      </p>
    </div>
  </Dialog>;
});

export default ModalSafeHref;