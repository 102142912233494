import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FooterContent from '../../components/footer/Footer';
import HeaderNoLogin from '../../components/header/HeaderNoLogin';
import { useAuth } from '../../provider/cognito/AuthContext';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { APIController } from '../../common/API';
import { useAlert } from '../../provider/alert/AlertProvider';

const UserRegistConfirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setProgressShow } = useProgress();
  const { setAlertShow } = useAlert();
  const { confirmSignUp } = useAuth();
  const [verifyCode, setVerifyCode] = React.useState('');
  const [verifyResult, setVerifyResult] = React.useState('');

  const mailAddress = React.useMemo(() => {
    if (!location.state) return '';
    return location.state.mailAddress as string;
  }, [location.state]);

  const userId = React.useMemo(() => {
    if (!location.state) return undefined;
    return location.state.userId as string;
  }, [location.state]);

  async function verify(code: string) {
    setProgressShow(true);
    try {
      await confirmSignUp(mailAddress, code);
      if (userId) {
        await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).PutUserConfirm(+userId);
      }
      navigate('/UserRegisterComplete');
    } catch (err: any) {
      switch (err.code) {
        case 'CodeMismatchException':
          setVerifyResult('検証コードが違います。');
          break;
      }
    }
    setProgressShow(false);
  }

  function resetCaretPosition() {
    const container = document.createElement('div'); // 編集不可の要素を作成
    container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
    container.style.opacity = '0'; // 編集不可の要素を非表示にする
    container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
    container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
    container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
    document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

    container.focus(); // 編集不可の要素にフォーカスを移動する
    window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

    // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
    container.style.display = 'none';
    container.style.display = '';

    container.blur(); // 編集不可の要素からフォーカスを外す
    document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
  }

  React.useEffect(() => {
    // resetCaretPosition関数を使用して、キャレット位置をリセットする
    resetCaretPosition();
  }, []);

  React.useEffect(() => {
    document.body.style.minWidth = 'auto';
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.minWidth = '1024px';
      document.body.style.overflowX = 'auto';
    };
  }, []);

  return (
    <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
      <div style={{ flex: '1' }}>
        <HeaderNoLogin />
        <div className='mainContentsBlock noFlowBar clientConfirmPageContainer'>
          <div className='clientRegistrationForm'>
            <div className='pageTitleBlock'>
              <div className='pageTitleInner'>
                <div className='pageTitleWithBtn'>
                  <h1 className='pageTitle'>仮登録完了</h1>
                </div>
              </div>
            </div>
            <div className='pageTitleDetailText'>
              <p>
                ご登録ありがとうございます。
                <br />
                ご記入いただいたメールアドレス宛に検証コードを送信致しました。
                <br />
                メールの内容をご確認いただき、検証コードを下記フォームに入力してください。
              </p>
            </div>

            <div className="confirmCodeForm" style={{ display: 'flex' }}>
              <label style={{ marginTop: '8px' }}>検証コード</label>
              <div className="confirmCodeInputContent" style={{ marginLeft: '10px' }}>
                <input
                  onChange={(event) => {
                    setVerifyCode(event.target.value);
                  }}
                ></input>

                <p style={{ color: '#d9534f', fontSize: '14px' }}>{verifyResult}</p>
              </div>
              <button
                style={{ marginLeft: '10px' }}
                className='btnWhiteBaseBlue'
                type='button'
                name='button'
                onClick={() => {
                  verify(verifyCode);
                }}
              >
                検証
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterContent noUI={true}></FooterContent>
    </div>
  );
};

export default UserRegistConfirm;
