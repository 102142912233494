import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';

interface Props {
  readOnly?: boolean;
  trialNumber: string;
  labelName: string;
  setLabelName: React.Dispatch<React.SetStateAction<string>>;
}

const NameArea: React.FC<Props> = React.memo(
  ({ readOnly, trialNumber, labelName, setLabelName }) => {
    return (
      <section className='modelNoSpecEditFormAccordionContent'>
        <input id='toggle1' type='checkbox' className='modelNoSpecEditFormToggle'></input>
        <label className='modelNoSpecEditFormToggleLabel' htmlFor='toggle1'>
          名称
        </label>

        <div className='modelNoSpecEditFormToggleContentBlock'>
          <div className='modelNoSpecEditFormToggleContent'>
            <div className='modelNoSpecEditFormInputBlock'>
              <label htmlFor='form1-1'>
                ラベル名
                <QuestionPopper value={QEXP.TrialCalcEdit.LABEL} />
              </label>
              <input
                type='text'
                name='form1-1'
                id='form1-1'
                defaultValue={labelName}
                onChange={(event) => {
                  setLabelName(event.target.value);
                }}
                disabled={readOnly}
                maxLength={50}
              ></input>
            </div>
          </div>

          <div className='modelNoSpecEditFormToggleContent'>
            <div className='modelNoSpecEditFormInputBlock'>
              <label htmlFor='form1-2'>
                試算番号
                <QuestionPopper value={QEXP.TrialCalcEdit.TRIAL_NUMBER} />
              </label>
              <input type='text' name='form1-2' id='form1-2' disabled value={trialNumber}></input>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default NameArea;
