import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/top.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
interface Props {}

const FAQ: React.FC<Props> = React.memo(() => {
  return (
    <div id='faq'>
      <div className='faqBlock'>
        <div className='faqLeftContents'>
          <h2>FAQ</h2>
          <a
            href='https://www.orixrentec.jp/3dprinter/faq/'
            target='_blank'
            rel='noopener noreferrer'
            className='faqLink'
          >
            その他のご質問
          </a>
        </div>
        <div className='faqRightContents'>
          <section className='faqAccordion'>
            <div className='faqAccordionBlock'>
              <input id='block-01' type='checkbox' className='toggle' />
              <label className='faqLabel' htmlFor='block-01'>
                <h3>
                  <span className='faqQuestion'>Q</span>
                  はじめて3D-FABsを利用したいのですが、どうすればよいですか
                </h3>
              </label>
              <div className='faqContent'>
                <p>
                  会員登録後に利用可能となりますので、トップ画面の「新規会員登録」ボタンをクリックしてお客さま情報を登録後、ログインしてください
                </p>
              </div>
            </div>
            <div className='faqAccordionBlock'>
              <input id='block-02' type='checkbox' className='toggle' />
              <label className='faqLabel' htmlFor='block-02'>
                <h3>
                  <span className='faqQuestion'>Q</span>
                  3D-FABsは法人ではなく個人でも利用できますか
                </h3>
              </label>
              <div className='faqContent'>
                <p>
                  3D-FABsの造形シミュレーションは、個人の方にもご利用いただけます
                  <br />
                  ただし実際の3Dプリントサービスのご注文は、個人の方からお受けすることはできません
                </p>
              </div>
            </div>
            <div className='faqAccordionBlock'>
              <input id='block-03' type='checkbox' className='toggle' />
              <label className='faqLabel' htmlFor='block-03'>
                <h3>
                  <span className='faqQuestion'>Q</span>3D-FABsの利用は有料でしょうか
                </h3>
              </label>
              <div className='faqContent'>
                <p>
                  3D-FABsはどなたでも、無料でご利用いただけます
                </p>
              </div>
            </div>
            <div className='faqAccordionBlock'>
              <input id='block-04' type='checkbox' className='toggle' />
              <label className='faqLabel' htmlFor='block-04'>
                <h3>
                  <span className='faqQuestion'>Q</span>3D-FABsから、3Dプリントの注文は可能ですか
                </h3>
              </label>
              <div className='faqContent'>
                <p>
                  3D-FABsはシミュレーションサービスであり、受発注を行う機能はありません
                  <br />
                  実際に3Dプリントのご注文をいただく際は、オリックス・レンテック株式会社の担当者から正式な
                  <br />
                  お見積もりをお送りし、受発注手続きをおこないます
                  <br />
                  お取引口座の開設には、審査がございます
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
});

export default FAQ;
