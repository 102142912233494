//*****************three.jsと描画空間のセットアップ**************************

//ライブラリの読み込み
import * as THREE from 'three';
import * as constant from './Constant';
// import { tessellatedJson } from './tessellated';
import { ObjectManager } from './Objects/ObjectManager';
import { CameraManager } from './Camera/CameraManager';
import { ModelControls } from './Utility/ModelControls';
import * as utils from 'three/examples/jsm/utils/BufferGeometryUtils';
import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

export class gavadonView {
  private _appElement: HTMLDivElement;

  private _scene = new THREE.Scene();
  private _model: ObjectManager | THREE.Mesh | null;
  private _modelType: 'model' | 'boxes' | 'STLModel' = 'model';
  private _feature: THREE.BufferGeometry | null;
  private _subModels: { name: string, model: ObjectManager }[] = [];
  private _frame = 0;
  private _frameRate = 1;
  private _viewMode = '';
  private _controlMode: { mode: "camera" | "model", type: "rotate" | "pan" | "zoom" } = { mode: "camera", type: "rotate" };
  private _renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
  private _screen = new CameraManager();
  private _hasScreen = false;
  private _aspect = constant.ASPECT;
  private _modelControls: ModelControls;
  private _basePlate: { mesh: THREE.Mesh, isGround: boolean, isFitCorner: boolean, exists: boolean, subModelsGround?: number } = { mesh: new THREE.Mesh(), isGround: false, isFitCorner: false, exists: false };
  private _boundary = { max: 100, width: 200 * constant.ASPECT, height: 200, center: new THREE.Vector3(), ground: 0 };

  constructor(app: HTMLElement) {
    this._appElement = app as HTMLDivElement;
    this._model = new ObjectManager();
    this._feature = new THREE.BufferGeometry();

    // レンダラーの初期化
    this._renderer.setClearColor(constant.BACKGROUND_COLOR, 1.0); // 背景色
    this._renderer.setPixelRatio(window.devicePixelRatio * 0.9);
    this._renderer.setSize(this._appElement.offsetWidth, this._appElement.offsetHeight);

    this._aspect = this._appElement.offsetWidth / this._appElement.offsetHeight;

    // レンダラーをDOMに追加
    this._appElement.appendChild(this._renderer.domElement);

    // マウスが動いたときにイベント
    this._renderer.domElement.addEventListener('pointermove', (event) => {
      if (event) this._frameRate = 1;
    });

    this._modelControls = new ModelControls(new THREE.Mesh(), new THREE.OrthographicCamera(), this._renderer.domElement);
  }

  //モデルを初期呼び出しするAPI
  public setModel(
    inputData?: any, dataType?: string,
    option?: {
      showAxis?: boolean,
      showBasePlate?: boolean,
      isGround?: boolean,
      basePlateColor?: THREE.ColorRepresentation,
      basePlateWidth?: number,
      basePlateHeight?: number,
      isFitCorner?: boolean,
      defaultRotation?: { x: number, y: number, z: number },
      defaultCameraRotation?: { x: number, y: number, z: number },
      opacity?: number,
      roughness?: number,
      metalness?: number,
      modelColor?: {
        edge?: THREE.ColorRepresentation,
        face?: THREE.ColorRepresentation
      },
      lights?: {
        ambient?: { color?: THREE.ColorRepresentation, intensity?: number },
        directional?: { color?: THREE.ColorRepresentation, intensity?: number },
        hemisphere?: { skyColor?: THREE.ColorRepresentation, groundColor?: THREE.ColorRepresentation, intensity?: number }
      }
    }
  ): Promise<void> {
    return new Promise((resolve) => {
      new Promise((resolve) => {
        this.clearScene()
          .then(() => {
            this._scene = new THREE.Scene();

            switch (dataType) {
              case 'stl':
                {
                  const loader = new STLLoader();
                  const geometry = loader.parse(inputData);
                  geometry.computeVertexNormals();
                  geometry.computeBoundingBox();
                  const bb = geometry.boundingBox ?? new THREE.Box3();

                  geometry.center();

                  const material = new THREE.MeshStandardMaterial({
                    color: option?.modelColor?.face === undefined ?
                      constant.FACE_COLOR :
                      option.modelColor.face,
                    visible: true,
                    roughness: 0.5,
                    metalness: 0.5,
                    side: THREE.DoubleSide
                  });

                  this._model = new THREE.Mesh(geometry, material);

                  if (option && option.defaultRotation) this.rotationFromEuler(option.defaultRotation);

                  this._scene.add(this._model);

                  this._modelType = 'STLModel';
                  this._feature = this._model.geometry;

                  if (option) {
                    if (option.metalness !== undefined) material.metalness = option.metalness;
                    if (option.roughness !== undefined) material.roughness = option.roughness;
                  }

                  const radius = bb.min.distanceTo(bb.max) / 2;
                  const resAspect = this._aspect ?? constant.ASPECT;
                  this._boundary = {
                    max: radius + constant.MARGIN,
                    width: (radius * 2 + constant.MARGIN) * resAspect,
                    height: radius * 2 + constant.MARGIN,
                    center: new THREE.Vector3(),
                    ground: bb.min.z
                  };

                  this._model.position.copy(this._boundary.center);
                }
                break;
              default:
                {
                  const materialOption = option?.modelColor ?
                    { edge: { color: option.modelColor.edge }, face: { color: option.modelColor.face } } : undefined;

                  if (inputData) {
                    this._model = new ObjectManager(inputData, dataType, materialOption);
                  }
                  else {
                    this._model = new ObjectManager();
                  }

                  if (option && option.defaultRotation) this.rotationFromEuler(option.defaultRotation);

                  this._model.addTo(this._scene);

                  this._modelType = 'model';
                  this._feature = this._model.face.getViewMesh().geometry;

                  const material = this._model.face.getViewMesh().material;
                  if (material instanceof THREE.MeshStandardMaterial && option) {
                    if (option.metalness !== undefined) material.metalness = option.metalness;
                    if (option.roughness !== undefined) material.roughness = option.roughness;
                  }

                  this._boundary = this._model.boundary(this._aspect);
                  this._model.face.getViewMesh().position.copy(this._boundary.center);
                }
                break;
            }

            //サブモデルのリセット
            this._subModels = [];

            const defaultLight = {
              ambient: { color: 0xffffff, intensity: 0.75 },
              directional: { color: 0xffffff, intensity: 0.75 },
              hemisphere: { skyColor: 0xeeeeff, groundColor: 0x775555, intensity: 0.25 }
            }
            this.setLights(option?.lights ? option?.lights : defaultLight);

            this._viewMode = 'face';
            if (this._model instanceof ObjectManager) this._model.reload(this._viewMode);

            resolve('set model');
          })
      })
        .then((res) => {
          if (res) {
            //カメラ設定
            if (!this._hasScreen) {
              this._hasScreen = true;

              //モデルとベースプレートの大きさで描画範囲(奥行)を決定
              const baseWidth = option?.basePlateWidth ? option.basePlateWidth : this._boundary.max * 2;
              const baseHeight = option?.basePlateHeight ? option.basePlateHeight : this._boundary.max * 2;
              const directionDepth = Math.sqrt(baseWidth * baseWidth + baseHeight * baseHeight) / 2;

              this._screen = new CameraManager(this._renderer.domElement, this._boundary, directionDepth);
              this._screen.addTo(this._scene);

              if (option && option.defaultCameraRotation) this.fromEuler(option.defaultCameraRotation);
              else this.fromEuler({ x: 0, y: 0, z: 0 });

              this._modelControls = this._model && this._model instanceof ObjectManager ?
                new ModelControls(this._model.face.getViewMesh() as THREE.Mesh, this._screen.camera, this._renderer.domElement) :
                (this._model && this._model instanceof THREE.Mesh) ?
                  new ModelControls(this._model, this._screen.camera, this._renderer.domElement) :
                  new ModelControls(new THREE.Mesh(), this._screen.camera, this._renderer.domElement);

              this.changeControl(this._controlMode.mode, this._controlMode.type);
            }

            if (option && option.showAxis) this.showAxis();
            if (option && option.showBasePlate) {
              this.showBasePlate(option.isGround, option.basePlateColor, option.basePlateWidth, option.basePlateHeight, option.isFitCorner);
              this.reloadGround(option.isGround, true);
            }
            if (option && option.opacity !== undefined) this.skeltonFace(option.opacity);

            resolve();
          }
        })
    })
  }

  //stlモデルを初期呼び出しするAPI
  // public setSTLModel(
  //   inputData: any,
  //   option?: {
  //     showAxis?: boolean,
  //     showBasePlate?: boolean,
  //     isGround?: boolean,
  //     basePlateColor?: THREE.ColorRepresentation,
  //     basePlateWidth?: number,
  //     basePlateHeight?: number,
  //     isFitCorner?: boolean,
  //     defaultRotation?: { x: number, y: number, z: number },
  //     defaultCameraRotation?: { x: number, y: number, z: number },
  //     opacity?: number,
  //     roughness?: number,
  //     metalness?: number,
  //     modelColor?: {
  //       edge?: THREE.ColorRepresentation,
  //       face?: THREE.ColorRepresentation
  //     },
  //     lights?: {
  //       ambient?: { color?: THREE.ColorRepresentation, intensity?: number },
  //       directional?: { color?: THREE.ColorRepresentation, intensity?: number },
  //       hemisphere?: { skyColor?: THREE.ColorRepresentation, groundColor?: THREE.ColorRepresentation, intensity?: number }
  //     }
  //   }
  // ): Promise<void> {
  //   return new Promise((resolve) => {
  //     new Promise((resolve) => {
  //       this.clearScene()
  //         .then(() => {
  //           this._scene = new THREE.Scene();

  //           // const materialOption = option?.modelColor ?
  //           //   { edge: { color: option.modelColor.edge }, face: { color: option.modelColor.face } } : undefined;

  //           const loader = new STLLoader();
  //           const geometry = loader.parse(inputData);
  //           geometry.computeVertexNormals();
  //           geometry.computeBoundingBox();
  //           geometry.center();

  //           const material = new THREE.MeshStandardMaterial({
  //               color: option?.modelColor?.face === undefined ?
  //                 constant.FACE_COLOR :
  //                 option.modelColor.face,
  //               visible: true,
  //               roughness: 0.5,
  //               metalness: 0.5,
  //               // side: THREE.DoubleSide
  //           });

  //           this._model = new THREE.Mesh(geometry, material);

  //           if (option && option.defaultRotation) this.rotationFromEuler(option.defaultRotation);

  //           this._scene.add(this._model);

  //           this._modelType = 'STLModel';
  //           this._feature = this._model.geometry;

  //           if (option) {
  //             if (option.metalness !== undefined) material.metalness = option.metalness;
  //             if (option.roughness !== undefined) material.roughness = option.roughness;
  //           }

  //           const bb = geometry.boundingBox ?? new THREE.Box3();
  //           const radius = bb.min.distanceTo(bb.max) / 2;
  //           const resAspect = this._aspect ?? constant.ASPECT;
  //           this._boundary =  {
  //               max: radius + constant.MARGIN,
  //               width: (radius * 2 + constant.MARGIN) * resAspect,
  //               height: radius * 2 + constant.MARGIN,
  //               center: new THREE.Vector3().addVectors(bb.min, bb.max).multiplyScalar(0.5),
  //               ground: bb.min.z
  //           };

  //           this._model.position.copy(this._boundary.center);

  //           //サブモデルのリセット
  //           this._subModels = [];

  //           const defaultLight = {
  //             ambient: { color: 0xffffff, intensity: 0.75 },
  //             directional: { color: 0xffffff, intensity: 0.75 },
  //             hemisphere: { skyColor: 0xeeeeff, groundColor: 0x775555, intensity: 0.25 }
  //           }
  //           this.setLights(option?.lights ? option?.lights : defaultLight);

  //           this._viewMode = 'face';

  //             resolve('set model');
  //           })
  //       })
  //         .then((res) => {
  //           if (res) {
  //             //カメラ設定
  //             if (!this._hasScreen) {
  //               this._hasScreen = true;

  //               //モデルとベースプレートの大きさで描画範囲(奥行)を決定
  //               const baseWidth = option?.basePlateWidth ? option.basePlateWidth : this._boundary.max * 2;
  //               const baseHeight = option?.basePlateHeight ? option.basePlateHeight : this._boundary.max * 2;
  //               const directionDepth = Math.sqrt(baseWidth * baseWidth + baseHeight * baseHeight) / 2;

  //               this._screen = new CameraManager(this._renderer.domElement, this._boundary, directionDepth);
  //               this._screen.addTo(this._scene);

  //               if (option && option.defaultCameraRotation) this.fromEuler(option.defaultCameraRotation);
  //               else this.fromEuler({ x: 0, y: 0, z: 0 });

  //               this._modelControls = this._model instanceof THREE.Mesh ?
  //                 new ModelControls(this._model, this._screen.camera, this._renderer.domElement) :
  //                 new ModelControls(new THREE.Mesh(), this._screen.camera, this._renderer.domElement);

  //               this.changeControl(this._controlMode.mode, this._controlMode.type);
  //             }

  //             if (option && option.showAxis) this.showAxis();
  //             if (option && option.showBasePlate) {
  //               this.showBasePlate(option.isGround, option.basePlateColor, option.basePlateWidth, option.basePlateHeight, option.isFitCorner);
  //               this.reloadGround(option.isGround, true);
  //             }
  //             if (option && option.opacity !== undefined) this.skeltonFace(option.opacity);

  //             resolve();
  //           }
  //         })
  //     })
  //   }

  //背景色の変更
  public setBackGroundColor(color: THREE.ColorRepresentation) {
    this._renderer.setClearColor(color, 1.0);
  }

  //光源の設定
  private setLights(option: {
    ambient?: { color?: THREE.ColorRepresentation, intensity?: number },
    directional?: { color?: THREE.ColorRepresentation, intensity?: number },
    hemisphere?: { skyColor?: THREE.ColorRepresentation, groundColor?: THREE.ColorRepresentation, intensity?: number }
  }) {
    //光源の追加
    if (option.hemisphere) {
      const hemisphere = new THREE.HemisphereLight(option.hemisphere.skyColor, option.hemisphere.groundColor, option.hemisphere.intensity);
      this._scene.add(hemisphere);
    }
    if (option.ambient) {
      const ambientLight = new THREE.AmbientLight(option.ambient.color, option.ambient.intensity);
      this._scene.add(ambientLight);
    }
    if (option.directional) {
      const light = new THREE.DirectionalLight(option.directional.color, option.directional.intensity);
      light.position.set(
        this._boundary.center.x + this._boundary.max * 2,
        this._boundary.center.y - this._boundary.max * 2,
        this._boundary.center.z + this._boundary.max * 2);
      this._scene.add(light);
    }
  }

  public clearScene(): Promise<void> {
    return new Promise((resolve) => {
      const limitCnt = 10;
      let cnt = 0;
      while (this._scene.children.length > 0 && cnt < limitCnt) {
        this._scene.children.forEach((object) => {
          this._scene.remove(object);
          if (object instanceof THREE.Mesh || object instanceof THREE.LineSegments) {
            object.material.dispose();
            object.geometry.dispose();
          }
        })
        this._screen.camera.children.forEach((object) => {
          this._screen.camera.remove(object);
          if (object instanceof THREE.Mesh || object instanceof THREE.LineSegments) {
            object.material.dispose();
            object.geometry.dispose();
          }
        })
        cnt++;
      }
      if (this._model && this._model instanceof ObjectManager) this._model.removeFrom(this._scene);
      this.clearSubModels();
      this._basePlate.exists = false;
      this._hasScreen = false;
      resolve();
    })
  }

  //アプリとレンダラーの破棄
  public dispose() {
    this.clearScene();
    this._model = null;
    this._renderer.dispose();
    this._renderer.domElement.remove();
  }

  //サブモデルの追加
  public addModel(
    inputData?: any,
    dataType?: string,
    option?: {
      modelColor?: {
        edge?: THREE.ColorRepresentation,
        face?: THREE.ColorRepresentation
      },
      rotation?: { x: number, y: number, z: number },
      position?: { x: number, y: number, z: number },
      isGround?: boolean
    },
    modelName?: string
  ): Promise<void> {
    return new Promise((resolve) => {
      if (inputData) {
        const materialOption = option?.modelColor ?
          { edge: { color: option.modelColor.edge }, face: { color: option.modelColor.face } } : undefined;

        const subModel = new ObjectManager(inputData, dataType, materialOption);

        if (option?.rotation) subModel.face.getViewMesh().rotation.set(option.rotation.x, option.rotation.y, option.rotation.z);
        if (option?.position) subModel.face.getViewMesh().position.set(option.position.x, option.position.y, option.position.z);

        const name = modelName ?? 'subModel' + this._subModels.length;
        subModel.addTo(this._scene);
        this._subModels.push({ name: name, model: subModel });

        const ground = subModel.getBoundingBox().min.z;
        if ((!(this._basePlate.subModelsGround)) || (this._basePlate.subModelsGround && ground < this._basePlate.subModelsGround)) { this._basePlate.subModelsGround = ground }

        if (option?.isGround && this._basePlate.exists) { this.showBasePlate(option.isGround); }

        subModel.reload(this._viewMode);
        resolve();
      }
    })
  }

  //サブモデルを破棄
  public clearSubModels(name?: string) {
    if (!name) {
      this._subModels.forEach(subModel => {
        subModel.model.removeFrom(this._scene);
      })
      this._subModels = [];
      this._basePlate.subModelsGround = undefined;
    } else {
      this._subModels.filter(subModel => subModel.name === name).forEach(subModel => {
        subModel.model.removeFrom(this._scene);
      })
      this._subModels = this._subModels.filter(subModel => subModel.name !== name);
    }
  }

  //スクリーンのキャプチャ画像をbase64で出力
  public captureScreen(width?: number, height?: number): Promise<string> {
    return new Promise((resolve) => {
      const targetCanvas = this._renderer.domElement;
      new Promise((resolve) => {
        const baseImgData = targetCanvas.toDataURL("image/png");
        resolve(baseImgData);
      })
        .then((baseImgData) => {
          //リサイズ処理
          const imgTag = document.createElement('img');
          imgTag.src = baseImgData as string;
          const resizeCanvas = document.createElement('canvas');
          const ctx = resizeCanvas.getContext('2d');
          if (!((width && height) && (width > 0 && height > 0))) {
            width = targetCanvas.width;
            height = targetCanvas.height;
          }
          resizeCanvas.width = width;
          resizeCanvas.height = height;
          imgTag.onload = function () {
            if (ctx && width && height) ctx.drawImage(imgTag, 0, 0, width, height);
            const resImgData = resizeCanvas.toDataURL("image/png");
            resolve(resImgData);
          }
        })
    });
  }

  //面、辺、頂点の切替API
  public showFace() {
    this._viewMode = 'face';
    if (this._model && this._model instanceof ObjectManager) this._model.reload(this._viewMode);
  }
  public showEdge() {
    this._viewMode = 'edge';
    if (this._model && this._model instanceof ObjectManager) this._model.reload(this._viewMode);
  }
  public showVertex() {
    this._viewMode = 'vertex';
    if (this._model && this._model instanceof ObjectManager) this._model.reload(this._viewMode);
  }

  //6面からのカメラ切替API
  public fromTop() {
    const camPos = new THREE.Vector3().copy(this._boundary.center);
    camPos.add(new THREE.Vector3(0, 0, this._boundary.max))
    this._screen.camera.position.copy(camPos);
    this._screen.controls.target.copy(this._boundary.center);
  }
  public fromBottom() {
    const camPos = new THREE.Vector3().copy(this._boundary.center);
    camPos.add(new THREE.Vector3(0, 0, -this._boundary.max));
    this._screen.camera.position.copy(camPos);
    this._screen.controls.target.copy(this._boundary.center);
  }
  public fromFront() {
    const camPos = new THREE.Vector3().copy(this._boundary.center);
    camPos.add(new THREE.Vector3(0, -this._boundary.max, 0));
    this._screen.camera.position.copy(camPos);
    this._screen.controls.target.copy(this._boundary.center);
  }
  public fromBack() {
    const camPos = new THREE.Vector3().copy(this._boundary.center);
    camPos.add(new THREE.Vector3(0, this._boundary.max, 0));
    this._screen.camera.position.copy(camPos);
    this._screen.controls.target.copy(this._boundary.center);
  }
  public fromLeft() {
    const camPos = new THREE.Vector3().copy(this._boundary.center);
    camPos.add(new THREE.Vector3(-this._boundary.max, 0, 0));
    this._screen.camera.position.copy(camPos);
    this._screen.controls.target.copy(this._boundary.center);
  }
  public fromRight() {
    const camPos = new THREE.Vector3().copy(this._boundary.center);
    camPos.add(new THREE.Vector3(this._boundary.max, 0, 0));
    this._screen.camera.position.copy(camPos);
    this._screen.controls.target.copy(this._boundary.center);
  }

  //モデルに合わせて画面をfitするAPI
  public fit() {
    //スクリーンの調整
    const rot = this._screen.camera.rotation;
    const quotinv = new THREE.Quaternion().setFromEuler(rot).invert();
    const modelGeometry = new THREE.BufferGeometry();
    if (this._modelType === 'model' && this._model && this._model instanceof ObjectManager) {
      modelGeometry.copy(this._model.face.getViewMesh().geometry);
      modelGeometry.applyQuaternion(new THREE.Quaternion().setFromEuler(this._model.face.getViewMesh().rotation));
    } else if (this._modelType === 'STLModel' && this._model && this._model instanceof THREE.Mesh) {
      modelGeometry.copy(this._model.geometry);
      modelGeometry.applyQuaternion(new THREE.Quaternion().setFromEuler(this._model.rotation));
    } else if (this._feature) {
      modelGeometry.copy(this._feature);
    }
    modelGeometry.applyQuaternion(quotinv);
    modelGeometry.computeBoundingBox();
    const modelBB = modelGeometry.boundingBox ? modelGeometry.boundingBox : new THREE.Box3();
    const BB = [
      new THREE.Vector3().copy(modelBB.min),
      new THREE.Vector3().copy(modelBB.max)
    ];
    this._screen.fit(BB);
    //カメラ位置の調整
    const cameraDirVector = new THREE.Vector3().subVectors(
      new THREE.Vector3(0, 0, this._boundary.max).add(this._boundary.center),
      this._boundary.center
    );
    this._screen.controls.target.copy(this._boundary.center);
    cameraDirVector.applyEuler(rot);
    this._screen.camera.position.copy(new THREE.Vector3().addVectors(this._boundary.center, cameraDirVector));

    modelGeometry.dispose();
  }

  //カメラのオイラー角を出力するAPI
  public getEuler() {
    return {
      x: this._screen.camera.rotation.x,
      y: this._screen.camera.rotation.y,
      z: this._screen.camera.rotation.z
    }
  }

  //カメラの回転を軸で出力するAPI
  public getRotationAxis() {
    return {
      up: {
        x: this._screen.rotationAxis().up.x,
        y: this._screen.rotationAxis().up.y,
        z: this._screen.rotationAxis().up.z
      },
      dir: {
        x: this._screen.rotationAxis().dir.x,
        y: this._screen.rotationAxis().dir.y,
        z: this._screen.rotationAxis().dir.z
      }
    };
  }

  //モデルの回転をオイラー角で返すAPI
  public getModelRotation() {
    if (this._model && this._model instanceof ObjectManager)
      return {
        x: this._model.face.getViewMesh().rotation.x,
        y: this._model.face.getViewMesh().rotation.y,
        z: this._model.face.getViewMesh().rotation.z
      };
    else if (this._model instanceof THREE.Mesh)
      return {
        x: this._model.rotation.x,
        y: this._model.rotation.y,
        z: this._model.rotation.z
      };
    else
      return {
        x: 0, y: 0, z: 0
      };
  }

  //モデルの回転を軸で出力するAPI
  public getModelRotationAxis() {
    if (this._model) {
      const up = new THREE.Vector3(0, 1, 0);
      const dir = new THREE.Vector3(0, 0, -1);

      const rot = this._model instanceof ObjectManager ? new THREE.Euler(
        this._model.face.getViewMesh().rotation.x,
        this._model.face.getViewMesh().rotation.y,
        this._model.face.getViewMesh().rotation.z
      ) :
        (this._model instanceof THREE.Mesh ? new THREE.Euler(
          this._model.rotation.x,
          this._model.rotation.y,
          this._model.rotation.z
        ) :
          new THREE.Euler());

      up.applyEuler(rot);
      dir.applyEuler(rot);
      return {
        up: {
          x: up.x,
          y: up.y,
          z: up.z
        },
        dir: {
          x: dir.x,
          y: dir.y,
          z: dir.z
        }
      };
    } else {
      return {
        up: { x: 0, y: 0, z: 0 },
        dir: { x: 0, y: 0, z: 0 }
      };
    }
  }

  //オイラー角からカメラを回転移動するAPI
  public fromEuler(form: { x: number, y: number, z: number }): Promise<void> {
    return new Promise((resolve) => {
      const rot = form instanceof THREE.Euler ? form : new THREE.Euler(form.x, form.y, form.z);

      const dir = new THREE.Vector3(0, -this._boundary.max, 0);
      dir.applyEuler(rot);

      const camPos = new THREE.Vector3().copy(this._boundary.center);
      camPos.add(dir);
      this._screen.camera.position.copy(camPos);
      this._screen.controls.target.copy(this._boundary.center);

      this._screen.update();
      this._renderer.render(this._scene, this._screen.camera);

      resolve();
    })
  }

  //オイラー角からオブジェクトを回転するAPI
  public rotationFromEuler(euler: { x: number, y: number, z: number }) {
    if (this._model && this._model instanceof ObjectManager)
      this._model.face.getViewMesh().rotation.set(euler.x, euler.y, euler.z);
    else if (this._model && this._model instanceof THREE.Mesh)
      this._model.rotation.set(euler.x, euler.y, euler.z);
  }

  //カメラとモデルの操作切替API
  public changeControl(mode: "camera" | "model", type?: "rotate" | "pan" | "zoom") {
    this._controlMode = { mode: mode, type: type ? type : "rotate" };
    switch (mode) {
      case "model":
        this._screen.controls.enabled = false;
        this._screen.cameraControls.enabled = false;
        this._modelControls.enabled = true;
        break;
      case "camera":
        this._screen.controls.enabled = true;
        this._screen.cameraControls.enabled = false;
        this._modelControls.enabled = false;
        const typesEnable = { rot: false, pan: false, zoom: false };
        switch (type) {
          case "pan":
            typesEnable.pan = true;
            this._screen.controls.mouseButtons = { LEFT: THREE.MOUSE.PAN, MIDDLE: THREE.MOUSE.PAN, RIGHT: THREE.MOUSE.PAN }
            break;
          case "zoom":
            typesEnable.zoom = true;
            this._screen.cameraControls.enabled = true;
            break;
          default:
            typesEnable.rot = true;
            this._screen.controls.mouseButtons = { LEFT: THREE.MOUSE.ROTATE, MIDDLE: THREE.MOUSE.ROTATE, RIGHT: THREE.MOUSE.ROTATE }
            break;
        }
        this._screen.controls.enableRotate = typesEnable.rot;
        this._screen.controls.enablePan = typesEnable.pan;
        break;
    }
  }

  //軸表示のAPI
  public showAxis() {
    this._screen.showAxis();
  }

  //半透明化API
  public skeltonFace(opacity?: number) {
    if (this._model) {
      const material = this._model instanceof ObjectManager ?
        this._model.face.getViewMesh().material :
        (this._model instanceof THREE.Mesh ? this._model.material : new THREE.Material());

      if (opacity !== 1 || opacity === undefined) {
        if (material instanceof THREE.Material) {
          material.transparent = true;
          material.depthTest = true;
          material.opacity = opacity === undefined ? 0.5 : opacity;
          material.needsUpdate = true;
        }
      } else {
        if (material instanceof THREE.Material) {
          material.transparent = false;
          material.depthTest = true;
          material.opacity = 1;
          material.needsUpdate = true;
        }
      }
    }
  }

  //複数モデル＋BB出力モード
  public setMultiModels(
    forms: {
      inputData?: any,
      dataType?: string,
      coords: { x: number, y: number, z: number, rot?: number }[],
      size?: { x: number, y: number, z: number },
      opacity?: number,
      roughness?: number,
      metalness?: number,
      modelColor?: {
        edge?: THREE.ColorRepresentation,
        face?: THREE.ColorRepresentation
      },
      modelRotation?: { x: number, y: number, z: number },
      boxColor?: THREE.ColorRepresentation
    }[],
    option?: {
      showAxis?: boolean,
      showBasePlate?: boolean,
      isGround?: boolean,
      basePlateColor?: THREE.ColorRepresentation,
      basePlateWidth?: number,
      basePlateHeight?: number,
      isFitCorner?: boolean,
      boxOpacity?: number,
      defaultCameraRotation?: { x: number, y: number, z: number },
      lights?: {
        ambient?: { color?: THREE.ColorRepresentation, intensity?: number },
        directional?: { color?: THREE.ColorRepresentation, intensity?: number },
        hemisphere?: { skyColor?: THREE.ColorRepresentation, groundColor?: THREE.ColorRepresentation, intensity?: number }
      },
      skeltonFrame?: boolean,
      modifiedValue?: number
    }
  ): Promise<void> {
    return new Promise((resolve) => {
      new Promise((resolve) => {
        this.clearScene()
          .then(() => {
            this._scene = new THREE.Scene();
            this._subModels = [];

            const geometryList: THREE.BufferGeometry[] = [];
            const vertices: THREE.Vector3[] = [];

            const bbOption = {
              showAxis: option?.showAxis,
              showBasePlate: option?.showBasePlate,
              isGround: option?.isGround,
              basePlateColor: option?.basePlateColor,
              basePlateWidth: option?.basePlateWidth,
              basePlateHeight: option?.basePlateHeight,
              isFitCorner: option?.isFitCorner,
              opacity: option?.boxOpacity,
              lights: option?.lights,
              skeltonFrame: option?.skeltonFrame
            }

            forms.forEach(form => {
              let boxSize = form.size ? form.size : { x: 100, y: 100, z: 100 };
              const innerModelGeometryOriginal = new THREE.BufferGeometry();
              const innerModelGeometry = new THREE.BufferGeometry();

              // 種類ごとの処理

              if (form.inputData !== undefined) {
                // モデルの処理
                // Object取り出し
                if (form.dataType === 'stl') {
                  const loader = new STLLoader();
                  innerModelGeometryOriginal.copy(loader.parse(form.inputData));
                } else {
                  const materialOption = form.modelColor ?
                    { edge: { color: form.modelColor.edge }, face: { color: form.modelColor.face } } : undefined;
                  const subModel = new ObjectManager(form.inputData, 'mpac', materialOption);
                  const innerModelMesh = subModel.face.getViewMesh();
                  innerModelGeometryOriginal.copy(innerModelMesh.geometry);
                }

                const rotation = form.modelRotation ?
                  new THREE.Euler(form.modelRotation.x, form.modelRotation.y, form.modelRotation.z) :
                  new THREE.Euler();

                // geometry修正＋回転
                if (option?.modifiedValue) {
                  const modifiedLimit = option.modifiedValue;
                  const modifier = new SimplifyModifier();
                  const dataSize = innerModelGeometryOriginal.attributes.position.count;
                  // オリジナル頂点数
                  console.log('vertex size:', dataSize)
                  innerModelGeometry.copy(innerModelGeometryOriginal);
                  if (dataSize > modifiedLimit * 3 && dataSize > 1000) {
                    innerModelGeometry.copy(modifier.modify(innerModelGeometryOriginal, modifiedLimit));
                    if (innerModelGeometry.attributes.position.count === 0) {
                      innerModelGeometry.copy(innerModelGeometryOriginal);
                    }
                  }
                }
                innerModelGeometry.computeVertexNormals();
                innerModelGeometry.applyQuaternion(new THREE.Quaternion().setFromEuler(rotation));
                innerModelGeometry.center();

                //modify後の頂点数
                console.log('after vertex size:', innerModelGeometry.attributes.position.count)

                // bb計算
                innerModelGeometry.computeBoundingBox();
                const innerModelBB = innerModelGeometry.boundingBox ? innerModelGeometry.boundingBox : new THREE.Box3();
                boxSize = form.size ?
                  form.size :
                  {
                    x: innerModelBB.max.x - innerModelBB.min.x,
                    y: innerModelBB.max.y - innerModelBB.min.y,
                    z: innerModelBB.max.z - innerModelBB.min.z
                  };
              }

              // ボックスの処理
              const color = form.boxColor === undefined ? 0x555555 : form.boxColor;
              const box = bbOption.skeltonFrame ?
                new THREE.EdgesGeometry(new THREE.BoxGeometry(boxSize.x, boxSize.y, boxSize.z)) :
                new THREE.BoxGeometry(boxSize.x, boxSize.y, boxSize.z);
              const boxMaterial = bbOption.skeltonFrame ?
                new THREE.LineBasicMaterial({ color: color }) :
                new THREE.MeshStandardMaterial({
                  color: color,
                  roughness: 0.5,
                  metalness: 0.5,
                  transparent: bbOption?.opacity !== undefined,
                  opacity: bbOption.opacity !== undefined ? bbOption.opacity : undefined
                });


              // const group = new THREE.Group();
              const innerModelMaterial = new THREE.MeshStandardMaterial({
                color: (!form.modelColor || !(form.modelColor.face)) ? constant.FACE_COLOR : form.modelColor.face,
                visible: true,
                roughness: form.roughness ? form.roughness : 0.5,
                metalness: form.metalness ? form.metalness : 0.5,
              });
              const originalMesh = new THREE.Mesh(
                innerModelGeometry,
                innerModelMaterial
              );
              if (form.opacity) {
                originalMesh.material.transparent = true;
                originalMesh.material.depthTest = false;
                originalMesh.material.opacity = form.opacity;
                originalMesh.material.needsUpdate = true;
                originalMesh.material.alphaTest = 0.5;
              }
              originalMesh.renderOrder = 1;

              // 各座標ごとの処理
              // let faceBuffers: THREE.BufferGeometry[] = new Array();
              let boxes: THREE.BufferGeometry[] = [];
              form.coords.forEach(coord => {
                const boxFeature = new THREE.BoxGeometry(boxSize.x, boxSize.y, boxSize.z);
                if (coord.rot) {
                  boxFeature.rotateZ(coord.rot);
                  boxFeature.computeBoundingBox();
                  const fixBB = boxFeature.boundingBox ? boxFeature.boundingBox : new THREE.Box3();
                  boxSize = {
                    x: fixBB.max.x - fixBB.min.x,
                    y: fixBB.max.y - fixBB.min.y,
                    z: fixBB.max.z - fixBB.min.z
                  };
                }

                // 箱の処理
                const BB = bbOption.skeltonFrame ? new THREE.LineSegments(box, boxMaterial) : new THREE.Mesh(box, boxMaterial);
                let rotateGap = {x: 0, y: 0, z: 0};
                if (coord.rot){
                  const copyBox = new THREE.BufferGeometry().copy(box);
                  const rotZ = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, coord.rot));
                  copyBox.applyQuaternion(rotZ);
                  copyBox.computeBoundingBox();
                  const bbCalc = copyBox.boundingBox ?? new THREE.Box3();
                  rotateGap.x = bbCalc.max.x - (boxSize.x / 2);
                  rotateGap.y = bbCalc.max.y - (boxSize.y / 2);
                  rotateGap.z = bbCalc.max.z - (boxSize.z / 2);
                  console.log(bbCalc);

                  BB.rotateZ(coord.rot);
                }
                
                const boxCenter = { x: coord.x + (boxSize.x / 2) + rotateGap.x, y: coord.y + (boxSize.y / 2) + rotateGap.y, z: coord.z + (boxSize.z / 2) + rotateGap.z }

                BB.renderOrder = 2;
                BB.position.set(boxCenter.x, boxCenter.y, boxCenter.z);
                this._scene.add(BB);

                // モデルの処理
                // if (form.inputData !== undefined){
                //   const geometry = new THREE.BufferGeometry();
                //   geometry.copy(innerModelGeometry);
                //   geometry.center();
                //   if (coord.rot) geometry.rotateZ(coord.rot);
                //   geometry.translate(coord.x + boxSize.x / 2, coord.y + boxSize.y / 2, coord.z + boxSize.z / 2);
                //   faceBuffers.push(geometry);
                // }
                const mesh = new THREE.Mesh().copy(originalMesh);
                if (coord.rot) mesh.rotateZ(coord.rot);
                mesh.position.set(boxCenter.x, boxCenter.y, boxCenter.z);
                // group.add(mesh);
                this._scene.add(mesh);

                
                // 全体の座標設定
                boxFeature.translate(boxCenter.x, boxCenter.y, boxCenter.z);
                boxes.push(boxFeature);

                vertices.push(new THREE.Vector3(coord.x, coord.y, coord.z));
                vertices.push(new THREE.Vector3(coord.x + boxSize.x + (rotateGap.x * 2), coord.y + boxSize.y + (rotateGap.y * 2), coord.z + boxSize.z + + (rotateGap.z * 2)));
              });

              // 種類ごとのモデルをマージして出力
              // if (faceBuffers.length > 0){
              //   const allGeometry = utils.mergeVertices((utils.mergeBufferGeometries(faceBuffers, true)));
              //   const allMaterial = new THREE.MeshStandardMaterial({
              //     color: (!form.modelColor || !(form.modelColor.face)) ? constant.FACE_COLOR : form.modelColor.face,
              //     visible: true,
              //     roughness: form.roughness ? form.roughness : 0.5,
              //     metalness: form.metalness ? form.metalness : 0.5,
              //   })

              //   if (form.opacity) {
              //     allMaterial.transparent = true;
              //     allMaterial.depthTest = false;
              //     allMaterial.opacity = form.opacity;
              //     allMaterial.needsUpdate = true;
              //   }
              //   const allMesh = new THREE.Mesh(allGeometry, allMaterial);
              //   allMesh.renderOrder = 1;
              //   this._scene.add(allMesh);
              // }

              // this._scene.add(group);

              const featureGeometry = utils.mergeBufferGeometries(boxes);
              geometryList.push(featureGeometry);

              innerModelGeometryOriginal.dispose();
              innerModelGeometry.dispose();
            })

            // 箱をマージ
            this._modelType = 'boxes';
            this._feature = utils.mergeBufferGeometries(geometryList);
            this._feature.center();

            //箱たち全体のBSを計算
            const boundingBox = new THREE.Box3().setFromPoints(vertices);
            const center = new THREE.Vector3().addVectors(boundingBox.min, boundingBox.max).multiplyScalar(0.5);
            const radius = boundingBox.min.distanceTo(boundingBox.max) * 0.5
            const boundary = {
              max: radius + constant.MARGIN,
              width: (radius * 2 + constant.MARGIN) * this._aspect,
              height: radius * 2 + constant.MARGIN,
              center: center,
              ground: boundingBox.min.z
            };
            this._boundary = boundary;

            const defaultLight = {
              ambient: { color: 0xffffff, intensity: 0.75 },
              directional: { color: 0xffffff, intensity: 0.75 },
              hemisphere: { skyColor: 0xeeeeff, groundColor: 0x775555, intensity: 0.25 }
            }
            this.setLights(option?.lights ? option?.lights : defaultLight);

            resolve(boundary);
          })
      })
        .then((res) => {

          if (res) {
            //カメラ設定
            if (!this._hasScreen) {
              this._hasScreen = true;

              //モデルとベースプレートの大きさで描画範囲(奥行)を決定
              const baseWidth = option?.basePlateWidth ? option.basePlateWidth : this._boundary.max * 2;
              const baseHeight = option?.basePlateHeight ? option.basePlateHeight : this._boundary.max * 2;
              const directionDepth = Math.sqrt(baseWidth * baseWidth + baseHeight * baseHeight);

              this._screen = new CameraManager(this._renderer.domElement, this._boundary, directionDepth);
              this._screen.addTo(this._scene);

              this.fromEuler({ x: 0, y: 0, z: 0 });
              this.changeControl(this._controlMode.mode, this._controlMode.type);
            }

            if (option && option.showAxis) this.showAxis();
            if (option && option.showBasePlate) this.showBasePlate(option.isGround, option.basePlateColor, option.basePlateWidth, option.basePlateHeight, option.isFitCorner);

            resolve();
          }

          resolve();
        })
    });
  }

  //BBのみの出力モード
  public displayBb(
    forms: {
      x_size: number, y_size: number, z_size: number,
      coords: { x: number, y: number, z: number }[],
      color?: THREE.ColorRepresentation
    }[],
    option?: {
      showAxis?: boolean,
      showBasePlate?: boolean,
      isGround?: boolean,
      basePlateColor?: THREE.ColorRepresentation,
      basePlateWidth?: number,
      basePlateHeight?: number,
      isFitCorner?: boolean,
      opacity?: number,
      roughness?: number,
      metalness?: number,
      lights?: {
        ambient?: { color?: THREE.ColorRepresentation, intensity?: number },
        directional?: { color?: THREE.ColorRepresentation, intensity?: number },
        hemisphere?: { skyColor?: THREE.ColorRepresentation, groundColor?: THREE.ColorRepresentation, intensity?: number }
      },
      setPos?: 'center'
      skeltonFrame?: boolean
    }
  ): Promise<void> {
    return new Promise((resolve) => {
      new Promise((resolve) => {
        this.clearScene()
          .then(() => {
            this._scene = new THREE.Scene();

            this._subModels = [];
            const geometryList: THREE.BufferGeometry[] = [];

            const vertices: THREE.Vector3[] = [];
            forms.forEach(form => {
              //箱の作成
              let boxes: THREE.BufferGeometry[] = [];
              const color = form.color === undefined ? 0x555555 : form.color;
              form.coords.forEach(coordinate => {
                const BB = option?.skeltonFrame ?
                  new THREE.LineSegments(
                    new THREE.EdgesGeometry(new THREE.BoxGeometry(form.x_size, form.y_size, form.z_size)),
                    new THREE.LineBasicMaterial({ color: color })
                  ) :
                  new THREE.Mesh(
                    new THREE.BoxGeometry(form.x_size, form.y_size, form.z_size),
                    new THREE.MeshStandardMaterial({
                      color: color,
                      roughness: (option && option.roughness !== undefined) ? option.roughness : 0.5,
                      metalness: (option && option.metalness !== undefined) ? option.metalness : 0.5,
                      transparent: option?.opacity !== undefined,
                      opacity: (option && option.opacity !== undefined) ? option.opacity : undefined
                    })
                  );
                BB.renderOrder = 1;
                if (option?.setPos === 'center') BB.position.set(coordinate.x, coordinate.y, coordinate.z);
                else BB.position.set(coordinate.x + form.x_size / 2, coordinate.y + form.y_size / 2, coordinate.z + form.z_size / 2);
                this._scene.add(BB);

                const box = new THREE.BoxGeometry(form.x_size, form.y_size, form.z_size);
                if (option?.setPos === 'center') box.translate(coordinate.x, coordinate.y, coordinate.z);
                else box.translate(coordinate.x + form.x_size / 2, coordinate.y + form.y_size / 2, coordinate.z + form.z_size / 2);
                boxes.push(box);

                if (option?.setPos === 'center') {
                  vertices.push(new THREE.Vector3(coordinate.x - form.x_size / 2, coordinate.y - form.y_size / 2, coordinate.z - form.z_size / 2));
                  vertices.push(new THREE.Vector3(coordinate.x + form.x_size / 2, coordinate.y + form.y_size / 2, coordinate.z + form.z_size / 2));
                } else {
                  vertices.push(new THREE.Vector3(coordinate.x, coordinate.y, coordinate.z));
                  vertices.push(new THREE.Vector3(coordinate.x + form.x_size, coordinate.y + form.y_size, coordinate.z + form.z_size));
                }
              });
              const geometry = utils.mergeBufferGeometries(boxes);
              // const BBs = new THREE.Mesh(
              //   geometry,
              //   new THREE.MeshStandardMaterial({
              //     color: color,
              //     roughness: (option && option.roughness !== undefined) ? option.roughness : 0.5,
              //     metalness: (option && option.metalness !== undefined) ? option.metalness : 0.5,
              //     transparent: option?.opacity !== undefined,
              //     opacity: (option && option.opacity !== undefined) ? option.opacity : undefined
              //   })
              // )
              // this._scene.add(BBs);
              geometryList.push(geometry);
            });

            this._modelType = 'boxes';
            this._feature = utils.mergeBufferGeometries(geometryList);
            this._feature.center();

            //箱たち全体のBSを計算
            const boundingBox = new THREE.Box3().setFromPoints(vertices);
            const center = new THREE.Vector3().addVectors(boundingBox.min, boundingBox.max).multiplyScalar(0.5);
            const radius = boundingBox.min.distanceTo(boundingBox.max) * 0.5
            const boundary = {
              max: radius + constant.MARGIN,
              width: (radius * 2 + constant.MARGIN) * this._aspect,
              height: radius * 2 + constant.MARGIN,
              center: center,
              ground: boundingBox.min.z
            };
            this._boundary = boundary;

            const defaultLight = {
              ambient: { color: 0xffffff, intensity: 0.75 },
              directional: { color: 0xffffff, intensity: 0.75 },
              hemisphere: { skyColor: 0xeeeeff, groundColor: 0x775555, intensity: 0.25 }
            }
            this.setLights(option?.lights ? option?.lights : defaultLight);

            resolve(boundary);
          });
      })
        .then((res) => {
          if (res) {
            //カメラ設定
            if (!this._hasScreen) {
              this._hasScreen = true;

              //モデルとベースプレートの大きさで描画範囲(奥行)を決定
              const baseWidth = option?.basePlateWidth ? option.basePlateWidth : this._boundary.max * 2;
              const baseHeight = option?.basePlateHeight ? option.basePlateHeight : this._boundary.max * 2;
              const directionDepth = Math.sqrt(baseWidth * baseWidth + baseHeight * baseHeight);

              this._screen = new CameraManager(this._renderer.domElement, this._boundary, directionDepth);
              this._screen.addTo(this._scene);

              this.fromEuler({ x: 0, y: 0, z: 0 });
              this.changeControl(this._controlMode.mode, this._controlMode.type);
            }

            if (option && option.showAxis) this.showAxis();
            if (option && option.showBasePlate) this.showBasePlate(option.isGround, option.basePlateColor, option.basePlateWidth, option.basePlateHeight, option.isFitCorner);

            resolve();
          }
        })
    });
  }

  //ベースプレート表示API
  public showBasePlate(
    isGround?: boolean, color?: THREE.ColorRepresentation, width?: number, height?: number, isFitCorner?: boolean
  ) {
    if (!this._basePlate.exists) {
      const sizeX = width ? width : this._boundary.max * 2;
      const sizeY = height ? height : this._boundary.max * 2;

      const basePlate = new THREE.Mesh(
        new THREE.BoxGeometry(sizeX, sizeY, 1),
        new THREE.MeshStandardMaterial({
          color: color === undefined ? constant.PLATE_COLOR : color,
          transparent: true,
          opacity: 0.5
        })
      );

      const modelBB = this.getBoundingBox();
      const rotDelta = new THREE.Vector3().addVectors(modelBB.min, modelBB.max).multiplyScalar(0.5);
      let posX = this._boundary.center.x + rotDelta.x;
      let posY = this._boundary.center.y + rotDelta.y;
      let posZ = this._boundary.center.z + rotDelta.z - this._boundary.max - 0.5;

      if (isFitCorner) {
        posX = sizeX / 2;
        posY = sizeY / 2;
      }

      if (isGround) {
        let minZ = 0;
        if (this._modelType === 'model' || this._modelType === 'STLModel') {
          minZ = this._basePlate.subModelsGround ? Math.min(this._boundary.center.z + modelBB.min.z, this._basePlate.subModelsGround) : this._boundary.center.z + modelBB.min.z;
        } else {
          minZ = this._basePlate.subModelsGround ? Math.min(this._boundary.ground, this._basePlate.subModelsGround) : this._boundary.ground;
        }
        posZ = minZ - 0.51;
      }

      basePlate.position.set(posX, posY, posZ);
      // if (isGround) basePlate.position.set(this._boundary.center.x, this._boundary.center.y, this._boundary.ground - 0.5);
      // else basePlate.position.set(this._boundary.center.x, this._boundary.center.y, this._boundary.center.z - this._boundary.max - 0.5);

      basePlate.updateMatrixWorld();
      this._basePlate = { mesh: basePlate, isGround: isGround ? true : false, isFitCorner: isFitCorner ? true : false, exists: true, subModelsGround: this._basePlate.subModelsGround };
      this._scene.add(this._basePlate.mesh);
    } else {
      //前のベースプレートのデータ引継ぎ
      const oldBasePlate = this._basePlate.mesh;
      oldBasePlate.geometry.computeBoundingBox();
      const bb = oldBasePlate.geometry.boundingBox ?? new THREE.Box3();
      const size = new THREE.Vector3().subVectors(bb.max, bb.min);
      const isGroundNew = isGround === undefined ? this._basePlate.isGround : isGround;
      const isFitCornerNew = isFitCorner === undefined ? this._basePlate.isFitCorner : isFitCorner;

      //前のベースプレートの破棄
      this._scene.remove(oldBasePlate);
      oldBasePlate.geometry.dispose();
      if (oldBasePlate.material instanceof THREE.Material) oldBasePlate.material.dispose();

      //作り直し
      const sizeX = width ? width : size.x;
      const sizeY = height ? height : size.y;

      const basePlate = new THREE.Mesh(
        new THREE.BoxGeometry(sizeX, sizeY, 1),
        new THREE.MeshStandardMaterial({
          color: color === undefined ? constant.PLATE_COLOR : color,
          transparent: true,
          opacity: 0.5
        })
      );

      //カメラ更新
      if (this._screen.camera instanceof THREE.OrthographicCamera) {
        const farOld = this._screen.camera.far;
        const directionDepth = Math.sqrt(sizeX * sizeX + sizeY * sizeY);
        const far = Math.max(farOld, directionDepth);
        this._screen.camera.far = far;
        this._screen.camera.near = -far;
        this._screen.camera.updateProjectionMatrix();
      }

      //プロパティ更新
      const modelBB = this.getBoundingBox();
      const rotDelta = new THREE.Vector3().addVectors(modelBB.min, modelBB.max).multiplyScalar(0.5);
      let posX = this._boundary.center.x + rotDelta.x;
      let posY = this._boundary.center.y + rotDelta.y;
      let posZ = this._boundary.center.z + rotDelta.z - this._boundary.max - 0.5;

      if (isFitCornerNew) {
        posX = sizeX / 2;
        posY = sizeY / 2;
      }

      console.log(this._basePlate.subModelsGround)
      if (isGround) {
        let minZ = 0;
        if (this._modelType === 'model' || this._modelType === 'STLModel') {
          minZ = this._basePlate.subModelsGround ? Math.min(this._boundary.center.z + modelBB.min.z, this._basePlate.subModelsGround) : this._boundary.center.z + modelBB.min.z;
        } else {
          minZ = this._basePlate.subModelsGround ? Math.min(this._boundary.ground, this._basePlate.subModelsGround) : this._boundary.ground;
        }
        posZ = minZ - 0.51;
      }

      basePlate.position.set(posX, posY, posZ);

      basePlate.updateMatrixWorld();
      this._basePlate = { mesh: basePlate, isGround: isGroundNew, isFitCorner: isFitCornerNew, exists: true, subModelsGround: this._basePlate.subModelsGround };
      this._scene.add(basePlate);
    }
  }

  public reloadGround(isGround?: boolean, visible?: boolean) {
    if (isGround !== undefined) {
      this._basePlate.isGround = isGround;
    }
    if (visible !== undefined) {
      this._basePlate.mesh.visible = visible;
    }
    if (this._basePlate.isGround) {
      let posZ = 0;
      if (this._modelType === 'model' && this._model && this._model instanceof ObjectManager) {
        //空間に対するモデルのAABBを再計算
        const modelGeometry = new THREE.BufferGeometry();
        modelGeometry.copy(this._model.face.getViewMesh().geometry);
        modelGeometry.applyQuaternion(new THREE.Quaternion().setFromEuler(this._model.face.getViewMesh().rotation));
        modelGeometry.computeBoundingBox();
        const modelBB = modelGeometry.boundingBox ? modelGeometry.boundingBox : new THREE.Box3();

        posZ = this._basePlate.subModelsGround ? Math.min(this._boundary.center.z + modelBB.min.z, this._basePlate.subModelsGround) : this._boundary.center.z + modelBB.min.z;
        modelGeometry.dispose();
      }
      else if (this._modelType === 'STLModel' && this._model && this._model instanceof THREE.Mesh) {
        //空間に対するモデルのAABBを再計算
        const modelGeometry = new THREE.BufferGeometry();
        modelGeometry.copy(this._model.geometry);
        modelGeometry.applyQuaternion(new THREE.Quaternion().setFromEuler(this._model.rotation));
        modelGeometry.computeBoundingBox();
        const modelBB = modelGeometry.boundingBox ? modelGeometry.boundingBox : new THREE.Box3();

        posZ = this._basePlate.subModelsGround ? Math.min(this._boundary.center.z + modelBB.min.z, this._basePlate.subModelsGround) : this._boundary.center.z + modelBB.min.z;
        modelGeometry.dispose();
      }
      else {
        posZ = this._basePlate.subModelsGround ? Math.min(this._boundary.ground, this._basePlate.subModelsGround) : this._boundary.ground;
      }

      this._basePlate.mesh.position.setZ(posZ - 0.51);
    } else {
      this._basePlate.mesh.position.set(this._boundary.center.x, this._boundary.center.y, this._boundary.center.z - this._boundary.max - 0.5);
    }
    this._basePlate.mesh.updateMatrixWorld();
  }

  private basePlateOrderingUpdate() {
    let order = 0;
    if (this._basePlate.mesh.position.z + 1.0 > this._screen.camera.position.z) {
      order = 1;
    }
    this._basePlate.mesh.renderOrder = order;
  }

  //現在のAABBの取得
  private getBoundingBox(): THREE.Box3 {
    const modelGeometry = new THREE.BufferGeometry();
    if (this._modelType === 'model' && this._model && this._model instanceof ObjectManager) {
      modelGeometry.copy(this._model.face.getViewMesh().geometry);
      modelGeometry.applyQuaternion(new THREE.Quaternion().setFromEuler(this._model.face.getViewMesh().rotation));
    } else if (this._modelType === 'STLModel' && this._model && this._model instanceof THREE.Mesh) {
      modelGeometry.copy(this._model.geometry);
      modelGeometry.applyQuaternion(new THREE.Quaternion().setFromEuler(this._model.rotation));
    } else if (this._feature) {
      modelGeometry.copy(this._feature);
    }
    modelGeometry.computeBoundingBox();
    const modelBB = modelGeometry.boundingBox ? modelGeometry.boundingBox : new THREE.Box3();
    return modelBB;
  }

  // Euler角からAABBの取得
  private getBoundingBoxWithEuler(euler: {x: number, y: number, z: number}): THREE.Box3 {
    const modelGeometry = new THREE.BufferGeometry();
    if (this._modelType === 'STLModel' && this._model && this._model instanceof THREE.Mesh) {
      modelGeometry.copy(this._model.geometry);
      modelGeometry.applyQuaternion(new THREE.Quaternion().setFromEuler(new THREE.Euler(euler.x, euler.y, euler.z)));
    }
    modelGeometry.computeBoundingBox();
    const modelBB = modelGeometry.boundingBox ? modelGeometry.boundingBox : new THREE.Box3();
    return modelBB;
  } 

  //現在のAABBのサイズ取得
  public getBoundingBoxSize(): { x: number, y: number, z: number } {
    const modelBB = this.getBoundingBox();
    return {
      x: modelBB.max.x - modelBB.min.x,
      y: modelBB.max.y - modelBB.min.y,
      z: modelBB.max.z - modelBB.min.z
    }
  }

  // Euler角からAABBのサイズ取得
  public getBoundingBoxSizeWithEuler(euler: {x: number, y: number, z: number}): { x: number, y: number, z: number } {
    const modelBB = this.getBoundingBoxWithEuler(euler);
    return {
      x: modelBB.max.x - modelBB.min.x,
      y: modelBB.max.y - modelBB.min.y,
      z: modelBB.max.z - modelBB.min.z
    }
  }

  //FR用の画像データ計算
  public getFRImageData(sliceInterval: number, limitDegree: number, angleTolerance: number): { numOfImages: number, imageSide: number } {
    //bbの大きさ取得
    const sides = this.getBoundingBoxSize();

    //画像枚数計算
    const numOfImages = Math.round(sides.z / sliceInterval);

    //画像サイズ計算
    const limitLength = sliceInterval * Math.tan((90.0 - limitDegree) * Math.PI / 180.0);
    let imageSide = 200.0;
    let angleGap = 360.0;
    const maxSide = Math.max(sides.x, sides.y);
    const maxSideSq = maxSide * maxSide

    while (angleGap > angleTolerance) {
      imageSide += 2.0;
      const areaPerPixel = maxSideSq / (imageSide * imageSide);
      const pixelEdgeLength = Math.sqrt(areaPerPixel);
      const originalDilateSize = limitLength / pixelEdgeLength;
      const dilateSize = Math.round(originalDilateSize);
      const actualAngle = 90.0 - Math.atan(dilateSize * pixelEdgeLength / sliceInterval) * 180.0 / Math.PI;
      angleGap = Math.abs(limitDegree - actualAngle);
    };

    return { numOfImages: numOfImages, imageSide: imageSide };
  }

  // おかしい三角のテスト用
  public addCSVTriangle(data: string): Promise<void> {
    return new Promise((resolve) => {

      const strLines: string[] = data.split("\r\n");
      let min = 0;
      let coord: THREE.Vector3[] = [];

      for (let strLine of strLines) {
        const triCoordStr = strLine.split(", ");

        if (triCoordStr.length != 9) { continue; }

        // 三角形をつくる
        for (let n = 0; n < 3; n++) {
          const vertex = new THREE.Vector3(
            Number(triCoordStr[n * 3]),
            Number(triCoordStr[n * 3 + 1]),
            Number(triCoordStr[n * 3 + 2])
          );
          min = Math.min(min, vertex.y);
          coord.push(vertex);
        }

        // const triangleGeometry = new THREE.BufferGeometry().setFromPoints(coord);
        // const triangleMaterial = new THREE.MeshBasicMaterial({color: 0xff0000});
        // const triangle = new THREE.Mesh(triangleGeometry, triangleMaterial);

        // this._scene.add(triangle);
      }

      const triangleGeometry = new THREE.BufferGeometry().setFromPoints(coord);
      const triangleMaterial = new THREE.PointsMaterial({ color: 0xff0000, size: 1.5 });
      const triangles = new THREE.Points(triangleGeometry, triangleMaterial);

      this._scene.add(triangles);

      resolve();
    })
  }

  //描画ループを実行する
  public animationLoop() {
    this._renderer.setAnimationLoop(() => {
      // this._screen.update();
      // this._modelControls.update();
      // this.basePlateOrderingUpdate();
      // this._renderer.setSize(this._appElement.offsetWidth, this._appElement.offsetHeight);
      // this._renderer.render(this._scene, this._screen.camera);

      // フレームレート調整用
      
      if(this._frame % this._frameRate || this._frameRate === 1){
        this._screen.update();
        this._modelControls.update();
        this.basePlateOrderingUpdate();
        this._renderer.setSize(this._appElement.offsetWidth, this._appElement.offsetHeight);
        this._renderer.render(this._scene, this._screen.camera);
      }

      if (this._frame > 1000) this._frame = 0;

      this._frameRate = 10;
      this._frame++;
    });
  }
}