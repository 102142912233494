import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
interface Props {
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalRecommendDetails: React.FC<Props> = React.memo(({ show, setIsModalOpen }) => {
  if (show) {
    return (
      <section className='modalWindowWrapper modelAboutModelingPostureWindow'>
        <div className='modalWindowBlock'>
          <div className='modalWindowInner'>
            <div className='modalWindowHeader'>
              <h1 className='modalWindowHeaderTitle'>造形姿勢について</h1>
              <button
                className='modalWindowHeaderCloseLink button-style'
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                閉じる
              </button>
            </div>
            <div className='modalWindowContents'>
              <div className='aboutModelingPosture'>
                <img src='../image/sample/sample_AboutModelingPosture.jpg' alt='' />
                <div className='aboutModelingPostureTextBlock'>
                  <h2 className='aboutModelingPostureH2'>造形エリア内と単価コスト</h2>
                  <p className='aboutModelingPostureText'>
                    この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。この文章はダミーです。文字の大きさ、量、字間、行間等を確認するために入れています。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
});

export default ModalRecommendDetails;
