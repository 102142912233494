import * as THREE from 'three';


//*********************描画空間関係************************
//スクリーンのアスペクト比
export const ASPECT = 16 / 9;
export const MARGIN = 3;
//空間の背景色
export const BACKGROUND_COLOR = 0x333366;


//******************オブジェクト描画関係********************
//オブジェクトタイプの列
export const OBJECT_TYPE = ['vertex', 'edge', 'face'];
//データ入力時の数値を丸める際に、四捨五入する小数の位
export const DIGIT = 10;
//面の基本色
export const FACE_COLOR = new THREE.Color(0xaaffcc);
//辺の基本色
export const EDGE_COLOR = new THREE.Color(0xff0000);
//頂点の基本色
export const VERTEX_COLOR = new THREE.Color(0x0000ff);
//ベースプレートの色
export const PLATE_COLOR = new THREE.Color(0xaaaaaa);
//頂点セレクターのサイズ
export const VERTEX_SIZE = 1;
//辺セレクターの太さ
export const EDGE_SIZE = 0.4;
//基本色をまとめた列
export const OBJECT_COLOR = [VERTEX_COLOR, EDGE_COLOR, FACE_COLOR];
//接触時の色
export const COLLISION_COLOR = new THREE.Color(0xffff00);
//選択時の色（１つ目）
export const CHOICE_COLOR_0 = new THREE.Color(0xff00ff);
//選択時の色（２つ目）
export const CHOICE_COLOR_1 = new THREE.Color(0xff0000);
//２オブジェクト間を繋ぐ最短経路の直線の色
export const DISTANCE_LINE_COLOR = new THREE.Color(0x00ffff);
//スクリーン上のパネルUIの位置
export const PANEL_POSITION_ON_SCREEN = new THREE.Vector2(0.71875, 0.0);
//スクリーン上のキューブUIの位置
export const CUBEUI_POSITION_ON_SCREEN = new THREE.Vector2(-0.8, -0.65);
//軸の長さ
export const AXES_SIZE = 100;
//軸の位置
export const AXES_POS = new THREE.Vector2(-0.38, -0.34);
//オブジェクト読み取り時の長さ最低値
export const MINIMAL_LENGTH = 10;
//スライス幅
export const SLICE_INTERVAL = 1.0;