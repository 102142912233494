import * as React from 'react';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientRegistrationForm.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';
import { GetZipCodeAddress } from '../../common/GetCodeAddress';

interface Props {
  viewType: 'Profile' | 'Regist';
  registInfo: MyIF.userRegistInfo;
  setRegistInfo: React.Dispatch<React.SetStateAction<MyIF.userRegistInfo>>;
}

const UserInfoTableEdit: React.FC<Props> = React.memo(({ viewType, registInfo, setRegistInfo }) => {
  const { setAlertShow, setMessageList } = useAlert();

  // 住所取得
  const getAddress = async () => {
    if (!registInfo.userInfo.postCode1 || !registInfo.userInfo.postCode2) {
      setMessageList([
        '郵便番号を入力してから住所取得を行ってください。',
      ]);
      setAlertShow(true);
      return;
    }
    const address = await GetZipCodeAddress(
      registInfo.userInfo.postCode1,
      registInfo.userInfo.postCode2
    );
    console.log(address);
    if (address === null) {
      setMessageList([
        '郵便番号検索が上手くできませんでした。お手数ですが住所を入力してください。',
      ]);
      setAlertShow(true);
    } else {
      setRegistInfo({
        ...registInfo,
        userInfo: {
          ...registInfo.userInfo,
          address1: address.address1 + address.address2 + address.address3,
        },
      });
    }
  };

  return (
    <div>
      <div className='registrationFormBlock'>
        <div className='registrationFormContent'>
          <div className='registrationFormContentLabel'>
            <label htmlFor='name'>
              氏名 <span className='attentionRedText'>※</span>
            </label>
            <QuestionPopper value={QEXP.CustomerRegist.NAME} />
          </div>
          <div className='registrationFormContentInput'>
            <input
              className='clientRegistrationFormInput50'
              type='text'
              name='lname'
              id='lname'
              value={registInfo.userInfo.lastName}
              onChange={(event) => {
                setRegistInfo({
                  ...registInfo,
                  userInfo: { ...registInfo.userInfo, lastName: event.target.value },
                });
              }}
            />
            <input
              className='clientRegistrationFormInput50'
              type='text'
              name='lname'
              id='fname'
              value={registInfo.userInfo.firstName}
              onChange={(event) => {
                setRegistInfo({
                  ...registInfo,
                  userInfo: { ...registInfo.userInfo, firstName: event.target.value },
                });
              }}
            />
          </div>
        </div>
        <div className='registrationFormContent'>
          <div className='registrationFormContentLabel'>
            <label htmlFor='name02'>
              氏名（フリガナ） <span className='attentionRedText'>※</span>
            </label>
            <QuestionPopper value={QEXP.CustomerRegist.NAME_KANA} />
          </div>
          <div className='registrationFormContentInput'>
            <input
              className='clientRegistrationFormInput50'
              type='text'
              name='lname02'
              id='lname02'
              value={registInfo.userInfo.lastNameKana}
              onChange={(event) => {
                setRegistInfo({
                  ...registInfo,
                  userInfo: { ...registInfo.userInfo, lastNameKana: event.target.value },
                });
              }}
            />
            <input
              className='clientRegistrationFormInput50'
              type='text'
              name='lname02'
              id='fname02'
              value={registInfo.userInfo.firstNameKana}
              onChange={(event) => {
                setRegistInfo({
                  ...registInfo,
                  userInfo: { ...registInfo.userInfo, firstNameKana: event.target.value },
                });
              }}
            />
          </div>
        </div>
        {viewType === 'Profile' && (
          <div className='registrationFormContent'>
            <div
              className='registrationFormContentLabel'
              style={{ padding: '14px 16px 14px 24px' }}
            >
              <label htmlFor='mail'>メールアドレス</label>
            </div>
            <div className='registrationFormContentInput'>{registInfo.userInfo.mailAddress}</div>
          </div>
        )}

        {viewType === 'Regist' && (
          <div className='registrationFormContent'>
            <div className='registrationFormContentLabel'>
              <label htmlFor='mail'>
                メールアドレス <span className='attentionRedText'>※</span>
              </label>
              <QuestionPopper value={QEXP.CustomerRegist.MAIL_ADDRESS} />
            </div>
            <div className='registrationFormContentInput'>
              <input
                className='clientRegistrationFormInput100'
                type='text'
                name='mail'
                id='mail'
                value={registInfo.userInfo.mailAddress}
                onChange={(event) => {
                  setRegistInfo({
                    ...registInfo,
                    userInfo: { ...registInfo.userInfo, mailAddress: event.target.value },
                  });
                }}
              />
            </div>
          </div>
        )}

        <div className='registrationFormContent'>
          <div className='registrationFormContentLabel'>
            <label htmlFor='tel'>
              電話番号 <span className='attentionRedText'>※</span>
            </label>
            <QuestionPopper value={QEXP.CustomerRegist.PHONE_NUMBER} />
          </div>
          <div className='registrationFormContentInput'>
            <input
              className='clientRegistrationFormInput100'
              type='text'
              name='tel'
              id='tel'
              value={registInfo.userInfo.phoneNumber}
              onChange={(event) => {
                setRegistInfo({
                  ...registInfo,
                  userInfo: { ...registInfo.userInfo, phoneNumber: event.target.value },
                });
              }}
            />
          </div>
        </div>
        <div className='registrationFormContent'>
          <div className='registrationFormContentLabel'>
            <label htmlFor='corporate_name'>
              法人名 <span className='attentionRedText'>※</span>
            </label>
            <QuestionPopper value={QEXP.CustomerRegist.CORP_NAME} />
          </div>
          <div className='registrationFormContentInput'>
            <input
              className='clientRegistrationFormInput100'
              type='text'
              name='corporate_name'
              id='corporate_name'
              value={registInfo.userInfo.corpoName}
              onChange={(event) => {
                setRegistInfo({
                  ...registInfo,
                  userInfo: { ...registInfo.userInfo, corpoName: event.target.value },
                });
              }}
            />
          </div>
        </div>
        <div className='registrationFormContent'>
          <div className='registrationFormContentLabel'>
            <label htmlFor='corporate_name02'>
              法人名（フリガナ） <span className='attentionRedText'>※</span>
            </label>
            <QuestionPopper value={QEXP.CustomerRegist.CORP_NAME_KANA} />
          </div>
          <div className='registrationFormContentInput'>
            <input
              className='clientRegistrationFormInput100'
              type='text'
              name='corporate_name02'
              id='corporate_name02'
              value={registInfo.userInfo.corpoNameKana}
              onChange={(event) => {
                setRegistInfo({
                  ...registInfo,
                  userInfo: { ...registInfo.userInfo, corpoNameKana: event.target.value },
                });
              }}
            />
          </div>
        </div>
        <div className='registrationFormContent'>
          <div className='registrationFormContentLabel'>
            <label htmlFor='department'>
              部署名
            </label>
            <QuestionPopper value={QEXP.CustomerRegist.DEPARTMENT} />
          </div>
          <div className='registrationFormContentInput'>
            <input
              className='clientRegistrationFormInput100'
              type='text'
              name='department'
              id='department'
              value={registInfo.userInfo.departmentName}
              onChange={(event) => {
                setRegistInfo({
                  ...registInfo,
                  userInfo: { ...registInfo.userInfo, departmentName: event.target.value },
                });
              }}
            />
          </div>
        </div>
        {viewType === 'Regist' && (
          <React.Fragment>
            <div className='registrationFormContent'>
              <div className='registrationFormContentLabel'>
                <label htmlFor='pass'>
                  パスワード <span className='attentionRedText'>※</span>
                </label>
                <QuestionPopper value={QEXP.CustomerRegist.PASSWORD} />
              </div>
              <div className='registrationFormContentInput'>
                <form>
                  <input
                    className='clientRegistrationFormInput100'
                    type='password'
                    name='pass'
                    id='pass'
                    value={registInfo.password1}
                    onChange={(event) => {
                      setRegistInfo({ ...registInfo, password1: event.target.value });
                    }}
                    autoComplete='on'
                  />
                  <span>{QEXP.CustomerRegist.PASSWORD2}</span>
                </form>
              </div>
            </div>
            <div className='registrationFormContent'>
              <div className='registrationFormContentLabel lastBorderNone'>
                <label htmlFor='pass02'>
                  パスワード（確認） <span className='attentionRedText'>※</span>
                </label>
                <QuestionPopper value={QEXP.CustomerRegist.PASSWORD_CONFIRM} />
                {registInfo.password1 !== registInfo.password2 && (
                  <div className='attentionRedText'>パスワードが一致していません</div>
                )}
              </div>
              <div className='registrationFormContentInput lastBorderNone'>
                <form>
                  <input
                    className='clientRegistrationFormInput100'
                    type='password'
                    name='pass02'
                    id='pass02'
                    value={registInfo.password2}
                    onChange={(event) => {
                      setRegistInfo({ ...registInfo, password2: event.target.value });
                    }}
                    autoComplete='on'
                  />
                </form>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className='registrationFormContent'>
          <div className='registrationFormContentLabel'>
            <label htmlFor='address'>
              郵便番号
            </label>
            <QuestionPopper value={QEXP.CustomerRegist.POSTCODE} />
          </div>
          <div className='registrationFormContentInput'>
            <p>
              〒
              <input
                type='text'
                name='post_code01'
                id='post_code01'
                value={registInfo.userInfo.postCode1}
                onChange={(event) => {
                  setRegistInfo({
                    ...registInfo,
                    userInfo: { ...registInfo.userInfo, postCode1: event.target.value },
                  });
                }}
                autoComplete='none'
              />
              -
              <input
                type='text'
                name='post_code02'
                id='post_code02'
                value={registInfo.userInfo.postCode2}
                onChange={(event) => {
                  setRegistInfo({
                    ...registInfo,
                    userInfo: { ...registInfo.userInfo, postCode2: event.target.value },
                  });
                }}
                autoComplete='none'
              />
              <button
                className='addressAcquisitionbtn'
                type='button'
                name='button'
                onClick={() => {
                  getAddress();
                }}
              >
                住所取得
              </button>
            </p>
          </div>
        </div>
        <div className='registrationFormContent'>
          <div className='registrationFormContentLabel'>
            <label htmlFor='address'>
              住所（都道府県・市区町村・番地）
            </label>
            <QuestionPopper value={QEXP.CustomerRegist.ADDRESS1} />
          </div>
          <div className='registrationFormContentInput'>
            <p>
              <input
                className='clientRegistrationFormInput100'
                type='text'
                name='address01'
                id='address01'
                value={registInfo.userInfo.address1}
                onChange={(event) => {
                  setRegistInfo({
                    ...registInfo,
                    userInfo: { ...registInfo.userInfo, address1: event.target.value },
                  });
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UserInfoTableEdit;
