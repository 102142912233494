import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';

interface Props {
  isModelingJudge: number;
}

const ModelingResultAreaTitle: React.FC<Props> = React.memo(({ isModelingJudge }) => {
  return isModelingJudge > 0 ? (
    <div
      className={`modelNoSpecEditFormCheckTag ${
        isModelingJudge === 1
          ? 'modelNoSpecEditFormCheckTagOk'
          : isModelingJudge === 2
          ? 'modelNoSpecEditFormCheckTagPartiallyOk'
          : 'modelNoSpecEditFormCheckTagNg'
      } `}
    >
      {isModelingJudge === 1 ? 'OK' : isModelingJudge === 2 ? 'OK（注意事項あり）' : 'NG'}
    </div>
  ) : null;
});

export default ModelingResultAreaTitle;
