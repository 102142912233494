import { saveAs } from 'file-saver';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIController } from '../../common/API';
import FlowArea from '../../components/flow/FlowArea';
import FooterContent from '../../components/footer/Footer';
import AllPagesPDFViewer from '../../components/pdf/allPage';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useProgress } from '../../provider/progress/ProgressProvider';

const usePDF = () => {
  const { setAlertShow } = useAlert();
  const { setProgressShow } = useProgress();
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setFile] = React.useState<File>();
  // 試算番号
  const trialNumber: string | undefined = React.useMemo(() => {
    if (!location.state) return undefined;
    return location.state.trialNumber;
  }, [location.state?.trialNumber]);

  const downloadPDF = () => {
    if (file) {
      saveAs(file, '試算表-' + trialNumber + '.pdf');
    }
  };

  async function getPDF(number: string) {
    setProgressShow(true);
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        navigate: navigate,
      })
    ).GetTrialCalcSpecPdf(number);
    if (result) {
      setFile(result);
    }
    setProgressShow(false);
  }
  React.useEffect(() => {
    if (trialNumber) {
      getPDF(trialNumber);
    }
  }, [trialNumber]);

  function resetCaretPosition() {
    const container = document.createElement('div'); // 編集不可の要素を作成
    container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
    container.style.opacity = '0'; // 編集不可の要素を非表示にする
    container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
    container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
    container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
    document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

    container.focus(); // 編集不可の要素にフォーカスを移動する
    window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

    // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
    container.style.display = 'none';
    container.style.display = '';

    container.blur(); // 編集不可の要素からフォーカスを外す
    document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
  }

  React.useEffect(() => {
    // resetCaretPosition関数を使用して、キャレット位置をリセットする
    resetCaretPosition();
  }, []);

  return (
    <div>
      <FlowArea active={3}></FlowArea>
      <div className='mainContentsBlock'>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>試算表ダウンロード</h1>
            </div>
          </div>
        </div>
        <div className='trialBalanceArea'>
          <AllPagesPDFViewer file={file} />
        </div>
        <div className='fixedListControlBlock'>
          <div className='fixedListControlInner'>
            <button
              className='btnBack'
              type='button'
              name='button'
              onClick={() => {
                navigate(-1);
              }}
              style={{ marginTop: '0px' }}
            >
              戻る
            </button>
            <button
              className='btnOrangeDownload'
              type='button'
              name='button'
              onClick={() => {
                downloadPDF();
              }}
              disabled={file === undefined ? true : false}
            >
              ダウンロードする
            </button>
          </div>
          <FooterContent />
        </div>
      </div>
    </div>
  );
};

export default usePDF;
