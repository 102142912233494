import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecList.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/header.css';
import OrixLogo from '../../assets/image/common/orix_logo.jpg';
import ModalSitePolicy from '../user/ModalSitePolicy';
import saveAs from 'file-saver';

interface Props {
  noUI?: boolean;
  topPage?: boolean;
}

const FooterContent: React.FC<Props> = React.memo(({ noUI, topPage }) => {
  const [isModalPolicyOpen, setIsModalPolicyOpen] = React.useState(false);
  return (
    <footer className={`footerBlock ${noUI ? 'footerNoUi' : 'footerHasControlUi'}`}>
      <div className={`footerInner ${topPage ? 'footerInnerOnTopPage' : null}`}>
        <div className='footerLogoBlock'>
          <div className='footerLogoLink'>
            <img src={OrixLogo} alt='OrixRentec' className='footerLogoImage'></img>
          </div>
        </div>
        <div className='footerMenuBlock'>
          <ul className={`footerMenuList ${topPage ? 'footerMenuListOnTopPage' : null}`}>
            <li className='footerMenuListItem'>
              <a
                className='footerMenuListItemLink'
                onClick={(event) => {
                  event.preventDefault();
                  saveAs(
                    process.env.PUBLIC_URL + '/TermsAndConditions/teams_and_conditons.pdf',
                    '造形受託約款.pdf'
                  );
                }}
              >
                利用規約
              </a>
            </li>
            <li className='footerMenuListItem'>
              <a
                className='footerMenuListItemLink'
                onClick={(event) => {
                  event.preventDefault();
                  setIsModalPolicyOpen(true);
                }}
              >
                サイトポリシー
              </a>
            </li>
            <li className='footerMenuListItem'>
              <a
                href='https://www.orixrentec.jp/privacypolicy.html'
                className='footerMenuListItemLink'
                target='_blank'
                rel='noopener noreferrer'
              >
                プライバシーポリシー
              </a>
            </li>
            <li className='footerMenuListItem'>
              <a
                href='https://www.orixrentec.jp/company/index.html'
                className='footerMenuListItemLink'
                target='_blank'
                rel='noopener noreferrer'
              >
                会社情報
              </a>
            </li>
            <li>
              <address className='footerAddress'>
                Copyright &copy; ORIX Rentec Corporation. All rights reserved.
              </address>
            </li>
          </ul>
        </div>
      </div>

      <ModalSitePolicy
        show={isModalPolicyOpen}
        setIsModalOpen={setIsModalPolicyOpen}
        topPage={topPage}
      ></ModalSitePolicy>
    </footer>
  );
});
export default FooterContent;
