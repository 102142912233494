import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../provider/cognito/AuthContext';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientLogin.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/header.css';
import FamsLogo from '../../assets/image/common/fabs_logo.svg';
import OrixLogo from '../../assets/image/common/orix_logo_gray.svg';
import { ReactComponent as LogoutIcon } from '../../assets/image/common/icon_logout.svg';
import ModalProfile from '../user/ModalProfile';
import ModalMailAddressEdit from '../user/ModalMailAddressEdit';
import ModalPasswordEdit from '../user/ModalPasswordEdit';
import { chatInit, chatReset, chatSetUser } from '../chat/ChatwootWidget';
import { useAlert } from '../../provider/alert/AlertProvider';
import { APIController } from '../../common/API';

interface Props {
  userName: string;
}

const HeaderContent: React.FC<Props> = React.memo(({ userName }) => {
  const { setAlertShow } = useAlert();
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const [isShowProfile, setIsShowProfile] = React.useState(false);
  const [isShowMailEdit, setIsShowMailEdit] = React.useState(false);
  const [isShowPWEdit, setIsShowPWEdit] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      chatInit(user);
      chatSetUser(user);
    }
  }, []);

  // サブメニューバーのポップアップ用
  const [isSubMenuPopUp, setIsSubMenuPopUp] = React.useState<Boolean>(false);

  // サブメニューバーエリア
  const subMenuPopupRef = React.useRef<HTMLDivElement>(null!);

  // サブメニューバーの非表示用にクリックイベントを設定
  function setPopupEvent() {
    // 対象の要素を取得
    const el = subMenuPopupRef.current;

    // 対象の要素がなければ何もしない
    if (!el) return;

    // クリックした時に実行する関数
    const handleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        // 外側をクリックしたときの処理
        setIsSubMenuPopUp(false);
      } else {
        // 内側をクリックしたときの処理
        // 特になし
      }
    };

    // クリックイベントを設定
    document.addEventListener('click', handleClickOutside);

    // クリーンアップ関数
    return () => {
      // コンポーネントがアンマウント、再レンダリングされたときにクリックイベントを削除
      document.removeEventListener('click', handleClickOutside);
    };
  }
  React.useEffect(setPopupEvent, [subMenuPopupRef]);

  return (
    <header className='headerBlock'>
      <div className='headerInner'>
        <div className='headerMainMenuBlock'>
          <div className='headerLogoBlock'>
            <a
              className='headerLogoLink'
              href='/#'
              onClick={(event) => {
                event.preventDefault();
                navigate('/ModelNumberList');
              }}
            >
              <img src={FamsLogo} alt='' className='headerLogoImage' />
              <img src={OrixLogo} alt='' className='headerOrixLogoImage' />
            </a>
          </div>
          <ul className='headerMainMenuList'>
            <li className='headerMainMenuListItemSingle'>
              <Link to='/ModelNumberList' className='headerMainMenuListItemSingleLink'>
                単価の試算一覧
              </Link>
            </li>
            <li className='headerMainMenuListItemMulti'>
              <Link to='/TrialCalcList' className='headerMainMenuListItemMultiLink'>
                複数個取りの試算一覧
              </Link>
            </li>
          </ul>
        </div>

        <div className='headerSubMenuBlock'>
          <a
            href='https://www.orixrentec.jp/3dprinter/faq/'
            className='headerSubMenuLinkFaq'
            target='_blank'
            rel='noopener noreferrer'
          >
            FAQ
          </a>
          <div
            className={`headerSubMenuLinkUserNameBlock ${
              isSubMenuPopUp
                ? 'headerSubMenuLinkUserNameBlockActive'
                : 'headerSubMenuLinkUserNameBlockDefalt'
            }`}
            ref={subMenuPopupRef}
          >
            <button
              className='headerSubMenuLinkUserNamePara button-style'
              onClick={() => {
                setIsSubMenuPopUp(true);
              }}
            >
              <span className='headerSubMenuLinkUserNameSpan'>{userName} 様</span>
            </button>
            <div className={`subMenuPopup ${isSubMenuPopUp ? '' : 'hidden'}`}>
              <ul className='headerSubMenuUserNameList'>
                <li className='headerSubMenuUserNameListItem'>
                  <button
                    className='headerSubMenuUserNameLink button-style-trans'
                    onClick={() => {
                      setIsShowProfile(true);
                    }}
                  >
                    プロフィール管理
                  </button>
                </li>
                <li className='headerSubMenuUserNameListItem'>
                  <button
                    className='headerSubMenuUserNameLink button-style-trans'
                    onClick={() => {
                      setIsShowMailEdit(true);
                    }}
                  >
                    メールアドレス変更
                  </button>
                </li>
                <li className='headerSubMenuUserNameListItem'>
                  <button
                    className='headerSubMenuUserNameLink button-style-trans'
                    onClick={() => {
                      setIsShowPWEdit(true);
                    }}
                  >
                    パスワード変更
                  </button>
                </li>
                <li className='headerSubMenuUserNameListItem'>
                  <button
                    className='headerSubMenuUserNameLink button-style-trans'
                    onClick={() => {
                      signOut();
                      chatReset();
                      navigate('/LogOut');
                    }}
                  >
                    ログアウト
                    <LogoutIcon className='headerSubMenuUserNameLinkImage'></LogoutIcon>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalProfile show={isShowProfile} setIsShowProfile={setIsShowProfile}></ModalProfile>

      <ModalMailAddressEdit
        show={isShowMailEdit}
        setIsShow={setIsShowMailEdit}
      ></ModalMailAddressEdit>

      <ModalPasswordEdit show={isShowPWEdit} setIsShow={setIsShowPWEdit}></ModalPasswordEdit>
    </header>
  );
});

export default HeaderContent;
