import React from 'react';
import Modal from '@mui/material/Modal';
import '../../assets/css/modalMailEdit.css';
import { useAuth } from '../../provider/cognito/AuthContext';
import { useProgress } from '../../provider/progress/ProgressProvider';

interface Props {
  show: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalPasswordEdit: React.FC<Props> = React.memo(({ show, setIsShow }) => {
  const { setProgressShow } = useProgress();
  const { changePassword } = useAuth();
  const [passwordNow, setPasswordNow] = React.useState('');
  const [passwordNew, setPasswordNew] = React.useState('');
  const [passwordNewConfirm, setPasswordNewConfirm] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  async function passwordChange() {
    setProgressShow(true);
    // パスワード変更
    try {
      await changePassword(passwordNow, passwordNew);
      setIsShow(false);
    } catch (err: any) {
      switch (err.code) {
        case 'LimitExceededException':
          setErrorMessage(
            'パスワードが試行回数が超過しました。しばらく時間が経ってからやり直してください。'
          );
          break;
        case 'NotAuthorizedException':
          setErrorMessage('パスワードが間違っています。');
          break;
        case 'InvalidPasswordException':
        case 'InvalidParameterException':
          setErrorMessage(
            'パスワードは英小文字と英大文字と数字を含み8文字以上で設定してください。\n記号（! # $ % & ( ) - = ^ @ + * . / ? _ 等）の使用も可能です。'
          );
          break;
        default:
          setErrorMessage('エラーが発生しました。やり直してください。');
          break;
      }
    }
    setProgressShow(false);
  }

  React.useEffect(() => {
    if (!show) {
      setErrorMessage('');
    }
  }, [show]);

  return (
    <div>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={show}
        disableScrollLock
      >
        <div className='modalMailEdit'>
          <h1 className='modalMailEditTitle'>パスワード変更</h1>
          <p className='modalMailEditTitleExp'>
            パスワードは英小文字と英大文字と数字を含み8文字以上で設定してください。<br></br>
            記号（! # $ % & ( ) - = ^ @ + * . / ? _ 等）の使用も可能です。
          </p>
          <div className='registrationFormContent'>
            <div className='modalMailEditLabel'>
              <label>現在のパスワード</label>
            </div>
            <div className='modalMailEditInput'>
              <form>
                <input
                  type='password'
                  readOnly
                  onFocus={(event) => {
                    event.target.removeAttribute('readonly');
                  }}
                  onChange={(event) => {
                    setPasswordNow(event.target.value);
                  }}
                />
              </form>
            </div>
          </div>
          <div className='registrationFormContent'>
            <div className='modalMailEditLabel'>
              <label>新しいパスワード</label>
            </div>
            <div className='modalMailEditInput'>
              <input
                type='password'
                readOnly
                onFocus={(event) => {
                  event.target.removeAttribute('readonly');
                }}
                value={passwordNew}
                onChange={(event) => {
                  const matchValue = event.target.value.match(
                    new RegExp('[^a-zA-Z0-9\\\\!"#$%&\'()*,-./:;<>?@[\\]^_`{|}~=+]')
                  );
                  if (!matchValue) {
                    setPasswordNew(event.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className='registrationFormContent'>
            <div className='modalMailEditLabel'>
              <label>新しいパスワード（確認）</label>
              {passwordNew !== passwordNewConfirm && (
                <div className='modalMailEditError'>パスワードが一致していません</div>
              )}
            </div>
            <div className='modalMailEditInput'>
              <input
                type='password'
                readOnly
                onFocus={(event) => {
                  event.target.removeAttribute('readonly');
                }}
                value={passwordNewConfirm}
                onChange={(event) => {
                  const matchValue = event.target.value.match(
                    new RegExp('[^a-zA-Z0-9\\\\!"#$%&\'()*,-./:;<>?@[\\]^_`{|}~=+]')
                  );
                  if (!matchValue) {
                    setPasswordNewConfirm(event.target.value);
                  }
                }}
              />
            </div>
          </div>

          <div className='modalMailEditCloseBtn'>
            <div className='modalMailEditError'>{errorMessage}</div>
            <button
              className='btnWhiteBaseBlue'
              type='button'
              name='button'
              onClick={() => {
                passwordChange();
              }}
              disabled={
                passwordNow === '' || passwordNew === '' || passwordNew !== passwordNewConfirm
                  ? true
                  : false
              }
            >
              変更
            </button>
            <button
              style={{ marginLeft: '20px' }}
              className='btnWhiteBaseGray'
              type='button'
              name='button'
              onClick={() => {
                setIsShow(false);
              }}
            >
              閉じる
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default ModalPasswordEdit;
