import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientRegistrationForm.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import UserInfoTableRead from './UserInfoTableRead';
import { viewProfileType } from './ModalProfile';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewProfileType>>;
  registInfo: MyIF.userRegistInfo;
  setIsShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileRead: React.FC<Props> = React.memo(
  ({ setViewSelect, registInfo, setIsShowProfile }) => {
    return (
      <div>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>プロフィール</h1>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <UserInfoTableRead profileFlg={true} registInfo={registInfo} />

          <div className='clientInformationConfirmationButtonArea'>
            <button
              className='btnBack'
              type='button'
              name='button'
              onClick={() => {
                setIsShowProfile(false);
              }}
            >
              閉じる
            </button>
            <button
              className='btnOrangeCommit'
              type='button'
              name='button'
              onClick={() => {
                setViewSelect('Edit');
              }}
            >
              編集
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default ProfileRead;
