import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/trialBalanceSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all';
import interactionPlugin from '@fullcalendar/interaction';
import styled from '@emotion/styled';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type calendarFunc = {
  setDatePrice(date: string, priceInfo?: MyIF.priceInfo): void;
};

interface Props {
  readOnly?: boolean;
  priceInfo: MyIF.priceInfo;
  holidayList: string[];
  deadLineInfo: MyIF.deadLineInfo;
  setDeadLineInfo: React.Dispatch<React.SetStateAction<MyIF.deadLineInfo>>;
  quantityAlert?: boolean;
}

export const StyleWrapper = styled.div`
  display: flex;
  gap: 32px;
  
  table,
  th,
  td {
    font-weight: normal;
    font-family: 'Arial';
  }

  // ボタン色
  .fc .fc-button-primary {
    background-color: #0056ba;
    border-color: #e6eef8;
    box-shadow: none;
  }
  .fc .fc-button-primary:active:focus {
    box-shadow: none;
  }

  // 選択中の日の色
  .fc .fc-highlight {
    background: transparent;
  }

  // ポインタ変更
  .fc-daygrid td {
    // cursor: pointer;
  }

  // ヘッダーの高さ
  .fc table th {
    height: 20px;
  }

  // 今日の背景
  .fc .fc-daygrid-day.fc-day-today {
    background: rgba(28, 110, 205, 0.4);
  }

  // 日付を枠の中心に
  .fc .fc-daygrid-day-top {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100%;
  }
  // 日付を枠の高さいっぱいに
  .fc .fc-daygrid-day-frame {
    height: 100%;
  }
  
  .fc .fc-daygrid-body, .fc .fc-scrollgrid-sync-table {
    width: 100%;
  }

  // 平日の色
  th.fc-day-mon,
  th.fc-day-thu,
  th.fc-day-tue,
  th.fc-day-wed,
  th.fc-day-fri {
    background-color: #ffffff;
  }

  // 土日の色
  th.fc-day-sat {
    background-color: #eaf4ff;
  }
  th.fc-day-sun {
    background-color: rgba(255, 234, 234, 0.7);
  }
  td.fc-day-sat {
    background-color: #eaf4ff;
  }
  td.fc-day-sun {
    background-color: rgba(255, 234, 234, 0.7);
  }

  // イベントの幅はいらないので非表示
  .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
    display: none;
  }
  
  // カスタムボタン
  .fc .fc-customPrev-button, .fc .fc-customNext-button {
    font-weight: bold;
  }
`;

const Calendar = React.forwardRef<calendarFunc, Props>(
  ({ readOnly, priceInfo, holidayList, deadLineInfo, setDeadLineInfo, quantityAlert }, ref) => {
    type eventType = {
      start: string;
      end: string;
      className: string;
      display: string;
    };

    // 今日から営業日で加算した日をISO形式で返却
    const getTodayAddDate = React.useCallback(
      (add: number) => {
        const holidayOffset = () => {
          let offsetNum = 0;
          // 休日ならオフセット繰り返す
          while (true) {
            if (holidayList.indexOf(convertISOdate(today)) !== -1) {
              today.setDate(today.getDate() + 1);
              offsetNum += 1;
            } else {
              break;
            }
          }
          // 日曜日なら1日オフセット
          if (today.getDay() === 0) {
            today.setDate(today.getDate() + 1);
            offsetNum += 1;
          }
          // 土曜日なら2日オフセット
          if (today.getDay() === 6) {
            today.setDate(today.getDate() + 2);
            offsetNum += 2;
          }
          return offsetNum;
        };

        const today = new Date();
        [...Array(add)].map((_, index) => {
          today.setDate(today.getDate() + 1);
          // １日単位でオフセットして休日であるかチェックする
          // 土日＆休日の繰り返しがあるため、オフセットがなくなるまでチェック
          while (true) {
            const offsetNum = holidayOffset();
            if (offsetNum === 0) {
              break;
            }
          }
          return null;
        });
        return convertISOdate(today);
      },
      [holidayList]
    );

    // ISO形式に変換
    const convertISOdate = (date: Date) => {
      function dateZeroPadding(date: number) {
        return ('0' + date).slice(-2);
      }
      const rtn =
        date.getFullYear() +
        '-' +
        dateZeroPadding(date.getMonth() + 1) +
        '-' +
        dateZeroPadding(date.getDate());
      return rtn;
    };

    // 初期値は過去日を追加
    const [leftEvents, setLeftEvents] = React.useState<eventType[]>([
      {
        start: '1990-01-01',
        end: getTodayAddDate(0),
        className: 'eventCalendarGray',
        display: 'background',
      },
    ]);

    const [rightEvents, setRightEvents] = React.useState<eventType[]>([
      {
        start: '1990-01-01',
        end: getTodayAddDate(0),
        className: 'eventCalendarGray',
        display: 'background',
      },
    ]);

    const leftRef = useRef<FullCalendar>(null);
    const rightRef = useRef<FullCalendar>(null);
    const firstEffect = useRef(true);

    const [isShowFull, setIsShowFull] = useState(true);
    const [isShowAlert, setIsShowAlert] = useState(true);

    React.useImperativeHandle(ref, () => ({
      setDatePrice(date: string, priceInfo?: MyIF.priceInfo) {
        setDatePricePrivate(date, priceInfo);
      },
    }));

    // 希望納期・価格を設定
    const setDatePricePrivate = (date: string, inPriceInfo?: MyIF.priceInfo) => {
      const tmpDeadLineInfo: MyIF.deadLineInfo = { deadLineDate: '', price: '' };
      let _priceInfo = inPriceInfo ? inPriceInfo : priceInfo;
      console.log('setDatePricePrivate:date', new Date(date));
      console.log('setDatePricePrivate', _priceInfo);
      if (
        _priceInfo.express.date &&
        _priceInfo.normal.date &&
        _priceInfo.express.date !== '' &&
        _priceInfo.normal.date !== ''
      ) {
        const expressDate = getTodayAddDate(Number(_priceInfo.express.date));
        const normalDate = getTodayAddDate(Number(_priceInfo.normal.date));
        console.log('normalDate', normalDate);
        console.log('expressDate', expressDate);
        if (new Date(normalDate) <= new Date(date)) {
          let price = _priceInfo.normal.price ? _priceInfo.normal.price.toLocaleString() : '-';
          tmpDeadLineInfo.price = '¥' + price;
        } else {
          if (new Date(expressDate) <= new Date(date)) {
            let price = _priceInfo.express.price ? _priceInfo.express.price.toLocaleString() : '-';
            tmpDeadLineInfo.price = '¥' + price;
          } else {
            tmpDeadLineInfo.price = '￥-';
          }
        }
      }
      if (new Date(date) < new Date()) {
        tmpDeadLineInfo.deadLineDate = '';
      } else {
        tmpDeadLineInfo.deadLineDate = date;
        // カレンダーに選択日を追加
        changeSelectDate(new Date(date));
      }
      setDeadLineInfo(tmpDeadLineInfo);
    };

    /**
     * 価格情報が変わればカレンダーもメンテナンス
     */
    React.useEffect(() => {
      if (!readOnly) {
        setDatePricePrivate(deadLineInfo.deadLineDate, priceInfo);
      }
    }, [priceInfo]);

    /**
     * カレンダーに休日、担当者連絡日、特急納期日を追加
     */
    React.useEffect(() => {
      let tmpEvents = leftEvents.slice();
      const pushEvents = (start: string, end: string, className: string) => {
        tmpEvents.unshift({
          start: start,
          end: end,
          className: className,
          display: 'background',
        });
      };

      // 休日がすでに追加されていれば削除
      tmpEvents = tmpEvents.filter((e) => e.className !== 'eventCalendarHoliday');

      // 休日追加
      holidayList.forEach((element) => {
        // 日曜は色が重複するから追加しない
        if (new Date(element).getDay() !== 0) {
          pushEvents(element, element, 'eventCalendarHoliday');
        }
      });

      // 担当者連絡
      // すでに追加されていれば削除
      tmpEvents = tmpEvents.filter((e) => e.className !== 'eventCalendarPhone');
      tmpEvents = tmpEvents.filter(
        (e) => e.className !== 'eventCalendarHoliday eventCalendarPhone'
      );

      // この先は日付が存在するときのみ実施
      if (priceInfo.express.date && priceInfo.normal.date) {
        // 枠をつけるために、１日ずつイベントを追加
        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        const startDate = convertISOdate(nextDay);
        const stopDate = getTodayAddDate(Number(priceInfo.express.date));
        if (new Date(startDate) < new Date(stopDate)) {
          const pushDate = new Date(startDate);
          while (true) {
            // 最後の日は含めない
            if (pushDate >= new Date(stopDate)) {
              break;
            }
            const isoDate = convertISOdate(pushDate);

            // 既に休日として登録されていれば、クラス合体させる
            tmpEvents.forEach((element) => {
              if (element.className === 'eventCalendarHoliday' && element.start === isoDate) {
                element.className = 'eventCalendarHoliday eventCalendarPhone';
              }
            });
            pushEvents(isoDate, isoDate, 'eventCalendarPhone');
            pushDate.setDate(pushDate.getDate() + 1);
          }
        }
      }

      // 特急納期
      // すでに追加されていれば削除
      tmpEvents = tmpEvents.filter((e) => e.className !== 'eventCalendarExpress');
      tmpEvents = tmpEvents.filter(
        (e) => e.className !== 'eventCalendarHoliday eventCalendarExpress'
      );

      // この先は日付が存在するときのみ実施
      if (priceInfo.express.date && priceInfo.normal.date) {
        // 枠をつけるために、１日ずつイベントを追加
        const startDateEx = getTodayAddDate(Number(priceInfo.express.date));
        const stopDateEx = getTodayAddDate(Number(priceInfo.normal.date));
        if (new Date(startDateEx) < new Date(stopDateEx)) {
          const pushDate = new Date(startDateEx);
          while (true) {
            // 最後の日は含めない
            if (pushDate >= new Date(stopDateEx)) {
              break;
            }
            const isoDate = convertISOdate(pushDate);

            // 既に休日として登録されていれば、クラス合体させる
            tmpEvents.forEach((element) => {
              if (element.className === 'eventCalendarHoliday' && element.start === isoDate) {
                element.className = 'eventCalendarHoliday eventCalendarExpress';
              }
            });
            pushEvents(isoDate, isoDate, 'eventCalendarExpress');
            pushDate.setDate(pushDate.getDate() + 1);
          }
        }
      }

      // 通常納期
      tmpEvents = tmpEvents.filter((e) => e.className !== 'eventCalendarNormal');
      tmpEvents = tmpEvents.filter(
        (e) => e.className !== 'eventCalendarHoliday eventCalendarNormal'
      );

      if (priceInfo.normal.date) {
        // 枠をつけるために、１日ずつイベントを追加
        const startDateEx = getTodayAddDate(Number(priceInfo.normal.date));
        const stopDateEx = getTodayAddDate(1000);
        if (new Date(startDateEx) < new Date(stopDateEx)) {
          const pushDate = new Date(startDateEx);
          while (true) {
            // 最後の日は含めない
            if (pushDate >= new Date(stopDateEx)) {
              break;
            }
            const isoDate = convertISOdate(pushDate);

            // 既に休日として登録されていれば、クラス合体させる
            tmpEvents.forEach((element) => {
              if (element.className === 'eventCalendarHoliday' && element.start === isoDate) {
                element.className = 'eventCalendarHoliday eventCalendarNormal';
              }
            });
            pushEvents(isoDate, isoDate, 'eventCalendarNormal');
            pushDate.setDate(pushDate.getDate() + 1);
          }
        }
      }

      setLeftEvents(tmpEvents);
      setRightEvents(tmpEvents);
    }, [priceInfo, getTodayAddDate, holidayList]);

    const changeSelectDate = (date: Date) => {
      const isoDate = convertISOdate(date);
      // 既に選択中のものがあれば、それを更新する
      let checkFlg = false;
      const tmpEvents = leftEvents.slice();
      tmpEvents.forEach((event) => {
        if (event.className === 'eventCalendarSelect') {
          event.start = isoDate;
          event.end = isoDate;
          checkFlg = true;
        }
      });

      if (checkFlg === false) {
        // なければ新規追加
        tmpEvents.push({
          start: isoDate,
          end: isoDate,
          className: 'eventCalendarSelect',
          display: 'background',
        });
      }
      setLeftEvents(tmpEvents);
      setRightEvents(tmpEvents);
    };

    const customButtons = useMemo(() => {
      return {
        customToday: {
          text: '本日',
          click: () => {
            const leftCalendarApi = leftRef.current?.getApi();
            const rightCalendarApi = rightRef.current?.getApi();
            leftCalendarApi?.today();
            rightCalendarApi?.today();
            rightCalendarApi?.next();
            setIsShowAlert(false);
          }
        },
        customPrev: {
          text: '<',
          click: () => {
            const leftCalendarApi = leftRef.current?.getApi();
            const rightCalendarApi = rightRef.current?.getApi();
            leftCalendarApi?.prev();
            rightCalendarApi?.prev();
            setIsShowAlert(false);
          }
        },
        customNext: {
          text: '>',
          click: () => {
            const leftCalendarApi = leftRef.current?.getApi();
            const rightCalendarApi = rightRef.current?.getApi();
            leftCalendarApi?.next();
            rightCalendarApi?.next();
            setIsShowAlert(false);
          }
        }
      }
    }, [])

    useEffect(() => {
      if (firstEffect.current) {
        const rightCalendarApi = rightRef.current?.getApi();
        rightCalendarApi?.next();
        firstEffect.current = false;
      }
    }, []);

    const showNormalStyleFlag = useMemo(() => readOnly || !isShowFull, [readOnly, isShowFull]);

    const renderAlert = useMemo(() => {
      return <div className="trialBalanceSpecEditFormCalendarAlert" onClick={() => setIsShowAlert(false)}>
        <p>
          ご希望の納品日を
          <br />
          クリックしてください。
        </p>
        <p style={{ marginTop: 8 }}>□　画面を閉じる</p>
      </div>
    }, []);

    return (
      <section
        className={`trialBalanceSpecEditFormAccordionContent ${showNormalStyleFlag ? '' : 'mainContentsBlock'}`}
        style={showNormalStyleFlag ? {} : { backgroundColor: '#ffffff', paddingRight: 24, position: 'relative', zIndex: 20 }}
      >
        <input id='toggle8' type='checkbox' className='trialBalanceSpecEditFormToggle' />
        <label
          className='trialBalanceSpecEditFormToggleLabel'
          htmlFor='toggle8'
          onClick={() => setIsShowFull((value) => !value)}
        >
          希望納期選択
          <QuestionPopper value={QEXP.TrialCalcEdit.DATE} />
        </label>

        <div className='trialBalanceSpecEditFormToggleContentBlock'>
          <div className='trialBalanceSpecEditFormToggleContent'>
            {/* ここにcalendar配置 */}
            {readOnly !== true && (
              <StyleWrapper>
                <div className="trialBalanceSpecEditFormCalendarBlock">
                  <FullCalendar
                    ref={leftRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView='dayGridMonth'
                    locales={allLocales}
                    locale='ja'
                    firstDay={1}
                    height={'auto'}
                    customButtons={customButtons}
                    headerToolbar={{ right: 'customToday customPrev,customNext' }}
                    events={leftEvents}
                    dayCellContent={(event) => {
                      event.dayNumberText = event.dayNumberText.replace('日', '');
                    }}
                    selectable={true}
                    select={(event) => {
                      changeSelectDate(event.start);
                      setIsShowAlert(false);
                    }}
                    selectAllow={(event) => {
                      // 複数選択は不可
                      let startDate = event.start;
                      let endDate = event.end;
                      endDate.setSeconds(endDate.getSeconds() - 1);
                      if (startDate.getDate() !== endDate.getDate()) {
                        return false;
                      }

                      // 過去の日は選択不可
                      if (event.start < new Date()) {
                        return false;
                      } else {
                        setDatePricePrivate(event.startStr);
                        return true;
                      }
                    }}
                  />
                  {isShowAlert ? renderAlert : null}
                </div>
                <div className="trialBalanceSpecEditFormCalendarBlock">
                  <FullCalendar
                    ref={rightRef}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView='dayGridMonth'
                    locales={allLocales}
                    locale='ja'
                    firstDay={1}
                    height={'auto'}
                    headerToolbar={{ right: undefined }}
                    events={rightEvents}
                    dayCellContent={(event) => {
                      event.dayNumberText = event.dayNumberText.replace('日', '');
                    }}
                    selectable={true}
                    select={(event) => {
                      changeSelectDate(event.start);
                      setIsShowAlert(false);
                    }}
                    selectAllow={(event) => {
                      // 複数選択は不可
                      let startDate = event.start;
                      let endDate = event.end;
                      endDate.setSeconds(endDate.getSeconds() - 1);
                      if (startDate.getDate() !== endDate.getDate()) {
                        return false;
                      }

                      // 過去の日は選択不可
                      if (event.start < new Date()) {
                        return false;
                      } else {
                        setDatePricePrivate(event.startStr);
                        return true;
                      }
                    }}
                  />
                  {isShowAlert ? renderAlert : null}
                </div>
              </StyleWrapper>
            )}
            {readOnly !== true && (
              <div className='trialBalanceSpecEditFormCalendarExplanation'>
                <ul>
                  <li>
                    <span className='telExplanationBox2'></span>
                    <p>担当者連絡</p>
                  </li>
                  <li>
                    <span className='expressfeeExplanationBox2'></span>
                    <p>特急料金</p>
                  </li>
                  <li>
                    <span className='normalfeeExplanationBox'></span>
                    <p>通常料金</p>
                  </li>
                </ul>
              </div>
            )}
            <div className='trialBalanceSpecEditFormCalendarResult'>
              <ul style={showNormalStyleFlag ? {} : { width: '50%' }}>
                <li>
                  <label>希望納期</label>
                  <div className='resultForm' style={{ width: '100px' }}>
                    {deadLineInfo.deadLineDate.replaceAll('-', '/')}
                  </div>
                </li>
                <li>
                  <label style={{ marginLeft: '-10px', width: '40px' }}>価格</label>
                  <div className='resultForm' style={{ width: '160px' }}>
                    {quantityAlert ? '￥-' : deadLineInfo.price}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default React.memo(Calendar);
