import * as THREE from 'three';
//vertex, edge, faceの親クラス（単体の取り扱いを行うクラス・実際は単体的複体）
export class Simplex {

    //フィールド変数
    private _vertexData: number[];
    private _simplexData: number[][];
    private _viewMesh: THREE.Mesh | THREE.LineSegments;
    private _collisionMeshes: THREE.Mesh[];

    //コンストラクタ
    constructor(vertexData: number[], simplexData: number[][], viewMesh: THREE.Mesh | THREE.LineSegments, collisionMeshes: THREE.Mesh[]){
        this._vertexData = vertexData;
        this._simplexData = simplexData;
        this._viewMesh = viewMesh;
        this._collisionMeshes = collisionMeshes;
    }

    //メソッド
    public addTo(scene: THREE.Scene){
        scene.add(this._viewMesh);
        this._collisionMeshes.forEach(mesh => scene.add(mesh));
    }
    public getVerticesData(): number[]{
        return this._vertexData;
    }
    public getData(): number[][]{
        return this._simplexData;
    }
    public getViewMesh(): THREE.Mesh | THREE.LineSegments{
        return this._viewMesh;
    }
    public getMeshes(): THREE.Mesh[]{
        return this._collisionMeshes;
    }
    public visibleOption(bool: boolean){
        if(!(this._viewMesh.material instanceof Array)) {
            this._viewMesh.material.visible = bool;
        }
    }
    public removeFrom(scene: THREE.Scene){
        if(this._viewMesh.material instanceof THREE.Material) this._viewMesh.material.dispose();
        for(var name in this._viewMesh.geometry.attributes){ 
            this._viewMesh.geometry.deleteAttribute(name);
        }
        this._viewMesh.geometry.dispose();
        scene.remove(this._viewMesh);
        this._collisionMeshes.forEach(mesh => {
            if(mesh.material instanceof THREE.Material) mesh.material.dispose();
            mesh.geometry.dispose();
            scene.remove(mesh)
        });
    }
}