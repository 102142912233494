import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/designCommon.css';
import '../../assets/css/top.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
interface Props {
  aboutRef: React.MutableRefObject<HTMLDivElement>;
  aboutRef2: React.MutableRefObject<HTMLDivElement>;
  aboutRef3: React.MutableRefObject<HTMLDivElement>;
}

const FirstViewContents: React.FC<Props> = React.memo(
  ({ aboutRef, aboutRef2, aboutRef3}) => {
    const navigate = useNavigate();

    return (
      <div>
        <div className='mobileLoginButtonContainer'>
          <button
            className='mobileRegistButton'
            onClick={() => {
              navigate('/UserRegister');
            }}
          >
            <span>新規会員登録</span>
          </button>
          <button
            className='mobileLoginButton'
            onClick={() => {
              navigate('/Login', { state: { viewSelect: 'Login' } });
            }}
          >
            <span>ログイン</span>
          </button>
        </div>
        <div className='topFirstViewContents'>
          <div className='subNaviBlock'>
            <ul className='subNaviContents'>
              <li onClick={() => {
                aboutRef2.current.scrollIntoView();
              }}
                  style={{ cursor: 'pointer' }}
              ><a>「3D-FABsで、できること」</a>
              </li>
            </ul>
          </div>
          <div className='topFirstViewBlock'>
            <div className='topFirstViewTextBlock'>
              <p className='subheading'>オンライン3Dプリント シミュレーション</p>
              <h1 className='topFirstViewTextH1'>
                3D-FABs
              </h1>
              <h2 className='topFirstViewTextH2'>
                スリーディーファブズ
              </h2>
              <p className='subtext'>
             <span className='textBlue'>「金額・納期・造形ノウハウ」
               <br />
               知りたい知識をAIが提供
             </span>
            </p>
            <div className='topFirstViewButtonArea'>
              <div className='conversionButton'>
                <a
                  className='btnOrangeSignup loginButton'
                  href='/#'
                  onClick={(event) => {
                    event.preventDefault();
                    navigate('/Login');
                  }}
                >
                  <span style={{ lineHeight: '20px' }}>
                    試算・造形シミュレーションが使える
                    <br />
                    無料会員登録はこちら
                  </span>
                  <span>{'>'}</span>
                </a>
              </div>
            </div>
            </div>
            <div className='topFirstViewMovieBlock'>
              <div className='topFirstViewMovieIframe'>
                <iframe className="pc-video"　width="668" height="376" src="https://www.youtube.com/embed/0IBvzi-OEHU?si=icSXRa1m0MiHV0N2&mute=1&rel=0" title="YouTube video player"
                  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowFullScreen>
                </iframe>
                <iframe  className="mobile-video" width="100%" height="auto" src="https://www.youtube.com/embed/0IBvzi-OEHU?si=icSXRa1m0MiHV0N2&mute=1&rel=0" title="YouTube video player"
                 frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"allowFullScreen>
                </iframe>
              </div>
             </div>
          </div>
        </div>
      </div>
    );
  }
);

export default FirstViewContents;
