import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import ModalMaterialDetails from './ModalMaterialDetails';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { useNavigate } from 'react-router-dom';

interface Props {
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modelViewInfo: MyIF.modelViewInfo;
  printerValue: string;
}

const ModalMaterialHikaku: React.FC<Props> = React.memo(
  ({ show, setIsModalOpen, modelViewInfo, printerValue }) => {
    const { setAlertShow } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();

    const [materialPriceList, setMaterialPriceList] = React.useState<MyIF.materialPrice[]>([]);

    // 材料詳細画面　制御用
    const [isModalMateDetailsOpen, setIsModalMateDetailsOpen] = React.useState(false);
    const [detailsTitle, setDetailsTitle] = React.useState('');
    const [detailsMemo, setDetailsMemo] = React.useState('');
    const [detailsImage, setDetailsImage] = React.useState('');
    const [detailsSubTitle, setDetailsSubTitle] = React.useState('');

    React.useEffect(() => {
      (async () => {
        if (show && modelViewInfo.id !== '') {
          setProgressShow(true);
          const result = await (
            await APIController.build({
              setAlertShow: setAlertShow,
              navigate: navigate,
            })
          ).GetMaterialPrice(modelViewInfo.id, printerValue);
          if (result != null) {
            setMaterialPriceList(result);
          }
          setProgressShow(false);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    if (show) {
      return (
        <div>
          <section className='modalWindowWrapper modelNoSpecEditModalCompareMaterial'>
            <div className='modalWindowBlock'>
              <div className='modalWindowInner modalWindowHasHeader'>
                <div className='modalWindowHeader'>
                  <h1 className='modalWindowHeaderTitle'>材料比較</h1>
                  <button
                    className='modalWindowHeaderCloseLink button-style'
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    閉じる
                  </button>
                </div>
                <div className='modalWindowContents'>
                  <div className='modelNoSpecEditModalCompareMaterialContents'>
                    <ul className='modelNoSpecEditModalCompareMaterialList'>
                      <li>
                        <div
                          style={{
                            fontSize: '12px',
                            textAlign: 'right',
                            marginTop: '10px',
                            marginRight: '10px',
                            color: 'red',
                          }}
                        >
                          ※価格にはオプション費用を含みません
                        </div>
                      </li>
                      {materialPriceList.map((materialPrice: MyIF.materialPrice, idx) => {
                        return (
                          <li
                            key={idx}
                            className='modelNoSpecEditModalCompareMaterialListItem'
                            onClick={() => {
                              setDetailsTitle(materialPrice.materialValue);
                              setDetailsMemo(materialPrice.materialMemo);
                              setDetailsImage(materialPrice.materialImage);
                              setDetailsSubTitle(materialPrice.materialSubTitle);
                              setIsModalMateDetailsOpen(true);
                            }}
                          >
                            <div className='modelNoSpecEditModalCompareMaterialTitle'>
                              {materialPrice.materialValue}
                            </div>
                            <div className='modelNoSpecEditModalCompareMaterialDetail'>
                              <div className='modelNoSpecEditModalCompareMaterialDetailPrice'>
                                ￥{materialPrice.price ? materialPrice.price.toLocaleString() : '-'}
                              </div>
                              <div className='modelNoSpecEditModalCompareMaterialDelivery'>
                                納期：{materialPrice.date ? materialPrice.date : '- '}営業日
                              </div>
                            </div>
                            <button
                              className='modelNoSpecEditModalCompareMaterialDetailLink'
                              onClick={() => {
                                setDetailsTitle(materialPrice.materialValue);
                                setDetailsMemo(materialPrice.materialMemo);
                                setDetailsImage(materialPrice.materialImage);
                                setDetailsSubTitle(materialPrice.materialSubTitle);
                                setIsModalMateDetailsOpen(true);
                              }}
                            >
                              材料詳細
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* 材料詳細画面 */}
          <ModalMaterialDetails
            show={isModalMateDetailsOpen}
            setIsModalOpen={setIsModalMateDetailsOpen}
            title={detailsTitle}
            memo={detailsMemo}
            image={detailsImage}
            subTitle={detailsSubTitle}
          ></ModalMaterialDetails>
        </div>
      );
    } else {
      return null;
    }
  }
);

export default ModalMaterialHikaku;
