import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import FlowArea from '../../components/flow/FlowArea';
import NameArea from '../../components/trialCalcSpecDetails/NameArea';
import ModelNumberSelectArea from '../../components/trialCalcSpecDetails/ModelNumberSelectArea';
import TrialCalcConditionArea from '../../components/trialCalcSpecDetails/TrialCalcConditionArea';
import TrialCalcPrice from '../../components/trialCalcSpecDetails/TrialCalcPrice';
import Calendar, { calendarFunc } from '../../components/trialCalcSpecDetails/Calendar';
import ViewerArea from '../../components/trialCalcSpecDetails/ViewerArea';
import { useProgress } from '../../provider/progress/ProgressProvider';
import FooterContent from '../../components/footer/Footer';
import saveAs from 'file-saver';

const useTrialCalcSpecRef = () => {
  const { setAlertShow, setMessageList } = useAlert();
  const { setProgressShow } = useProgress();
  const location = useLocation();
  const navigate = useNavigate();

  // 試算番号
  const trialNumber: string = location.state.trialNumber;
  // ラベル名
  const [labelName, setLabelName] = React.useState<string>(location.state.labelName);
  // 型番リスト
  const [addModelNumberList, setAddModelNumberList] = React.useState<MyIF.addModelNumber[]>([]);
  // 造形条件
  const [selectModelCondition, setSelectModelCondition] =
    React.useState<MyIF.selectModelingConditionType>({
      modelingMaterialId: undefined,
      materialValue: '',
      methodValue: '',
      printerValue: '',
    });
  // 単価情報
  const [priceInfo, setPriceInfo] = React.useState<MyIF.priceInfo>({
    normal: {
      price: 0,
      date: '',
    },
    express: {
      price: 0,
      date: '',
    },
  });
  // バウンディングボックス
  const [boudingBox, setBoudingBox] = React.useState<MyIF.boundingBoxInfo>();
  // バウンディングボックス　カレントバッチ
  const [currentBatch, setCurrentBatch] = React.useState<number>(1);
  // 休日リスト
  const holidayList: string[] = [];
  // 希望納期・価格
  const [deadLineInfo, setDeadLineInfo] = React.useState<MyIF.deadLineInfo>({
    deadLineDate: '',
    price: '',
  });
  // カレンダーフック
  const calendarRef = React.useRef<calendarFunc>(null!);
  // PDFタイプ
  const pdfType = React.useMemo(() => {
    return location.state.pdfType as 'Quote' | 'TrialCalc' | 'disabled';
  }, [location.state.pdfType]);
  // 表示するフロータイプ
  const flowType = React.useMemo(() => {
    return location.state.flow4Flg as boolean | undefined;
  }, [location.state.flow4Flg]);

  /**
   * 計算結果取得
   */
  const GetCalcResult = React.useCallback(
    async (addList: MyIF.addModelNumber[]) => {
      const calcResult = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetTrialCalcResult(trialNumber, addList);
      if (calcResult !== null) {
        setBoudingBox(calcResult.boundingBoxInfo);
      }
    },
    [trialNumber]
  );

  /**
   * 試算仕様詳細取得API
   */
  React.useEffect(() => {
    (async () => {
      if (trialNumber === '') {
        // 型番が空白の場合は新規のため呼び出ししない
        return;
      }
      setProgressShow(true);

      // 詳細取得API
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          setAlertMessage: setMessageList,
          navigate: navigate,
        })
      ).GetTrialCalcDetail(trialNumber);
      if (result !== null) {
        setLabelName(result.labelName);
        setAddModelNumberList(result.modelNumberList);
        setSelectModelCondition(result.selectModelCondition);
        setPriceInfo(result.priceInfo);
        GetCalcResult(result.modelNumberList);
        setDeadLineInfo(result.deadLineInfo);
      }
      setProgressShow(false);
    })();
  }, []);

  async function GetQuotePdf(specNumber: string) {
    const result = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        navigate: navigate,
      })
    ).GetQuotePdf(specNumber);
    if (result) {
      saveAs(result, '見積書-' + specNumber + '.pdf');
    }
  }

  return (
    <div>
      <FlowArea active={flowType ? 4 : 2}></FlowArea>
      <div className='mainContentsBlock'>
        <div className='modelNoSpecEditBlock'>
          <div className='modelNoSpecEditFormBlock'>
            <h1 className='pageTitle'>複数個取りと納期の試算（参照）</h1>
            {/* 名称 */}
            <NameArea
              readOnly={true}
              trialNumber={trialNumber}
              labelName={labelName}
              setLabelName={setLabelName}
            ></NameArea>

            {/* 対象型番と数量 */}
            <ModelNumberSelectArea
              readOnly={true}
              addModelNumberList={addModelNumberList}
              setAddModelNumberList={setAddModelNumberList}
            ></ModelNumberSelectArea>

            {/* 試算条件 */}
            <TrialCalcConditionArea
              selectModelCondition={selectModelCondition}
            ></TrialCalcConditionArea>

            {/* 試算結果 */}
            <TrialCalcPrice priceInfo={priceInfo}></TrialCalcPrice>

            {/* 納期カレンダー */}
            <Calendar
              ref={calendarRef}
              readOnly={true}
              priceInfo={priceInfo}
              holidayList={holidayList}
              deadLineInfo={deadLineInfo}
              setDeadLineInfo={setDeadLineInfo}
            ></Calendar>
          </div>

          <ViewerArea
            readOnly={true}
            boudingBox={boudingBox}
            currentBatch={currentBatch}
            setCurrentBatch={setCurrentBatch}
          />
        </div>
        <div className='fixedListControlBlock'>
          <div className='fixedListControlInner'>
            <button
              className='btnWhiteBaseGray'
              type='button'
              name='button'
              onClick={() => {
                navigate('/TrialCalcList');
              }}
            >
              戻る
            </button>
            {pdfType === 'Quote' && (
              <button
                className='btnQuoteDownload'
                type='button'
                name='button'
                onClick={() => {
                  GetQuotePdf(trialNumber);
                }}
                style={{ marginLeft: '10px' }}
              >
                正式見積ダウンロード
              </button>
            )}
            {pdfType === 'disabled' && (
              <button
                className='btnQuoteDownload'
                type='button'
                name='button'
                disabled
                style={{ marginLeft: '10px' }}
              >
                試算表ダウンロード
              </button>
            )}
            {pdfType === 'TrialCalc' && (
              <button
                className='btnQuoteDownload'
                type='button'
                name='button'
                onClick={() => {
                  navigate('/PDF', {
                    state: {
                      trialNumber: trialNumber,
                    },
                  });
                }}
                style={{ marginLeft: '10px' }}
              >
                試算表ダウンロード
              </button>
            )}
          </div>
          <FooterContent />
        </div>
      </div>
    </div>
  );
};

export default useTrialCalcSpecRef;
