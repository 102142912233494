import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import Modal from '@mui/material/Modal';

interface Props {
  show: boolean;
  message: string;
  handleCancel: () => void;
}

const AlertFailCalcMessage : React.FC<Props> = React.memo(
  ({ show, message, handleCancel }) => {
    return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={show}
    >
      <div className='modalWindowBlock modalRemindMessage' style={{ minWidth: '500px' }}>
        <div className='modalWindowInner modalWindowNoHeader'>
          <div className='modalWindowContents'>
            <h1 className='modalRemindMessageTitle'>警告</h1>
            <p className='modalRemindMessagePara'>複数個取りの計算でエラーが発生しました。</p>

            <div className='modalRemindMessageBtnBlock'>
              <button
                className='btnWhiteBaseGray'
                type='button'
                name='button'
                onClick={handleCancel}
              >
                閉じる
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default AlertFailCalcMessage;
