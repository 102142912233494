import React from 'react';
import * as MyIF from '../../common/Interface';
import FooterContent from '../../components/footer/Footer';
import HeaderNoLogin from '../../components/header/HeaderNoLogin';
import UserRegistRation from '../../components/user/Registration';
import UserRegistRationConfirm from '../../components/user/RegistrationConfirm';
import '../../assets/css/userRegist.css'

export type viewUserRegistType = 'Input' | 'Confirm';

const UserRegist = () => {
  const [registInfo, setRegistInfo] = React.useState<MyIF.userRegistInfo>({
    userInfo: {
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      mailAddress: '',
      phoneNumber: '',
      corpoName: '',
      corpoNameKana: '',
      departmentName: '',
      postCode1: '',
      postCode2: '',
      address1: '',
    },
    password1: '',
    password2: '',
    policyFlg: false,
    mailFlg: false,
  });

  const [viewSelect, setViewSelect] = React.useState<viewUserRegistType>('Input');
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [viewSelect]);

  function resetCaretPosition() {
    const container = document.createElement('div'); // 編集不可の要素を作成
    container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
    container.style.opacity = '0'; // 編集不可の要素を非表示にする
    container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
    container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
    container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
    document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

    container.focus(); // 編集不可の要素にフォーカスを移動する
    window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

    // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
    container.style.display = 'none';
    container.style.display = '';

    container.blur(); // 編集不可の要素からフォーカスを外す
    document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
  }

  React.useEffect(() => {
    // resetCaretPosition関数を使用して、キャレット位置をリセットする
    resetCaretPosition();
  }, []);

  React.useEffect(() => {
    document.body.style.minWidth = 'auto';
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.minWidth = '1024px';
      document.body.style.overflowX = 'auto';
    };
  }, []);

  return (
    <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
      <div style={{ flex: '1' }}>
        <HeaderNoLogin></HeaderNoLogin>
        {viewSelect === 'Input' && (
          <UserRegistRation
            setViewSelect={setViewSelect}
            registInfo={registInfo}
            setRegistInfo={setRegistInfo}
          ></UserRegistRation>
        )}
        {viewSelect === 'Confirm' && (
          <UserRegistRationConfirm
            setViewSelect={setViewSelect}
            registInfo={registInfo}
            setRegistInfo={setRegistInfo}
          ></UserRegistRationConfirm>
        )}
      </div>
      <FooterContent noUI={true}></FooterContent>
    </div>
  );
};

export default UserRegist;
