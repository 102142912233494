import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/trialBalanceSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';

interface Props {
  readOnly?: boolean;
  quantityAlert?: boolean;
  setIsAddModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  addModelNumberList: MyIF.addModelNumber[];
  setAddModelNumberList: React.Dispatch<React.SetStateAction<MyIF.addModelNumber[]>>;
  batchAlert?: boolean;
}

const ModelNumberSelectArea: React.FC<Props> = React.memo(
  ({
    readOnly,
    setIsAddModalOpen,
    addModelNumberList,
    setAddModelNumberList,
    quantityAlert,
    batchAlert,
  }) => {
    const deleteModelNumber = (modelNumber: string) => {
      let tmpList = addModelNumberList.slice();
      tmpList = tmpList.filter((x) => {
        return !(x.modelNumber === modelNumber);
      });
      setAddModelNumberList(tmpList);
    };

    const changeNum = (modelNumber: string, newNum: string) => {
      let tmpList = addModelNumberList.slice();
      tmpList.map((value) => {
        if (value.modelNumber === modelNumber) {
          value.num = newNum;
        }
        return value;
      });
      setAddModelNumberList(tmpList);
    };

    return (
      <section className='trialBalanceSpecEditFormAccordionContent'>
        <input id='toggle6' type='checkbox' className='trialBalanceSpecEditFormToggle' />
        <label className='trialBalanceSpecEditFormToggleLabel' htmlFor='toggle6'>
          対象型番と数量
          <QuestionPopper value={QEXP.TrialCalcEdit.MODEL_NUMBER_NUM} />
        </label>

        <div className='trialBalanceSpecEditFormToggleContentBlock'>
          <div className='trialBalanceSpecEditFormToggleContent'>
            <div className='trialBalanceSpecEditFormOptionSettingBlock'>
              {addModelNumberList.map((value: MyIF.addModelNumber, ii: number) => {
                return (
                  <div key={ii} className='trialBalanceSpecEditFormInputBlock'>
                    <label htmlFor={'form6-' + ii}>
                      {value.label === ''
                        ? value.modelNumber
                        : value.modelNumber + '(' + value.label + ')'}
                    </label>
                    <select
                      id={'form6-' + ii}
                      value={value.num}
                      onChange={(event) => {
                        changeNum(value.modelNumber, event.target.value);
                      }}
                      disabled={readOnly}
                    >
                      <option value='0'>選択してください</option>
                      {[...Array(30)].map((_, jj) => {
                        return (
                          <option key={jj} value={(jj + 1).toString()}>
                            {jj + 1}個
                          </option>
                        );
                      })}
                    </select>
                    {readOnly !== true && (
                      <button
                        className='trialBalanceSpecEditFormInputRowDeleteLink button-style'
                        onClick={() => {
                          deleteModelNumber(value.modelNumber);
                        }}
                      ></button>
                    )}
                  </div>
                );
              })}
              {quantityAlert && (
                <p className='trialBalanceSpecEditFormInputAttention'>
                  ※最大数を超えているため、造形レイアウト及び自動見積はできません。
                </p>
              )}

              {batchAlert && (
                <p className='trialBalanceSpecEditFormInputAttention'>
                  ※ページネーションが発生した場合、造形エリアに部品をすべて配置できないため、複数回の造形になります。
                </p>
              )}
              {readOnly !== true && (
                <div className='trialBalanceSpecEditFormInputAddRow'>
                  <button
                    className='trialBalanceSpecEditFormInputAddRowLink button-style'
                    onClick={() => {
                      if (setIsAddModalOpen) {
                        setIsAddModalOpen(true);
                      }
                    }}
                  >
                    <span className='trialBalanceSpecEditFormInputAddRowLinkBlink'>&emsp;&emsp;</span>
                    型番追加
                  </button>
                  <p className='trialBalanceSpecEditFormInputAddRowText'>使用する材料とプリンターが共通の型番は、同時に造形できます。</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default ModelNumberSelectArea;
