import * as React from 'react';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientLogin.css';
import { viewLoginType } from '../../pages/users/Login';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewLoginType>>;
}

const PasswordResetCompleted: React.FC<Props> = React.memo(({ setViewSelect }) => {
  function resetCaretPosition() {
    const container = document.createElement('div'); // 編集不可の要素を作成
    container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
    container.style.opacity = '0'; // 編集不可の要素を非表示にする
    container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
    container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
    container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
    document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

    container.focus(); // 編集不可の要素にフォーカスを移動する
    window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

    // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
    container.style.display = 'none';
    container.style.display = '';

    container.blur(); // 編集不可の要素からフォーカスを外す
    document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
  }

  React.useEffect(() => {
    // resetCaretPosition関数を使用して、キャレット位置をリセットする
    resetCaretPosition();
  }, []);

  return (
    <div className='mainContentsBlock noFlowBar'>
      <div className='clientRegistrationForm'>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>パスワードリセット完了</h1>
            </div>
          </div>
        </div>

        <div className='pageTitleDetailTextInput'>
          <p className='pageTitleDetailText passwordResetCompleted'>
            3D-FABsのパスワードのリセットが完了しました。
            <br />
            設定されたID（メールアドレス）及びパスワードを用いてログインしてください。
          </p>
        </div>

        <div className='clientpasswordResetButtonArea'>
          <button
            className='btnOrangeCommit'
            type='button'
            name='button'
            onClick={() => {
              setViewSelect('Login');
            }}
          >
            3D-FABs ログイン
          </button>
        </div>
      </div>
    </div>
  );
});

export default PasswordResetCompleted;
