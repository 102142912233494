import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/quotationRequest.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';

interface Props {
  userInfo: MyIF.userInfo;
}

const CommonContactTable: React.FC<Props> = React.memo(({ userInfo }) => {
  return (
    <table className='modelNoSpecAddressConfirmation'>
      <tbody>
        <tr>
          <th>氏名</th>
          <td>
            {userInfo.lastName} {userInfo.firstName}
          </td>
        </tr>
        <tr>
          <th>氏名（フリガナ）</th>
          <td>
            {userInfo.lastNameKana} {userInfo.firstNameKana}
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>{userInfo.mailAddress}</td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td>{userInfo.phoneNumber}</td>
        </tr>
        <tr>
          <th>法人名</th>
          <td>{userInfo.corpoName}</td>
        </tr>
        <tr>
          <th>法人名（フリガナ）</th>
          <td>{userInfo.corpoNameKana}</td>
        </tr>
        <tr>
          <th>部署名</th>
          <td>{userInfo.departmentName}</td>
        </tr>
        <tr>
          <th>住所</th>
          <td>
            〒{userInfo.postCode1}-{userInfo.postCode2}
            <br />
            {userInfo.address1}
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export default CommonContactTable;
