import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';

interface Props {
  trialCalcInfo: MyIF.trialCalcInfoType | undefined;
}

const TrialCalcInfoAreaContent: React.FC<Props> = React.memo(({ trialCalcInfo }) => {
  return (
    <div className='modelNoSpecEditFormToggleContentBlock'>
      <div className='modelNoSpecEditFormToggleContent'>
        <ul className='modelNoSpecEditFormCalculationList'>
          <li className='modelNoSpecEditFormCalculationListItem'>
            <div className='modelNoSpecEditFormCalculationTitle'>外形寸法</div>
            {trialCalcInfo && (
              <div className='modelNoSpecEditFormCalculationDetail'>
                X:{trialCalcInfo.outLine.x}mm　Y:{trialCalcInfo.outLine.y}mm　Z:
                {trialCalcInfo.outLine.z}mm
              </div>
            )}
          </li>
          <li className='modelNoSpecEditFormCalculationListItem'>
            <div className='modelNoSpecEditFormCalculationTitle'>面積</div>
            {trialCalcInfo && (
              <div className='modelNoSpecEditFormCalculationDetail'>
                表面積：{trialCalcInfo.area.surface}cm<span>2</span>　底面積：
                {trialCalcInfo.area.back}
                cm<span>2</span>
              </div>
            )}
          </li>
          <li className='modelNoSpecEditFormCalculationListItem'>
            <div className='modelNoSpecEditFormCalculationTitle'>体積</div>
            {trialCalcInfo && (
              <div className='modelNoSpecEditFormCalculationDetail'>
                本体：{trialCalcInfo.volume.main}cm<span>3</span>　サポート材：
                {trialCalcInfo.volume.support}
                cm<span>3</span>
              </div>
            )}
          </li>
          <li className='modelNoSpecEditFormCalculationListItem'>
            <div className='modelNoSpecEditFormCalculationTitle'>造形時間</div>
            {trialCalcInfo && (
              <div className='modelNoSpecEditFormCalculationDetail'>
                {trialCalcInfo.createTime}時間
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
});

export default TrialCalcInfoAreaContent;
