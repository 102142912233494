import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import '../../assets/css/pdf.css';

interface Props {
  file: File | undefined;
}
const AllPages: React.FC<Props> = React.memo(({ file }) => {
  const [numPages, setNumPages] = React.useState<number>(null!);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  return (
    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
      {[...Array(numPages)].map((_, index) => {
        return (
          <Page
            className={'PDF-Page'}
            renderMode={'svg'}
            key={index}
            pageNumber={index + 1}
            width={1100}
          />
        );
      })}
    </Document>
  );
});

export default AllPages;
