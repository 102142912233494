import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import QuotationMain from '../../components/requestQuotation/QuotationMain';
import QuotationContact from '../../components/requestQuotation/QuotationContact';
import QuotationComplete from '../../components/requestQuotation/QuotationComplete';
import ModelReUpload from '../../components/requestQuotation/ModelReUpload';
import QuotationDestEdit from '../../components/requestQuotation/QuotationDestEdit';
import { useProgress } from '../../provider/progress/ProgressProvider';

export type viewSelectType = 'Init' | 'Upload' | 'Main' | 'Edit' | 'Contract' | 'Complete';

const useRequestQuotation = () => {
  const { setAlertShow } = useAlert();
  const { setProgressShow } = useProgress();
  const navigate = useNavigate();
  const location = useLocation();

  const [viewSelect, setViewSelect] = React.useState<viewSelectType>('Init');
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [viewSelect]);

  const [userInfo, setUserInfo] = React.useState<MyIF.userInfo>({
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    mailAddress: '',
    phoneNumber: '',
    corpoName: '',
    corpoNameKana: '',
    departmentName: '',
    postCode1: '',
    postCode2: '',
    address1: '',
  });
  const [campaignCode, setCampaignCode] = React.useState<string>();
  const [contactChangeFlg, setContactChangeFlg] = React.useState(false);
  // 試算番号リスト
  const trialNumberList: string[] = location.state.trialNumberList;
  // モデルが削除されている型番リスト
  const [deleteModelNumberList, setDeleteModelNumberList] = React.useState<
    MyIF.deleteModelNumber[]
  >([]);

  React.useEffect(() => {
    (async () => {
      setProgressShow(true);
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetUserContactAddress(trialNumberList);
      if (result !== null) {
        setUserInfo(result.userInfo);
        setCampaignCode(result.campaignCode);
      }

      const deleteModelNumber = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetDeletedModelNumbers(trialNumberList);
      if (deleteModelNumber) {
        setDeleteModelNumberList(deleteModelNumber);
        if (deleteModelNumber.length > 0) {
          setViewSelect('Upload');
        } else {
          setViewSelect('Main');
        }
      }
      setProgressShow(false);
    })();
  }, []);

  return (
    <div>
      {viewSelect === 'Upload' && (
        <ModelReUpload
          setViewSelect={setViewSelect}
          deleteModelNumberList={deleteModelNumberList}
        ></ModelReUpload>
      )}
      {viewSelect === 'Main' && (
        <QuotationMain
          setViewSelect={setViewSelect}
          userInfo={userInfo}
          contactChangeFlg={contactChangeFlg}
          initCampaignCode={campaignCode}
        ></QuotationMain>
      )}
      {viewSelect === 'Edit' && (
        <QuotationDestEdit
          setViewSelect={setViewSelect}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          setContactChangeFlg={setContactChangeFlg}
        ></QuotationDestEdit>
      )}
      {viewSelect === 'Contract' && <QuotationContact></QuotationContact>}
      {viewSelect === 'Complete' && <QuotationComplete></QuotationComplete>}
    </div>
  );
};

export default useRequestQuotation;
