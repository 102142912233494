import * as React from 'react';

const NoticeArea = () => {
  return (
    <ul style={{ width: '100%', maxWidth: 1200, margin: '0 auto', fontSize: 14, letterSpacing: '0.5px' }}>
      <li>
        <p>(※1)</p>
        <p>システムメンテナンスのため、ご利用いただけない場合がございます</p>
      </li>
      <li>
        <p>(※2)</p>
        <p>標準メニュー以外の項目は自動試算に対応しておりません</p>
      </li>
      <li>
        <p>(※3)</p>
        <p>チャットは平日９時-１７時で対応
          <br />
          営業時間内の問い合わせは数時間以内に返答いたします
        </p>
      </li>
      <li>
        <p>(※4)</p>
        <p>本データは原作者のクレジット（氏名、作品タイトルなど）を表示し、かつ非営利目的であることを主な条件に、改変したり再配布したりすることができるCCライセンスとなります
          <br />
          クリエイティブコモンズのマーク詳細は
          <a href="https://creativecommons.org/licenses/by-nc/4.0/" target='_blank'
             rel='noopener noreferrer'>こちらのリンク</a>
          をご確認ください
          <br />
          ダウンロードデータのクレジットはオリックス・レンテックに帰属します
        </p>
      </li>
    </ul>
  )
}

export default NoticeArea