const amplifyConfig = {
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION
    ? process.env.REACT_APP_COGNITO_REGION
    : '',
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOLS_ID
    ? process.env.REACT_APP_COGNITO_USER_POOLS_ID
    : '',
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOLS_CLIENT_ID
    ? process.env.REACT_APP_COGNITO_USER_POOLS_CLIENT_ID
    : '',
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
    ? process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
    : '',
  storage: window.sessionStorage,
};

export default amplifyConfig;
