import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import { FileController } from '../../common/FileController';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { useNavigate } from 'react-router-dom';

interface Props {
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addModelNumberList: MyIF.addModelNumber[];
  setAddModelNumberList: React.Dispatch<React.SetStateAction<MyIF.addModelNumber[]>>;
  selectModelCondition: MyIF.selectModelingConditionType;
}

const ModalAddModelNumber: React.FC<Props> = React.memo(
  ({ show, setIsModalOpen, addModelNumberList, setAddModelNumberList, selectModelCondition }) => {
    const { setAlertShow } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();

    enum SortByItems {
      ASC = 'ASC',
      DESC = 'DESC',
    }

    enum SortItems {
      CHECK_BOX = 'checkBox',
      FILE_NAME = 'fileName',
      LABEL_NAME = 'labelName',
      MODEL_NUMBER = 'modelNumber',
      PRICE = 'price',
      DATE = 'date',
      UPDATE_DATE = 'updateDate',
    }

    const HEADER_ITEMS_STRINGS = {
      [SortItems.CHECK_BOX]: '',
      [SortItems.FILE_NAME]: 'ファイル名',
      [SortItems.LABEL_NAME]: 'ラベル名',
      [SortItems.MODEL_NUMBER]: '型番',
      [SortItems.PRICE]: '単価',
      [SortItems.DATE]: '標準納期',
      [SortItems.UPDATE_DATE]: '更新日時',
    };

    // 型番仕様一覧
    const [modelNumberList, setModelNumberList] = React.useState<MyIF.modelNumberList[]>([]);
    // どのアイテムで降順、昇順をリクエストしたか記録しておく。
    const [sorting, setSorting] = React.useState<string>(SortItems.MODEL_NUMBER);
    // ソート順
    const [sortBy, setSortBy] = React.useState<string>('DESC');
    // 全件数
    const [allCount, setAllCount] = React.useState(0);
    // 型番仕様一覧の総ページ数
    const [totalPageNum, setTotalPageNum] = React.useState<number>(1);
    // 表示しているページ番号
    const [currentPage, setCurrentPage] = React.useState<number>(1);

    // ソート反転
    const sortItems = (sortItem: string) => {
      if (sortItem === sorting) {
        // 直前にソートした項目であれば逆転
        if (sortBy === SortByItems.DESC) {
          setSortBy(SortByItems.ASC);
        } else {
          setSortBy(SortByItems.DESC);
        }
      } else {
        // 初めてソートする項目であれば、降順にする
        setSortBy(SortByItems.DESC);
      }
      setSorting(sortItem);
    };

    /**
     * 追加型番仕様一覧取得API呼び出し
     */
    const GetModelNumberList = React.useCallback(async () => {
      if (selectModelCondition.modelingMaterialId) {
        setProgressShow(true);
        console.log('selectModelCondition', selectModelCondition);
        // API呼び出し
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetTrialCalcModelNumberAddList(
          selectModelCondition.modelingMaterialId,
          selectModelCondition.methodValue,
          selectModelCondition.printerValue,
          sorting,
          sortBy,
          currentPage,
          [
            ...addModelNumberList.map((value) => {
              return value.modelNumber;
            }),
          ]
        );
        if (result !== null) {
          let tmpList: MyIF.modelNumberList[] = [];
          for (let ii = 0; ii < result.data.dataList.length; ii++) {
            // 画像を取得
            const resultURL = await new FileController().GetPresignedURL(
              result.data.imageBucketName,
              result.data.dataList[ii].image
            );
            const imageURL = resultURL ? resultURL : '';

            tmpList.push({
              ...result.data.dataList[ii],
              image: imageURL,
              materialName: '',
              modelName: '',
              optionStressRelief: '',
            });
          }
          setModelNumberList(tmpList);

          setTotalPageNum(
            Math.ceil(result.data.allCount / 7) === 0 ? 1 : Math.ceil(result.data.allCount / 7)
          );

          setAllCount(result.data.allCount);
        }
        setProgressShow(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting, sortBy, currentPage, selectModelCondition, addModelNumberList]);

    /**
     * 初回呼び出し
     */
    React.useEffect(() => {
      if (show) {
        GetModelNumberList();
      } else {
        setModelNumberList([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, GetModelNumberList]);

    if (show) {
      return (
        <section className='modalWindowWrapper'>
          <div className='modalWindowBlock'>
            <div className='modalWindowInner modalWindowHasHeader'>
              <div className='modalWindowHeader'>
                <h1 className='modalWindowHeaderTitle'>型番追加</h1>
                <button
                  className='modalWindowHeaderCloseLink button-style'
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  閉じる
                </button>
              </div>
              <div className='modalWindowContents'>
                <div className='addModelNumberContents'>
                  <div className='addModelNumberBlock'>
                    <table className='modelNumberListTable'>
                      <thead>
                        <tr>
                          {Object.entries(HEADER_ITEMS_STRINGS).map(([key, value]) => {
                            return (
                              <th key={key}>
                                {value}
                                {value !== '' && (
                                  <button
                                    className={`${
                                      sorting === key
                                        ? sortBy === SortByItems.DESC
                                          ? 'modelNumberListSortLink modelNumberListSortLinkDescending button-style'
                                          : 'modelNumberListSortLink modelNumberListSortLinkAscending button-style'
                                        : 'modelNumberListSortLink modelNumberListSortLinkDefalt button-style'
                                    } `}
                                    onClick={() => sortItems(key)}
                                  ></button>
                                )}
                              </th>
                            );
                          })}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {modelNumberList.map((modelNumber: MyIF.modelNumberList, i: number) => {
                          return (
                            <tr
                              key={i}
                              onClick={() => {
                                let tmpList = addModelNumberList.slice();
                                tmpList.push({
                                  modelNumber: modelNumber.modelNumber,
                                  label: modelNumber.labelName,
                                  num: '1',
                                });
                                setAddModelNumberList(tmpList);
                                setIsModalOpen(false);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <td>
                                <div className='modelNumberListTableItemThumb'>
                                  <img
                                    src={modelNumber.image}
                                    alt=''
                                    className='modelNumberListTableItemThumbImage'
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='modelNumberListTableItemFilename'>
                                  {modelNumber.fileName}
                                </div>
                              </td>
                              <td>
                                <div className='modelNumberListTableItemLabelname'>
                                  {modelNumber.labelName}
                                </div>
                              </td>
                              <td>
                                <div className='modelNumberListTableItemModelname'>
                                  {modelNumber.modelNumber}
                                </div>
                              </td>
                              <td>
                                <div className='modelNumberListTableItemPrice'>
                                  ￥{modelNumber.price ? modelNumber.price.toLocaleString() : '-'}
                                </div>
                              </td>
                              <td>
                                <div className='modelNumberListTableItemDelivery'>
                                  {modelNumber.date}
                                </div>
                              </td>
                              <td>
                                <div className='modelNumberListTableItemDate'>
                                  {modelNumber.updateDate}
                                </div>
                              </td>
                              <td>
                                <div className='modelNumberListTableItemToDetail'>
                                  <button
                                    className='modelNumberListTableItemCheckbtn'
                                    onClick={() => {
                                      let tmpList = addModelNumberList.slice();
                                      tmpList.push({
                                        modelNumber: modelNumber.modelNumber,
                                        label: modelNumber.labelName,
                                        num: '1',
                                      });
                                      setAddModelNumberList(tmpList);
                                      setIsModalOpen(false);
                                    }}
                                  >
                                    選択
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* ページング */}
                <div className='addModelNumberContentsmodelNumberListPagingBlock'>
                  <p className='modelNumberListPagingPara'>全{allCount}件</p>
                  <button
                    className={
                      currentPage === 1
                        ? 'modelNumberListPagingLinkPrevDisabled button-style'
                        : 'modelNumberListPagingLinkPrevActive button-style'
                    }
                    onClick={() => {
                      if (currentPage > 1) {
                        setCurrentPage(currentPage - 1);
                      }
                    }}
                    disabled={currentPage === 1 ? true : false}
                  ></button>
                  <p className='modelNumberListPagingCount'>
                    <span className='modelNumberListPagingCountDisplay'>{currentPage}</span> /{' '}
                    {totalPageNum}ページ
                  </p>
                  <button
                    className={
                      currentPage === totalPageNum
                        ? 'modelNumberListPagingLinkNextDisabled button-style'
                        : 'modelNumberListPagingLinkNextActive button-style'
                    }
                    onClick={() => {
                      if (totalPageNum > currentPage) {
                        setCurrentPage(currentPage + 1);
                      }
                    }}
                    disabled={currentPage === totalPageNum ? true : false}
                  ></button>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    } else {
      return null;
    }
  }
);

export default ModalAddModelNumber;
