import * as THREE from 'three';
import * as constant from '../Constant';
// import { Edge } from './Edge_v2';
// import { Face } from './Face_v2';
// import { Vertex } from './Vertex_v2';
import { ChoiceObject } from './ChoiceObject';
import { GavadonData } from './GavadonData';
import { Simplex } from './Simplex';
import { tessellatedJson } from '../tessellated';
import { decode } from "@msgpack/msgpack";

//orix用stl変換データ対応face
import { Edge } from './Edge_v3';
import { Face } from './Face_v3';
import { Vertex } from './Vertex_v3';

export class ObjectManager{

    //フィールド変数
    private complex: Simplex[];
    public vertex: Vertex;
    public edge: Edge;
    public face: Face;
    public objectArray: number[][][] = new Array(3);
    public collisionArray: THREE.Mesh[][] = new Array(3);
    public choice = new ChoiceObject();

    //コンストラクタ（与えられたJSONデータから作成）
    constructor(data?: any, option?: string,
            materialOption?: {
                edge?: {color?: THREE.ColorRepresentation},
                face?: {color?: THREE.ColorRepresentation}
            }
        ){
        const blank :tessellatedJson = {edge_data: {colors: [], edges: [], points: []}, face_data: {colors: [], faces:[], normals: [], points: [], triangles: []}};
        data = data ? data : blank;
        if(!option) option = 'json';
        const translatedData = option === 'json' ? this.translateToGavadonData(data) : this.translateToGavadonData(decode(data) as tessellatedJson);
        this.vertex = new Vertex(translatedData);
        this.edge = new Edge(translatedData, materialOption?.edge);
        this.face = new Face(translatedData, materialOption?.face);

        const bb = this.vertex.getBoundingBox();
        const center = new THREE.Vector3().addVectors(bb.min, bb.max).multiplyScalar(0.5);
        this.face.getViewMesh().geometry.translate(-center.x, -center.y, -center.z);

        this.complex = [this.vertex, this.edge, this.face];
        this.objectArray = [this.vertex.getData(), this.edge.getData(), this.face.getData()];
        this.collisionArray = [this.vertex.getMeshes(), this.edge.getMeshes(), this.face.getMeshes()];
    }

    //メソッド
    //GAVADON用のデータに変換するメソッド
    private translateToGavadonData(data: tessellatedJson): GavadonData{
        // msgpackの変換確認用
        // console.log(data);
        //jsonの場合の変換
        let resData: GavadonData = {
            edge_data: {
                edges: [],
                points: []
            },
            face_data: {
                faces: [],
                points: []
            }
        };
        if (data.edge_data.edges) data.edge_data.edges.forEach( edge => {
            resData.edge_data.edges.push(edge);
        });
        else resData.edge_data.edges = [];
        resData.edge_data.points = data.edge_data.points;
        if (data.face_data.faces) data.face_data.faces.forEach( face => {
            resData.face_data.faces.push(face);
        })
        else resData.face_data.faces = [];
        resData.face_data.points = data.face_data.points;
        return resData;
    }

    //シーンに追加するメソッド
    public addTo(scene: THREE.Scene){
        this.vertex.addTo(scene);
        this.edge.addTo(scene);
        this.face.addTo(scene);
    }

    //オブジェクトの各軸方向のサイズを返すメソッド（Vertexクラスのものと同一）
    public measure(){
        return this.vertex.measure();
    }

    public getBoundingSphere(){
        return this.vertex.getBoundingSphere();
    }

    public getBoundingBox(){
        return this.vertex.getBoundingBox();
    }

    public boundary(aspect? :number){
        //boundingSphereによる定義
        // return {
        //     max: this.vertex.getBoundingSphere().radius + constant.MARGIN,
        //     width: (this.vertex.getBoundingSphere().radius * 2 + constant.MARGIN) * constant.ASPECT,
        //     height: this.vertex.getBoundingSphere().radius * 2 + constant.MARGIN,
        //     center: this.vertex.getBoundingSphere().center,
        //     ground: this.vertex.measure().minZ
        // };
        //boundingBoxによる定義
        const bb = this.vertex.getBoundingBox();
        const radius = bb.min.distanceTo(bb.max) / 2;
        const resAspect = aspect ? aspect : constant.ASPECT;
        return {
            max: radius + constant.MARGIN,
            width: (radius * 2 + constant.MARGIN) * resAspect,
            height: radius * 2 + constant.MARGIN,
            center: new THREE.Vector3().addVectors(bb.min, bb.max).multiplyScalar(0.5),
            ground: bb.min.z
        };
    }

    //オブジェクトのタイプに対応するインデックスを返すメソッド（移動予定？）
    public dimOf(type: string): number{
        let dim = -1;
        for(let i = 0; i < 3; i++){
            if(type === constant.OBJECT_TYPE[i]) dim = i;
        }
        return dim;
    }

    //オブジェクトを選ぶメソッド（ChoiceObjectクラスと同一）
    public choose(type: string, id: number){
        this.choice.choose(this.objectArray[this.dimOf(type)][id], type, id);
    }

    //オブジェクトの描画更新のメソッド（typeは描画するオブジェクト）
    public reload(type: string){
        //各オブジェクトごとの処理
        let conditions = [false, true, false];
        for(let i = 0; i < 3; i++){
            //typeに一致する場合だけ表示。ただし、辺のとき（n=1）は常に表示。
            if (constant.OBJECT_TYPE[i] === type) conditions[i] = true;
            this.complex[i].visibleOption(conditions[i]);
        }
        this.collisionArray.forEach(simp => {
            simp.forEach(object =>{
                if (object.material instanceof THREE.LineBasicMaterial 
                    || object.material instanceof THREE.MeshBasicMaterial 
                    || object.material instanceof THREE.MeshStandardMaterial)
                {
                    object.material.visible = false;
                    object.material.color = constant.COLLISION_COLOR;
                }
            })
        })
        //選択中のものは着色
        if (this.choice.information[0]) {
            const info0 = this.choice.information[0];
            const collisionMaterial0 = this.collisionArray[this.dimOf(info0.type)][info0.id].material;
            if (collisionMaterial0 instanceof THREE.LineBasicMaterial 
                || collisionMaterial0 instanceof THREE.MeshBasicMaterial 
                || collisionMaterial0 instanceof THREE.MeshStandardMaterial)
            {
                collisionMaterial0.visible = true;
                collisionMaterial0.color = constant.CHOICE_COLOR_0;
            }
            if (this.choice.information[1]) {
                const info1 = this.choice.information[1];
                const collisionMaterial1 = this.collisionArray[this.dimOf(info1.type)][info1.id].material;
                if (collisionMaterial1 instanceof THREE.LineBasicMaterial 
                    || collisionMaterial1 instanceof THREE.MeshBasicMaterial 
                    || collisionMaterial1 instanceof THREE.MeshStandardMaterial)
                {
                    collisionMaterial1.visible = true;
                    collisionMaterial1.color = constant.CHOICE_COLOR_1;
                }
            }
        }
    }

    public removeFrom(scene: THREE.Scene){
        this.vertex.removeFrom(scene);
        this.edge.removeFrom(scene);
        this.face.removeFrom(scene);
    }
}