import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CognitoAuthProvider from './provider/cognito/CognitoAuthProvider';
import awsconfig from './provider/cognito/amplifyConfig';
import { AlertProvider } from './provider/alert/AlertProvider';
import { ProgressProvider } from './provider/progress/ProgressProvider';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import TagManager from 'react-gtm-module';

process.env.NODE_ENV !== 'development' && (console.log = () => {});

try {
  const getInteractionId = (event: Event): string => {
    const eventPath = event.composedPath() as Element[];
    for (const element of eventPath) {
      if (element.hasAttribute && element.hasAttribute('data-rum-id')) {
        return element.getAttribute('data-rum-id') as string;
      }
    }
    return '';
  };

  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_AWSRUM_ROLE_ARN,
    identityPoolId: process.env.REACT_APP_AWSRUM_IDENTITY_POOL_ID,
    endpoint: process.env.REACT_APP_AWSRUM_ENDPOINT,
    telemetries: [
      'performance',
      'errors',
      'http',
      [
        'interaction',
        {
          events: [{ event: 'click', element: document }],
          interactionId: getInteractionId,
        },
      ],
    ],
    allowCookies: true,
    enableXRay: true,
  };

  const APPLICATION_ID: string = process.env.REACT_APP_AWSRUM_APPLICATION_ID || '';
  const APPLICATION_VERSION: string = process.env.REACT_APP_AWSRUM_APPLICATION_VERSION || '';
  const APPLICATION_REGION: string = process.env.REACT_APP_AWSRUM_APPLICATION_REGION || '';

  new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
};
console.log('tagManagerARgs', tagManagerArgs);
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AlertProvider>
    <ProgressProvider>
      <CognitoAuthProvider amplifyConfig={awsconfig}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </CognitoAuthProvider>
    </ProgressProvider>
  </AlertProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
