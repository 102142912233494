import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/trialBalanceSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/viewer.css';
import GavadonComponent, { gavadonFuncHandle } from '../viewer/Gavadon';

interface Props {
  readOnly?: boolean;
  boudingBox: MyIF.boundingBoxInfo | undefined;
  currentBatch: number;
  setCurrentBatch: React.Dispatch<React.SetStateAction<number>>;
  gavadonRef?: React.MutableRefObject<gavadonFuncHandle>;
}

const ViewerArea: React.FC<Props> = React.memo(
  ({ boudingBox, currentBatch, setCurrentBatch, gavadonRef }) => {
    const [totalPageNum, setTotalPageNum] = React.useState<number>(1);

    React.useEffect(() => {
      if (boudingBox) {
        setTotalPageNum(boudingBox.batchList.length);
      } else {
        setTotalPageNum(1);
      }
    }, [boudingBox]);

    return (
      <div className='trialBalanceSpecEditViewerBlock'>
        <div className='trialBalanceSpecEditViewerInner'>
          <div className='GavadonArea'>
            <GavadonComponent
              boundingBox={boudingBox}
              boundingBatchNumber={currentBatch - 1}
              ref={gavadonRef}
            />
          </div>
          {boudingBox && boudingBox?.batchList.length > currentBatch - 1 && (
            <div className='trialBalanceSpecEditViewerFormBlock'>
              <div className='trialBalanceSpecEditPagingBlock'>
                <button
                  className={
                    currentBatch === 1
                      ? 'modelNumberListPagingLinkPrevDisabled button-style'
                      : 'modelNumberListPagingLinkPrevActive button-style'
                  }
                  onClick={() => {
                    if (currentBatch > 1) {
                      setCurrentBatch(currentBatch - 1);
                    }
                  }}
                  disabled={currentBatch === 1 ? true : false}
                ></button>
                <p className='modelNumberListPagingCount'>
                  <span className='modelNumberListPagingCountDisplay'>{currentBatch}</span> /{' '}
                  {totalPageNum}ページ
                </p>
                <button
                  className={
                    currentBatch === totalPageNum
                      ? 'modelNumberListPagingLinkNextDisabled button-style'
                      : 'modelNumberListPagingLinkNextActive button-style'
                  }
                  onClick={() => {
                    if (totalPageNum > currentBatch) {
                      setCurrentBatch(currentBatch + 1);
                    }
                  }}
                  disabled={currentBatch === totalPageNum ? true : false}
                ></button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ViewerArea;
