import * as React from 'react';
import { useAuth } from '../../provider/cognito/AuthContext';
import { useAlert } from '../../provider/alert/AlertProvider';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientLogin.css';
import { viewLoginType } from '../../pages/users/Login';
import PasswordForm from './PasswordForm';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewLoginType>>;
  mailAddress: string;
}

const PasswordChangeOneTime: React.FC<Props> = React.memo(({ setViewSelect, mailAddress }) => {
  const { forgotPasswordConfirm } = useAuth();
  const { setAlertShow, setMessageList } = useAlert();
  const [code, setCode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');

  const passwordChange = () => {
    if (password !== passwordConfirm) {
      setMessageList(['パスワードが一致しません。']);
      setAlertShow(true);
    } else {
      forgotPasswordConfirm(mailAddress, code, password)
        .then(() => {
          setViewSelect('ResetPWComplete');
        })
        .catch((err) => {
          console.error(err);
          switch (err.code) {
            case 'CodeMismatchException':
              setMessageList(['ワンタイムパスワードが間違っています。']);
              break;
            case 'InvalidPasswordException':
            case 'InvalidParameterException':
              setMessageList([
                'パスワードは英小文字と英大文字と数字を含み8文字以上で設定してください。',
                '記号（! # $ % & ( ) - = ^ @ + * . / ? _ 等）の使用も可能です。',
              ]);
              break;
            default:
              setMessageList(['エラーが発生しました。やり直してください。']);
              break;
          }
          setAlertShow(true);
        });
    }
  };

  function resetCaretPosition() {
    const container = document.createElement('div'); // 編集不可の要素を作成
    container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
    container.style.opacity = '0'; // 編集不可の要素を非表示にする
    container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
    container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
    container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
    document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

    container.focus(); // 編集不可の要素にフォーカスを移動する
    window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

    // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
    container.style.display = 'none';
    container.style.display = '';

    container.blur(); // 編集不可の要素からフォーカスを外す
    document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
  }

  React.useEffect(() => {
    // resetCaretPosition関数を使用して、キャレット位置をリセットする
    resetCaretPosition();
  }, []);

  return (
    <div className='mainContentsBlock noFlowBar'>
      <div className='clientRegistrationForm'>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>新しいパスワードの入力</h1>
            </div>
          </div>
        </div>

        <div className='pageTitleDetailTextInput'>
          <p className='pageTitleDetailText' style={{ marginBottom: '10px' }}>
            メールに通知されたワンタイムパスワードを入力してください{' '}
            <QuestionPopper value={QEXP.OnetimeMail.MAIL_ADDRESS} />
          </p>
          <div className='oneTimePasswodInputBlock'>
            <input
              className='oneTimePasswodInput'
              type='text'
              name='lname'
              id='lname'
              placeholder='ワンタイムパスワード（※数字6桁）'
              defaultValue={code}
              onChange={(event) => {
                setCode(event.target.value);
              }}
            />
          </div>
        </div>
        <PasswordForm
          setPassword={setPassword}
          setPasswordConfirm={setPasswordConfirm}
        ></PasswordForm>

        <div className='clientpasswordResetButtonArea'>
          <button
            className='btnCancel'
            type='button'
            name='button'
            onClick={() => {
              setViewSelect('Login');
            }}
          >
            キャンセル
          </button>
          <button
            className='btnOrangeCommit'
            type='button'
            name='button'
            onClick={() => {
              passwordChange();
            }}
            disabled={!(password === passwordConfirm)}
          >
            確定
          </button>
        </div>
      </div>
    </div>
  );
});

export default PasswordChangeOneTime;
