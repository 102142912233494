import * as React from 'react';
import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

interface Props {
  show: boolean;
}

const CalcLoading: React.FC<Props> = React.memo(({ show }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000 }} open={show}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
});

export default CalcLoading;
