import React from 'react';
import { APIController } from '../../common/API';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { useAuth } from '../../provider/cognito/AuthContext';
import Modal from '@mui/material/Modal';
import '../../assets/css/modalMailEdit.css';

interface Props {
  show: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalMailAddressEdit: React.FC<Props> = React.memo(({ show, setIsShow }) => {
  const { setProgressShow } = useProgress();
  const { setAlertShow } = useAlert();
  const { changeMailAddress, verifyMailAddress } = useAuth();
  const navigate = useNavigate();

  const [nowMailAddress, setNowMailAddress] = React.useState('');
  const [verifyView, setVerifyView] = React.useState(false);
  const [mailAddress, setMailAddress] = React.useState('');
  const [verifyCode, setVerifyCode] = React.useState('');
  const [verifyResult, setVerifyResult] = React.useState('');
  const [changeMailAddressResult, setChangeMailAddressResult] = React.useState('');

  React.useEffect(() => {
    (async () => {
      if (show) {
        setProgressShow(true);
        // 現在のメールアドレス取得
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetUserContactAddress();
        if (result !== null) {
          setNowMailAddress(result.userInfo.mailAddress);
        }
        setProgressShow(false);
      } else {
        setVerifyView(false);
        setVerifyResult('');
        setChangeMailAddressResult('')
      }
    })();
  }, [show]);

  async function updateMailAddress(address: string) {
    setProgressShow(true);
    try {
      // 属性更新＆検証コード送信
      await changeMailAddress(address);
      // 検証コード入力フォームを表示
      setVerifyView(true);
      setChangeMailAddressResult('')
    } catch (err:any) {
      switch (err.code) {
        case 'InvalidParameterException':
          setChangeMailAddressResult('メールアドレスの形式になっていません');
          break;
        default:
          setChangeMailAddressResult('不明なエラーが発生しました。もう一度やり直してください。');
          break;
      }}
    setProgressShow(false);
  }

  async function verify(code: string) {
    setProgressShow(true);
    try {
      // 検証
      await verifyMailAddress(code);

      // メールアドレス更新
      await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).PutMailAddress();

      setIsShow(false);
    } catch (err: any) {
      switch (err.code) {
        case 'CodeMismatchException':
          setVerifyResult('検証コードが違います');
          break;
        default:
          setVerifyResult('不明なエラーが発生しました。もう一度やり直してください。');
          break;
      }
    }
    setProgressShow(false);
  }
  return (
    <div>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={show}
        disableScrollLock
      >
        <div className='modalMailEdit'>
          <h1 className='modalMailEditTitle'>メールアドレス変更</h1>
          <p className='modalMailEditTitleExp'>
            メールアドレスを入力して変更ボタンを押下すると、<br></br>
            メールアドレスに検証用のコードが送信されますので、<br></br>
            検証コードを入力して検証ボタンを押下してください。
          </p>
          <div className='modalMailEditContent'>
            <div className='modalMailEditLabel'>
              <label>現在のメールアドレス</label>
            </div>
            <div className='modalMailEditValue'>
              <div>{nowMailAddress}</div>
            </div>
          </div>
          <div className='modalMailEditContent'>
            <div className='modalMailEditLabel'>
              <label>変更後のメールアドレス</label>
            </div>
            <div className='modalMailEditInput'>
              <input
                type='text'
                disabled={verifyView}
                onChange={(event) => {
                  setMailAddress(event.target.value);
                }}
              />
              {changeMailAddressResult !== '' && <p className='modalMailEditError'>{changeMailAddressResult}</p>}
            </div>
          </div>
          {verifyView && (
            <div className='modalMailEditContent'>
              <div className='modalMailEditLabel'>
                <label>検証コード</label>
              </div>
              <div className='modalMailEditInput'>
                <input
                  type='text'
                  onChange={(event) => {
                    setVerifyCode(event.target.value);
                  }}
                />
                {verifyResult !== '' && <p className='modalMailEditError'>{verifyResult}</p>}
              </div>
            </div>
          )}

          <div className='modalMailEditCloseBtn'>
            <button
              className='btnWhiteBaseBlue'
              type='button'
              name='button'
              onClick={() => {
                {
                  verifyView ? verify(verifyCode) : updateMailAddress(mailAddress);
                }
              }}
              disabled={
                verifyView ? (verifyCode === '') : (mailAddress === '' || mailAddress === nowMailAddress)
              }
            >
              {verifyView ? '検証' : '変更'}
            </button>
            <button
              style={{ marginLeft: '20px' }}
              className='btnWhiteBaseGray'
              type='button'
              name='button'
              onClick={() => {
                setIsShow(false);
              }}
            >
              閉じる
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default ModalMailAddressEdit;
