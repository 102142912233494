import * as THREE from 'three';
import * as constant from '../Constant';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CameraControls } from '../Utility/CameraControls';

export class CameraManager {
    private _scale: number;
    private _fitBuffer: THREE.Vector2;
    private _canvasElement: HTMLCanvasElement;
    private _axes: THREE.Group;
    private _screenData: {
        max: number;
        width: number;
        height: number;
        center: THREE.Vector3;
    };
    private _far: number;
    private _defaultRotation: THREE.Euler;

    public mode: string;
    public camera: THREE.Camera;
    public controls: OrbitControls;
    public cameraControls: CameraControls;

    constructor(canvasElement?: HTMLCanvasElement,
        screenData?: {
            max: number;
            width: number;
            height: number;
            center: THREE.Vector3;
        },
        far?: number,
        rotation? : THREE.Euler
    ) {
        //初期化
        this._scale = 1.0;
        this._fitBuffer = new THREE.Vector2();
        this._canvasElement = canvasElement ? canvasElement : document.createElement('canvas') as HTMLCanvasElement;
        this._screenData = screenData ? screenData : { max: 0, width: 0, height: 0, center: new THREE.Vector3()};
        this._far = far ? Math.max(far, this._screenData.max) : this._screenData.max;
        //モード設定
        this.mode = 'ortho';
        //カメラ設定
        this._defaultRotation = rotation ? rotation : new THREE.Euler(0, 0, 0);
        this.camera = this.defaultCamera();

        //軸を作成
        const axisGroup = new THREE.Group();

        const axes = new THREE.AxesHelper(this._screenData.max / 5);
        axes.setColors(new THREE.Color(0xff0000), new THREE.Color(0x00ff00), new THREE.Color(0x0000ff));
        axisGroup.add(axes);

        for(let i = 0; i < 3; i++){
            let label = 'X'
            let pos = new THREE.Vector3(1, 0, 0);
            switch(i){
                case 1:
                    label = 'Y';
                    pos.set(0, 1, 0);
                    break;
                case 2:
                    label = 'Z';
                    pos.set(0, 0, 1);
                    break;
            }
            const texture = new THREE.CanvasTexture(this.labelCanvas(label));
            const sprite = new THREE.Sprite(new THREE.SpriteMaterial({map: texture}));
            sprite.scale.set(this._screenData.max * 0.05, this._screenData.max * 0.05 ,0);
            sprite.position.copy(pos.multiplyScalar(this._screenData.max * 0.25));
            axisGroup.add(sprite);
        }

        this._axes = axisGroup;
        this._axes.position.set(this._screenData.width * constant.AXES_POS.x, this._screenData.height * constant.AXES_POS.y, 1);
        this._axes.visible = false;

        this.camera.add(this._axes);

        //コントローラーを導入
        this.controls = this.defaultControls();
        this.controls.target.copy(this._screenData.center);

        this.cameraControls = new CameraControls(this.camera, this._canvasElement);
        this.cameraControls.enabled = false;
    }

    private labelCanvas(text: string){
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.canvas.width = 200;
            ctx.canvas.height = 200;
            ctx.textBaseline = "top";
            ctx.textAlign = "left";
            ctx.fillStyle = 'rgba(10, 10, 10, 0)';
            ctx.fillRect(0, 0, 200, 200);
            ctx.font = '200px sans-serif';
            ctx.fillStyle = 'rgb(255, 255, 255)';
            ctx.fillText(text, 0, 5);
        }
        return canvas;
    }

    public cameraChanger() {
        this.camera.traverse(child => this.camera.remove(child));
        if(this.mode === 'ortho') this.mode = 'pers';
        else if (this.mode === 'pers') this.mode = 'ortho';
        this._scale = 1.0;
        this.camera = this.defaultCamera();
        this.controls = this.defaultControls();
        this.controls.target.copy(this._screenData.center);
    }

    public defaultCamera(): THREE.OrthographicCamera {
        const camera = new THREE.OrthographicCamera(
            -this._screenData.width / 2, this._screenData.width / 2,
            this._screenData.height / 2, -this._screenData.height / 2, -this._far * 2, this._far * 2
        );
        //視点と位置を決定
        camera.up = new THREE.Vector3(0, 0, 1);
        const dirVector = new THREE.Vector3(0, 0, this._screenData.max);
        dirVector.applyEuler(this._defaultRotation);
        camera.position.copy(dirVector);
        camera.updateProjectionMatrix();
        return camera;
    }

    public defaultControls(): OrbitControls {
        let controls = new OrbitControls(this.camera, this._canvasElement);
        controls.maxPolarAngle = Math.PI;
        controls.minDistance = 0.1;
        controls.maxDistance = 500;
        return controls;
    }

    public addTo(scene: THREE.Scene) {
        scene.add(this.camera);
    }

    public setRotationFromEuler(rotation: THREE.Euler) {
        const dirVector = new THREE.Vector3(0, 0, this._screenData.max);
        dirVector.applyEuler(rotation);
        this.camera.position.copy(dirVector);
    }

    public showAxis(){ this._axes.visible = true };

    public update() {
        this.controls.update();
        if (this.camera instanceof THREE.OrthographicCamera) {
            this._axes.scale.copy(new THREE.Vector3(1,1,1).multiplyScalar(this._scale / this.camera.zoom));
            this._axes.position.set(this._screenData.width * constant.AXES_POS.x * this._scale / this.camera.zoom + this._fitBuffer.x, this._screenData.height * constant.AXES_POS.y * this._scale / this.camera.zoom + this._fitBuffer.y ,1);
            this._axes.quaternion.copy(this.camera.quaternion).conjugate();
        }
    }

    //サイズに対する、アスペクト・マージンを含めた画面サイズの計算
    private formSize (borderVectors: THREE.Vector3[]): THREE.Vector3[] {
        const resizeVectors = borderVectors;
        const width = resizeVectors[1].x - resizeVectors[0].x;
        const height = resizeVectors[1].y - resizeVectors[0].y;
        const aspect = this._screenData.width / this._screenData.height;
        if (height * aspect > width) {
            resizeVectors[0].x = borderVectors[0].x * (constant.MARGIN * 2 + height) * aspect / width;
            resizeVectors[1].x = borderVectors[1].x * (constant.MARGIN * 2 + height) * aspect / width;
            resizeVectors[0].y -= constant.MARGIN;
            resizeVectors[1].y += constant.MARGIN;
        } else {
            resizeVectors[0].x -= constant.MARGIN;
            resizeVectors[1].x += constant.MARGIN;
            resizeVectors[0].y = borderVectors[0].y * (constant.MARGIN * 2 + width) / aspect / height;
            resizeVectors[1].y = borderVectors[1].y * (constant.MARGIN * 2 + width) / aspect / height;
        }
        return resizeVectors;
    }

    public getDefaultRotation() { return this._defaultRotation; }

    //画面フィットを行う関数
    public fit(borderVectors: THREE.Vector3[]){
        const sizedVectors = this.formSize(borderVectors);
        this._scale = (sizedVectors[1].y - sizedVectors[0].y) / this._screenData.height;
        if (this.camera instanceof THREE.OrthographicCamera) {
            this.camera.zoom = 1;
            const windowCenter = new THREE.Vector2(
                (this.camera.left + this.camera.right) / 2,
                (this.camera.top + this.camera.bottom) / 2
            );
            const updateCenter = new THREE.Vector2(
                (sizedVectors[0].x + sizedVectors[1].x) / 2,
                (sizedVectors[0].y + sizedVectors[1].y) / 2
            );
            this._fitBuffer.add(new THREE.Vector2().subVectors(updateCenter, windowCenter));
            this.camera.left = sizedVectors[0].x;
            this.camera.right = sizedVectors[1].x;
            this.camera.bottom = sizedVectors[0].y;
            this.camera.top = sizedVectors[1].y;
            this.camera.updateProjectionMatrix();
        }
    }

    public rotationAxis(){
        const cameraUp = new THREE.Vector3(0, 1, 0);
        cameraUp.applyEuler(this.camera.rotation);
        const cameraDir = new THREE.Vector3(0, 0, 1);
        cameraDir.applyEuler(this.camera.rotation);
        return {
            up: cameraUp,
            dir: cameraDir
        };
    }

    public center(){
        return this._screenData.center;
    }
}