import * as React from 'react';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientLogin.css';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';

interface Props {
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordConfirm: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordForm: React.FC<Props> = React.memo(({ setPassword, setPasswordConfirm }) => {
  function resetCaretPosition() {
    const container = document.createElement('div'); // 編集不可の要素を作成
    container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
    container.style.opacity = '0'; // 編集不可の要素を非表示にする
    container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
    container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
    container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
    document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

    container.focus(); // 編集不可の要素にフォーカスを移動する
    window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

    // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
    container.style.display = 'none';
    container.style.display = '';

    container.blur(); // 編集不可の要素からフォーカスを外す
    document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
  }

  React.useEffect(() => {
    // resetCaretPosition関数を使用して、キャレット位置をリセットする
    resetCaretPosition();
  }, []);

  return (
    <div>
      <div className='pageTitleDetailTextInput'>
        <p className='pageTitleDetailText' style={{ marginBottom: '10px' }}>
          新しいパスワードを入力してください
          <QuestionPopper value={QEXP.NewPassword.PASSWORD} />
        </p>
        <div className='newPasswodInputBlock'>
          <input
            className='newPasswodInput'
            type='password'
            name='password'
            id='password'
            placeholder='新しいパスワード'
            autoComplete='new-password'
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </div>
        <div className='newPasswodInputBlock'>
          <input
            className='newPasswodInput'
            type='password'
            name='passwordConfirmation'
            id='passwordConfirmation'
            placeholder='確認のためもう一度入力してください'
            autoComplete='new-password'
            onChange={(event) => {
              setPasswordConfirm(event.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
});

export default PasswordForm;
