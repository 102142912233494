import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import { ClickAwayListener, Popper } from '@mui/material';

interface Props {
  anchorEl: HTMLButtonElement | HTMLSelectElement | null;
  handleClose: () => void;
  children: React.ReactElement;
}

const CustomPopper: React.FC<Props> = React.memo(({ anchorEl, handleClose, children }) => {
  return (
    anchorEl && (
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          id={anchorEl ? 'simple-popover' : undefined}
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          placement='right'
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 100 }}
        >
          {children}
        </Popper>
      </ClickAwayListener>
    )
  );
});

export default CustomPopper;
