import React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import ProfileEdit from './ProfileEdit';
import ProfileRead from './ProfileRead';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';

export type viewProfileType = 'Read' | 'Edit';

interface Props {
  show: boolean;
  setIsShowProfile: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalProfile: React.FC<Props> = React.memo(({ show, setIsShowProfile }) => {
  const { setAlertShow } = useAlert();
  const navigate = useNavigate();

  const [registInfo, setRegistInfo] = React.useState<MyIF.userRegistInfo>({
    userInfo: {
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      mailAddress: '',
      phoneNumber: '',
      corpoName: '',
      corpoNameKana: '',
      departmentName: '',
      postCode1: '',
      postCode2: '',
      address1: '',
    },
    policyFlg: false,
    mailFlg: false,
  });

  const modal = React.useRef<HTMLDivElement>(null!);
  const [viewSelect, setViewSelect] = React.useState<viewProfileType>('Read');

  React.useEffect(() => {
    if (modal.current !== null) modal.current.scrollTop = 0;
  }, [viewSelect]);

  React.useEffect(() => {
    (async () => {
      if (show && viewSelect === 'Read') {
        console.log('GetUserContactAddress呼び出し');
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetUserContactAddress();
        if (result !== null) {
          setRegistInfo(result);
        }
      }
    })();
  }, [viewSelect, show]);

  return (
    <div>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={show}
        disableScrollLock
      >
        <div className='modalProfile' ref={modal}>
          {viewSelect === 'Read' && (
            <ProfileRead
              setViewSelect={setViewSelect}
              registInfo={registInfo}
              setIsShowProfile={setIsShowProfile}
            ></ProfileRead>
          )}
          {viewSelect === 'Edit' && (
            <ProfileEdit setViewSelect={setViewSelect} registInfo={registInfo}></ProfileEdit>
          )}
        </div>
      </Modal>
    </div>
  );
});

export default ModalProfile;
