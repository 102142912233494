import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/viewer.css';
import GavadonComponent, { gavadonFuncHandle } from '../viewer/Gavadon';
import saveAs from 'file-saver';

interface Props {
  readOnly?: boolean;
  viewerMemo: string;
  setViewerMemo: React.Dispatch<React.SetStateAction<string>>;
  selectModelCondition: MyIF.selectModelingConditionType;
  threeDFileName: string | undefined;
  modelViewInfo: MyIF.modelViewInfo;
  gavadonRef: React.MutableRefObject<gavadonFuncHandle>;
  GetCalcTime?(): void;
  GetBasePlateSize():
    | {
        x: number;
        y: number;
      }
    | undefined;
  SetCheckBoudingBoxSize?(): void;
}

const ViewerArea: React.FC<Props> = React.memo(
  ({
    readOnly,
    viewerMemo,
    setViewerMemo,
    selectModelCondition,
    threeDFileName,
    modelViewInfo,
    gavadonRef,
    GetCalcTime,
    GetBasePlateSize,
    SetCheckBoudingBoxSize,
  }) => {
    const captureSave = () => {
      gavadonRef.current.getCapture('VIEW').then((captureBase64) => {
        saveAs(captureBase64, 'capture.png');
      });
    };

    return (
      <div className='modelNoSpecEditViewerBlock'>
        <div className='modelNoSpecEditViewerInner'>
          <div className='GavadonArea'>
            <GavadonComponent
              modelViewInfo={modelViewInfo}
              ref={gavadonRef}
              GetCalcTime={GetCalcTime}
              GetBasePlateSize={GetBasePlateSize}
              SetCheckBoudingBoxSize={SetCheckBoudingBoxSize}
            />
          </div>

          <div className='modelNoSpecEditViewerFormBlock'>
            <div className='modelNoSpecEditViewerBtnBlock'>
              <button
                className={`${
                  modelViewInfo.modelBlob
                    ? 'modelNoSpecEditViewerBtnCapture button-style'
                    : 'modelNoSpecEditViewerBtnCaptureDisabled button-style'
                } `}
                disabled={!modelViewInfo.modelBlob}
                onClick={() => {
                  captureSave();
                }}
              >
                画面キャプチャ
              </button>
            </div>
            <textarea
              name='name'
              className='modelNoSpecEditViewerFormTextarea'
              placeholder='仕様に関するメモを記入できます。'
              defaultValue={viewerMemo}
              onChange={(event) => {
                setViewerMemo(event.target.value);
              }}
              disabled={readOnly}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }
);

export default ViewerArea;
