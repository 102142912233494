import AWS from 'aws-sdk';
import axios from 'axios';
import awsconfig from '../provider/cognito/amplifyConfig';
import { Auth } from 'aws-amplify';
import { gzipSync } from 'fflate';

AWS.config.region = awsconfig.aws_cognito_region;
// new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: awsconfig.aws_cognito_identity_pool_id,
// });

export class FileController {
  /**
   * S3からファイル取得
   * @param bucket
   * @param path
   * @param contentType
   */
  FileDownloadS3 = async (
    bucket: string,
    path: string,
    decompress: boolean = false
  ): Promise<Blob | undefined> => {
    try {
      const url = await this.GetPresignedURL(bucket, path);
      console.log(url);
      if (url !== undefined) {
        const blob = await this.FileDownloadURL(url, decompress);
        if (blob !== undefined) {
          return blob;
        } else {
          console.error('mpacファイル取得失敗');
        }
      } else {
        console.error('署名付きURL取得失敗');
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * ファイル取得
   * @param signedDownloadUrl
   * @param contentType
   * @returns
   */
  FileDownloadURL = async (
    signedDownloadUrl: string,
    decompress: boolean = false
  ): Promise<Blob | undefined> => {
    let blob: Blob | undefined = undefined;
    try {
      const res = await axios({
        url: signedDownloadUrl,
        method: 'GET',
        responseType: 'blob',
        decompress: decompress,
      });
      console.log(res);
      blob = new Blob([res.data], {
        type: res.data.type,
      });
    } catch (err) {
      console.error(err);
    }
    return blob;
  };

  /**
   * 署名付きURL取得
   * @param bucket
   * @param path
   */
  GetPresignedURL = async (bucket: string, path: string) => {
    try {
      const region = process.env.REACT_APP_BUCKET_REGION;
      AWS.config.credentials = await Auth.currentCredentials();
      const s3param: AWS.S3.ClientConfiguration = {
        params: { Bucket: bucket },
        region: region,
      };
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_ACCESS_POINT === 'development'
      ) {
        s3param.credentials = {
          accessKeyId: 'root',
          secretAccessKey: 'password',
        };
        s3param.endpoint = 'http://localhost:9090';
        s3param.s3ForcePathStyle = true;
      }
      let putBucket = new AWS.S3(s3param);
      const params = {
        Bucket: bucket,
        Key: path,
        Expires: 60,
      };
      const result = await putBucket.getSignedUrl('getObject', params);
      return result;
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * ファイルアップロード
   * @param bucket
   * @param uploadFile
   * @param path
   */
  FileUploadURL = async (
    url: string,
    contentType: string,
    fileData: File,
    encoding?: string,
    setProgress?: React.Dispatch<React.SetStateAction<number>>
  ) => {
    try {
      console.log('uploadURL:', url);
      console.log('file', fileData);
      console.log('contentType', contentType);

      const headers: { [key: string]: string } = {
        'Content-Type': contentType,
      };
      if (encoding) headers['Content-Encoding'] = encoding;
      const res = await axios.put(url, fileData, {
        headers: headers,
        onUploadProgress: function (progressEvent) {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log('percent', percent);
          setProgress && setProgress(percent);
        },
      });
      console.log(res);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  /**
   * ファイル圧縮
   * @param fileData
   * @param fileName
   * @returns
   */
  GetCompressionGzip = async (fileData: File, fileName: string) => {
    return new File(
      [gzipSync(new Uint8Array(await fileData.arrayBuffer()), { filename: fileName, mtime: 0 })],
      fileName + '.gz',
      { type: 'application/gzip' }
    );
  };

  // /**
  //  * ファイルアップロード
  //  * @param bucket
  //  * @param uploadFile
  //  * @param path
  //  */
  // FileUpload = async (bucket: string, uploadFile: File, path: string) => {
  //   try {
  //     const region = process.env.REACT_APP_BUCKET_REGION;
  //     // Storage.configure({ bucket: bucket, region: region });
  //     // const result = await Storage.put(path, uploadFile);

  //     let s3param: { [key: string]: any } = {
  //       params: { Bucket: bucket },
  //       region: region,
  //     };
  //     if (process.env.NODE_ENV !== 'development') {
  //       s3param.endpoint = 'localhost:9091';
  //     }
  //     let putBucket = new AWS.S3(s3param);
  //     const params = {
  //       ACL: 'public-read',
  //       Body: uploadFile,
  //       Bucket: bucket,
  //       Key: path,
  //     };
  //     const result = await putBucket.putObject(params);
  //     console.log(result);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
}
