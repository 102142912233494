import Dexie from 'dexie';

/**
 * IndexedDBに保存するオブジェクト
 */
export interface S3Cache {
    key: string;
    blobData: Blob;
};

/**
 * IndexedDBをラップするDexieクラス
 */
export class FabsDatabase extends Dexie {

    s3Cache!: Dexie.Table<S3Cache, string>;

    constructor() {
        super('3d-fabs');

        // テーブルとインデックスを定義
        this.version(1).stores({
            s3Cache: 'key',
        });
        this.s3Cache = this.table('s3Cache');
    }

}