import * as THREE from 'three';

export class ModelControls {

    private _object: THREE.Mesh;
    private _camera: THREE.Camera;
    private _domElement: HTMLCanvasElement;
    private _dragFlag: boolean;

    private _rotateStart: THREE.Vector2;
    private _rotateEnd: THREE.Vector2;
    private _rotateDelta: THREE.Vector2;
    private _sphericalDelta: THREE.Vector2;

    public enabled: boolean;

    constructor(object: THREE.Mesh, camera: THREE.Camera, domElement: HTMLCanvasElement){

        this._object = object;
        this._camera = camera;
        this._domElement = domElement;
        this._dragFlag = false;
        this.enabled = true;

        this._rotateStart = new THREE.Vector2(0, 0);
        this._rotateEnd = new THREE.Vector2(0, 0);
        this._rotateDelta = new THREE.Vector2(0, 0);
        this._sphericalDelta = new THREE.Vector2(0, 0);

        this._domElement.addEventListener('pointerdown', (event) => this.rotateStart(event));
        this._domElement.addEventListener('pointermove', (event) => this.rotateMove(event));
        this._domElement.addEventListener('pointerup', (event) => this.rotateEnd(event));
    }
    
    private rotateStart(event: PointerEvent){
        if(!this.enabled) return;

        this._dragFlag = true;
        this._sphericalDelta.set(0, 0);
        this._rotateStart.set(event.clientX, event.clientY);
        this._rotateEnd.set(event.clientX, event.clientY);
    }

    private rotateMove(event: PointerEvent){

        if(!this._dragFlag) return;

        this._rotateEnd.set(event.clientX, event.clientY);
        this._rotateDelta.subVectors(this._rotateEnd, this._rotateStart);

        this.rotateDeltaIn(
            2 * Math.PI * this._rotateDelta.x / this._domElement.clientHeight,
            2 * Math.PI * this._rotateDelta.y / this._domElement.clientHeight
        );

        this._rotateStart.copy(this._rotateEnd);

        this.update();
    }

    private rotateDeltaIn(x: number, y: number){
        this._sphericalDelta.x -= x;
        this._sphericalDelta.y -= y;
    }

    private rotateEnd(event: PointerEvent){
        if(event) {
            this._dragFlag = false;
            this.update();
        }
    }

    public update(){
        if(!this._dragFlag) return;

        const leftRotationAxis = new THREE.Vector3(0, -1, 0);
        const upRotationAxis = new THREE.Vector3(-1, 0, 0);

        const cameraRot = (new THREE.Quaternion().setFromEuler(this._camera.rotation));
        const modelRot = (new THREE.Quaternion().setFromEuler(this._object.rotation)).invert();

        const transQuot = new THREE.Quaternion().multiplyQuaternions(modelRot, cameraRot);

        leftRotationAxis.applyQuaternion(transQuot);
        upRotationAxis.applyQuaternion(transQuot);

        this._object.rotateOnAxis(leftRotationAxis, this._sphericalDelta.x);
        this._object.rotateOnAxis(upRotationAxis, this._sphericalDelta.y);

        this._sphericalDelta.set(0, 0);
    }
}