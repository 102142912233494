import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import FlowArea from '../flow/FlowArea';
import '../../assets/css/designCommon.css';
import '../../assets/css/quotationRequest.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import { viewSelectType } from '../../pages/requestQuotation/RequestQuotation';
import CommonContactTable from '../contactPerson/ContactTable';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { useState } from 'react';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewSelectType>>;
  userInfo: MyIF.userInfo;
  contactChangeFlg: boolean;
  initCampaignCode?: string;
}
const QuotationMain: React.FC<Props> = React.memo(
  ({ setViewSelect, userInfo, contactChangeFlg, initCampaignCode }) => {
    const { setAlertShow } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();
    const location = useLocation();

    type destType = 'PDF' | 'mail';
    const [destFlg, setDestFlg] = React.useState<destType>('PDF');

    const [campaignCode, setCampaignCode] = useState('');

    /**
     * 見積依頼
     */
    const putQuoteAuto = async () => {
      setProgressShow(true);
      const quotationInfo: MyIF.quotationInfo = {
        specNumber: location.state.trialNumberList,
        destFlg: destFlg,
        contactChangeFlg: contactChangeFlg,
        campaignCode: campaignCode,
      };
      if (contactChangeFlg) {
        quotationInfo.userInfo = userInfo;
      }
      console.log('[Auto] quotationInfo', quotationInfo);
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).PutQuoteAuto(quotationInfo);
      if (result) {
        setViewSelect('Complete');
      }
      setProgressShow(false);
    };

    /**
     * 見積依頼（担当者）
     */
    const putQuoteManual = async () => {
      setProgressShow(true);
      const quotationInfo: MyIF.quotationInfo = {
        specNumber: location.state.trialNumberList,
        destFlg: '',
        contactChangeFlg: contactChangeFlg,
        campaignCode: campaignCode,
      };
      if (contactChangeFlg) {
        quotationInfo.userInfo = userInfo;
      }
      console.log('[Manual] quotationInfo', quotationInfo);
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).PutQuoteManual(quotationInfo);
      if (result) {
        setViewSelect('Contract');
      }
      setProgressShow(false);
    };

    React.useEffect(() => {
      if (initCampaignCode) setCampaignCode(initCampaignCode);
    }, [initCampaignCode]);

    return (
      <div>
        <FlowArea active={3} subActive={[1, 2]}></FlowArea>
        <div className='mainContentsBlock' style={{ marginBottom: '20px' }}>
          <div className='pageTitleBlock'>
            <div className='pageTitleInner'>
              <div className='pageTitleWithBtn'>
                <h1 className='pageTitle'>正式見積依頼</h1>
              </div>
            </div>
          </div>
          <div className='pageTitleDetailText'>
            <p>
              オリックス・レンテックの担当部署から、正式なお見積もりを発行します。
              <br />
              見積書の送付先の情報、表記される部署名、お名前等について、ご確認ください。
              <br />
              登録と異なる送付先に送付をご希望される場合は、送付先情報変更ボタンを選択してください。
            </p>
          </div>
          <CommonContactTable userInfo={userInfo}></CommonContactTable>

          <div style={{ padding: '20px 24px' }}>
            <div
              className='registrationFormContent'
              style={{ alignItems: 'center', gap: 16, marginBottom: 8 }}
            >
              <span style={{ fontSize: 14, fontWeight: 'bold', color: '#333333' }}>
                キャンペーンコード（任意）
              </span>
              <input
                style={{ fontSize: 14, color: '#333333' }}
                value={campaignCode}
                onChange={(e) => setCampaignCode(e.target.value)}
                maxLength={50}
              />
            </div>
            <span style={{ fontSize: 14, color: '#333333' }}>
              ※メルマガやアンケートに記載されているキャンペーンコードをご入力ください
            </span>
          </div>

          <div className='quotationRequestButtonArea'>
            <a
              href='"'
              className='changeShippingInfoLink button-style'
              onClick={(event) => {
                event.preventDefault();
                setViewSelect('Edit');
              }}
            >
              送付先情報変更
            </a>

            <div className='quotationRequestButtonBlock'>
              <div className='contactPerson'>
                <h3>担当者連絡をご希望の方</h3>
                <p>
                  確認事項があるため、
                  <br />
                  担当者からの連絡をご希望の方はこちらから
                </p>
                <button
                  className='btnContactPerson'
                  type='button'
                  name='button'
                  onClick={() => {
                    putQuoteManual();
                  }}
                >
                  担当者からの連絡希望
                </button>
              </div>
              <div className='formalQuoteRequest'>
                <h3>正式見積書の送付方法を選択</h3>
                <p>
                  <select
                    defaultValue={destFlg}
                    onChange={(event) => {
                      setDestFlg(event.target.value as destType);
                    }}
                  >
                    <option value='PDF'>PDFファイルにて送信</option>
                    <option value='mail'>郵送</option>
                  </select>
                </p>
                <button
                  className='btnOrangeCommit'
                  type='button'
                  name='button'
                  onClick={() => {
                    putQuoteAuto();
                  }}
                >
                  正式見積依頼
                </button>
              </div>
            </div>
          </div>
          <div className='modelReuploadButtonArea'>
            <button
              className='btnBack'
              type='button'
              name='button'
              onClick={() => {
                navigate('/TrialCalcList');
              }}
            >
              戻る
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default QuotationMain;
