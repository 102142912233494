import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import TrialCalcInfoAreaContent from './TrialCalcInfoAreaContent';

interface Props {
  trialCalcInfo: MyIF.trialCalcInfoType | undefined;
}

const TrialCalcInfoArea: React.FC<Props> = React.memo(({ trialCalcInfo }) => {
  return (
    <section className='modelNoSpecEditFormAccordionContent'>
      <input id='toggle5' type='checkbox' className='modelNoSpecEditFormToggle'></input>
      <label className='modelNoSpecEditFormToggleLabel' htmlFor='toggle5'>
        試算基本情報
      </label>
      <TrialCalcInfoAreaContent trialCalcInfo={trialCalcInfo} />
    </section>
  );
});

export default TrialCalcInfoArea;
