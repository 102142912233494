import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import FlowArea from '../../components/flow/FlowArea';
import NameArea from '../../components/trialCalcSpecDetails/NameArea';
import ModelNumberSelectArea from '../../components/trialCalcSpecDetails/ModelNumberSelectArea';
import TrialCalcConditionArea from '../../components/trialCalcSpecDetails/TrialCalcConditionArea';
import TrialCalcPrice from '../../components/trialCalcSpecDetails/TrialCalcPrice';
import Calendar, { calendarFunc } from '../../components/trialCalcSpecDetails/Calendar';
import ModalAddModelNumber from '../../components/trialCalcSpecDetails/ModalAddModelNumber';
import FooterContent from '../../components/footer/Footer';
import CommonContact from '../../components/contactPerson/Contact';
import InformationMessage from '../../components/message/InformationMessage';
import ViewerArea from '../../components/trialCalcSpecDetails/ViewerArea';
import { gavadonFuncHandle } from '../../components/viewer/Gavadon';
import { useProgress } from '../../provider/progress/ProgressProvider';

const useTrialCalcSpecEdit = () => {
  const { setAlertShow, setMessageList } = useAlert();
  const { setProgressShow } = useProgress();

  const location = useLocation();
  const navigate = useNavigate();

  // 型番追加画面　制御用
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
  // 担当者連絡画面　制御用
  const [isContactPage, setIsContactPage] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [isContactPage]);
  // 試算番号
  const [trialNumber, setTrialNumber] = React.useState<string>(location.state.trialNumber);
  // ラベル名
  const [labelName, setLabelName] = React.useState<string>(location.state.labelName);
  // 型番リスト
  const [addModelNumberList, setAddModelNumberList] = React.useState<MyIF.addModelNumber[]>([]);
  // 型番３０個数量チェック
  const [quantityAlert, setQuantityAlert] = React.useState(false);
  // 造形条件
  const [selectModelCondition, setSelectModelCondition] =
    React.useState<MyIF.selectModelingConditionType>({
      modelingMaterialId: undefined,
      materialValue: '',
      methodValue: '',
      printerValue: '',
    });
  // 単価情報
  const [priceInfo, setPriceInfo] = React.useState<MyIF.priceInfo>({
    normal: {
      price: 0,
      date: '',
    },
    express: {
      price: 0,
      date: '',
    },
  });
  // バウンディングボックス
  const [boudingBox, setBoudingBox] = React.useState<MyIF.boundingBoxInfo>();
  // バウンディングボックス　カレントバッチ
  const [currentBatch, setCurrentBatch] = React.useState<number>(1);
  // 休日リスト
  const [holidayList, setHolidayList] = React.useState<string[]>([]);
  // 希望納期・価格
  const [deadLineInfo, setDeadLineInfo] = React.useState<MyIF.deadLineInfo>({
    deadLineDate: '',
    price: '',
  });
  // 初期モーダル制御
  const [isInitMessage, setIsInitMessage] = React.useState(true);
  const handleInitClose = () => {
    setIsInitMessage(false);
  };
  // カレンダーフック
  const calendarRef = React.useRef<calendarFunc>(null!);
  // Gavadonフック
  const gavadonRef = React.useRef<gavadonFuncHandle>(null!);

  // 数量チェック
  React.useEffect(() => {
    let count = 0;
    addModelNumberList.forEach((element) => {
      count += Number(element.num);
    });
    setQuantityAlert(count > 30 ? true : false);
  }, [addModelNumberList, deadLineInfo.price]);

  /**
   * 計算結果取得
   */
  async function GetCalcResult(addList: MyIF.addModelNumber[]) {
    if (addList.length > 0) {
      setProgressShow(true);
      const calcResult = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetTrialCalcResult(trialNumber, addList);
      if (calcResult !== null) {
        setPriceInfo(calcResult.priceInfo);
        setBoudingBox(calcResult.boundingBoxInfo);
        console.log('BoudingBox', calcResult.boundingBoxInfo);
        if (calcResult.boundingBoxInfo.batchList.length <= currentBatch) {
          setCurrentBatch(1);
        }
      }
      setProgressShow(false);
    } else {
      setPriceInfo({
        normal: {
          price: 0,
          date: '',
        },
        express: {
          price: 0,
          date: '',
        },
      });
      setBoudingBox(undefined);
      setCurrentBatch(1);
    }
  }

  /**
   * 初期設定
   */
  React.useEffect(() => {
    (async () => {
      setProgressShow(true);
      if (location.state.modelNumberList) {
        console.log('location.state.modelNumberList', location.state.modelNumberList);
        let tmpList: MyIF.addModelNumber[] = [];
        const results: Promise<{
          labelName: string;
          threeDFileName: string;
          modelConditon: MyIF.selectModelingConditionType;
          optionInfo: MyIF.optionInfo;
          priceInfo: MyIF.priceInfo;
          viewerMemo: string;
          requireCalcFlg: boolean;
          modelViewInfo: MyIF.modelViewInfo;
        } | null>[] = [];
        const controller = await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        });
        location.state.modelNumberList.map(async (value: string) => {
          results.push(controller.GetModelNumberDetail(value));
          return results;
        });
        await Promise.all(results).then((result) => {
          for (let ii = 0; ii < result.length; ii++) {
            let tmpResult = result[ii];
            if (tmpResult !== null) {
              tmpList.push({
                modelNumber: location.state.modelNumberList[ii],
                label: tmpResult.labelName,
                num: '1',
              });
            }
          }
        });
        console.log('tmpList', tmpList);
        setAddModelNumberList(tmpList);
        GetCalcResult(tmpList);

        // 試算条件
        await (
          await APIController.build({
            setAlertShow: setAlertShow,
            setAlertMessage: setMessageList,
            navigate: navigate,
          })
        )
          .GetModelNumberDetail(tmpList[0].modelNumber)
          .then((result) => {
            if (result) {
              console.log('試算条件', result);
              setSelectModelCondition(result.modelConditon);
            }
          });
      } else {
        if (trialNumber !== '') {
          // 詳細取得API
          const result = await (
            await APIController.build({
              setAlertShow: setAlertShow,
              setAlertMessage: setMessageList,
              navigate: navigate,
            })
          ).GetTrialCalcDetail(trialNumber);
          if (result !== null) {
            setLabelName(result.labelName);
            setAddModelNumberList(result.modelNumberList);
            setSelectModelCondition(result.selectModelCondition);
            setPriceInfo(result.priceInfo);
            GetCalcResult(result.modelNumberList);
            // 納期と日付は再メンテナンス
            calendarRef.current.setDatePrice(result.deadLineInfo.deadLineDate, result.priceInfo);
            // setDeadLineInfo(result.deadLineInfo);
          }
        }
      }

      // 休日リスト取得
      (async () => {
        const holidayList = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetHolidayList();
        if (holidayList !== null) {
          setHolidayList(holidayList);
        }
      })();
      setProgressShow(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 計算実行
   */
  React.useEffect(() => {
    GetCalcResult(addModelNumberList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addModelNumberList]);

  const saveClose = async (confirmFlg: boolean) => {
    setProgressShow(true);
    // キャプチャを取る
    let captureS: string | undefined;
    let captureL: string[] | undefined;
    if (boudingBox) {
      captureL = [];
      for (let ii = 0; ii < boudingBox.batchList.length; ii++) {
        // バウンディングボックス描画
        await gavadonRef.current.boudingBoxView(boudingBox, ii);
        // カメラ位置を変更
        await gavadonRef.current.setCamera({ x: 0, y: -(Math.PI * 0.25), z: Math.PI * 0.75 });
        if (ii === 0) {
          captureS = await gavadonRef.current.getCapture();
        }
        captureL.push(await gavadonRef.current.getCapture('PDF'));
      }
    }

    // とりあえず保存
    const newNumber = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        setAlertMessage: setMessageList,
        navigate: navigate,
      })
    ).PutTrialCalc(
      trialNumber,
      labelName,
      addModelNumberList,
      deadLineInfo.deadLineDate,
      captureS,
      captureL
    );
    if (newNumber === null) {
      // エラーの場合は遷移させない
    } else {
      // 採番されていれば新しいのを使う
      const tmpNumber = newNumber ? newNumber : trialNumber;
      setTrialNumber(tmpNumber);

      if (confirmFlg) {
        // 確定API呼び出し
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            setAlertMessage: setMessageList,
            navigate: navigate,
          })
        ).PutTrialCalcConfirm(tmpNumber);
        if (result) {
          console.log('確定API判定結果', result.confirmFlg);
          if (result.confirmFlg) {
            // 試算条件の確定
            navigate('/TrialCalcList');
          } else {
            // 担当者連絡
            setIsContactPage(true);
          }
        }
      } else {
        // 試算条件の確定
        navigate('/TrialCalcList');
      }
    }
    setProgressShow(false);
  };

  return (
    <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
      <div style={{ flex: '1' }}>
        <FlowArea active={2} subActive={[1]}></FlowArea>
        {isContactPage ? (
          <CommonContact
            updateFlg={1}
            updateNumber={trialNumber}
            setIsContactPage={setIsContactPage}
          ></CommonContact>
        ) : (
          <div className='mainContentsBlock'>
            <div className='modelNoSpecEditBlock'>
              <div className='modelNoSpecEditFormBlock'>
                <h1 className='pageTitle'>複数個取りと納期の試算</h1>
                {/* 名称 */}
                <NameArea
                  trialNumber={trialNumber}
                  labelName={labelName}
                  setLabelName={setLabelName}
                ></NameArea>

                {/* 対象型番と数量 */}
                <ModelNumberSelectArea
                  setIsAddModalOpen={setIsAddModalOpen}
                  addModelNumberList={addModelNumberList}
                  setAddModelNumberList={setAddModelNumberList}
                  quantityAlert={quantityAlert}
                  batchAlert={boudingBox && boudingBox?.batchList.length > 1 ? true : false}
                ></ModelNumberSelectArea>

                {/* 試算条件 */}
                <TrialCalcConditionArea
                  selectModelCondition={selectModelCondition}
                ></TrialCalcConditionArea>

                {/* 試算結果 */}
                <TrialCalcPrice
                  priceInfo={priceInfo}
                  quantityAlert={quantityAlert}
                ></TrialCalcPrice>

                {/* 納期カレンダー */}
                <Calendar
                  ref={calendarRef}
                  priceInfo={priceInfo}
                  holidayList={holidayList}
                  deadLineInfo={deadLineInfo}
                  setDeadLineInfo={setDeadLineInfo}
                  quantityAlert={quantityAlert}
                ></Calendar>
              </div>

              <ViewerArea
                boudingBox={boudingBox}
                currentBatch={currentBatch}
                setCurrentBatch={setCurrentBatch}
                gavadonRef={gavadonRef}
              />
            </div>
            <div className='fixedListControlBlock' style={{ zIndex: 110 }}>
              <div className='fixedListControlInner'>
                <button
                  className='btnWhiteBaseGray'
                  type='button'
                  name='button'
                  onClick={() => {
                    navigate(-1);
                  }}
                  style={{ marginRight: '8px' }}
                >
                  戻る
                </button>
                <button
                  className='btnOrangeCommit'
                  type='button'
                  name='button'
                  onClick={() => {
                    saveClose(true);
                  }}
                  disabled={deadLineInfo.deadLineDate === '' ? true : false}
                >
                  {quantityAlert
                    ? '担当者連絡'
                    : deadLineInfo.price === '￥-'
                    ? '担当者連絡'
                    : '保存して、次へ進む'}
                </button>
              </div>
              <FooterContent noUI={isContactPage} />
            </div>

            <ModalAddModelNumber
              show={isAddModalOpen}
              setIsModalOpen={setIsAddModalOpen}
              addModelNumberList={addModelNumberList}
              setAddModelNumberList={setAddModelNumberList}
              selectModelCondition={selectModelCondition}
            ></ModalAddModelNumber>
          </div>
        )}

        <InformationMessage
          show={isInitMessage}
          title={'操作説明'}
          message={''}
          imagePath={'TrialCalcDetail.png'}
          handleClose={handleInitClose}
        ></InformationMessage>
      </div>
      <FooterContent noUI={isContactPage} />
    </div>
  );
};

export default useTrialCalcSpecEdit;
