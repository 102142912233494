import * as THREE from 'three';
import * as constant from '../Constant';
import { Calculate } from './Calculate';
import { Simplex } from './Simplex';
import { GavadonData } from './GavadonData';

export class Edge extends Simplex{

    //フィールド変数

    //コンストラクタ
    constructor(data: GavadonData, option?: {color?: THREE.ColorRepresentation}){
        const calculate = new Calculate();
        //頂点データ
        const vertices = calculate.roundArrayOf(data.edge_data.points, constant.DIGIT);
        //データの準備
        let edgeList = new Array();
        let segmentPoints = new Array();
        let collisions = new Array();

        //進捗確認ログ用
        // const total = data.edge_data.edges.length;
        // console.log('edges:', total);

        //各辺ごとに処理
        data.edge_data.edges.forEach(edge => {
            //頂点をリストに追加
            const verticesOfEdge = vertices.slice(edge.start_index * 3, (edge.end_index + 1) * 3);
            edgeList.push(verticesOfEdge);
            //辺の線分ごとの頂点をリストに追加
            for(let i = 0; i < edge.end_index - edge.start_index; i++){
                for(let j = 0; j < 6; j++){
                    segmentPoints.push(verticesOfEdge[i * 3 + j]);
                }
            }
        });

        //描画用全体メッシュ
        var edgeBuffer = new THREE.BufferAttribute(new Float32Array(segmentPoints), 3);
        const geometry = new THREE.BufferGeometry().setAttribute('position', edgeBuffer);
        const allEdges = new THREE.LineSegments(
            geometry,
            new THREE.LineBasicMaterial({ color: option?.color === undefined ? constant.EDGE_COLOR : option.color })
        );
        allEdges.material.needsUpdate = true;
        //フィールド変数への代入
        super(
            vertices,
            edgeList,
            allEdges,
            collisions
        );

        geometry.dispose();
    }

    //メソッド
}