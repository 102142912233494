import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  active: number;
  subActive?: number[];
}

const FlowArea: React.FC<Props> = React.memo(({ active, subActive }) => {
  const navigate = useNavigate();

  const onNavigate = async (type: number) => {
    switch (type) {
      case 1:
        navigate('/ModelNumberList');
        break;
      case 2:
        navigate('/TrialCalcList');
        break;
      default:
        break;
    }
  };

  return (
    <section className='specFlowBlock'>
      <div className='specFlowInner'>
        <ul className='specFlowList'>
          {active === 1
            ? <li className='specFlowListItemActive'>1.単価の試算</li>
            : subActive?.includes(1)
              ? <li className='specFlowListItemActive specFlowListItemActiveNavigate' onClick={() => onNavigate(1)}>
                1.単価の試算
              </li>
              : <li className='specFlowListItem'>1.単価の試算</li>
          }

          {active === 2
            ? <li className='specFlowListItemActive'>2.複数個取りの試算</li>
            : subActive?.includes(2)
              ? <li className='specFlowListItemActive specFlowListItemActiveNavigate' onClick={() => onNavigate(2)}>
                2.複数個取りの試算
              </li>
              : <li className='specFlowListItem'>2.複数個取りの試算</li>
          }

          {active === 3 ? (
            <li className='specFlowListItemActive'>3.正式見積依頼</li>
          ) : (
            <li className='specFlowListItem'>3.正式見積依頼</li>
          )}

          {active === 4 ? (
            <li className='specFlowListItemActive'>4.見積依頼完了</li>
          ) : (
            <li className='specFlowListItem'>4.見積依頼完了</li>
          )}
        </ul>
      </div>
    </section>
  );
});

export default FlowArea;
