import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientRegistrationForm.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import { viewUserRegistType } from '../../pages/users/UserRegist';
import ModalSitePolicy from './ModalSitePolicy';
import UserInfoTableEdit from './UserInfoTableEdit';
import ConfirmMessage from '../message/ConfirmMessage';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { saveAs } from 'file-saver';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewUserRegistType>>;
  registInfo: MyIF.userRegistInfo;
  setRegistInfo: React.Dispatch<React.SetStateAction<MyIF.userRegistInfo>>;
}

const UserRegistRation: React.FC<Props> = React.memo(
  ({ setViewSelect, registInfo, setRegistInfo }) => {
    const { setAlertShow, setMessageList } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();

    const [isModalPolicyOpen, setIsModalPolicyOpen] = React.useState(false);

    // チェックボックス制御用
    const [isCheckDisabledTerms, setIsCheckDisabledTerms] = React.useState(true);
    const [isCheckDisabledPolicy, setIsCheckDisabledPolicy] = React.useState(true);

    // モーダル制御用
    const [isConfirmMessage, setIsConfirmMessage] = React.useState(false);

    const downloadTerms = () => {
      saveAs(
        process.env.PUBLIC_URL + '/TermsAndConditions/teams_and_conditons.pdf',
        '造形受託約款.pdf'
      );
      setIsCheckDisabledTerms(false);
    };

    const handleOK = () => {
      // navigate('/Login');
      navigate(-1);
    };
    const handleCancel = () => {
      setIsConfirmMessage(false);
    };

    /**
     * 登録情報チェック
     */
    const GetUserRegistCheck = async () => {
      setProgressShow(true);
      const checkResult = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).GetUserRegistCheck(registInfo);
      if (checkResult) {
        if (checkResult.length > 0) {
          setMessageList(checkResult);
          setAlertShow(true);
        } else {
          setViewSelect('Confirm');
        }
      }
      setProgressShow(false);
    };

    return (
      <div className='mainContentsBlock noFlowBar'>
        <div className='clientRegistrationForm'>
          <div className='pageTitleBlock'>
            <div className='pageTitleInner'>
              <div className='pageTitleWithBtn'>
                <h1 className='pageTitle'>お客さま情報登録</h1>
              </div>
            </div>
          </div>
          <div className='pageTitleDetailText'>
            <p>
              <span className='attentionRedText'>※</span>マークは入力必須項目です。
            </p>
          </div>

          <div className='registrationFormBlock'>
            <UserInfoTableEdit
              viewType='Regist'
              registInfo={registInfo}
              setRegistInfo={setRegistInfo}
            />
            <div className='registrationFormContentAddition'>
              <div className='registrationFormContentAdditionTitle'>
                <label htmlFor='site_policy'>
                  サイトポリシー <span className='attentionRedText'>※</span>
                </label>
              </div>
              <div className='registrationFormContentArea'>
                <p className='registrationFormContentText'>
                  サイトポリシーの承諾が必要です。
                  <br />
                  サイトポリシーの詳細は以下のリンクをクリックしてください。
                </p>
                <p className='registrationFormContentLink'>
                  <a
                    href='/#'
                    onClick={(event) => {
                      event.preventDefault();
                      setIsModalPolicyOpen(true);
                      setIsCheckDisabledPolicy(false);
                    }}
                  >
                    サイトポリシーを確認
                  </a>
                </p>
                <div className='registrationFormContentAdditionCheckbox'>
                  <input
                    id='sitePolicyCheckBox'
                    type='checkbox'
                    name=''
                    checked={registInfo.policyFlg}
                    onChange={(event) => {
                      setRegistInfo({ ...registInfo, policyFlg: event.target.checked });
                    }}
                    disabled={isCheckDisabledPolicy}
                  />
                  <label htmlFor='sitePolicyCheckBox'>承諾する</label>
                </div>
              </div>
            </div>
            {/* <div className='registrationFormContentAddition'>
              <div className='registrationFormContentAdditionTitle'>
                <label htmlFor='mail_magazine'>メールマガジンの購読</label>
              </div>
              <div className='registrationFormContentArea'>
                <p className='registrationFormContentText'>
                  本システムのアップデート情報や3Dプリントに関係するメールマガジンを購読されますか。
                </p>
                <div className='registrationFormContentAdditionCheckbox'>
                  <input
                    id='mailCheckBox'
                    type='checkbox'
                    name=''
                    checked={registInfo.mailFlg}
                    onChange={(event) => {
                      setRegistInfo({ ...registInfo, mailFlg: event.target.checked });
                    }}
                  />
                  <label htmlFor='mailCheckBox'>購読する</label>
                </div>
              </div>
            </div> */}
          </div>

          <div className='clientInformationRegistrationButtonArea'>
            <button
              className='btnBack'
              type='button'
              name='button'
              onClick={() => {
                setIsConfirmMessage(true);
              }}
            >
              戻る
            </button>
            <button
              className='btnOrangeCommit'
              type='button'
              name='button'
              onClick={GetUserRegistCheck}
            >
              次へ
            </button>
          </div>
        </div>

        <ModalSitePolicy
          show={isModalPolicyOpen}
          setIsModalOpen={setIsModalPolicyOpen}
        ></ModalSitePolicy>

        <ConfirmMessage
          show={isConfirmMessage}
          title={'確認'}
          message={'入力した内容がクリアされますが、よろしいですか？'}
          handleOK={handleOK}
          handleCancel={handleCancel}
        ></ConfirmMessage>
      </div>
    );
  }
);

export default UserRegistRation;
