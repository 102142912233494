import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientLogin.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/header.css';
import FamsLogo from '../../assets/image/common/fabs_logo.svg';
import OrixLogo from '../../assets/image/common/orix_logo_gray.svg';

const HeaderNoLogin: React.FC = React.memo((props) => {
  const navigate = useNavigate();

  return (
    <header className='headerBlock'>
      <div className='headerInner'>
        <div className='headerMainMenuBlock'>
          <div className='headerLogoBlock'>
            <a
              className='headerLogoLink'
              href='/#'
              onClick={(event) => {
                event.preventDefault();
                navigate('/');
              }}
            >
              <img src={FamsLogo} alt='' className='headerLogoImage' />
              <img src={OrixLogo} alt='' className='headerOrixLogoImage' />
            </a>
          </div>
        </div>

        <div className='headerSubMenuBlock'>
          <a
            href='https://www.orixrentec.jp/3dprinter/faq/'
            className='headerSubMenuLinkFaqNoLogin'
            target='_blank'
            rel='noopener noreferrer'
          >
            FAQ
          </a>
          <button
            className='headerSubMenuLinkSignUpBlock button-style'
            onClick={() => {
              navigate('/UserRegister');
            }}
          >
            <div className='headerSubMenuLinkSignUp'>
              <span className='headerSubMenuSignUpSpan'>新規会員登録</span>
            </div>
          </button>
          <button
            className='headerSubMenuLinkUserNameBlock headerSubMenuLinkUserNameBlockLogin button-style'
            onClick={() => {
              navigate('/Login', { state: { viewSelect: 'Login' } });
            }}
          >
            <div className='headerSubMenuLinkLogin'>
              <span className='headerSubMenuLoginSpan'>ログイン</span>
            </div>
          </button>
        </div>
      </div>
    </header>
  );
});

export default HeaderNoLogin;
