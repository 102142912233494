import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import FooterContent from '../../components/footer/Footer';
import HeaderNoLogin from '../../components/header/HeaderNoLogin';
const LogOut = () => {
  const navigate = useNavigate();

  function resetCaretPosition() {
    const container = document.createElement('div'); // 編集不可の要素を作成
    container.setAttribute('contenteditable', 'true'); // 編集不可の要素にcontenteditable属性を設定
    container.style.opacity = '0'; // 編集不可の要素を非表示にする
    container.style.position = 'fixed'; // 編集不可の要素を画面外に配置する
    container.style.pointerEvents = 'none'; // 編集不可の要素をクリック可能にするためにpointer-eventsをnoneにする
    container.textContent = '\u200b'; // 編集不可の要素に空白を設定する
    document.body.appendChild(container); // 編集不可の要素をdocumentに追加する

    container.focus(); // 編集不可の要素にフォーカスを移動する
    window.getSelection()?.removeAllRanges(); // 選択範囲を全て解除する

    // 編集不可の要素を非表示にしてから表示することで、キャレット位置のリセットを実現する
    container.style.display = 'none';
    container.style.display = '';

    container.blur(); // 編集不可の要素からフォーカスを外す
    document.body.removeChild(container); // 編集不可の要素をdocumentから削除する
  }

  React.useEffect(() => {
    // resetCaretPosition関数を使用して、キャレット位置をリセットする
    resetCaretPosition();
  }, []);

  return (
    <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
      <div style={{ flex: '1' }}>
        <HeaderNoLogin />
        <div className='mainContentsBlock noFlowBar'>
          <div className='clientRegistrationForm'>
            <div className='pageTitleBlock'>
              <div className='pageTitleInner'>
                <div className='pageTitleWithBtn'>
                  <h1 className='pageTitle'>ログアウト</h1>
                </div>
              </div>
            </div>
            <div className='pageTitleDetailText'>
              <p>ログアウトされました。</p>
            </div>

            <div className='clientInformationConfirmationButtonArea'>
              <button
                className='btnOrangeCommit'
                type='button'
                name='button'
                onClick={() => {
                  navigate('/');
                }}
              >
                3D-FABs トップページへ
              </button>
            </div>
          </div>
        </div>
      </div>
      <FooterContent noUI={true}></FooterContent>
    </div>
  );
};

export default LogOut;
