import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/top.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import Image1 from '../../assets/image/top/top_fv_model_01.jpg';
import Image2 from '../../assets/image/top/top_fv_model_02.jpg';
import Image3 from '../../assets/image/top/top_fv_model_03.jpg';
import Image4 from '../../assets/image/top/top_fv_model_04.jpg';
import Image5 from '../../assets/image/top/top_fv_model_05.jpg';
import Image6 from '../../assets/image/top/top_fv_model_06.jpg';
import Image7 from '../../assets/image/top/top_fv_model_07.jpg';
import Image8 from '../../assets/image/top/top_fv_model_08.jpg';
import Image9 from '../../assets/image/top/top_fv_model_09.jpg';
import Image10 from '../../assets/image/top/top_fv_model_10.jpg';
import ExampleImg1 from '../../assets/image/top/top_example_model_1.jpg';
import ExampleImg2 from '../../assets/image/top/top_example_model_2.jpg';
import ExampleImg3 from '../../assets/image/top/top_example_model_3.jpg';
import ExampleImg4 from '../../assets/image/top/top_example_model_4.jpg';
import ExampleImg5 from '../../assets/image/top/top_example_model_5.jpg';
import StepImage1 from '../../assets/image/top/top_step01.jpg';
import StepImage2 from '../../assets/image/top/top_step02.jpg';
import StepImage3 from '../../assets/image/top/top_step03.jpg';
import PointImage1 from '../../assets/image/top/top_point01.png';
import PointImage2 from '../../assets/image/top/top_point02.png';
import PointImage3 from '../../assets/image/top/top_point03.png';
import CCImage1 from '../../assets/image/top/cc-logo.svg';
import CCImage2 from '../../assets/image/top/cc-by.svg';
import CCImage3 from '../../assets/image/top/cc-nc.svg';
import ThreeDescription1 from '../../assets/image/top/top_pdf_description01.jpg';
import ThreeDescription2 from '../../assets/image/top/top_pdf_description02.jpg';
import Fabscontents2 from '../../assets/image/top/about_3dfabs_contents4_image.png';
import Fabscontents3 from '../../assets/image/top/about_3dfabs_contents5_image.jpg';
import Fabsfeatureleft1 from '../../assets/image/top/about_3dfabs_contents3_feature1_blockleft_img.png';
import Fabsfeatureleft2 from '../../assets/image/top/about_3dfabs_contents3_feature2_blockleft_img.png';
import Fabsfeatureright1 from '../../assets/image/top/about_3dfabs_contents3_feature1_blockright01_img.png';
import Fabsfeatureright2 from '../../assets/image/top/about_3dfabs_contents3_feature1_blockright02_img.png';
import Fabsfeatureright3 from '../../assets/image/top/about_3dfabs_contents3_feature1_blockright03_img.png';
import Fabsfeatureright4 from '../../assets/image/top/about_3dfabs_contents3_feature2_blockright01_img.png';
import Fabsfeatureright5 from '../../assets/image/top/about_3dfabs_contents3_feature2_blockright02_img.png';
import { LegacyRef, useCallback, useEffect } from 'react';
import { saveAs } from 'file-saver';
import ModalSafeHref from './ModalSafeHref';
import ModalSitePolicy from '../user/ModalSitePolicy';
import { useNavigate } from 'react-router-dom';

interface Props {
  aboutRef: React.MutableRefObject<HTMLDivElement>;
  aboutRef2: React.MutableRefObject<HTMLDivElement>;
  aboutRef3: React.MutableRefObject<HTMLDivElement>;
}

interface ImageProps {
  id: number;
  img: string;
  stl?: string;
  stlName?: string;
  isExample: boolean
}

const ExampleStl1 = `${process.env.PUBLIC_URL}/ExpStl/waveguide.stl`
const ExampleStl2 = `${process.env.PUBLIC_URL}/ExpStl/testpiece.stl`
const ExampleStl3 = `${process.env.PUBLIC_URL}/ExpStl/couplingA.stl`
const ExampleStl4 = `${process.env.PUBLIC_URL}/ExpStl/orientation.stl`
const ExampleStl5 = `${process.env.PUBLIC_URL}/ExpStl/Mark_Two_jig_sup.stl`
const ExamplePdf = `${process.env.PUBLIC_URL}/ExpPdf/threeDPdf.pdf`

const imageList: ImageProps[] = [
  {
    id: 1,
    img: Image1,
    isExample: false,
  },
  {
    id: 2,
    img: Image2,
    isExample: false,
  },
  {
    id: 3,
    img: ExampleImg1,
    stl: ExampleStl1,
    stlName: 'waveguide.stl',
    isExample: true,
  },
  {
    id: 4,
    img: Image3,
    isExample: false,
  },
  {
    id: 5,
    img: Image4,
    isExample: false,
  },
  {
    id: 6,
    img: ExampleImg2,
    stl: ExampleStl2,
    stlName: 'testpiece.stl',
    isExample: true,
  },
  {
    id: 7,
    img: Image5,
    isExample: false,
  },
  {
    id: 8,
    img: Image6,
    isExample: false,
  },
  {
    id: 9,
    img: ExampleImg3,
    stl: ExampleStl3,
    stlName: 'couplingA.stl',
    isExample: true,
  },
  {
    id: 10,
    img: Image7,
    isExample: false,
  },
  {
    id: 11,
    img: Image8,
    isExample: false,
  },
  {
    id: 12,
    img: ExampleImg4,
    stl: ExampleStl4,
    stlName: 'orientation.stl',
    isExample: true,
  },
  {
    id: 13,
    img: Image9,
    isExample: false,
  },
  {
    id: 14,
    img: Image10,
    isExample: false,
  },
  {
    id: 15,
    img: ExampleImg5,
    stl: ExampleStl5,
    stlName: 'Mark_Two_jig_sup.stl',
    isExample: true,
  },
]

const timer = 3000

const About3DFABs: React.FC<Props> = React.memo(({ aboutRef, aboutRef2, aboutRef3 }) => {
  const navigate = useNavigate();
  //タブ切り替え：青色と緑色で独立させるために2つ用意
  const [activeTab1, setActiveTab1] = React.useState(0);
  const [activeTab2, setActiveTab2] = React.useState(0);
  const price1TimerRef = React.useRef<NodeJS.Timeout>()
  const price2TimerRef = React.useRef<NodeJS.Timeout>()

  const [isModalPolicyOpen, setIsModalPolicyOpen] = React.useState(false);
  const [isModalSafeHrefOpen, setIsModalSafeHrefOpen] = React.useState(false);

  const [isThreeDownloadActive, setIsThreeDownloadActive] = React.useState(false)

  const slideUlRef = React.useRef<HTMLUListElement>()
  const [exampleHoverId, setExampleHoverId] = React.useState<number>()

  const exampleItemAction = (item: ImageProps, itemActive: boolean) => {
    if (!item.isExample) return;

    if (itemActive) {
      setExampleHoverId(item.id)
      slideUlRef.current!.style.animationPlayState = 'paused';
    } else {
      setExampleHoverId(undefined)
      slideUlRef.current!.style.animationPlayState = 'running';
    }
  }

  const onExampleItemDownload = (item: ImageProps) => {
    if (item.stl && item.stlName) {
      saveAs(item.stl, item.stlName);
    }
  }

  const clearPriceInterval = useCallback((timer?: NodeJS.Timeout | null) => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
  }, [])

  const startPrice1Timer = useCallback(() => {
    if (price1TimerRef.current) clearPriceInterval(price1TimerRef.current)

    price1TimerRef.current = setInterval(() => {
      setActiveTab1(value => value >= 2 ? 0 : value + 1)
    }, timer)
  }, [clearPriceInterval])

  const startPrice2Timer = useCallback(() => {
    if (price2TimerRef.current) clearPriceInterval(price2TimerRef.current)

    price2TimerRef.current = setInterval(() => {
      setActiveTab2(value => value >= 1 ? 0 : value + 1)
    }, timer)
  }, [clearPriceInterval])

  const handleTabChange1 = (index: number) => {
    setActiveTab1(index);
  };

  const handleTabChange2 = (index: number) => {
    setActiveTab2(index);
  };

  const onThreeLeftDownload = (e: any) => {
    e.stopPropagation()
    saveAs(ExamplePdf, "材料のご紹介");
  }

  useEffect(() => {
    const timer1 = price1TimerRef?.current
    const timer2 = price2TimerRef?.current

    startPrice1Timer()
    startPrice2Timer()

    return () => {
      clearPriceInterval(timer1)
      clearPriceInterval(timer2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div ref={aboutRef}>
        {/* 3D-FABsについて */}
        <div id='about3dfabsPoints' className=''>
          {/* <div className='backgroundTitleText'><span>ABOUT 3D-FABs</span></div> */}
          <div className='about3dfabsPointsBlock'>
            <h2 className='about3dfabsHeadline'>3D-FABsの3つおすすめポイント</h2>
            <ul className='about3dfabs3PointsList'>
              <li>
                <div className='about3dfabs3PointsListPara'>
                  <img src={PointImage1} alt='' />
                  <ul>
                    3Dデータの改善策を
                    <br />
                    無料ですぐにアドバイ
                    <br />ス
                  </ul>
                </div>
              </li>
              <li>
                <div className='about3dfabs3PointsListPara'>
                  <img src={PointImage2} alt='' />
                  <ul>
                    24時間いつでも
                    <br />
                    概算見積もりがわか
                    <br />
                    ります(※1)
                  </ul>
                </div>
              </li>
              <li>
                <div className='about3dfabs3PointsListPara'>
                  <img src={PointImage3} alt='' />
                  <ul>
                    コストを抑える
                    <br />
                    ヒントが得られます
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div id='about3dfabs' className=''>
          {/*<div className='backgroundTitleText'><span>ABOUT 3D-FABs</span></div>*/}
          <div className='about3dfabsContentsBlock'>
            <h2 className='about3dfabsHeadline'>3D-FABsのシミュレーションの3つステップ</h2>
            <ul className='about3dfabs3stepsList'>
              <li>
                <h3 className="about3dfabs3stepsListH3">3Dデータ登録</h3>
                <div className="about3dfabs3stepsListImg"><img src={StepImage1} alt=""/></div>
                <div className="about3dfabs3stepsListPara">
                  <ul>
                    <li>3D CADデータをアップロード</li>
                    <li>材料、プリンターの機種を選択</li>
                    <li>造形シミュレーションスタート！</li>
                  </ul>
                </div>
              </li>
              <li>
                <h3 className="about3dfabs3stepsListH3">単価の試算</h3>
                <div className="about3dfabs3stepsListImg"><img src={StepImage2} alt=""/></div>
                <div className='about3dfabs3stepsListPara'>
                  <ul>
                    <li>単価と納期を確認</li>
                    <li>
                      造形姿勢を変えると、サポート材体積、
                      <br />
                      造形時間、単価も変わります
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <h3 className='about3dfabs3stepsListH3'>複数個取りの試算</h3>
                <div className='about3dfabs3stepsListImg'><img src={StepImage3} alt=""/></div>
                <div className='about3dfabs3stepsListPara'>
                  <ul>
                    <li>
                      複数個をまとめて造形すれば、単価が
                      <br />
                      安くなります
                    </li>
                    <li>
                      プリンターの機種とパーツの数量に
                      <br />
                      応じた最適なレイアウトを自動表示
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="safeInfo">
      {/* 3D-FABsのセキュリティポリシー */}
        <div className="safeInfoInner">
          <h1>「3D-FABsのセキュリティポリシー」</h1>
          <p>
            3D-FABsはオリックス・レンテック株式会社が
            <br />
            オリックスグループのセキュリティポリシーに準じて、企画・運営しています
          </p>
          <p>
            お客さま登録の二重認証や通信の暗号化、定期的なシステムの脆弱性診断などの
            <br />
            セキュリティ対策を実装し、お客さまの個人情報とアップロードデータの機密性を担保します
          </p>
          <p className="safeInfoHrefInner">
            <span>オリックスグループの</span>
            <a
              href='https://www.orix.co.jp/grp/information_security_policy.html'
              target='_blank'
              rel='noopener noreferrer'
            >
              情報セキュリティポリシーはこちら＞
            </a>
            <a
              href='https://www.orixrentec.jp/privacypolicy.html'
              target='_blank'
              rel='noopener noreferrer'
            >
              プライバシー・ポリシーはこちら＞
            </a>
            <br />
            <a
              href='/#'
              onClick={(event) => {
                event.preventDefault();
                setIsModalPolicyOpen(true);
            }}>
              3D-FABsのサイトポリシーはこちら＞
            </a>
            <br />
            <a
              href='/#'
              onClick={(event) => {
                event.preventDefault();
                setIsModalSafeHrefOpen(true);
            }}>
              機密保持契約が必要なお客さまはこちら＞
            </a>
          </p>
        </div>
      </div>
      <div ref={aboutRef2}>
        {/* 3D-FABsについて 3つのできる*/}
         <div id="threeFeatures">
           <div className='threeFeaturesInner'>
             <div className='about3dfabsContents1Block'>
               <h1>「3D-FABsで、できること」</h1>
               <h2><span className='textBlue'>最適な材料と3Dプリンターの選択</span></h2>
               <p>
                 プロユースの3Dプリンター6機種、
                 <br />
                 10材料を比較して、用途に応じた最適な選択をサポート
               </p>
             </div>

             <div className='about3dfabsContents2Block'>
               <div
                 className='threeLeftInner'
                 onMouseEnter={() => setIsThreeDownloadActive(true)}
                 onMouseLeave={() => setIsThreeDownloadActive(false)}
                 onClick={() => setIsThreeDownloadActive(true)}
               >
                 <img src={ThreeDescription1} alt='description' />
                 <div className={`threeLeftDownload ${isThreeDownloadActive ? 'threeLeftDownloadActive' : ''}`}>
                   <p>
                     「材料のご紹介」を
                     <br />
                     ダウンロードしますか？
                   </p>
                   <div>
                     <button onClick={onThreeLeftDownload}>□ Yes</button>
                     <button onClick={(e) => {
                       e.stopPropagation();
                       setIsThreeDownloadActive(false);
                     }}>□ No
                     </button>
                   </div>
                 </div>
               </div>
               <img className='threeRightImg' src={ThreeDescription2} alt='description' />
             </div>

             <div className='about3dfabsContents3Block'>
               <h2><span className='textBlue'>3Dプリントの試算</span></h2>
               <p className='about3dfabsContents3Para'>
                 3Dプリンターの長所は、同時に複数個造形できること
                 <br />
                 その場合の金額と納期の変化をシミュレーションできます
               </p>
               <p className='about3dfabsContents3ParaSub'>
                 3D-CADデータはアップロードから２週間後に自動的に消去されます
               </p>

               <div className='about3dfabsContents3Feature1Block'>
                 <div className='about3dfabsContents3Feature1BlockLeft'>
                   <h3 className='about3dfabsContents3FeatureBlockH3'>造形姿勢とサポート材の変化を可視化</h3>
                   <p className='about3dfabsContents3FeatureBlockPara'>調整前</p>
                   <div className='about3dfabsContents3FeatureBlockImagePrice'>
                     <img src={Fabsfeatureleft1} alt='' />
                     <dl className='about3dfabsContents3FeatureBlockDlist'>
                       <dt>単価</dt>
                       <dd>￥288,500</dd>
                     </dl>
                   </div>
                 </div>

                 <div className='about3dfabsContents3Feature1Tab'>
                   {/* <!--tab1--> */}
                   <label
                     className={activeTab1 === 0 ? 'about3dfabsContents3Feature1TabBtn1 active' : 'about3dfabsContents3Feature1TabBtn1'}
                     onMouseEnter={() => clearPriceInterval(price1TimerRef.current)}
                     onMouseLeave={startPrice1Timer}
                   >
                     <input type='radio' name='about3dfabsContents3Feature1Tab' value='Tab1' checked={activeTab1 === 0}
                            onChange={() => handleTabChange1(0)} />
                     角度を調整
                   </label>
                   <div
                     className={activeTab1 === 0 ? 'about3dfabsContents3Feature1BlockRight active' : 'about3dfabsContents3Feature1BlockRight'}>
                     <h3 className='about3dfabsContents3FeatureBlockH3'>造形姿勢を変えると・・</h3>
                     <div className='about3dfabsContents3FeatureBlockImagePrice'>
                       <img src={Fabsfeatureright1} alt='' />
                       <dl className='about3dfabsContents3FeatureBlockDlist'>
                         <dt>単価</dt>
                         <dd>￥380,200</dd>
                       </dl>
                     </div>
                   </div>

                   {/* <!--tab2--> */}
                   <label
                     className={activeTab1 === 1 ? 'about3dfabsContents3Feature1TabBtn2 active' : 'about3dfabsContents3Feature1TabBtn2'}
                     onMouseEnter={() => clearPriceInterval(price1TimerRef.current)}
                     onMouseLeave={startPrice1Timer}
                   >
                     <input type='radio' name='about3dfabsContents3Feature1Tab' value='Tab2' checked={activeTab1 === 1}
                            onChange={() => handleTabChange1(1)} />
                     高さを調整
                   </label>
                   <div
                     className={activeTab1 === 1 ? 'about3dfabsContents3Feature1BlockRight active' : 'about3dfabsContents3Feature1BlockRight'}>
                     <h3 className='about3dfabsContents3FeatureBlockH3'>造形姿勢を変えると・・</h3>
                     <div className='about3dfabsContents3FeatureBlockImagePrice'>
                       <img src={Fabsfeatureright2} alt='' />
                       <dl className='about3dfabsContents3FeatureBlockDlist'>
                         <dt>単価</dt>
                         <dd>￥280,500</dd>
                       </dl>
                     </div>
                   </div>

                   {/* <!--tab3--> */}
                   <label
                     className={activeTab1 === 2 ? 'about3dfabsContents3Feature1TabBtn3 active' : 'about3dfabsContents3Feature1TabBtn3'}
                     onMouseEnter={() => clearPriceInterval(price1TimerRef.current)}
                     onMouseLeave={startPrice1Timer}
                   >
                     <input type='radio' name='about3dfabsContents3Feature1Tab' value='Tab3' checked={activeTab1 === 2}
                            onChange={() => handleTabChange1(2)} />
                     サポート材を調整
                   </label>
                   <div
                     className={activeTab1 === 2 ? 'about3dfabsContents3Feature1BlockRight active' : 'about3dfabsContents3Feature1BlockRight'}>
                     <h3 className='about3dfabsContents3FeatureBlockH3'>造形姿勢を変えると・・</h3>
                     <div className='about3dfabsContents3FeatureBlockImagePrice'>
                       <img src={Fabsfeatureright3} alt='' />
                       <dl className='about3dfabsContents3FeatureBlockDlist'>
                         <dt>単価</dt>
                         <dd>￥279,200</dd>
                       </dl>
                     </div>
                   </div>
                 </div>
               </div>

               <div className='about3dfabsContents3Feature2Block'>
                 <div className='about3dfabsContents3Feature2BlockLeft'>
                   <h3 className='about3dfabsContents3FeatureBlockH3'>単価の試算</h3>
                   <p className='about3dfabsContents3FeatureBlockPara'>調整前</p>
                   <div className='about3dfabsContents3FeatureBlockImagePrice'>
                     <img src={Fabsfeatureleft2} alt='' />
                     <dl className='about3dfabsContents3FeatureBlockDlist'>
                       <dt>部品単価</dt>
                       <dd>￥288,500</dd>
                       <dt>1バッチ価格</dt>
                       <dd>￥288,500</dd>
                     </dl>
                   </div>
                 </div>

                 <div className='about3dfabsContents3Feature2Tab'>
                   {/* <!--tab1--> */}
                   <label
                     className={activeTab2 === 0 ? 'about3dfabsContents3Feature2TabBtn1 active' : 'about3dfabsContents3Feature2TabBtn1'}
                     onMouseEnter={() => clearPriceInterval(price2TimerRef.current)}
                     onMouseLeave={startPrice2Timer}
                   >
                     <input type='radio' name='about3dfabsContents3Feature2Tab' checked={activeTab2 === 0}
                            onChange={() => handleTabChange2(0)} />
                     4個同時造形
                   </label>
                   <div
                     className={activeTab2 === 0 ? 'about3dfabsContents3Feature2BlockRight active' : 'about3dfabsContents3Feature2BlockRight'}>
                     <h3 className='about3dfabsContents3FeatureBlockH3'>
                       複数個造形時
                     </h3>
                     <div className='about3dfabsContents3FeatureBlockImagePrice'>
                       <img src={Fabsfeatureright4} alt='' />
                       <dl className='about3dfabsContents3FeatureBlockDlist'>
                         <dt>部品単価</dt>
                         <dd>￥111,550</dd>
                         <dt>1バッチ価格</dt>
                         <dd>￥446,200</dd>
                       </dl>
                     </div>
                   </div>

                   {/* <!--tab2--> */}
                   <label
                     className={activeTab2 === 1 ? 'about3dfabsContents3Feature2TabBtn2 active' : 'about3dfabsContents3Feature2TabBtn2'}
                     onMouseEnter={() => clearPriceInterval(price2TimerRef.current)}
                     onMouseLeave={startPrice2Timer}
                   >
                     <input type='radio' name='about3dfabsContents3Feature2Tab' checked={activeTab2 === 1}
                            onChange={() => handleTabChange2(1)} />
                     2種×各4個<br />
                     (計8個)同時造形
                   </label>
                   <div
                     className={activeTab2 === 1 ? 'about3dfabsContents3Feature2BlockRight active' : 'about3dfabsContents3Feature2BlockRight'}>
                     <h3 className='about3dfabsContents3FeatureBlockH3'>
                       {/*<span>複数種類×複数個造形の試算もできる！</span><br />*/}
                       複数個造形時
                     </h3>
                     <div className='about3dfabsContents3FeatureBlockImagePrice'>
                       <img src={Fabsfeatureright5} alt='' />
                       <dl className='about3dfabsContents3FeatureBlockDlist'>
                         <dt>部品単価</dt>
                         <dd>￥58,375</dd>
                         <dt>1バッチ価格</dt>
                         <dd>￥467,000</dd>
                       </dl>
                     </div>
                   </div>
                 </div>
               </div>

               <div className='about3dfabsContents3Additional'>
                 <p>さらに…</p>
                 <div className='about3dfabsContents3AdditionalListCont'>
                   <ul className='about3dfabsContents3AdditionalList'>
                     <li><span className='textBlue'>後加工（オプション）も含めた試算が可能(※2)</span></li>
                     <li><span className='textBlue'>試算結果はPDFで出力、検討資料として利用可能</span></li>
                     <li><span className='textBlue'>試算結果をアーカイブとして保存、造形ノウハウの蓄積が可能</span></li>
                   </ul>
                 </div>
               </div>
             </div>

             <div className='about3dfabsContents4Block'>
               <h2><span className='textBlue'>無料で、何回でも、納得いくまで</span></h2>
               <div className='about3dfabsContents4Inner'>
                 <div className='about3dfabsContents4InnerText'>
                   <ul className='about3dfabsContents4List'>
                     <li>短時間で概算を表示し、エンジニアの検討時間を削減</li>
                     <li>プリンターの機種ごとに異なる限界特性や注意点を可視化</li>
                     <li>不明点はチャットで問い合わせ(※3)</li>
                     <li>3D-FABsのご利用は無料</li>
                   </ul>
                 </div>
                 <div className='about3dfabsContents4Image'>
                   <img src={Fabscontents2} alt='' />
                 </div>
                 <div className='about3dfabsContents5Image'>
                   <img src={Fabscontents3} alt='' />
                 </div>
               </div>
             </div>
           </div>

           <div className="modelSliderContainer">
             <div className="modelSliderBlock">
               <div className='modelLoopSlide'>
                 <ul ref={slideUlRef as LegacyRef<HTMLUListElement>}>
                   {[1, 2].map((v) => (
                     <React.Fragment key={v}>
                       {imageList.map(v => (
                         <li
                           key={v.id}
                           className={v.isExample ? 'modelExampleItem' : ''}
                           onMouseEnter={() => exampleItemAction(v, true)}
                           onMouseLeave={() => exampleItemAction(v, false)}
                         >
                           {v.isExample ? (
                             <div
                               className={`modelExampleItemInner ${exampleHoverId === v.id ? 'modelExampleItemInnerActive' : ''}`}>
                               <img src={v.img} alt='' />
                               <div className="modelExampleItemCC">
                                 <img src={CCImage1} alt='' />
                                 <img src={CCImage2} alt='' />
                                 <img src={CCImage3} alt='' />
                                 <span>ダウンロード可能</span>
                               </div>
                               <div
                                 className={`modelExampleDownload ${exampleHoverId === v.id ? 'modelExampleDownloadActive' : ''}`}>
                                 <p>STLファイルをダウンロードしますか？</p>
                                 <div>
                                   <button onClick={() => onExampleItemDownload(v)}>□ Yes</button>
                                   <button onClick={() => exampleItemAction(v, false)}>□ No</button>
                                 </div>
                               </div>
                             </div>
                             ) : <img src={v.img} alt='' />
                           }
                         </li>
                       ))}
                     </React.Fragment>
                   ))}
                   </ul>
               </div>
               <div className="modelSliderDescription">
                 <div>
                   <div className="firstLine">
                     <img src={CCImage1} alt='' />
                     <img src={CCImage2} alt='' />
                     <img src={CCImage3} alt='' />
                     <span>
                       マークのあるサンプルはダウンロード可能です(※4)
                     </span>
                   </div>
                   <span>
                     3D-FABsのシミュレーションでご利用ください
                   </span>
                 </div>
               </div>
             </div>
           </div>
         </div>
        <div className='conversionButton' style={{ margin: '16px 0' }}>
          <a
            className='btnOrangeSignup loginButton'
            href='/#'
            onClick={(event) => {
              event.preventDefault();
              navigate('/Login');
            }}
          >
            <span style={{ lineHeight: '20px' }}>
              試算・造形シミュレーションが使える
              <br />
              無料会員登録はこちら
            </span>
            <span>{'>'}</span>
          </a>
        </div>
      </div>

      <ModalSitePolicy show={isModalPolicyOpen} setIsModalOpen={setIsModalPolicyOpen} topPage />
      <ModalSafeHref show={isModalSafeHrefOpen} setIsModalOpen={setIsModalSafeHrefOpen} />
    </div>
  );
});

export default About3DFABs;
