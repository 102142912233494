import * as React from 'react';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useDropzone } from 'react-dropzone';
import uuid from 'react-uuid';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/css/trialBalanceSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import QEXP from '../../common/QuestionExp.json';
import QuestionPopper from '../popper/QuestionPopper';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { useNavigate } from 'react-router-dom';

interface Props {
  readOnly?: boolean;
  // 材料情報
  selectModelCondition: MyIF.selectModelingConditionType;
  // 現在選択中のオプション情報
  selectOptionInfo: MyIF.optionInfo;
  // 現在選択中のオプション情報（セット関数）
  setSelectOptionInfo: React.Dispatch<React.SetStateAction<MyIF.optionInfo>>;
}

const OptionArea: React.FC<Props> = React.memo(
  ({ readOnly, selectModelCondition, selectOptionInfo, setSelectOptionInfo }) => {
    const { setAlertShow, setMessageList } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();

    // ファイルドラッグ＆ドロップ
    const onDrop = React.useCallback(
      (files: File[]) => {
        if (files.length > 0) {
          if (files[0].size > 10485760) {
            setMessageList([
              'ファイルサイズオーバーです。',
              'ファイルサイズは10MB以下でアップロードしてください。',
            ]);
            setAlertShow(true);
          } else {
            setSelectOptionInfo({
              ...selectOptionInfo,
              dirFile: files[0],
              dirFileName: files[0].name,
            });
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectOptionInfo]
    );
    const { fileRejections, getRootProps, getInputProps } = useDropzone({
      onDrop,
      maxFiles: 1,
      multiple: false,
      accept: {
        '': ['.jpeg', '.jpg'],
        'application/pdf': [],
        'image/png': [],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.ms-powerpoint': [],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
      },
      useFsAccessApi: false,
    });

    /**
     * 複数選択できる項目のコンポーネント
     */
    const OptionMultiItem: React.FC<{
      labelName: string;
      select: {
        value: string;
        num: string | undefined;
        index: string | undefined;
      };
      items?: {
        value: string;
        calcFlg: boolean;
        price: number;
      }[];
      maxNum: number;
      count: number;
      perType: MyIF.optionPerType | undefined;
    }> = ({ labelName, select, items, maxNum, count, perType }) => {
      return (
        <div className='modelNoSpecEditFormInputAddRowInner' style={{ height: '40px' }}>
          <select
            value={select.value}
            onChange={(event) => {
              optionSelectChangeHandle(labelName, select.index, {
                value: event.target.value,
                calcFlg: event.target[event.target.selectedIndex].dataset.value,
              });
            }}
            disabled={readOnly}
          >
            <option value={''}>なし（＋0円）</option>
            {items?.map((item, index) => {
              return (
                <option key={index} value={item.value} data-value={item.calcFlg}>
                  {item.value}
                  {item.calcFlg
                    ? '（＋' + item.price.toLocaleString() + '円）'
                    : '（※自動見積り不可）'}
                </option>
              );
            })}
          </select>
          {select.value !== '' && (
            <div className='modelNoSpecEditFormInputAddRowAmountBlock'>
              <select
                value={select.num}
                onChange={(event) => {
                  optionSelectChangeHandle(labelName, select.index, undefined, {
                    value: event.target.value,
                  });
                }}
                disabled={readOnly}
              >
                {perType && perType === 'PER10'
                  ? [...Array(maxNum / 10)].map((_, jj) => {
                      return (
                        <option key={jj} value={jj + 1}>
                          ～{(jj + 1) * 10}
                        </option>
                      );
                    })
                  : [...Array(maxNum)].map((_, jj) => {
                      return (
                        <option key={jj} value={jj + 1}>
                          {jj + 1}
                        </option>
                      );
                    })}
              </select>
              個
            </div>
          )}

          {readOnly !== true && count > 1 && (
            <button
              className='modelNoSpecEditFormInputRowDeleteLink button-style'
              onClick={() => {
                const selectListCopy = selectOptionInfo.optionSelectList.slice();
                console.log(selectListCopy);
                selectListCopy.map((optionSelect) => {
                  if (optionSelect.labelName === labelName) {
                    optionSelect.select = optionSelect.select.filter((x) => {
                      return !(x.index === select.index);
                    });
                  }
                  return optionSelect;
                });
                setSelectOptionInfo({ ...selectOptionInfo, optionSelectList: selectListCopy });
              }}
            ></button>
          )}
        </div>
      );
    };

    /**
     * オプション項目変更イベント
     * @param labelName
     * @param optionIndex
     * @param changeValue
     * @param changeNum
     */
    const optionSelectChangeHandle = (
      labelName: string,
      optionIndex: string | undefined,
      changeValue?: {
        value: string;
        calcFlg: string | undefined;
      },
      changeNum?: {
        value: string;
      }
    ) => {
      const selectListCopy = selectOptionInfo.optionSelectList.slice();
      console.log(labelName);
      console.log(optionIndex);
      console.log(changeValue);
      console.log(selectListCopy);
      selectListCopy.map((optionSelect) => {
        if (optionSelect.labelName === labelName) {
          optionSelect.select.map((select) => {
            if (select.index === optionIndex) {
              if (changeValue) {
                select.value = changeValue?.value;
                select.calcFlg = changeValue?.calcFlg ? true : false;
              }
              if (changeNum) {
                select.num = changeNum.value;
              }
            }
            return select;
          });
        }
        return optionSelect;
      });
      setSelectOptionInfo({ ...selectOptionInfo, optionSelectList: selectListCopy });
    };

    const fileRejectionItems = fileRejections.map(({ file, errors }, index) => (
      <div key={index}>
        {errors.map((_, index) => (
          <p
            key={index}
            className='modelNoSpecEditFormFileUploadedLinkDisalbed'
            style={{ color: 'red' }}
          >
            {file.name}は許可された拡張子ではありません
          </p>
        ))}
      </div>
    ));

    return (
      <section className='modelNoSpecEditFormAccordionContent'>
        <input id='toggle6' type='checkbox' className='modelNoSpecEditFormToggle'></input>
        <label className='modelNoSpecEditFormToggleLabel' htmlFor='toggle6'>
          オプション設定（任意）
        </label>

        <div className='modelNoSpecEditFormToggleContentBlock'>
          <div className='modelNoSpecEditFormToggleContent'>
            <div className='modelNoSpecEditFormOptionSettingBlock'>
              {/* オプション項目エリア */}
              {selectOptionInfo.optionSelectList.map((option, ii) => {
                return (
                  <div key={ii} className='modelNoSpecEditFormInputBlock'>
                    <label htmlFor={'form6-' + ii}>
                      {option.labelName}
                      {option.exp && <QuestionPopper value={option.exp} />}
                    </label>
                    {option.maxNum ? (
                      <div className='modelNoSpecEditFormInputAddRowBlock'>
                        <div className='modelNoSpecEditFormInputAddRow'>
                          {[...Array(option.select.length)].map((_, jj) => {
                            return (
                              <OptionMultiItem
                                key={jj}
                                labelName={option.labelName}
                                select={{
                                  value: option.select[jj].value,
                                  num: option.select[jj].num,
                                  index: option.select[jj].index,
                                }}
                                items={option.items}
                                maxNum={option.maxNum ? option.maxNum : 0}
                                count={option.select.length}
                                perType={option.perType}
                              ></OptionMultiItem>
                            );
                          })}
                          {readOnly !== true && (
                            <button
                              className='modelNoSpecEditFormInputAddRowLink button-style'
                              onClick={() => {
                                const selectListCopy = selectOptionInfo.optionSelectList.slice();
                                selectListCopy.map((optionSelect) => {
                                  if (optionSelect.labelName === option.labelName) {
                                    optionSelect.select.push({
                                      value: '',
                                      num: '1',
                                      index: uuid(),
                                    });
                                  }
                                  return optionSelect;
                                });
                                setSelectOptionInfo({
                                  ...selectOptionInfo,
                                  optionSelectList: selectListCopy,
                                });
                              }}
                            >
                              項目追加
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <select
                        id={'form6-' + ii}
                        value={option.select[0].value}
                        onChange={(event) => {
                          optionSelectChangeHandle(option.labelName, option.select[0].index, {
                            value: event.target.value,
                            calcFlg: event.target[event.target.selectedIndex].dataset.value,
                          });
                        }}
                        disabled={readOnly}
                      >
                        {option.items?.map((item, index) => {
                          return (
                            <option key={index} value={item.value} data-value={item.calcFlg}>
                              {item.value}
                              {item.calcFlg
                                ? '（＋' + item.price.toLocaleString() + '円）'
                                : '（※自動見積り不可）'}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                );
              })}

              {/* 指示書フラグエリア */}
              {readOnly !== true && (
                <div className='modelNoSpecEditFormOptionSettingAddRequest'>
                  <p className='modelNoSpecEditFormOptionSettingAddRequestPara'>
                    その他追加要望がある場合は「ON」を選択してコメントを入力もしくは指示書をアップロードして下さい。
                    <br />
                    後ほど担当者よりお見積りの回答を致します。
                  </p>

                  <div className='toggleSwitchBlock'>
                    <div className='toggleSwitchInner'>
                      <input
                        type='checkbox'
                        className='toggleSwitchCheckbox'
                        checked={selectOptionInfo.dirFlg}
                        onChange={() => {
                          setSelectOptionInfo({
                            ...selectOptionInfo,
                            dirFlg: !selectOptionInfo.dirFlg,
                            dirFile: undefined,
                            dirFileName: undefined,
                          });
                        }}
                      ></input>
                      <div className='toggleSwitchKnobs'>
                        <span></span>
                      </div>
                      <div className='toggleSwitchLayer'></div>
                    </div>
                  </div>
                </div>
              )}

              {/* 指示書コメントエリア */}
              <div className='modelNoSpecEditFormInputBlock'>
                <label htmlFor='form6-9'>
                  コメント欄
                  <QuestionPopper value={QEXP.ModelNumberEdit.COMMENT} />
                </label>
                <textarea
                  id='form6-9'
                  name='name'
                  className=''
                  placeholder='記入してください'
                  disabled={readOnly || selectOptionInfo.dirFlg}
                  defaultValue={selectOptionInfo.dirComment}
                  onChange={(event) => {
                    selectOptionInfo.dirComment = event.target.value;
                  }}
                ></textarea>
              </div>

              {/* 指示書アップロードエリア */}
              <div className='modelNoSpecEditFormInputBlock'>
                <label htmlFor='form6-10'>
                  指示書アップロード
                  <QuestionPopper value={QEXP.ModelNumberEdit.INSTRUCTION_UPLOAD} />
                </label>
                <div className='modelNoSpecEditFormOptionSettingFileUploadInner'>
                  {readOnly !== true && (
                    <div
                      className={`modelNoSpecEditFormOptionSettingFileUploadBlock ${
                        selectOptionInfo.dirFlg
                          ? 'modelNoSpecEditFormOptionSettingFileUploadBlockDisalbed'
                          : 'modelNoSpecEditFormOptionSettingFileUploadBlockActive'
                      }`}
                      id='form6-10'
                      {...(selectOptionInfo.dirFlg ? null : { ...getRootProps() })}
                    >
                      <input {...getInputProps()} />
                      <div className='modelNoSpecEditFormOptionSettingFileUploadPara'>
                        <p>ファイルをドラッグ＆ドロップしてください</p>
                        <p className='modelNoSpecEditFormFileUploadAreaPara2'>
                          ファイルを選択する場合は
                          <button className='modelNoSpecEditFormFileUploadAreaLink button-style-trans'>
                            クリック
                          </button>
                          してください
                        </p>
                      </div>
                    </div>
                  )}

                  {fileRejectionItems}
                  {readOnly ? (
                    <div className='modelNoSpecEditFormFileUploadedName'>
                      <div
                        className='modelNoSpecEditFormFileUploadedLinkDisalbed'
                        style={{ marginTop: '7px' }}
                      >
                        {selectOptionInfo.dirFileName}
                      </div>
                    </div>
                  ) : (
                    <div className='modelNoSpecEditFormFileUploadedName'>
                      {selectOptionInfo.dirFileName && (
                        <button
                          className='modelNoSpecEditFormFileUploadedLink button-style'
                          onClick={() => {
                            setSelectOptionInfo({
                              ...selectOptionInfo,
                              dirFile: undefined,
                              dirFileName: undefined,
                            });
                          }}
                        >
                          {selectOptionInfo.dirFileName}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default OptionArea;
