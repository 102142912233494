export enum FilterItems {
  ALL = 'all',
  FINISH = 'finish',
  REQUEST = 'request',
  CONTACT = 'contact',
  WAIT = 'wait',
  TMPSAVE = 'tmpsave',
  CONTACT_WAIT_PRICE = 'contact_wait_price',
  HUMAN_DEAL = 'human_deal',
}

export const FILTER_ITEMS_STRINGS = {
  [FilterItems.ALL]: 'すべて',
  [FilterItems.FINISH]: '正式見積完了',
  [FilterItems.REQUEST]: '正式見積待ち',
  [FilterItems.CONTACT]: '担当者連絡待ち',
  [FilterItems.WAIT]: '試算完了',
  [FilterItems.TMPSAVE]: '一時保存中',
  [FilterItems.CONTACT_WAIT_PRICE]: '価格設定担当者確認',
  [FilterItems.HUMAN_DEAL]: '3D-FABs外対応',
};

export function GetPdfType(status: string) {
  let rtnPdfType: 'Quote' | 'TrialCalc' | 'disabled';
  switch (status) {
    case FILTER_ITEMS_STRINGS[FilterItems.FINISH]:
      rtnPdfType = 'Quote';
      break;
    case FILTER_ITEMS_STRINGS[FilterItems.CONTACT]:
    case FILTER_ITEMS_STRINGS[FilterItems.TMPSAVE]:
    case FILTER_ITEMS_STRINGS[FilterItems.CONTACT_WAIT_PRICE]:
    case FILTER_ITEMS_STRINGS[FilterItems.HUMAN_DEAL]:
      rtnPdfType = 'disabled';
      break;
    default:
      rtnPdfType = 'TrialCalc';
      break;
  }
  return rtnPdfType;
}
