import { createContext, useState, useContext, Dispatch, SetStateAction } from 'react';

export const AlertContext = createContext(
  {} as {
    alertShow: boolean;
    setAlertShow: Dispatch<SetStateAction<boolean>>;
    messageList: string[];
    setMessageList: Dispatch<SetStateAction<string[]>>;
  }
);

export const AlertProvider = (props: any) => {
  const [alertShow, setAlertShow] = useState(false);
  const [messageList, setMessageList] = useState<string[]>([]);

  return (
    <AlertContext.Provider value={{ alertShow, setAlertShow, messageList, setMessageList }}>
      {props.children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
