import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';

interface Props {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModelingJudge: number;
  priceInfo: MyIF.priceInfo;
}

const PriceAreaRef: React.FC<Props> = React.memo(({ setIsModalOpen, isModelingJudge, priceInfo }) => {
  return (
    <section className='modelNoSpecEditFormAccordionContent'>
      <input id='toggle7' type='checkbox' className='modelNoSpecEditFormToggle'></input>
      <label className='modelNoSpecEditFormToggleLabel' htmlFor='toggle7'>
        単価
      </label>

      <div className='modelNoSpecEditFormToggleContentBlock'>
        <div className='modelNoSpecEditFormToggleContent'>
          <div
            className={`modelNoSpecEditFormUnitPriceBlock ${
              isModelingJudge === 3 ? 'modelNoSpecEditFormUnitPriceBlockDisabled' : ''
            }`}
          >
            <div className='modelNoSpecEditFormUnitPriceTitle'>標準納期・単価</div>
            <div className='modelNoSpecEditFormUnitPriceDetail'>
              <p className='modelNoSpecEditFormUnitPriceDeliveryPara'>
                納期：{priceInfo.normal.date ? priceInfo.normal.date : '- '}営業日
              </p>
              <p className='modelNoSpecEditFormUnitPricePara'>
                ￥{priceInfo.normal.price ? priceInfo.normal.price.toLocaleString() : '-'}
              </p>
            </div>
          </div>
          <div
            className={`modelNoSpecEditFormUnitPriceBlock ${
              isModelingJudge === 3 ? 'modelNoSpecEditFormUnitPriceBlockDisabled' : ''
            }`}
          >
            <div className='modelNoSpecEditFormUnitPriceTitle'>特急納期・単価</div>
            <div className='modelNoSpecEditFormUnitPriceDetail'>
              <p className='modelNoSpecEditFormUnitPriceDeliveryPara'>
                納期：{priceInfo.express.date ? priceInfo.express.date : '- '}営業日
              </p>
              <p className='modelNoSpecEditFormUnitPricePara'>
                ￥{priceInfo.express.price ? priceInfo.express.price.toLocaleString() : '-'}
              </p>
            </div>
          </div>
          <button
            type='button'
            name='button'
            className='btnWhiteBaseBlueCompare'
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            材料比較
          </button>
        </div>
      </div>
    </section>
  );
});

export default PriceAreaRef;
