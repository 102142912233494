import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/top.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, Swiper as RealSwiper } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Arrowleft from '../../assets/image/top/top_icon_arrowleft_gray.svg';
import Arrowright from '../../assets/image/top/top_icon_arrowright_blue.svg';

RealSwiper.use([Autoplay]);

interface Props {
  newsRef: React.MutableRefObject<HTMLDivElement>;
  releaseInfoList: MyIF.releaseInfo[];
}

const Information: React.FC<Props> = React.memo(({ newsRef, releaseInfoList }) => {
  const SwiperComponent = () => {
    return (
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        loop={true}
      >
        {slides}
      </Swiper>
    );
  };

  const slides = releaseInfoList.map(function (releaseInfo, index) {
    if (index < 5) {
      return (
        <SwiperSlide key={index}>
          <button
            className='headerInfoSwiperSlide button-style-trans'
            onClick={() => {
              newsRef.current.scrollIntoView();
            }}
          >
            <div className='headerInfoArticleBlock'>
              <div className='date'>{releaseInfo.date}</div>
              <div
                className={`${
                  releaseInfo.type === 'インフォメーション'
                    ? 'headerInfoCategoryLabelInformation'
                    : 'headerInfoCategoryLabel'
                }`}
              >
                {releaseInfo.type}
              </div>
            </div>
            <div className='headerInfoArticleTitle'>{releaseInfo.title}</div>
          </button>
        </SwiperSlide>
      );
    } else {
      return null;
    }
  });

  return (
    <div className='headerInformationBlock'>
      <div className='headerInformationContents'>
        <h3 className='titleText'>新着情報</h3>
        {/* <a href="#" className="prev"><img src={Arrowleft} alt=""/></a>
        <a href="#" className="next"><img src={Arrowright} alt=""/></a> */}
        <SwiperComponent />
      </div>
    </div>
  );
});

export default Information;
