import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientRegistrationForm.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import QuestionPopper from '../popper/QuestionPopper';
import { GetZipCodeAddress } from '../../common/GetCodeAddress';
import { useAlert } from '../../provider/alert/AlertProvider';

interface interQEXP {
  CORP_NAME: string;
  CORP_NAME_KANA: string;
  DEPARTMENT: string;
  NAME: string;
  NAME_KANA: string;
  PHONE_NUMBER: string;
  MAIL_ADDRESS: string;
  POSTCODE: string;
  ADDRESS1: string;
}

interface Props {
  userInfo: MyIF.userInfo;
  setUserInfo: React.Dispatch<React.SetStateAction<MyIF.userInfo>>;
  QEXP: interQEXP;
}

const CommonContactEditTable: React.FC<Props> = React.memo(({ userInfo, setUserInfo, QEXP }) => {
  const { setAlertShow, setMessageList } = useAlert();

  const getAddress = async () => {
    if (!userInfo.postCode1 || !userInfo.postCode2) {
      setMessageList([
        '郵便番号を入力してから住所取得を行ってください。',
      ]);
      setAlertShow(true);
      return;
    }
    const address = await GetZipCodeAddress(userInfo.postCode1, userInfo.postCode2);
    if (address !== null) {
      setUserInfo({
        ...userInfo,
        address1: address.address1 + address.address2 + address.address3,
      });
    } else {
      setMessageList([
        '郵便番号検索が上手くできませんでした。お手数ですが住所を入力してください。',
      ]);
      setAlertShow(true);
    }
  };
  return (
    <div className='registrationFormBlock'>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='name'>
            氏名 <span className='attentionRedText'>※</span>
          </label>
          <QuestionPopper value={QEXP.NAME} />
        </div>
        <div className='registrationFormContentInput'>
          <input
            className='clientRegistrationFormInput50'
            type='text'
            name='lname'
            id='lname'
            value={userInfo.lastName}
            onChange={(event) => {
              setUserInfo({ ...userInfo, lastName: event.target.value });
            }}
          />
          <input
            className='clientRegistrationFormInput50'
            type='text'
            name='lname'
            id='fname'
            value={userInfo.firstName}
            onChange={(event) => {
              setUserInfo({ ...userInfo, firstName: event.target.value });
            }}
          />
        </div>
      </div>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='name02'>
            氏名（フリガナ） <span className='attentionRedText'>※</span>
          </label>
          <QuestionPopper value={QEXP.NAME_KANA} />
        </div>
        <div className='registrationFormContentInput'>
          <input
            className='clientRegistrationFormInput50'
            type='text'
            name='lname02'
            id='lname02'
            value={userInfo.lastNameKana}
            onChange={(event) => {
              setUserInfo({ ...userInfo, lastNameKana: event.target.value });
            }}
          />
          <input
            className='clientRegistrationFormInput50'
            type='text'
            name='lname02'
            id='fname02'
            value={userInfo.firstNameKana}
            onChange={(event) => {
              setUserInfo({ ...userInfo, firstNameKana: event.target.value });
            }}
          />
        </div>
      </div>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='mail'>
            メールアドレス <span className='attentionRedText'>※</span>
          </label>
          <QuestionPopper value={QEXP.MAIL_ADDRESS} />
        </div>
        <div className='registrationFormContentInput'>
          <input
            className='clientRegistrationFormInput100'
            type='text'
            name='mail'
            id='mail'
            value={userInfo.mailAddress}
            onChange={(event) => {
              setUserInfo({ ...userInfo, mailAddress: event.target.value });
            }}
          />
        </div>
      </div>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='tel'>
            電話番号 <span className='attentionRedText'>※</span>
          </label>
          <QuestionPopper value={QEXP.PHONE_NUMBER} />
        </div>
        <div className='registrationFormContentInput'>
          <input
            className='clientRegistrationFormInput100'
            type='text'
            name='tel'
            id='tel'
            value={userInfo.phoneNumber}
            onChange={(event) => {
              setUserInfo({ ...userInfo, phoneNumber: event.target.value });
            }}
          />
        </div>
      </div>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='corporate_name'>
            法人名 <span className='attentionRedText'>※</span>
          </label>
          <QuestionPopper value={QEXP.CORP_NAME} />
        </div>
        <div className='registrationFormContentInput'>
          <input
            className='clientRegistrationFormInput100'
            type='text'
            name='corporate_name'
            id='corporate_name'
            value={userInfo.corpoName}
            onChange={(event) => {
              setUserInfo({ ...userInfo, corpoName: event.target.value });
            }}
          />
        </div>
      </div>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='corporate_name02'>
            法人名（フリガナ） <span className='attentionRedText'>※</span>
          </label>
          <QuestionPopper value={QEXP.CORP_NAME_KANA} />
        </div>
        <div className='registrationFormContentInput'>
          <input
            className='clientRegistrationFormInput100'
            type='text'
            name='corporate_name02'
            id='corporate_name02'
            value={userInfo.corpoNameKana}
            onChange={(event) => {
              setUserInfo({ ...userInfo, corpoNameKana: event.target.value });
            }}
          />
        </div>
      </div>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='department'>
            部署名
          </label>
          <QuestionPopper value={QEXP.DEPARTMENT} />
        </div>
        <div className='registrationFormContentInput'>
          <input
            className='clientRegistrationFormInput100'
            type='text'
            name='department'
            id='department'
            value={userInfo.departmentName}
            onChange={(event) => {
              setUserInfo({ ...userInfo, departmentName: event.target.value });
            }}
          />
        </div>
      </div>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='address'>
            郵便番号
          </label>
          <QuestionPopper value={QEXP.POSTCODE} />
        </div>
        <div className='registrationFormContentInput'>
          <p>
            〒
            <input
              type='text'
              name='post_code01'
              id='post_code01'
              value={userInfo.postCode1}
              onChange={(event) => {
                setUserInfo({ ...userInfo, postCode1: event.target.value });
              }}
              autoComplete='none'
            />
            -
            <input
              type='text'
              name='post_code02'
              id='post_code02'
              value={userInfo.postCode2}
              onChange={(event) => {
                setUserInfo({ ...userInfo, postCode2: event.target.value });
              }}
              autoComplete='none'
            />
            <button
              className='addressAcquisitionbtn'
              type='button'
              name='button'
              onClick={() => {
                getAddress();
              }}
            >
              住所取得
            </button>
          </p>
        </div>
      </div>
      <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='address'>
            住所（都道府県・市区町村・番地）
          </label>
          <QuestionPopper value={QEXP.ADDRESS1} />
        </div>
        <div className='registrationFormContentInput'>
          <p>
            <input
              className='clientRegistrationFormInput100'
              type='text'
              name='address01'
              id='address01'
              value={userInfo.address1}
              onChange={(event) => {
                setUserInfo({ ...userInfo, address1: event.target.value });
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
});

export default CommonContactEditTable;
