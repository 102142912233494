import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientRegistrationForm.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';

interface Props {
  registInfo: MyIF.userRegistInfo;
  profileFlg?: boolean;
}

const UserInfoTableRead: React.FC<Props> = React.memo(({ registInfo, profileFlg }) => {
  return (
    <table className='quotationRequestAddressConfirmation'>
      <tbody>
        <tr>
          <th>氏名</th>
          <td>
            {registInfo.userInfo.lastName}　{registInfo.userInfo.firstName}
          </td>
        </tr>
        <tr>
          <th>氏名（フリガナ）</th>
          <td>
            {registInfo.userInfo.lastNameKana}　{registInfo.userInfo.firstNameKana}
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>{registInfo.userInfo.mailAddress}</td>
        </tr>
        <tr>
          <th>電話番号</th>
          <td>{registInfo.userInfo.phoneNumber}</td>
        </tr>
        <tr>
          <th>法人名</th>
          <td>{registInfo.userInfo.corpoName}</td>
        </tr>
        <tr>
          <th>法人名（フリガナ）</th>
          <td>{registInfo.userInfo.corpoNameKana}</td>
        </tr>
        <tr>
          <th>部署名</th>
          <td>{registInfo.userInfo.departmentName}</td>
        </tr>
        <tr>
          <th>住所</th>
          <td>
            〒{registInfo.userInfo.postCode1}-{registInfo.userInfo.postCode2}
            <br />
            {registInfo.userInfo.address1}
          </td>
        </tr>
        {profileFlg !== true && (
          <tr>
            <th>サイトポリシー</th>
            {registInfo.policyFlg ? <td>承諾する</td> : <td>承諾しない</td>}
          </tr>
        )}
        {/* <tr>
          <th>メールマガジン</th>
          {registInfo.mailFlg ? <td>購読する</td> : <td>購読しない</td>}
        </tr> */}
      </tbody>
    </table>
  );
});

export default UserInfoTableRead;
