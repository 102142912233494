import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientRegistrationForm.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import { viewUserRegistType } from '../../pages/users/UserRegist';
import UserInfoTableRead from './UserInfoTableRead';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useProgress } from '../../provider/progress/ProgressProvider';
import { useNavigate } from 'react-router-dom';

// import { useAuth } from '../../provider/cognito/AuthContext';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewUserRegistType>>;
  registInfo: MyIF.userRegistInfo;
  setRegistInfo: React.Dispatch<React.SetStateAction<MyIF.userRegistInfo>>;
}

const UserRegistRationConfirm: React.FC<Props> = React.memo(
  ({ setViewSelect, registInfo, setRegistInfo }) => {
    const { setAlertShow } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();

    // const { signUp } = useAuth();

    /**
     * 登録
     */
    const PutUserRegist = async () => {
      setProgressShow(true);
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).PutUserRegist(registInfo);
      if (result) {
        navigate('/UserRegisterConfirm', {
          state: {
            mailAddress: registInfo.userInfo.mailAddress,
            userId: result ?? ''
          },
        });
      }

      // ★★★★★　SignUpお試し　★★★★★
      // try {
      //   await signUp({
      //     username: registInfo.userInfo.mailAddress,
      //     password: 'AiueoAiueo1234',
      //     attributes: { phone_number: '+810800' },
      //   });
      //   navigate('/UserRegistConfirm', {
      //     state: {
      //       mailAddress: registInfo.userInfo.mailAddress,
      //     },
      //   });
      // } catch (err) {
      //   console.error(err);
      // }
      setProgressShow(false);
    };

    return (
      <div className='mainContentsBlock noFlowBar'>
        <div className='clientRegistrationForm'>
          <div className='pageTitleBlock'>
            <div className='pageTitleInner'>
              <div className='pageTitleWithBtn'>
                <h1 className='pageTitle'>お客さま情報登録確認</h1>
              </div>
            </div>
          </div>
          <UserInfoTableRead registInfo={registInfo} />

          <div className='clientInformationConfirmationButtonArea'>
            <p className='clientInformationConfirmationButtonAreaText'>この内容でよろしいですか</p>
            <button
              className='btnBack'
              type='button'
              name='button'
              onClick={() => {
                setRegistInfo({ ...registInfo, password1: '', password2: '' });
                setViewSelect('Input');
              }}
            >
              戻る
            </button>
            <button className='btnOrangeCommit' type='button' name='button' onClick={PutUserRegist}>
              確定
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default UserRegistRationConfirm;
