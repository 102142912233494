import React from 'react';
import { useState, useEffect } from 'react';
import { SignUpParams } from '@aws-amplify/auth/lib-esm/types';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import { AuthContext } from './AuthContext';
import { APIController } from '../../common/API';

export type LoginOption = {
  username: string;
  password: string;
};

interface ICognitoAuthProviderParams {
  amplifyConfig: {
    aws_cognito_region: string;
    aws_user_pools_id: string;
    aws_user_pools_web_client_id: string;
    aws_cognito_identity_pool_id: string;
  };
  children: any;
}

export default function CognitoAuthProvider(props: ICognitoAuthProviderParams) {
  Amplify.configure(props.amplifyConfig);

  // セッションチェック
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // 実行中管理
  const [isLoading, setIsLoading] = useState(true);

  // エラー
  const [error, setError] = useState<any>(null);

  // Cognitoユーザー情報
  const [user, setUser] = useState<CognitoUser>();

  // 氏名
  const [userName, setUserName] = React.useState('');

  useEffect(() => {
    checkAuthenticated();
    currentAuthenticatedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async () => {
      if (user) {
        // ユーザー情報取得
        (await APIController.build({})).GetUserContactAddress().then((userInfo) => {
          console.log(userInfo);
          if (userInfo !== null) {
            setUserName(userInfo.userInfo.lastName + ' ' + userInfo.userInfo.firstName);
          }
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // ログイン中のユーザーセッション情報取得
  const checkAuthenticated = () => {
    setIsLoading(true);
    Auth.currentSession()
      .then((data) => {
        console.log('currentSession', data);
        if (data) {
          setIsAuthenticated(true);

          // グローバルサインアウトではIDトークンは無効化されないため、
          // ↑のセッションは生きた状態になる
          // currentUserInfoでアクセストークンを使ってセッションチェックする
          Auth.currentUserInfo().then((data) => {
            if (data === null || data.username === undefined) {
              // サインアウトしている
              setIsAuthenticated(false);
            }
          });
        }
      })
      .catch((err) => console.log('current session error', err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // ログイン中のユーザー情報取得
  const currentAuthenticatedUser = async (): Promise<void> => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (err) {
      console.log(err);
    }
  };

  // サインイン
  const signIn = async ({ username, password }: LoginOption): Promise<CognitoUser> => {
    console.log('SignInCall');
    setIsLoading(true);
    let result: CognitoUser = null!;
    try {
      result = await Auth.signIn(username, password);
      // 多重ログイン禁止のため、一度グローバルサインアウトする
      await Auth.signOut({ global: true });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      result = await Auth.signIn(username, password);
      setUser(result);
      setIsAuthenticated(true);
    } catch (error: any) {
      console.error('error signing in', error);
      switch (error.message) {
        case 'User is not confirmed.':
          // 検証コード再送
          await Auth.resendSignUp(username);
          break;
      }
      setError(error);
      setIsAuthenticated(false);
      setIsLoading(false);
      return Promise.reject(error);
    }
    setIsLoading(false);
    return result;
  };

  // サインアップ
  const signUp = async (param: SignUpParams): Promise<CognitoUser | undefined> => {
    setIsLoading(true);
    let result;
    try {
      result = await Auth.signUp(param);
      setUser(result.user);
    } catch (error) {
      console.log('error signing up', error);
      setError(error);
    }
    setIsLoading(false);
    return result?.user;
  };

  // 認証コード確認
  const confirmSignUp = async (username: string, code: string): Promise<boolean> => {
    try {
      await Auth.confirmSignUp(username, code);
      return Promise.resolve(true);
    } catch (error) {
      console.error('error confirming sign up', error);
      return Promise.reject(error);
    }
  };

  // サインアウト
  const signOut = () => {
    setIsLoading(true);
    Auth.signOut()
      .then(() => {
        setIsAuthenticated(false);
      })
      .catch((err) => console.log('error signing out: ', err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  // 初回パスワード変更
  const newPassword = async (password: string): Promise<void> => {
    setIsLoading(true);
    try {
      await Auth.completeNewPassword(user, password);
    } catch (error) {
      console.log('error password change', error);
      setError(error);
      setIsLoading(false);
      return Promise.reject(error);
    }
    setIsLoading(false);
  };

  // パスワードを忘れた際の確認コードの送信
  const forgotPassword = async (mailAddress: string): Promise<void> => {
    try {
      await Auth.forgotPassword(mailAddress);
    } catch (error) {
      console.log('error forgotPassword', error);
      setError(error);
      return Promise.reject(error);
    }
  };

  // パスワードの再設定
  const forgotPasswordConfirm = async (mailAddress: string, code: string, password: string) => {
    try {
      await Auth.forgotPasswordSubmit(mailAddress, code, password);
    } catch (error) {
      console.log('error forgotPasswordConfirm', error);
      setError(error);
      return Promise.reject(error);
    }
  };

  // パスワード変更
  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      return Promise.resolve(true);
    } catch (error) {
      console.error('error passwordChange', error);
      setError(error);
      return Promise.reject(error);
    }
  };

  // メールアドレス変更
  const changeMailAddress = async (newEmail: string) => {
    try {
      await Auth.updateUserAttributes(user, { email: newEmail });
      return Promise.resolve(true);
    } catch (error) {
      console.error('error changeMailAddress', error);
      setError(error);
      return Promise.reject(error);
    }
  };

  // メールアドレス検証
  const verifyMailAddress = async (code: string) => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
      return Promise.resolve(true);
    } catch (error) {
      console.error('error verifyMailAddress', error);
      setError(error);
      return Promise.reject(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        signIn,
        signUp,
        confirmSignUp,
        signOut,
        newPassword,
        forgotPassword,
        forgotPasswordConfirm,
        changePassword,
        changeMailAddress,
        verifyMailAddress,
        user,
        error,
        userName,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
