export class Calculate{
    public roundArrayOf(data: number[], digit: number): number[]{
        let roundedArray = new Array();
        data.forEach(value => {
            value *= Math.pow(10, digit);
            value = Math.round(value);
            value /= Math.pow(10, digit);
            roundedArray.push(value);
        });
        return roundedArray;
    }
}