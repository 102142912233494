import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import ModalRecommend from './ModalRecommend';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

interface Props {
  modelingResultList: MyIF.modelingResultType[] | undefined;
}

const ModelingResultAreaContent: React.FC<Props> = React.memo(({ modelingResultList }) => {
  // レコメンドモーダル画面　制御用
  const [isRecommendModal, setIsRecommendModal] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [imagePath, setImagePath] = React.useState('');
  const [recommend, setRecommend] = React.useState('');

  // modelingResultList = [
  //   {
  //     judge: 1,
  //     comment: '輪郭OK',
  //   },
  //   {
  //     judge: 2,
  //     comment: '3Dモデルに微小なギャップを検出いたしました。',
  //     recommend:
  //       'エラーにより試算結果が正しく算出されない場合があります。修正の上、データを再度アップロードすることをお勧めいたします。',
  //     link: '3-2.jpg',
  //     title: 'ギャップ',
  //   },
  //   {
  //     judge: 2,
  //     comment: '3Dモデルに反転した面を検出いたしました。',
  //     recommend:
  //       'エラーにより試算結果が正しく算出されない場合があります。修正の上、データを再度アップロードすることをお勧めいたします。',
  //     link: '3-3.jpg',
  //     title: '面の反転',
  //   },
  //   {
  //     judge: 1,
  //     comment: '造形高さOK',
  //   },
  //   {
  //     judge: 1,
  //     comment: 'サイズOK',
  //   },
  //   {
  //     judge: 3,
  //     comment: '3Dモデルが配置禁止エリアと干渉しています。',
  //     recommend:
  //       '以下のご検討をお願いいたします。\r\n・造形姿勢の変更\r\n・3Dモデルの分割、形状変更、サイズ変更\r\n・大型の3Dプリンタでの造形\r\n該当する3Dプリンタが見つからない場合は「担当者とチャット」にてお問い合わせください。',
  //     link: '5-1.jpg',
  //     title: '3Dモデルのサイズ',
  //   },
  // ];

  React.useEffect(() => {
    if (imagePath) {
      setIsRecommendModal(true);
    }
  }, [imagePath]);

  React.useEffect(() => {
    if (isRecommendModal === false) {
      setImagePath('');
      setRecommend('');
      setModalTitle('');
    }
  }, [isRecommendModal]);

  return (
    <div className='modelNoSpecEditFormToggleContentBlock'>
      <div className='modelNoSpecEditFormToggleContent'>
        <div className='modelNoSpecEditFormCheckDetail'>
          {modelingResultList?.map((resultType, index) => {
            return (
              <div
                key={index}
                className={`modelNoSpecEditFormCheckPara ${
                  resultType.judge === 1
                    ? 'modelNoSpecEditFormCheckParaOk'
                    : resultType.judge === 2
                    ? 'modelNoSpecEditFormCheckParaPartiallyOk'
                    : 'modelNoSpecEditFormCheckParaNg'
                }`}
              >
                {resultType.recommend ? (
                  <button
                    className={`button-style-trans ${
                      resultType.judge === 1
                        ? null
                        : resultType.judge === 2
                        ? 'modelNoSpecEditFormCheckLinkPartiallyOk'
                        : 'modelNoSpecEditFormCheckLinkNg'
                    }`}
                    style={{ textDecoration: 'underline', textAlign: 'left' }}
                    onClick={() => {
                      const path = resultType.link
                        ? process.env.PUBLIC_URL + '/RecommendImage/' + resultType.link
                        : '';
                      setImagePath(path);
                      setRecommend(resultType.recommend ? resultType.recommend : '');
                      setModalTitle(resultType.title ? resultType.title : '');
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {resultType.comment}
                      {resultType.judge === 2 && (
                        <ChatBubbleOutlineIcon
                          className='recommendFlash'
                          sx={{ color: '#ff8f22', marginLeft: '5px' }}
                          fontSize='small'
                        ></ChatBubbleOutlineIcon>
                      )}
                      {resultType.judge === 3 && (
                        <ChatBubbleOutlineIcon
                          className='recommendFlash'
                          sx={{ color: '#d70a30', marginLeft: '5px' }}
                          fontSize='small'
                        ></ChatBubbleOutlineIcon>
                      )}
                    </div>
                  </button>
                ) : (
                  <p style={{ marginLeft: '5px' }}>{resultType.comment}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <ModalRecommend
        show={isRecommendModal}
        setIsModalOpen={setIsRecommendModal}
        imagePath={imagePath}
        title={modalTitle}
        recommend={recommend}
      ></ModalRecommend>
    </div>
  );
});

export default ModelingResultAreaContent;
