import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useProgress } from '../../provider/progress/ProgressProvider';

const ProgressRing = React.memo(() => {
  const { progressShow } = useProgress();
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2000 }}
      open={progressShow}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
});

export default ProgressRing;
