import { gavadonFuncHandle } from '../components/viewer/Gavadon';
import * as MyIF from './Interface';
import { CameraOutputDto, XyzDegDto } from '../rest';

interface CameraCustomProps {
  '0': CameraOutputDto;
  '90': CameraOutputDto;
  '45': CameraOutputDto;
}

export const degreeList: Required<XyzDegDto>[] = [
  { x: 0, y: 0, z: 0 },
  { x: 0, y: 45, z: 0 },
  { x: 0, y: 45, z: 45 },
  { x: 0, y: 45, z: 90 },
  { x: 0, y: 45, z: 135 },
  { x: 0, y: 45, z: 180 },
  { x: 0, y: 45, z: 225 },
  { x: 0, y: 45, z: 270 },
  { x: 0, y: 45, z: 315 },
  { x: 0, y: 90, z: 0 },
  { x: 0, y: 90, z: 45 },
  { x: 0, y: 90, z: 90 },
  { x: 0, y: 90, z: 135 },
  { x: 0, y: 90, z: 180 },
  { x: 0, y: 90, z: 225 },
  { x: 0, y: 90, z: 270 },
  { x: 0, y: 90, z: 315 },
  { x: 0, y: 135, z: 0 },
  { x: 0, y: 135, z: 45 },
  { x: 0, y: 135, z: 90 },
  { x: 0, y: 135, z: 135 },
  { x: 0, y: 135, z: 180 },
  { x: 0, y: 135, z: 225 },
  { x: 0, y: 135, z: 270 },
  { x: 0, y: 135, z: 315 },
  { x: 0, y: 180, z: 0 },
];

const sqrt = Math.sqrt(2) * 0.5;
export const cameraList: CameraOutputDto[] = [
  { camera_pos: { x: 0, y: 0, z: -1 }, camera_up: { x: 0, y: 1, z: 0 } },
  { camera_pos: { x: -sqrt, y: 0, z: -sqrt }, camera_up: { x: 0, y: 1, z: 0 } },
  { camera_pos: { x: -sqrt, y: 0, z: -sqrt }, camera_up: { x: -0.5, y: sqrt, z: 0.5 } },
  { camera_pos: { x: -sqrt, y: 0, z: -sqrt }, camera_up: { x: -sqrt, y: 0, z: sqrt } },
  { camera_pos: { x: -sqrt, y: 0, z: -sqrt }, camera_up: { x: -0.5, y: -sqrt, z: 0.5 } },
  { camera_pos: { x: -sqrt, y: 0, z: -sqrt }, camera_up: { x: 0, y: -1, z: 0 } },
  { camera_pos: { x: -sqrt, y: 0, z: -sqrt }, camera_up: { x: 0.5, y: -sqrt, z: 0.5 } },
  { camera_pos: { x: -sqrt, y: 0, z: -sqrt }, camera_up: { x: sqrt, y: 0, z: -sqrt } },
  { camera_pos: { x: -sqrt, y: 0, z: -sqrt }, camera_up: { x: 0.5, y: sqrt, z: -0.5 } },
  { camera_pos: { x: -1, y: 0, z: 0 }, camera_up: { x: 0, y: 1, z: 0 } },
  { camera_pos: { x: -1, y: 0, z: 0 }, camera_up: { x: 0, y: sqrt, z: sqrt } },
  { camera_pos: { x: -1, y: 0, z: 0 }, camera_up: { x: 0, y: 0, z: 1 } },
  { camera_pos: { x: -1, y: 0, z: 0 }, camera_up: { x: 0, y: -sqrt, z: sqrt } },
  { camera_pos: { x: -1, y: 0, z: 0 }, camera_up: { x: 0, y: -1, z: 0 } },
  { camera_pos: { x: -1, y: 0, z: 0 }, camera_up: { x: 0, y: -sqrt, z: -sqrt } },
  { camera_pos: { x: -1, y: 0, z: 0 }, camera_up: { x: 0, y: 0, z: -1 } },
  { camera_pos: { x: -1, y: 0, z: 0 }, camera_up: { x: 0, y: sqrt, z: -sqrt } },
  { camera_pos: { x: -sqrt, y: 0, z: sqrt }, camera_up: { x: 0, y: 1, z: 0 } },
  { camera_pos: { x: -sqrt, y: 0, z: sqrt }, camera_up: { x: 0.5, y: sqrt, z: 0.5 } },
  { camera_pos: { x: -sqrt, y: 0, z: sqrt }, camera_up: { x: sqrt, y: 0, z: sqrt } },
  { camera_pos: { x: -sqrt, y: 0, z: sqrt }, camera_up: { x: 0.5, y: -sqrt, z: 0.5 } },
  { camera_pos: { x: -sqrt, y: 0, z: sqrt }, camera_up: { x: 0, y: -1, z: 0 } },
  { camera_pos: { x: -sqrt, y: 0, z: sqrt }, camera_up: { x: -0.5, y: -sqrt, z: -0.5 } },
  { camera_pos: { x: -sqrt, y: 0, z: sqrt }, camera_up: { x: -sqrt, y: 0, z: -sqrt } },
  { camera_pos: { x: -sqrt, y: 0, z: sqrt }, camera_up: { x: -0.5, y: sqrt, z: -0.5 } },
  { camera_pos: { x: 0, y: 0, z: 1 }, camera_up: { x: 0, y: 1, z: 0 } },
];

const cameraY0: CameraCustomProps = {
  '0': cameraList[0],
  '90': { camera_pos: { x: 0, y: 0, z: -1 }, camera_up: { x: -1, y: 0, z: 0 } },
  '45': { camera_pos: { x: 0, y: 0, z: -1 }, camera_up: { x: -sqrt, y: sqrt, z: 0 } },
};

const cameraY180: CameraCustomProps = {
  '0': cameraList[cameraList.length - 1],
  '90': { camera_pos: { x: 0, y: 0, z: 1 }, camera_up: { x: 1, y: 0, z: 0 } },
  '45': { camera_pos: { x: 0, y: 0, z: 1 }, camera_up: { x: sqrt, y: sqrt, z: 0 } },
};

const rotateDegree = [0, 90, 45];

export const degreeToRadian = (value: number) => {
  return value * Math.PI / 180;
};

export const calcBoxSize = (gavadon: gavadonFuncHandle, printer: MyIF.threeDPrinter) => {
  const { maximumBuildSizeWidth, maximumBuildSizeDepth, maximumBuildSizeHeight } = printer;

  const checkedList: XyzDegDto[] = [];
  const cameraCheckedList: CameraOutputDto[] = [];

  const checkSize = (result: { x: number, y: number, z: number }) => {
    if (!result) return false;
    return !(result.x > maximumBuildSizeWidth || result.y > maximumBuildSizeDepth || result.z > maximumBuildSizeHeight);
  };

  degreeList.forEach((item, index) => {
    for (let i = 0; i < rotateDegree.length; i++) {
      const z = item.z + rotateDegree[i];

      const result = gavadon.getBoundingBoxSizeWithEuler({
        x: degreeToRadian(item.x), y: degreeToRadian(item.y), z: degreeToRadian(z),
      });

      const isEligible = checkSize(result);
      if (isEligible) {
        // add to checked list
        checkedList.push({ x: item.x, y: item.y, z: z });
        // add to camera list
        if (index !== 0 && index !== degreeList.length - 1) {
          cameraCheckedList.push(cameraList[index]);
        } else {
          const key = `${z}` as '0' | '90' | '45';

          if (item.x === 0 && item.y === 0) {
            // (0, 0, z)
            cameraCheckedList.push(cameraY0[key]);
          } else if (item.x === 0 && item.y === 180) {
            // (0, 180, z)
            cameraCheckedList.push(cameraY180[key]);
          }
        }

        break;
      }

      // (0, 0, 0) (0, 180, 0) -> 90° / 45°
      if (index !== 0 && index !== degreeList.length - 1) {
        break;
      }
    }
  });

  return { checkedList, cameraList: cameraCheckedList };
};