import * as THREE from 'three';

export class DistancePMI {
    //vertex-edgeの最短距離測定関数
    //このメソッドは「与えられた頂点を基準として、与えられた線分までの距離最小のベクトル」を返す
    public fromVertexToEdge(vertex: number[], edgeLines: number[]) {
        let minDistance = new THREE.Vector3();
        //与えれた頂点と、辺を構成する各線分ごとに距離をとり、最小のものを探索
        //3次元の線分の始点(と終点)が((頂点数) - 1)個ある
        for (let n = 0; n < edgeLines.length / 3 - 1; n++) {
            let initial = new THREE.Vector3(edgeLines[n * 3], edgeLines[n * 3 + 1], edgeLines[n * 3 + 2]);
            let terminal = new THREE.Vector3(edgeLines[n * 3 + 3], edgeLines[n * 3 + 4], edgeLines[n * 3 + 5]);
            let vertexPosition = new THREE.Vector3().fromArray(vertex);
            let distance = this.distanceFromPointToLine(vertexPosition, initial, terminal);
            minDistance = (n === 0 || distance.length() < minDistance.length()) ? distance: minDistance;
        }
        return minDistance;
    }

    //点と線分の最短距離ベクトル返す関数
    public distanceFromPointToLine(origin: THREE.Vector3, initialPoint: THREE.Vector3, terminalPoint: THREE.Vector3) {
        let position = new THREE.Vector3().subVectors(initialPoint, origin);
        let line = new THREE.Vector3().subVectors(terminalPoint, initialPoint);
        let param = Math.min(Math.max(-(position.dot(line)) / (line.lengthSq()), 0), 1);
        let distance = new THREE.Vector3().copy(position).add(line.multiplyScalar(param));
        return distance;
    }

    //vertex-faceの最短距離測定関数
    //このメソッドは「与えられた頂点を基準として、与えられた面までの距離最小のベクトル」を返す
    public fromVertexToFace(vertex: number[], verticesOnFace: number[]) {
        let minDistance = new THREE.Vector3();
        //与えられた頂点と、面を構成する各三角形(3次元×3頂点=9成分)ごとに距離をとり、最小のものを探索
        for (let n = 0; n < verticesOnFace.length / 9; n++) {
            let triangle = [
                new THREE.Vector3(verticesOnFace[n * 9], verticesOnFace[n * 9 + 1], verticesOnFace[n * 9 + 2]),
                new THREE.Vector3(verticesOnFace[n * 9 + 3], verticesOnFace[n * 9 + 4], verticesOnFace[n * 9 + 5]),
                new THREE.Vector3(verticesOnFace[n * 9 + 6], verticesOnFace[n * 9 + 7], verticesOnFace[n * 9 + 8]),
            ];
            let vertexPosition = new THREE.Vector3().fromArray(vertex);
            let distance = this.distanceFromPointToTriangle(vertexPosition, triangle);
            minDistance = (n === 0 || distance.length() < minDistance.length()) ? distance: minDistance;
        }
        return minDistance;
    }

    //点と三角形の最短距離ベクトルを返す関数
    public distanceFromPointToTriangle(origin: THREE.Vector3, triangle: THREE.Vector3[]) {
        let position = new THREE.Vector3().subVectors(triangle[0], origin);
        let dir1 = new THREE.Vector3().subVectors(triangle[1], triangle[0]);
        let dir2 = new THREE.Vector3().subVectors(triangle[2], triangle[0]);
        let param1 = -(dir2.lengthSq() * position.dot(dir1) - dir1.dot(dir2) * position.dot(dir2))
            / (dir1.lengthSq() * dir2.lengthSq() - Math.pow(dir1.dot(dir2), 2));
        let param2 = -(dir1.lengthSq() * position.dot(dir2) - dir1.dot(dir2) * position.dot(dir1))
            / (dir1.lengthSq() * dir2.lengthSq() - Math.pow(dir1.dot(dir2), 2));
        let distance = new THREE.Vector3();
        if (param1 < 0) distance = this.distanceFromPointToLine(origin, triangle[0], triangle[2]);
        else if (param2 < 0) distance = this.distanceFromPointToLine(origin, triangle[0], triangle[1]);
        else if (param1 + param2 > 1) distance = this.distanceFromPointToLine(origin, triangle[1], triangle[2]);
        else distance = new THREE.Vector3().copy(position).add(dir1.multiplyScalar(param1)).add(dir2.multiplyScalar(param2));
        return distance;
    }

    //edge-edgeの最短距離測定関数
    //このメソッドは「与えられた線分edge0、edge1に対し、始点がedge0、終点がedge1上にあるような最小の線分」の
    //始点、終点、長さを返す
    public betweenEdges(edge0: number[], edge1: number[]) {
        const edgeLines = [edge0, edge1];
        let minInitial = new THREE.Vector3();
        let minTerminal = new THREE.Vector3();
        let minDistance = 0;
        //辺を構成する線分をedge0とedge1から各１つずつ取り、距離を測定、最小のものを探索する
        for (let m = 0; m < edgeLines[0].length / 3 - 1; m++) {
            for (let n = 0; n < edgeLines[1].length / 3 - 1; n++) {
                let initialLine = [
                    new THREE.Vector3(edgeLines[0][m * 3], edgeLines[0][m * 3 + 1], edgeLines[0][m * 3 + 2]),
                    new THREE.Vector3(edgeLines[0][m * 3 + 3], edgeLines[0][m * 3 + 4], edgeLines[0][m * 3 + 5])
                ];
                let terminalLine = [
                    new THREE.Vector3(edgeLines[1][n * 3], edgeLines[1][n * 3 + 1], edgeLines[1][n * 3 + 2]),
                    new THREE.Vector3(edgeLines[1][n * 3 + 3], edgeLines[1][n * 3 + 4], edgeLines[1][n * 3 + 5])
                ];
                let measureLine = this.distanceBetweenLines(initialLine, terminalLine);
                let distance = new THREE.Vector3().copy(measureLine[1]).sub(measureLine[0]).length();
                if ((n === 0 && m === 0) || distance < minDistance) {
                    minTerminal = measureLine[0];
                    minInitial = measureLine[1];
                    minDistance = distance;
                }
            }
        }
        return { start: minInitial, end: minTerminal, distance: minDistance };
    }

    //近似的に線分と線分の最短距離ベクトルを返す関数（未使用）
    public distanceBetweenLinesProto(line0: THREE.Vector3[], line1: THREE.Vector3[]): THREE.Vector3[] {
        //近似するメソッドを実行する回数（多いほうが最短距離に近づく）
        //ただし、線分の組み合わせで近似精度が変わるためよくない
        const step = 5;
        let initial = new THREE.Vector3().copy(line0[0]);
        let terminal = new THREE.Vector3();
        for (let i = 0; i < step; i++) {
            terminal = this.distanceFromPointToLine(initial, line1[0], line1[1]).add(initial);
            initial = this.distanceFromPointToLine(terminal, line0[0], line0[1]).add(terminal);
        }
        return [initial, terminal];
    }
    //線分と線分の最短距離のベクトルを返す関数（厳密解）
    public distanceBetweenLines(line0: THREE.Vector3[], line1: THREE.Vector3[]): THREE.Vector3[] {
        let lineVector = [
            new THREE.Vector3().subVectors(line0[1], line0[0]),
            new THREE.Vector3().subVectors(line1[1], line1[0]),
        ];
        //与えられた2線分の始点をつなぐベクトル
        let contactVector = new THREE.Vector3().subVectors(line1[0], line0[0]);
        //距離の2乗の関数とみたときのヘッセ行列
        let hesse = new THREE.Matrix3().set(
            lineVector[0].lengthSq(), -lineVector[0].dot(lineVector[1]), 0,
            -lineVector[0].dot(lineVector[1]), lineVector[1].lengthSq(), 0,
            0, 0, 1
        );
        //解（始点終点）の格納場所
        let initial = new THREE.Vector3();
        let terminal = new THREE.Vector3();
        //距離の2乗の最小が領域内かどうかを示す真理値の格納場所
        let isInner = false;
        //領域内かチェック
        if (hesse.determinant() > 0) {
            let scalarVector = new THREE.Vector3(
                contactVector.dot(lineVector[0]),
                -contactVector.dot(lineVector[1]),
                1
            );
            let solution = scalarVector.applyMatrix3(hesse.invert());
            //距離の2乗が領域内で最小をもつ場合の解
            if (0 <= solution.x && solution.x <= 1 && 0 <= solution.y && solution.y <= 1) {
                isInner = true;
                initial = new THREE.Vector3().addVectors(line0[0], lineVector[0].multiplyScalar(solution.x));
                terminal = new THREE.Vector3().addVectors(line1[0], lineVector[1].multiplyScalar(solution.y));
            }
        }
        //距離の2乗が領域外で最小を持つ場合の解
        if (isInner === false) {
            //領域の境界に対応する(頂点,線分)組のリスト
            let measureVector = [
                this.distanceFromPointToLine(line0[0], line1[0], line1[1]),
                this.distanceFromPointToLine(line0[1], line1[0], line1[1]),
                this.distanceFromPointToLine(line1[0], line0[0], line0[1]),
                this.distanceFromPointToLine(line1[1], line0[0], line0[1]),
            ];
            let min = measureVector[0];
            initial = line0[0];
            terminal = new THREE.Vector3().addVectors(initial, min);
            //リストの中で距離が最小となる組を探索
            for (let i = 1; i < measureVector.length; i++) {
                if (min.length() > measureVector[i].length()) {
                    min = measureVector[i];
                    if (i === 1) initial = line0[1];
                    else initial = line1[i % 2];
                    terminal = new THREE.Vector3().addVectors(initial, min);
                }
            }
        }
        return [initial, terminal];
    }

    //edge-faceの最短距離測定関数
    //このメソッドは「与えられた線分edge、面faceに対し、始点がedge、終点がface上にあるような最小の線分」の
    //始点、終点、長さを返す
    public fromEdgeToFace(edgeLines: number[], faceTriangles: number[]) {
        let minInitial = new THREE.Vector3();
        let minTerminal = new THREE.Vector3();
        let minDistance = 0;
        //辺の各線分と、面の各三角形、の組を取り、距離を求め、最小のものを探索
        for (let m = 0; m < edgeLines.length / 3 - 1; m++) {
            for (let n = 0; n < faceTriangles.length / 9; n++) {
                let line = [
                    new THREE.Vector3(edgeLines[m * 3], edgeLines[m * 3 + 1], edgeLines[m * 3 + 2]),
                    new THREE.Vector3(edgeLines[m * 3 + 3], edgeLines[m * 3 + 4], edgeLines[m * 3 + 5])
                ];
                let triangle = [
                    new THREE.Vector3(faceTriangles[n * 9], faceTriangles[n * 9 + 1], faceTriangles[n * 9 + 2]),
                    new THREE.Vector3(faceTriangles[n * 9 + 3], faceTriangles[n * 9 + 4], faceTriangles[n * 9 + 5]),
                    new THREE.Vector3(faceTriangles[n * 9 + 6], faceTriangles[n * 9 + 7], faceTriangles[n * 9 + 8]),
                ];
                let measureLine = this.distanceFromLineToTriangle(line, triangle);
                let distance = new THREE.Vector3().copy(measureLine[1]).sub(measureLine[0]).length();
                if ((n === 0 && m === 0) || distance < minDistance) {
                    minTerminal = measureLine[0];
                    minInitial = measureLine[1];
                    minDistance = distance;
                }
            }
        }
        return { start: minInitial, end: minTerminal, distance: minDistance };
    }

    //線分と三角形の最短距離ベクトルを返す関数
    public distanceFromLineToTriangle(line: THREE.Vector3[], triangle: THREE.Vector3[]): THREE.Vector3[] {
        const lineVector = new THREE.Vector3().subVectors(line[1], line[0]);
        const triangleVector =  [
            new THREE.Vector3().subVectors(triangle[1], triangle[0]),
            new THREE.Vector3().subVectors(triangle[2], triangle[0])
        ];
        const contactVector = new THREE.Vector3().subVectors(triangle[0], line[0]);
        let hesse = new THREE.Matrix3().set(
            lineVector.lengthSq(), -lineVector.dot(triangleVector[0]), -lineVector.dot(triangleVector[1]),
            -lineVector.dot(triangleVector[0]), triangleVector[0].lengthSq(), triangleVector[0].dot(triangleVector[1]),
            -lineVector.dot(triangleVector[1]), triangleVector[0].dot(triangleVector[1]), triangleVector[1].lengthSq()
        );
        let initial = new THREE.Vector3();
        let terminal = new THREE.Vector3();
        let isInner = false;
        if (hesse.determinant() > 0) {
            const scalarVector = new THREE.Vector3(
                lineVector.dot(contactVector),
                -triangleVector[0].dot(contactVector),
                -triangleVector[0].dot(contactVector)
            );
            let solution = scalarVector.applyMatrix3(hesse.invert());
            if (0 <= solution.x && solution.x <= 1 
                && 0 <= solution.y && 0 <= solution.z && solution.y + solution.z <= 1) {
                isInner = true;
                initial = line[0].addScaledVector(lineVector, solution.x);
                const planeVector = new THREE.Vector3().addVectors(
                    new THREE.Vector3().copy(triangleVector[0]).multiplyScalar(solution.y),
                    new THREE.Vector3().copy(triangleVector[1]).multiplyScalar(solution.z)
                )
                terminal = new THREE.Vector3().addVectors(triangle[0], planeVector);
            }
        }
        if (isInner === false){
            const firstMeasureVector = [
                this.distanceFromPointToTriangle(line[0], triangle),
                this.distanceFromPointToTriangle(line[1], triangle)
            ];
            let first = firstMeasureVector[0].length() < firstMeasureVector[1].length() ? 0 : 1;
            let min = firstMeasureVector[first].length();
            initial = line[first];
            terminal = new THREE.Vector3().addVectors(line[first], firstMeasureVector[first]);
            let measure = [
                this.distanceBetweenLines(line, [triangle[0], triangle[1]]),
                this.distanceBetweenLines(line, [triangle[0], triangle[2]]),
                this.distanceBetweenLines(line, [triangle[1], triangle[2]])
            ];
            for(let i = 0; i < 3; i++){
                let measureVector = new THREE.Vector3().subVectors(measure[i][1], measure[i][0]);
                if(min > measureVector.length()){
                    min = measureVector.length();
                    initial = measure[i][0];
                    terminal = measure[i][1];
                }
            }
        }
        return [initial, terminal];
    }

    //face-faceの距離測定関数
    //このメソッドは「与えられた面face0、face1に対し、始点がface0、終点がface1上にあるような最小の線分」の
    //始点、終点、長さを返す
    public betweenFaces(face0: number[], face1: number[]) {
        const faceVertices = [face0, face1];
        let minInitial = new THREE.Vector3();
        let minTerminal = new THREE.Vector3();
        let minDistance = 0;
        for (let m = 0; m < faceVertices[0].length / 9; m++) {
            for (let n = 0; n < faceVertices[1].length / 9; n++) {
                let triangle0 = [
                    new THREE.Vector3(faceVertices[0][m * 9], faceVertices[0][m * 9 + 1], faceVertices[0][m * 9 + 2]),
                    new THREE.Vector3(faceVertices[0][m * 9 + 3], faceVertices[0][m * 9 + 4], faceVertices[0][m * 9 + 5]),
                    new THREE.Vector3(faceVertices[0][m * 9 + 6], faceVertices[0][m * 9 + 7], faceVertices[0][m * 9 + 8])
                ];
                let triangle1 = [
                    new THREE.Vector3(faceVertices[1][n * 9], faceVertices[1][n * 9 + 1], faceVertices[1][n * 9 + 2]),
                    new THREE.Vector3(faceVertices[1][n * 9 + 3], faceVertices[1][n * 9 + 4], faceVertices[1][n * 9 + 5]),
                    new THREE.Vector3(faceVertices[1][n * 9 + 6], faceVertices[1][n * 9 + 7], faceVertices[1][n * 9 + 8])
                ];
                let measureLine = this.distanceBetweenTriangles(triangle0, triangle1);
                let distance = new THREE.Vector3().subVectors(measureLine[1], measureLine[0]).length();
                if ((n === 0 && m === 0) || distance < minDistance) {
                    minTerminal = measureLine[0];
                    minInitial = measureLine[1];
                    minDistance = distance;
                }
            }
        }
        return { start: minInitial, end: minTerminal, distance: minDistance };
    }

    //三角形同士の最短ベクトルを返す関数
    public distanceBetweenTriangles(triangle0: THREE.Vector3[], triangle1: THREE.Vector3[]): THREE.Vector3[]{
        let initial = new THREE.Vector3();
        let terminal = new THREE.Vector3();
        let min = 0;
        let measureList: THREE.Vector3[] = new Array(2);
        let measureVector = new THREE.Vector3();
        for(let n = 0; n < 3; n++){
            for(let m = 0; m < 3; m++){
                measureList = this.distanceBetweenLines(
                    [triangle0[(n+1)%3], triangle0[n]],
                    [triangle1[(m+1)%3], triangle1[m]]
                );
                measureVector = new THREE.Vector3().subVectors(measureList[1], measureList[0]);
                if((n === 0 && m === 0) || min > measureVector.length()){
                    min = measureVector.length();
                    initial = measureList[0];
                    terminal = measureList[1];
                }
            }
        }
        return [initial, terminal];
    }
}