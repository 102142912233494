import React, { useContext } from 'react';
import { SignUpParams } from '@aws-amplify/auth/lib-esm/types';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthState, initialContext } from './AuthState';
import { LoginOption } from './CognitoAuthProvider';
interface IAuthContext extends AuthState {
  signIn: (signInOption: LoginOption) => Promise<CognitoUser>;
  signUp: (params: SignUpParams) => Promise<CognitoUser | undefined>;
  confirmSignUp: (username: string, code: string) => Promise<boolean>;
  signOut: () => void;
  newPassword: (password: string) => Promise<void>;
  forgotPassword: (mailAddress: string) => Promise<void>;
  forgotPasswordConfirm: (mailAddress: string, code: string, password: string) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<boolean>;
  changeMailAddress: (newEmail: string) => Promise<boolean>;
  verifyMailAddress: (code: string) => Promise<boolean>;
}
export const AuthContext = React.createContext<IAuthContext>(initialContext);
export const useAuth = () => useContext(AuthContext);
