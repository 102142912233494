import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import Modal from '@mui/material/Modal';

interface Props {
  show: boolean;
  title: string;
  message: string;
  handleOK: () => void;
  handleCancel: () => void;
}

const ConfirmMessage: React.FC<Props> = React.memo(
  ({ show, title, message, handleOK, handleCancel }) => {
    return (
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={show}
      >
        <div className='modalWindowBlock modalRemindMessage' style={{ minWidth: '500px' }}>
          <div className='modalWindowInner modalWindowNoHeader'>
            <div className='modalWindowContents'>
              <h1 className='modalRemindMessageTitle'>{title}</h1>
              <p className='modalRemindMessagePara'>{message}</p>

              <div className='modalRemindMessageBtnBlock'>
                <button className='btnBlue' type='button' name='button' onClick={handleOK}>
                  OK
                </button>
                <button
                  className='btnWhiteBaseGray'
                  type='button'
                  name='button'
                  onClick={handleCancel}
                >
                  キャンセル
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default ConfirmMessage;
