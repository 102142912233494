import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/quotationRequest.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import AcrobatImage from '../../assets/image/common/acrobat_reader_bnr.png';
import { Dialog, Modal } from '@mui/material';
interface Props {
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  topPage?: boolean;
}

const ModalSitePolicy: React.FC<Props> = React.memo(({ show, setIsModalOpen, topPage }) => {
  const TITLE_CONTENT =
    '当ウェブサイト(https://3dfabs.orixrentec.jp 以下) は、以下のサイトポリシーに沿って制作・運営されています。内容をご確認の上、ご利用ください。\nまた、当ウェブサイトは、当社グループ会社や関連会社が運営するウェブサイトにリンクしていますが、それぞれ制作・運営方針が異なります。ご利用の際には、当該サイトに掲載されているプライバシーポリシーや利用条件をご確認の上、ご利用ください。';

  const POLICY_LIST = [
    {
      title: 'サービスの目的とご利用上の注意事項について',
      content:
        '当ウェブサイトは業務用 3D プリンターの利用体験を手軽にシミュレーションすることを目的としたサービスです。\n表示される金額、納期などのデータは AI による試算をベースとしているため±10％程度の誤差を含む場合があります。\n実際に 3D プリントをご注文される際には、当ウェブサイトの試算結果を基に誤差分を補正した正式版のお見積をオリックス・レンテック株式会社から発行します。\n初回のお取引の際には、法人契約に基づく審査があります。\nなお、当ウェブサイトへアップロードされた 3D CAD データは、アップロードの 2 週間後に自動的に消去されます。',
    },
    {
      title: '著作権について',
      content:
        '当ウェブサイトに掲載されているコンテンツ（文字・写真・画像や情報など、作成・編集された内容すべて）の著作権を含む一切の権利は、オリックスグループならびにその他の著作権者に帰属しています。これらを事前に著作権者の許諾を得ることなく使用、転載、複製、再配布、再出版などを行うことはできません。',
    },
    {
      title: '商標について',
      content:
        '当ウェブサイト上に掲載されているオリックスグループの社名、商品・サービス名やこれらのロゴ、マークなどはオリックスグループの登録商標または商標です。これらを事前に当サイト運営会社の許諾を得ることなく使用、転載、複製、改変などを行なうことはできません。',
    },
    {
      title: '当ウェブサイトへのリンクについて',
      content:
        '当ウェブサイトへリンクされる場合は、リンク元のサイトの運営主体、リンクの目的、リンク元のページの URL アドレスを事前に当社までご連絡くださいますよう、お願いします。違法なコンテンツを有するサイトからのリンクは固くお断りします。',
    },
    {
      title: 'Cookie（クッキー）やウェブビーコンについて',
      content:
        '当ウェブサイトでは、一部のページで Cookie（クッキー）やウェブビーコンの仕組みを利用しています。\nCookie とは、Web ブラウザを通してお客さまのコンピューターのハードディスクやメモリーに小さなデータファイルを送ることで、お客さまを確認する仕組みです。\nウェブビーコンとは、ウェブページに埋め込まれた小さな画像ファイルやタグで、特定のページにウェブビーコンを埋め込むことにより、そのページが訪問されたかなどを確認する仕組みです。\n当社では、この Cookie、およびウェブビーコンを利用し、ウェブサイトの利用状況に関する統計分析、広告などの効果測定を行っています。これはお客さまにより良い情報およびサービスをご提供していくことを目的としています。\nCookie やウェブビーコンによって、お客さまのお名前やご住所、電話番号などが当社に伝わることはありません。また、お客さまはブラウザの設定により、Cookie 機能を無効にすることができます。しかし、Cookie 機能を無効にした場合、一部サービスをご利用いただけない場合がありますのでご了承ください。\nまた、当社はこれらの計測・分析業務を外部委託する場合があります。',
    },
    {
      title: '免責について',
      content:
        '当ウェブサイトは、信頼できると考えられる情報に基づいて作成していますが、情報の正確性、確実性を保証するものではありません。万一、当ウェブサイトに掲載されている情報などにより何らかの被害をこうむった場合、その損害に関してオリックスグループが一切の責任を負うものではありません。\nまた、当ウェブサイトに掲載されている内容は、予告なしに変更または廃止されることがあります。',
    },
    {
      title: 'セキュリティーについて',
      content:
        '当ウェブサイトでは、専用フォームで個人情報を入力していただく部分をはじめ、全てのページに TLS(Transport Layer Security)1.2 以上のデータ暗号化機能を採用しています。また、サイト内における情報の保護にも、ファイヤウォールを設置するなどの方策をとっていますが、インターネット通信の性格上、セキュリティーを完全に保証するものではありません。あらかじめご了承ください。\nTLS とは、お客さまと当社サーバ間の通信を保護する仕組みです。お客さまと当ウェブサイトの運営会社のサーバ間でやりとりするデータを暗号化するため、お客さまから送信された情報（入力内容など）や該当ページから返信された情報（入力データの確認画面など）が、インターネット上の第三者に傍受されたり、改ざんされる可能性が極めて低くなります。',
    },
    {
      title: 'アクセスログについて',
      content:
        '当ウェブサイトでは、アクセスされた方の情報をアクセスログという形で記録しています。\nこのアクセスログは、ウェブサイトの利用状況に関する統計分析や利便性向上の検討、システム障害や不正アクセスに対する調査・原因究明に使用する場合があります。\nアクセスログには、アクセスされた方のドメイン名や IP アドレス、アクセスされた日付と時刻、アクセスされたファイル名、リンク元のページの URL、使用している Web ブラウザ名や OS 名などが含まれますが、個人情報にはリンクしていません。',
    },
    {
      title: 'Google Analytics 使用について',
      content:
        '当ウェブサイトでは当ウェブサイト改善のために、お客さまからのアクセスを分析するツールとして Google Analytics を使用しています。Google Analytics ではCookieを使用し、個人を特定する情報を含まずにログを収集しています。\n',
    },
    {
      subtitle: '■Google Analytics の広告向け機能について',
      subcontent: '当ウェブサイトでは、「Google Analytics の広告向けの機能」を有効にしており、下記の機能を利用しています。',
    },
    {
      subsubtitle: '・Google Analytics のユーザーの分布とインタレストカテゴリに関するレポート',
      subcontent: 'これにより、当ウェブサイトでは Google Analytics の Cookie や Google シグナルを利用して、お客さまの年齢・性別・当社サービスに関する関心の傾向をおおよそ把握するための分析が可能となっています。',
    },
    {
      subsubtitle: '・Google アナリティクス リマーケティング',
      subcontent: 'これにより、当ウェブサイトでは Google Analytics の Cookie や Google シグナルを利用して、当ウェブサイト内での個人を特定する情報を含まないお客様の行動データを把握し、適切な広告配信を行うことが可能となっています。',
    },
    {
      subsubtitle: '・広告掲載目的のデータ収集（広告 Cookie と識別子によるデータ収集を含む）のために Google アナリティクスを必要とする統合サービス',
      subcontent: '当社と Google 社を含むサードパーティベンダーは、Google アナリティクスの Cookieなど当社の発行するファーストパーティ Cookie（または他のファーストパーティ ID）とGoogle 広告 Cookie などのサードパーティ Cookie（または他のサードパーティ ID）を組み合せ、当サイトを訪れたユーザーの属性等の理解、及び効果的な広告配信のために利用しています。\n「Google Analytics の広告向けの機能」を利用されたくない場合は、設定によってトラッキングを無効にすることが可能です。Google Analytics オプトアウト アドオンをブラウザにインストールされると簡単に無効化できます。',
    },
    {
      title: '当ウェブサイト以外にリンクする、リンク元サイト・リンク先サイトについて',
      content:
        '当社は当ウェブサイト以外にリンクする、リンク元サイト・リンク先サイトのプライバシー保護やコンテンツに関しては、責任を負いません。リンク元サイト・リンク先サイトのプライバシー･ポリシーや利用条件をお客さまご自身でご確認いただきますようお願いします。',
    },
  ];

  const POLICY1 = {
    title: 'ご利用環境について',
    content:
      '当ウェブサイトの閲覧においては、以下の OS・ブラウザの利用をおすすめします。\nただし、OS・ブラウザ・修正プログラムのバージョンや組み合わせによっては、閲覧できない場合があります。\nまた、当ウェブサイトは  Cookie・TLS・JavaScript を使用しています。使用できない設定になっている場合には一部機能の使用が制限されます。［有効にする］または［利用する］、［ON］などにしてご利用ください。また、使用できないバーションをお使いの場合は、使用できるバージョンでご利用ください。',
  };

  const POLICY2 = {
    title: '使用プラグインについて',
    content: '当ウェブサイトの一部コンテンツでは、PDF を使用しています。',
  };

  const POLICY_LIST2 = [
    {
      title: 'JavaScript について',
      content:
        '当ウェブサイトではより便利にご利用いただくために、JavaScript を使用しています。ご使用のブラウザの設定で、JavaScript を無効に設定している場合には、一部ナビゲーションや、文字サイズ変更ボタンなど、当ウェブサイトの機能の一部が正常に動作しない場合があります。当ウェブサイトのご利用にあたっては、JavaScript を有効に設定いただきますようお願いします。',
    },
    {
      title: '準拠法・管轄裁判所について',
      content:
        '当ウェブサイトの利用ならびに当サイトポリシー、免責事項の解釈および適用は、日本国法に準拠するものとします。 当ウェブサイトに関する全ての紛争については、他に別段の定めのない限り東京地方裁判所を第一審の専属管轄裁判所とします。',
    },
    {
      title: '本文書の更新について',
      content:
        'このサイトポリシーは、更新する場合があります。最終更新日をご確認ください。\n\n最終更新日：2023 年 8 月 14 日',
    },
  ];

  return (
    <Dialog open={show} maxWidth={'lg'}>
      <div className='modelSitePolicy'>
        <div className='modalWindowHeader'>
          <h1 className='modalWindowHeaderTitle'>サイトポリシー</h1>
          <button
            className='modalWindowHeaderCloseLink button-style'
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            閉じる
          </button>
        </div>
        <div className='modalWindowContents'>
          <div className='modelSitePolicyContents'>
            <div className='modelSitePolicyBlock'>
              <div className={`modelSitePolicys ${topPage ? 'modelSitePolicysOnTopPage' : null}`}>
                <div className='modelSitePolicyPara'>
                  <p className='modelSitePolicyPara'>{TITLE_CONTENT}</p>

                  {POLICY_LIST.map((policy, index) => {
                    return (
                      <React.Fragment key={index}>
                        <h2>{policy.title}</h2>
                        <p className='modelSitePolicyPara'>{policy.content}</p>
                        <h3>{policy.subtitle}</h3>
                        <h4>{policy.subsubtitle}</h4>
                        <p className='modelSitePolicyPara'>{policy.subcontent}</p>
                        {policy.title === 'Google Analytics 使用について' && (
                          <>
                            <a href='https://marketingplatform.google.com/about/' target='_blank'>
                              Google Analytics
                            </a>
                            <br></br>
                            <br></br>
                            <a
                              href='https://policies.google.com/technologies/partner-sites?hl=ja'
                              target='_blank'
                            >
                              Google によるデータ使用について
                            </a>
                            <br></br>
                            <br></br>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}

                  <h2>{POLICY1.title}</h2>
                  <p className='modelSitePolicyPara'>{POLICY1.content}</p>
                  <table className='recommendedBrowserTable'>
                    <caption className='top'>[パソコン]</caption>
                    <thead>
                      <tr>
                        <th>OS</th>
                        <th>Windows10 20H2以降</th>
                        <th>Mac OS 11.6以降</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>推奨ブラウザ</th>
                        <td>
                          Chrome 最新版
                          <br />
                          Microsoft Edge 最新版
                        </td>
                        <td>
                          Chrome 最新版
                          <br />
                          Safari 最新版
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <h2>{POLICY2.title}</h2>
                  <p className='modelSitePolicyPara'>
                    {POLICY2.content}
                    <a
                      className='acrobatDownloadLink'
                      href='https://get.adobe.com/jp/reader/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img src={AcrobatImage} />
                      <br />
                      Adobe Reader のダウンロード
                    </a>
                    閲覧にはAcrobat Readerが必要です。お持ちでない方は
                    <a
                      href='https://get.adobe.com/jp/reader/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      「Adobe<sup>&reg;</sup>のサイト」
                    </a>
                    から無料で配布されておりますので、そちらからダウンロードし、ご利用ください。
                    <br />
                    <br />
                  </p>

                  {POLICY_LIST2.map((policy, index) => {
                    return (
                      <React.Fragment key={index}>
                        <h2>{policy.title}</h2>
                        <p className='modelSitePolicyPara'>{policy.content}</p>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
});

export default ModalSitePolicy;
