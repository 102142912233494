import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/top.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import BannerImageChannel from '../../assets/image/top/top_bnr_RentecChannel.jpg';
import BannerImageInsight from '../../assets/image/top/top_bnr_RentecInsight.jpg';
import BannerImageTopLeft from '../../assets/image/top/top_bnr_TopLeft.jpg';
import BannerImageTopRight from '../../assets/image/top/top_bnr_TopRight.jpg';

interface Props {}

const LinkArea: React.FC<Props> = React.memo(() => {
  return (
    <div className='linkArea'>
      <div className='linkAreaContents'>
        <h1>
          オリックス・レンテックの
          <br />
          3Dプリントお役立ち情報
        </h1>
        <div className='rentecInsightBnr'>
          <a
            href='https://www.orixrentec.jp/3dprinter/index.html'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={BannerImageTopLeft} alt='' />
          </a>
          <a
            href='https://www.orixrentec.jp/3dprinter/faq/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={BannerImageTopRight} alt='' />
          </a>
        </div>
        <div className='rentecInsightBnr'>
          <a
            href='https://3dfabs.orixrentec.jp/channel/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={BannerImageChannel} alt='' />
          </a>
          <a
            href='https://go.orixrentec.jp/rentecinsight/3dprinter'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={BannerImageInsight} alt='' />
          </a>
        </div>
      </div>
    </div>
  );
});

export default LinkArea;
