import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import Modal from '@mui/material/Modal';
import { Dialog } from '@mui/material';

interface Props {
  show: boolean;
  title: string;
  message: string;
  handleClose: () => void;
  imagePath?: string;
}

const InformationMessage: React.FC<Props> = React.memo(
  ({ show, title, message, handleClose, imagePath }) => {
    const [checked, setChecked] = React.useState(false);

    function setter() {
      sessionStorage.setItem('operation_exp_' + imagePath, checked ? 'hidden' : 'visible');
    }
    function getter() {
      return sessionStorage.getItem('operation_exp_' + imagePath);
    }

    return (
      <Dialog open={getter() === 'hidden' ? false : show} maxWidth={'lg'}>
        <div style={{ padding: '20px' }}>
          <h1 className='modalRemindMessageTitle'>{title}</h1>
          <div style={{ border: '2px solid gray', padding: '5px' }}>
            <p
              className='modalRemindMessagePara'
              style={{ whiteSpace: 'pre-line', textAlign: 'start' }}
            >
              {message}
            </p>
            {imagePath && (
              <img src={`${process.env.PUBLIC_URL}/ExpImage/${imagePath}`} alt=''></img>
            )}
          </div>
          <div className='modalRemindMessageBtnBlock'>
            <div style={{ margin: '10px' }}>
              <input
                type='checkbox'
                id='nextVisible'
                name='nextVisible'
                checked={checked}
                onChange={() => setChecked((pre) => !pre)}
                style={{ cursor: 'pointer' }}
              />
              <label htmlFor='nextVisible' style={{ marginLeft: '5px', cursor: 'pointer' }}>
                次回から表示しない
              </label>
            </div>
            <button
              className='btnWhiteBaseGray'
              type='button'
              name='button'
              onClick={() => {
                setter();
                handleClose();
              }}
            >
              閉じる
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
);

export default InformationMessage;
