import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import ModalRecommendDetails from './ModalRecommendDetails';
import Modal from '@mui/material/Modal';

interface Props {
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  imagePath: string;
  title: string;
  recommend: string;
}

const ModalRecommend: React.FC<Props> = React.memo(
  ({ show, setIsModalOpen, imagePath, title, recommend }) => {
    // モーダル詳細画面　制御用
    const [isRecoomendDetailsModal, setIsRecoomendDetailsModal] = React.useState(false);

    return (
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={show}
        disableScrollLock
      >
        <section className='modalWindowWrapper modelAboutModelingPostureWindow'>
          <div className='modalWindowBlock'>
            <div className='modalWindowInner'>
              <div className='modalWindowHeader'>
                <h1 className='modalWindowHeaderTitle'>{title}について</h1>
                <button
                  className='modalWindowHeaderCloseLink button-style'
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                >
                  閉じる
                </button>
              </div>
              <div className='modalWindowContents'>
                <div className='aboutModelingPosture'>
                  <div style={{ textAlign: 'center' }}>
                    <img src={imagePath} alt='' style={{ maxWidth: '100%', maxHeight: '500px' }} />
                  </div>
                  <div className='aboutModelingPostureTextBlock'>
                    <h2 className='aboutModelingPostureH2'>{title}</h2>
                    <p className='aboutModelingPostureText' style={{ whiteSpace: 'pre-line' }}>
                      {recommend}
                    </p>
                    <div className='aboutModelModelingPostureButtonBlock'>
                      {/* <button
                      className='btnReadmoreBlue'
                      type='button'
                      name='button'
                      onClick={() => {
                        setIsRecoomendDetailsModal(true);
                      }}
                    >
                      さらに詳しく
                    </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalRecommendDetails
            show={isRecoomendDetailsModal}
            setIsModalOpen={setIsRecoomendDetailsModal}
          ></ModalRecommendDetails>
        </section>
      </Modal>
    );
  }
);

export default ModalRecommend;
