import * as React from 'react';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import Modal from '@mui/material/Modal';
import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';

interface Props {
  show: boolean;
  handleCancel: () => void;
  calcPredictTime:
    | {
        h: number;
        m: number;
        s: number;
      }
    | undefined;
}

const ModalCalcCancel: React.FC<Props> = React.memo(({ show, handleCancel, calcPredictTime }) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
  const styleDiv = {
    width: '200px',
    height: '200px',
    padding: '100px',
  };

  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={show}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 3000 }}
    >
      <div
        className='modalWindowBlock modalRemindMessage'
        style={{ minWidth: '500px', borderRadius: '10px' }}
      >
        <div className='modalWindowInner modalWindowNoHeader'>
          <div className='modalWindowContents'>
            <div className='modalRemindMessagePara' style={{ margin: '10px' }}>
              <p style={{ fontSize: '16px' }}>試算中です...</p>
              <CircularProgress style={{ margin: '10px' }} />
            </div>
            <div className='modalRemindMessageBtnBlock'>
              {calcPredictTime && (
                <>
                  <div style={{ fontSize: '16px' }}>
                    予想試算時間：
                    <span style={{ color: 'red' }}>
                      {calcPredictTime.h > 0
                        ? calcPredictTime.h +
                          '時' +
                          calcPredictTime.m +
                          '分' +
                          calcPredictTime.s +
                          '秒'
                        : calcPredictTime.m > 0
                        ? calcPredictTime.m + '分' + calcPredictTime.s + '秒'
                        : calcPredictTime.s + '秒'}
                    </span>
                  </div>
                  <div
                    style={{
                      margin: '10px',
                      fontSize: '12px',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {
                      '※実際の試算時間は、お客さまの通信環境により\n予想と差異が生じる場合があります。\nまた、表面積が大きいモデルの場合、\n極端に試算時間が長くなる場合があります。'
                    }
                  </div>
                </>
              )}
              <button
                className='btnWhiteBaseGray'
                type='button'
                name='button'
                onClick={handleCancel}
              >
                キャンセル
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    // <Modal
    //   aria-labelledby='simple-modal-title'
    //   aria-describedby='simple-modal-description'
    //   open={show}
    //   sx={{ zIndex: (theme) => theme.zIndex.drawer + 3000 }}
    // >
    //   <Box sx={style}>
    //     <div style={styleDiv}>
    //       <CircularProgress size={200} disableShrink thickness={2} />
    //       <div
    //         style={{
    //           position: 'absolute',
    //           top: '105px',
    //           left: '105px',
    //           width: '190px',
    //           height: '190px',
    //           borderRadius: '100%',
    //           background: 'rgba(255, 255, 255, 0.9)',
    //           display: 'flex',
    //           flexFlow: 'column',
    //           justifyContent: 'center',
    //           alignItems: 'center',
    //         }}
    //       >
    //         <Typography>試算中です...</Typography>
    //         <button
    //           className='btnWhiteBaseGray'
    //           type='button'
    //           name='button'
    //           onClick={handleCancel}
    //           style={{ marginTop: '10px' }}
    //         >
    //           キャンセル
    //         </button>
    //       </div>
    //     </div>
    //   </Box>
    // </Modal>
  );
});

export default ModalCalcCancel;
