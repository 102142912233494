import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../provider/cognito/AuthContext';
import { useAlert } from '../../provider/alert/AlertProvider';
import { LoginOption } from '../../provider/cognito/CognitoAuthProvider';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientLogin.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { viewLoginType } from '../../pages/users/Login';
import { APIController } from '../../common/API';
import { Auth } from 'aws-amplify';
import QuestionPopper from '../popper/QuestionPopper';
import QEXP from '../../common/QuestionExp.json';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewLoginType>>;
}

const LoginForm: React.FC<Props> = React.memo(({ setViewSelect }) => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const { setAlertShow, setMessageList } = useAlert();
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [pass, setPass] = React.useState('');

  const funcSignIn = () => {
    const signInOption: LoginOption = {
      username: email,
      password: pass,
    };

    signIn(signInOption)
      .then((cognitoUser) => {
        if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          console.log('NEWPW');
          setViewSelect('NewPW');
        } else {
          if (cognitoUser) {
            Auth.currentCredentials().then((credentials) => {
              const federatedId = credentials.identityId;
              APIController.build({
                setAlertShow: setAlertShow,
                navigate: navigate,
              }).then((api) => {
                api.PutFederatedId(federatedId);
              });
            });

            navigate('/ModelNumberList');
          }
        }
      })
      .catch((err) => {
        switch (err.message) {
          case 'Incorrect username or password.':
            setMessageList(['IDまたはPWが間違っています。']);
            setAlertShow(true);
            break;
          case 'Password attempts exceeded':
            setMessageList([
              'アカウントがロックされました。',
              'しばらく時間が経ってから再度ログインしてください。',
            ]);
            setAlertShow(true);
            break;
          case 'User is not confirmed.':
            navigate('/UserRegisterConfirm', {
              state: {
                mailAddress: email,
              },
            });
            break;
        }
      });
  };

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <div className='mainContentsBlock noFlowBar'>
      <div className='clientLogin'>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>ログイン</h1>
            </div>
          </div>
        </div>
        <div className=''>
          <div className='pageTitleDetailTextInput'>
            <p className='pageTitleDetailText'>
              3D-FABsで登録したログインIDとパスワードを入力してください。
            </p>
            <Box sx={{ mt: 1 }}>
              <div className='loginInputBlock'>
                <div style={{ marginRight: '10px' }}>
                  <QuestionPopper value={QEXP.Login.ID} />
                </div>
                <TextField
                  required
                  fullWidth
                  id='email'
                  name='email'
                  autoComplete='email'
                  placeholder='ログインID入力'
                  autoFocus
                  value={email}
                  onChange={(event) => {
                    console.log('email change', event.target.value);
                    setEmail(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      funcSignIn();
                    }
                  }}
                />
              </div>
              <div className='loginInputBlock' style={{ marginTop: '20px' }}>
                <div style={{ marginRight: '10px' }}>
                  <QuestionPopper value={QEXP.Login.PASSWORD} />
                </div>
                <TextField
                  required
                  fullWidth
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  id='password'
                  placeholder='パスワード入力'
                  autoComplete='current-password'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end' style={{ marginLeft: '-55px' }}>
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  style={{ paddingRight: '0' }}
                  value={pass}
                  onChange={(event) => {
                    setPass(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      funcSignIn();
                    }
                  }}
                />
              </div>

              <div className='clientLoginButtonArea' style={{ marginTop: '20px' }}>
                <p className='forgotPassword'>
                  パスワードをお忘れの方は
                  <a
                    href='/#'
                    onClick={(event) => {
                      event.preventDefault();
                      setViewSelect('ResetPW');
                    }}
                  >
                    こちら
                  </a>
                </p>
                <button
                  className='btnBlueCommit'
                  onClick={() => {
                    funcSignIn();
                  }}
                >
                  ログイン
                </button>
              </div>
            </Box>
          </div>

          <div className='pageTitleBlock'>
            <div className='pageTitleInner'>
              <div className='pageTitleWithBtn'>
                <h1 className='pageTitle'>はじめてご利用のお客さま</h1>
              </div>
            </div>
          </div>
          <div className='clientSignUpButtonArea'>
            <button
              className='btnOrangeCommit'
              type='button'
              name='button'
              onClick={() => {
                navigate('/UserRegister');
              }}
            >
              新規会員登録
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LoginForm;
