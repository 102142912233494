import '../../assets/css/chatwoot.css';
import { APIController } from '../../common/API';

/**
 * チャット初期化
 */
export function chatInit(user) {
  (function (d, t) {
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: 'right',
      locale: 'ja',
      type: 'expanded_bubble',
      launcherTitle: '担当者と\n\nチャット',
    };

    const BASE_URL = process.env.REACT_APP_CHAT_BASE_URL;
    const g = d.createElement(t),
      s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + '/packs/js/sdk.js';
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: process.env.REACT_APP_CHAT_WEB_SITE_TOKEN,
        baseUrl: BASE_URL,
      });
      window.addEventListener('chatwoot:ready', function () {
        chatSetUser(user);
      });
    };
  })(document, 'script');
}

/**
 * チャットリセット
 */
export function chatReset() {
  if (window.$chatwoot) {
    window.$chatwoot.reset();
    window.$chatwoot.toggleBubbleVisibility('hide');
  }
}

/**
 * チャットユーザー設定
 * @param {CognitoUser} user
 */
export async function chatSetUser(user) {
  const hash = await (await APIController.build({})).GetChatHash();
  user.getUserAttributes(function (err, result) {
    result?.forEach((att) => {
      if (att.getName() === 'email') {
        const userSub = user.getUsername();
        const email = att.getValue();

        console.log(userSub, email, hash);

        if (window.$chatwoot) {
          window.$chatwoot.user = {
            email: email,
            identifier_hash: hash,
          };
          window.$chatwoot.setUser(userSub, {
            email: email,
            identifier_hash: hash,
          });
          window.$chatwoot.toggleBubbleVisibility('show');
        }
      }
    });
  });
}

/**
 * チャットを開く
 */
export function chatOpen() {
  if (window.$chatwoot) {
    window.$chatwoot.toggle('open');
  }
}
