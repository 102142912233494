import * as THREE from 'three';
import * as constant from '../Constant';
import * as utils from 'three/examples/jsm/utils/BufferGeometryUtils';
import { Calculate } from './Calculate';
import { Simplex } from './Simplex';
import { GavadonData } from './GavadonData';

//頂点の当たり判定と重複判定をスキップすることで読み込み速度を上げる

export class Vertex extends Simplex {

  //フィールド変数
  private _duplicateList: number[];
  private _boundingSphere: THREE.Sphere;
  private _boundingBox: THREE.Box3;
  public sizeData: { maxX: number, minX: number, maxY: number, minY: number, maxZ: number, minZ: number, max: number, width: number, height: number };

  //コンストラクタ
  constructor(data: GavadonData) {
    const calculate = new Calculate();
    //頂点データ
    const vertices = data.edge_data.points.length > 0 ? calculate.roundArrayOf(data.edge_data.points, constant.DIGIT) : calculate.roundArrayOf(data.face_data.points, constant.DIGIT);
    //データの準備
    let points = new Array();
    let verticesList = new Array();
    let collisions = new Array();
    let duplicateResult = new Array();
    let vectorList = new Array();
    let size = { max: [0, 0, 0], min: [0, 0, 0] };

    //進捗確認ログ用
    // const total = vertices.length / 3;
    // console.log('vertices:', total);

    //各頂点ごとに処理
    for (let n = 0; n < (vertices.length / 3); n++) {
      //頂点ごとにリストに追加
      const position = [vertices[n * 3], vertices[n * 3 + 1], vertices[n * 3 + 2]];
      points.push(position);
      vectorList.push(new THREE.Vector3(vertices[n * 3], vertices[n * 3 + 1], vertices[n * 3 + 2]));
      //サイズ判定
      if (n === 0) {
        for (let dir = 0; dir < 3; dir++) {
          size.max[dir] = position[dir];
          size.min[dir] = position[dir];
        }
      } else {
        for (let dir = 0; dir < 3; dir++) {
          if (size.max[dir] < position[dir]) size.max[dir] = position[dir];
          if (size.min[dir] > position[dir]) size.min[dir] = position[dir];
        }
      }
      //重複リスト更新
      duplicateResult.push(n);
    }
    //描画用全体メッシュ
    const allVertices = verticesList.length > 0 ?
      new THREE.Mesh(
        utils.mergeBufferGeometries(verticesList),
        new THREE.MeshBasicMaterial({ color: constant.VERTEX_COLOR, visible: false })
      ) : new THREE.Mesh();
    if (!(allVertices.material instanceof Array)) allVertices.material.needsUpdate = true;
    //フィールド変数への代入
    super(
      vertices,
      points,
      allVertices,
      collisions
    );
    this._duplicateList = duplicateResult;

    this._boundingSphere = new THREE.Sphere().setFromPoints(vectorList);
    this._boundingBox = new THREE.Box3().setFromPoints(vectorList);

    let width = Math.max(size.max[0] - size.min[0], size.max[1] - size.min[1], constant.MINIMAL_LENGTH) + constant.MARGIN;
    let height = Math.max(size.max[2] - size.min[2], size.max[1] - size.min[1], constant.MINIMAL_LENGTH) + constant.MARGIN;
    if (width < height * constant.ASPECT) {
      width = height * constant.ASPECT;
    } else {
      height = width / constant.ASPECT;
    }
    this.sizeData = {
      maxX: size.max[0],
      minX: size.min[0],
      maxY: size.max[1],
      minY: size.min[1],
      maxZ: size.max[2],
      minZ: size.min[2],
      max: this._boundingSphere.radius,
      width: width,
      height: height
    };
  }

  //メソッド
  public measure() {
    return this.sizeData;
  }

  public getBoundingBox(): THREE.Box3{
    return this._boundingBox;
  }

  public getBoundingSphere(): THREE.Sphere {
    return this._boundingSphere;
  }

  public getDuplicate(): number[] {
    return this._duplicateList;
  }
}