import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import '../../assets/css/designCommon.css';
import '../../assets/css/quotationRequest.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import CommonContactTable from './ContactTable';
import CommonContactEdit from './ContactEdit';
import { useProgress } from '../../provider/progress/ProgressProvider';

interface Props {
  // 0:型番 1:試算
  updateFlg: number;
  updateNumber: string;
  setIsContactPage: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommonContact: React.FC<Props> = React.memo(
  ({ updateFlg, updateNumber, setIsContactPage }) => {
    const { setAlertShow } = useAlert();
    const { setProgressShow } = useProgress();
    const navigate = useNavigate();

    const [isEditShow, setIsEditShow] = React.useState(false);
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [isEditShow]);

    const [userInfo, setUserInfo] = React.useState<MyIF.userInfo>({
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      mailAddress: '',
      phoneNumber: '',
      corpoName: '',
      corpoNameKana: '',
      departmentName: '',
      postCode1: '',
      postCode2: '',
      address1: '',
    });
    const [contactChangeFlg, setContactChangeFlg] = React.useState(false);

    React.useEffect(() => {
      (async () => {
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).GetUserContactAddress();
        if (result !== null) {
          setUserInfo(result.userInfo);
        }
      })();
    }, []);

    const updateContact = async () => {
      setProgressShow(true);
      // 担当者連絡API
      const result = await (
        await APIController.build({
          setAlertShow: setAlertShow,
          navigate: navigate,
        })
      ).PutContactAddress(updateFlg, updateNumber, contactChangeFlg, userInfo);
      if (result) {
        if (updateFlg === 0) {
          navigate('/ModelNumberList');
        } else {
          navigate('/TrialCalcList');
        }
      }
      setProgressShow(false);
    };

    return (
      <div>
        {isEditShow ? (
          <CommonContactEdit
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            setIsEditShow={setIsEditShow}
            setContactChangeFlg={setContactChangeFlg}
          ></CommonContactEdit>
        ) : (
          <div className='mainContentsBlock'>
            <div className='pageTitleBlock'>
              <div className='pageTitleInner'>
                <div className='pageTitleWithBtn'>
                  <h1 className='pageTitle'>担当者連絡</h1>
                </div>
              </div>
            </div>

            <div className='pageTitleDetailText'>
              <p>
                <span className='autoNumberingText'>
                  {updateNumber}({updateFlg === 0 ? '型番' : '試算番号'})
                </span>
                の内容でオリックス・レンテックの問い合わせ窓口へ連絡してもよろしいですか。
                <br />
                連絡先の情報、表記される部署名、お名前等について、ご確認ください。ご希望と異なる場合は、連絡先情報変更ボタンを選択してください。
              </p>
            </div>

            <CommonContactTable userInfo={userInfo}></CommonContactTable>

            <div className='modelNoSpecButtonArea'>
              <a
                href='/#'
                className='changeShippingInfoLink'
                onClick={(event) => {
                  event.preventDefault();
                  setIsEditShow(true);
                }}
              >
                連絡先情報変更
              </a>
            </div>
            <div className='modelReuploadButtonArea'>
              <button
                className='btnBack'
                type='button'
                name='button'
                onClick={() => {
                  setIsContactPage(false);
                }}
              >
                戻る
              </button>
              <button
                className='btnOrangeCommit'
                type='button'
                name='button'
                onClick={() => {
                  updateContact();
                }}
              >
                担当者連絡
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default CommonContact;
