import { FileController } from './FileController';
import { FabsDatabase } from './FabsDatabase';

export class CachedS3ContentProvider {
  private db: FabsDatabase;

  constructor() {
    this.db = new FabsDatabase();
  }

  /**
   * モデル本体のMpacをBlobで取得します
   * @param bucket S3バケット名
   * @param path S3キー
   * @param threedId 3DデータID
   */
  GetContent = async (bucket: string, path: string): Promise<Blob | undefined> => {
    // IndexedDBにあればそこから取得
    const savedModel = await this.db.s3Cache.get(bucket + '/' + path);
    if (savedModel !== undefined) {
      console.log('既存');
      return savedModel.blobData;
    }

    const fc = new FileController();
    const decompress = path.split('.').pop() === 'gz';
    const file = await fc.FileDownloadS3(bucket, path, decompress);
    if (file !== undefined) {
      // あればIndexedDBに保存しておく
      this.db.s3Cache.put({
        key: bucket + '/' + path,
        blobData: file,
      });
    }
    return file;
  };

  /**
   * モデルをDBに保存する
   * @param key
   * @param file
   */
  SetContent = async (key: string, file: File) => {
    this.db.s3Cache.put({
      key: key,
      blobData: file,
    });
  };
}
