import * as React from 'react';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/trialBalanceSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';

interface Props {
  priceInfo: MyIF.priceInfo;
  quantityAlert?: boolean;
}

const TrialCalcPrice: React.FC<Props> = React.memo(({ priceInfo, quantityAlert }) => {
  return (
    <section className='trialBalanceSpecEditFormAccordionContent'>
      <input id='toggle7' type='checkbox' className='trialBalanceSpecEditFormToggle' />
      <label className='trialBalanceSpecEditFormToggleLabel' htmlFor='toggle7'>
        試算結果
      </label>

      <div className='trialBalanceSpecEditFormToggleContentBlock'>
        <div className='trialBalanceSpecEditFormToggleContent'>
          <div className='trialBalanceSpecEditFormUnitPriceBlock'>
            <div className='trialBalanceSpecEditFormUnitPriceTitle'>標準納期・価格</div>
            <div className='trialBalanceSpecEditFormUnitPriceDetail'>
              <p className='trialBalanceSpecEditFormUnitPriceDeliveryPara'>
                納期：{priceInfo.normal.date ? priceInfo.normal.date : '- '}営業日
              </p>
              <p className='trialBalanceSpecEditFormUnitPricePara'>
                ￥
                {quantityAlert
                  ? '-'
                  : priceInfo.normal.price
                  ? priceInfo.normal.price.toLocaleString()
                  : '-'}
              </p>
            </div>
          </div>
          <div className='trialBalanceSpecEditFormUnitPriceBlock'>
            <div className='trialBalanceSpecEditFormUnitPriceTitle'>特急納期・価格</div>
            <div className='trialBalanceSpecEditFormUnitPriceDetail'>
              <p className='trialBalanceSpecEditFormUnitPriceDeliveryPara'>
                納期：{priceInfo.express.date ? priceInfo.express.date : '- '}営業日
              </p>
              <p className='trialBalanceSpecEditFormUnitPricePara'>
                ￥
                {quantityAlert
                  ? '-'
                  : priceInfo.express.price
                  ? priceInfo.express.price.toLocaleString()
                  : '-'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default TrialCalcPrice;
