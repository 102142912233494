import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/designCommon.css';
import '../../assets/css/quotationRequest.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import FlowArea from '../flow/FlowArea';

interface Props {}

const QuotationComplete: React.FC<Props> = React.memo(() => {
  const navigate = useNavigate();

  return (
    <div>
      <FlowArea active={4} subActive={[1, 2]}></FlowArea>
      <div className='mainContentsBlock' style={{ marginBottom: '20px' }}>
        <div className='pageTitleBlock'>
          <div className='pageTitleInner'>
            <div className='pageTitleWithBtn'>
              <h1 className='pageTitle'>正式見積書発行</h1>
            </div>
          </div>
        </div>

        <div className='pageTitleDetailText'>
          <p>受付順に見積書を発行します、しばらくお待ち下さい。</p>
        </div>

        <div className='modelReuploadButtonArea'>
          <button
            className='btnBack'
            type='button'
            name='button'
            onClick={() => {
              navigate('/TrialCalcList');
            }}
          >
            戻る
          </button>
        </div>
      </div>
    </div>
  );
});

export default QuotationComplete;
