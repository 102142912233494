import React from 'react';
import { useAuth } from '../../provider/cognito/AuthContext';
import LoginForm from '../../components/account/LoginForm';
import PasswordChange from '../../components/account/PasswordChange';
import FooterContent from '../../components/footer/Footer';
import HeaderNoLogin from '../../components/header/HeaderNoLogin';
import PasswordReset from '../../components/account/PasswordReset';
import PasswordResetCompleted from '../../components/account/PassWordResetCompleted';
import PasswordChangeOneTime from '../../components/account/PasswordChangeOneTime';
import { useLocation } from 'react-router-dom';

export type viewLoginType = 'Login' | 'NewPW' | 'OneTime' | 'ResetPW' | 'ResetPWComplete';

function Login() {
  const location = useLocation();
  const { user, isAuthenticated } = useAuth();
  const [viewSelect, setViewSelect] = React.useState<viewLoginType>(
    isAuthenticated && user?.challengeName === 'NEW_PASSWORD_REQUIRED' ? 'NewPW' : 'Login'
  );

  React.useEffect(() => {
    if (location.state?.viewSelect) {
      setViewSelect(location.state.viewSelect);
    }
  }, [location.state]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [viewSelect]);

  React.useEffect(() => {
    sessionStorage.clear();
  }, []);

  const [mailAddress, setMailAddress] = React.useState('');

  React.useEffect(() => {
    document.body.style.minWidth = 'auto';
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.minWidth = '1024px';
      document.body.style.overflowX = 'auto';
    };
  }, []);

  return (
    <div style={{ display: 'flex', flexFlow: 'column', minHeight: '100vh' }}>
      <div style={{ flex: '1' }}>
        <HeaderNoLogin />
        {viewSelect === 'Login' && <LoginForm setViewSelect={setViewSelect}></LoginForm>}
        {viewSelect === 'NewPW' && (
          <>
            <PasswordChange setViewSelect={setViewSelect}></PasswordChange>
            {/* <TrackVirtualPageView suffix='NewPassword'></TrackVirtualPageView> */}
          </>
        )}
        {viewSelect === 'ResetPW' && (
          <>
            <PasswordReset
              setViewSelect={setViewSelect}
              mailAddress={mailAddress}
              setMailAddress={setMailAddress}
            ></PasswordReset>
            {/* <TrackVirtualPageView suffix='PasswordReset'></TrackVirtualPageView> */}
          </>
        )}
        {viewSelect === 'OneTime' && (
          <>
            <PasswordChangeOneTime
              setViewSelect={setViewSelect}
              mailAddress={mailAddress}
            ></PasswordChangeOneTime>
            {/* <TrackVirtualPageView suffix='OneTime'></TrackVirtualPageView> */}
          </>
        )}
        {viewSelect === 'ResetPWComplete' && (
          <>
            <PasswordResetCompleted setViewSelect={setViewSelect}></PasswordResetCompleted>
            {/* <TrackVirtualPageView suffix='PasswordResetCompleted'></TrackVirtualPageView> */}
          </>
        )}
      </div>
      <FooterContent noUI={true} />
    </div>
  );
}

export default Login;
