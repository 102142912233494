import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import '../../assets/css/designCommon.css';
import '../../assets/css/clientRegistrationForm.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import { viewProfileType } from './ModalProfile';
import UserInfoTableEdit from './UserInfoTableEdit';
import ConfirmMessage from '../message/ConfirmMessage';
import { useAlert } from '../../provider/alert/AlertProvider';
import { useNavigate } from 'react-router-dom';
import { useProgress } from '../../provider/progress/ProgressProvider';

interface Props {
  setViewSelect: React.Dispatch<React.SetStateAction<viewProfileType>>;
  registInfo: MyIF.userRegistInfo;
}

const ProfileEdit: React.FC<Props> = React.memo(({ setViewSelect, registInfo }) => {
  const [registEditInfo, setRegistEditInfo] = React.useState<MyIF.userRegistInfo>(registInfo);

  const { setProgressShow } = useProgress();
  const { setAlertShow, setMessageList } = useAlert();
  const navigate = useNavigate();

  // モーダル制御用
  const [isConfirmMessage, setIsConfirmMessage] = React.useState(false);

  const handleOK = () => {
    setViewSelect('Read');
  };
  const handleCancel = () => {
    setIsConfirmMessage(false);
  };

  const save = async () => {
    setProgressShow(true);
    const checkResult = await (
      await APIController.build({
        setAlertShow: setAlertShow,
        navigate: navigate,
      })
    ).PutUserInfo(registEditInfo);
    if (checkResult) {
      if (checkResult.length > 0) {
        setMessageList(checkResult);
        setAlertShow(true);
      } else {
        setViewSelect('Read');
      }
    }
    setProgressShow(false);
  };

  return (
    <div>
      <div className='pageTitleBlock'>
        <div className='pageTitleInner'>
          <div className='pageTitleWithBtn'>
            <h1 className='pageTitle'>プロフィール（編集）</h1>
          </div>
        </div>
      </div>
      <div className='pageTitleDetailText'>
        <p>
          <span className='attentionRedText'>※</span>マークは入力必須項目です。
        </p>
      </div>
      <UserInfoTableEdit
        viewType='Profile'
        registInfo={registEditInfo}
        setRegistInfo={setRegistEditInfo}
      />

      {/* <div className='registrationFormContent'>
        <div className='registrationFormContentLabel'>
          <label htmlFor='mail_magazine'>メールマガジンの購読</label>
        </div>
        <div className='registrationFormContentInput'>
          <p className='registrationFormContentText'>
            本システムのアップデート情報や3Dプリントに関係するメールマガジンを購読されますか。
          </p>
          <div className='registrationFormContentAdditionCheckbox'>
            <input
              id='mailCheckBox'
              type='checkbox'
              name=''
              checked={registEditInfo.mailFlg}
              onChange={(event) => {
                setRegistEditInfo({ ...registEditInfo, mailFlg: event.target.checked });
              }}
            />{' '}
            <label htmlFor='mailCheckBox'>購読する</label>
          </div>
        </div>
      </div> */}
      <div className='clientInformationConfirmationButtonArea'>
        <button
          className='btnBack'
          type='button'
          name='button'
          onClick={() => {
            setIsConfirmMessage(true);
          }}
        >
          戻る
        </button>
        <button className='btnOrangeCommit' type='button' name='button' onClick={save}>
          保存
        </button>
      </div>

      <ConfirmMessage
        show={isConfirmMessage}
        title={'確認'}
        message={'入力した内容がクリアされますが、よろしいですか？'}
        handleOK={handleOK}
        handleCancel={handleCancel}
      ></ConfirmMessage>
    </div>
  );
});

export default ProfileEdit;
