import * as React from 'react';
import { APIController } from '../../common/API';
import * as MyIF from '../../common/Interface';
import { useAlert } from '../../provider/alert/AlertProvider';
import '../../assets/css/designCommon.css';
import '../../assets/css/modelNumberSpecEdit.css';
import '../../assets/common/normalize.css';
import '../../assets/common/reset.css';
import '../../assets/common/base2.css';
import '../../assets/css/viewer.css';
import IconBackGray from '../../assets/image/common/icon_back_gray.svg';
import ModelingResultAreaTitle from './ModelingResultAreaTitle';
import ModelingResultAreaContent from './ModelingResultAreaContent';
import TrialCalcInfoAreaContent from './TrialCalcInfoAreaContent';
import GavadonComponent, { gavadonFuncHandle } from '../viewer/Gavadon';
import { useNavigate } from 'react-router-dom';
import ModalCalcCancel from './ModalCalcCancel';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Dialog,
  IconButton,
  Popover,
} from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useState } from 'react';
import QuestionPopper from '../popper/QuestionPopper';
import QEXP from '../../common/QuestionExp.json';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Gavadon from '../viewer/Gavadon';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { CameraOutputDto, XyzDegDto } from '../../rest';

interface Props {
  handleToggle?: () => Promise<void>;
  show: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModelingJudge: number;
  setIsModelingJudge: React.Dispatch<React.SetStateAction<number>>;
  modelingResultList: MyIF.modelingResultType[] | undefined;
  setModelingResultList: React.Dispatch<
    React.SetStateAction<MyIF.modelingResultType[] | undefined>
  >;
  trialCalcInfo: MyIF.trialCalcInfoType | undefined;
  setTrialCalcInfo: React.Dispatch<React.SetStateAction<MyIF.trialCalcInfoType | undefined>>;
  modelViewInfo: MyIF.modelViewInfo;
  setModelViewInfo: React.Dispatch<React.SetStateAction<MyIF.modelViewInfo>>;
  setCalcFlg: React.Dispatch<React.SetStateAction<boolean>>;
  gavadonRef: React.MutableRefObject<gavadonFuncHandle>;
  selectModelCondition: MyIF.selectModelingConditionType;
  printerInfo: MyIF.threeDPrinter | undefined;
  cameraList: CameraOutputDto[];
  checkList: XyzDegDto[];

  GetBasePlateSize():
    | {
    x: number;
    y: number;
  }
    | undefined;

  CheckBoudingBoxSize(
    iPrinterInfo?: MyIF.threeDPrinter | undefined,
    iGavadonRef?: React.MutableRefObject<gavadonFuncHandle>,
  ):
    | {
    message: string;
    image: string;
  }
    | undefined;

  calcPredictTime:
    | {
    h: number;
    m: number;
    s: number;
  }
    | undefined;

  GetCalcResult(
    id?: string,
    selectOption?: MyIF.optionInfo,
    selectCondition?: MyIF.selectModelingConditionType,
  ): Promise<void>;

  GetCalcTime(
    iPrinterInfo?: MyIF.threeDPrinter | undefined,
    iGavadonRef?: React.MutableRefObject<gavadonFuncHandle>,
    isRecommendCalc?: boolean,
  ): void;
}

const ModalModelingPostureEdit: React.FC<Props> = React.memo(
  ({
     show,
     setIsModalOpen,
     isModelingJudge,
     setIsModelingJudge,
     modelingResultList,
     setModelingResultList,
     trialCalcInfo,
     setTrialCalcInfo,
     modelViewInfo,
     setModelViewInfo,
     setCalcFlg,
     gavadonRef,
     selectModelCondition,
     printerInfo,
     GetBasePlateSize,
     CheckBoudingBoxSize,
     calcPredictTime,
     GetCalcResult,
     GetCalcTime,
     handleToggle,
     cameraList,
     checkList,
   }) => {
    const { setAlertShow, alertShow } = useAlert();
    const navigate = useNavigate();
    /**
     * モーダル画面上の設定値用
     */
      // 造形可否判定結果　True:OK Flase:NG
    const [_isModelingJudge, _setIsModelingJudge] = React.useState<number>(1);
    // 造形可否判定結果
    const [_modelingResultList, _setModelingResultList] =
      React.useState<MyIF.modelingResultType[]>();
    // 試算基本情報
    const [_trialCalcInfo, _setTrialCalcInfo] = React.useState<MyIF.trialCalcInfoType>();
    // 3D描画情報
    const [_modelViewInfo, _setModelViewInfo] = React.useState<MyIF.modelViewInfo>();
    // モデルサイズ造形オーバー注意文言
    const [_modelSizeOverMessage, _setModelSizeOverMessage] = React.useState<string | undefined>(
      undefined,
    );
    const [_modelSizeOverImg, _setModelSizeOverImg] = React.useState<string | undefined>(undefined);
    // Recommend -> true
    const recommendCalcRunningRef = React.useRef(false);

    const [accordingExpanded, setAccordingExpanded] = React.useState(false);

    const [initialRotate, setInitialRotate] = React.useState<{ x: number, y: number, z: number }>({ x: 0, y: 0, z: 0 });

    React.useEffect(() => {
      if (show) {
        console.log('modelViewInfo設定', modelViewInfo);
        _setModelViewInfo(modelViewInfo);
        _setIsModelingJudge(isModelingJudge);
        _setModelingResultList(modelingResultList);
        _setTrialCalcInfo(trialCalcInfo);
        setAccordingExpanded(false);
        setInitialRotate(modelViewInfo.rotate || { x: 0, y: 0, z: 0 });
      } else {
        _setModelViewInfo(undefined);
        initializeSidebar();
        _setModelSizeOverMessage(undefined);
        setInitialRotate({ x: 0, y: 0, z: 0 });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    React.useEffect(() => {
      if (recommendCalcRunningRef.current) {
        recommendCalcRunningRef.current = false;
        return;
        // } else {
        //   _setModelViewInfo(modelViewInfo);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelViewInfo.id]);

    // Gavadonフック
    const _gavadonRef = React.useRef<gavadonFuncHandle>({} as gavadonFuncHandle);

    React.useEffect(() => {
      if (alertShow && show) {
        _gavadonRef.current.switchToggle(false);
      }
    }, [alertShow]);

    // 確定フラグ
    const [confirmFlg, setConfirmFlg] = React.useState(false);

    //サポート材最小探索実行中フラグ
    const [recommendCalcRunning, setRecommendCalcRunning] = React.useState(false);

    // 計算中モーダル制御
    const [isCalcCancel, setIsCalcCancel] = React.useState(false);
    const calcCancelRef = React.useRef(isCalcCancel);
    calcCancelRef.current = isCalcCancel;

    const sleepTimeoutID = React.useRef<NodeJS.Timeout>();
    const sleepTimeout = async () => {
      return new Promise((resolve) => {
        sleepTimeoutID.current = setTimeout(resolve, 5000);
      });
    };

    /**
     * 試算キャンセル
     */
    const handleCalcCancel = () => {
      if (typeof sleepTimeoutID.current === 'number') {
        clearTimeout(sleepTimeoutID.current);
      }
      setIsCalcCancel(false);
      setRecommendCalcRunning(false);
      //トグルスイッチ
      _gavadonRef.current.switchToggle(false);
    };

    /**
     * 造形可否判定結果取得
     */
    async function GetModelingResult(inModelViewInfo?: MyIF.modelViewInfo, isRecommendCalc: boolean = false) {
      let tmpInfo = inModelViewInfo ? inModelViewInfo : _modelViewInfo;
      let status: boolean = true;

      let loopCount = 0;
      const timeOutCount = ((Number(process.env.REACT_APP_CALC_POLLING_TIME_OUT) || 40) * 60) / 5;
      console.log('timeOutCount', timeOutCount);

      // API呼び出し
      if (tmpInfo && tmpInfo.id !== '') {
        while (true) {
          if (loopCount > timeOutCount) {
            setAlertShow(true);
            status = false;
            _gavadonRef.current.switchToggle(false);
            break;
          }

          const modelResult = isRecommendCalc ?
            await (
              await APIController.build({
                setAlertShow: setAlertShow,
                navigate: navigate,
              })
            ).GetRecommendModelingResult(tmpInfo.id) :
            await (
              await APIController.build({
                setAlertShow: setAlertShow,
                navigate: navigate,
              })
            ).GetModelingResult(tmpInfo.id);
          if (modelResult !== null) {
            if (modelResult.status === 0) {
              await sleepTimeout();
              loopCount += 1;

              // キャンセル押されている場合は、終了
              if (calcCancelRef.current === false) {
                console.log('キャンセル押されたので終了');
                status = false;
                break;
              }
            } else if (modelResult.status === 1) {
              if (isRecommendCalc && _modelViewInfo) {
                tmpInfo.id = modelResult.threeDDataId ?? '';
                tmpInfo.rotate = {
                  x: Number(modelResult.rotateInfo?.bank),
                  y: Number(modelResult.rotateInfo?.attitude),
                  z: Number(modelResult.rotateInfo?.heading),
                };
                _gavadonRef.current.setRotate(tmpInfo.rotate);
              }
              _setIsModelingJudge(modelResult.isJudge);
              _setModelingResultList(modelResult.modelingResult);
              _setTrialCalcInfo(modelResult.trialCalcInfo);
              tmpInfo.supportMpacKey = modelResult.supportModel.key;
              tmpInfo.supportBlob = modelResult.supportModel.blob;
              tmpInfo.onPartsSupportBlob = modelResult.onPartsSupportBlob;
              tmpInfo.crossSectionalNgBlob = modelResult.crossSectionalNgBlob;
              break;
            } else {
              status = false;
              break;
            }
          } else {
            status = false;
            break;
          }
        }
      }
      return { modelViewInfo: tmpInfo, status };
    }

    /**
     * 計算実行＆計算結果取得API
     */
    const CalcExecute = async () => {
      GetCalcTime && GetCalcTime(undefined, _gavadonRef, false);
      if (selectModelCondition.modelingMaterialId) {
        setIsCalcCancel(true);
        if (_modelViewInfo) {
          _setModelViewInfo({
            ..._modelViewInfo,
            supportMpacKey: undefined,
          });
        }

        // カメラ情報
        const camera = _gavadonRef.current.getCamera();
        // 回転情報
        const rotate = _gavadonRef.current.getRotate();
        // 計算実行
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).PutThreeDModelCalc(
          modelViewInfo.id,
          selectModelCondition.modelingMaterialId,
          selectModelCondition.methodValue,
          selectModelCondition.printerValue,
          camera,
          rotate,
        );
        if (result) {
          const tmpViewInfo = Object.assign({}, _modelViewInfo);
          if (result.newID) {
            console.log('NewID', result.newID);
            if (tmpViewInfo) {
              tmpViewInfo.id = result.newID;
            }
          }

          // 造形可否判定結果取得
          const resultViewInfo = await GetModelingResult(tmpViewInfo);
          if (resultViewInfo.status) {
            _setModelViewInfo(resultViewInfo.modelViewInfo);
            setConfirmFlg(true);
            _gavadonRef.current.setCameraMode('camera');
            _gavadonRef.current.reloadGround();
          }
        }
        setIsCalcCancel(false);
      }
    };

    /**
     * サポート材最小探索計算実行＆計算結果取得API
     */
    const RecommendCalcExecute = async () => {
      setRecommendCalcRunning(true);
      recommendCalcRunningRef.current = true;
      GetCalcTime && GetCalcTime(undefined, _gavadonRef);
      if (selectModelCondition.modelingMaterialId) {
        setIsCalcCancel(true);
        if (_modelViewInfo) {
          _setModelViewInfo({
            ..._modelViewInfo,
            supportMpacKey: undefined,
          });
        }

        // カメラ情報
        // const camera = _gavadonRef.current.getCamera();
        // 回転情報
        // const rotate = _gavadonRef.current.getRotate();
        // 計算実行
        const result = await (
          await APIController.build({
            setAlertShow: setAlertShow,
            navigate: navigate,
          })
        ).PutThreeDModelRecommendCalc(
          modelViewInfo.id,
          selectModelCondition.modelingMaterialId,
          selectModelCondition.methodValue,
          selectModelCondition.printerValue,
          cameraList,
          checkList,
        );
        if (result) {
          const tmpViewInfo = Object.assign({}, _modelViewInfo);
          if (result.groupID) {
            console.log('groupID', result.groupID);
            if (tmpViewInfo) {
              tmpViewInfo.id = result.groupID;
            }
          }

          // 造形可否判定結果取得
          const resultViewInfo = await GetModelingResult(tmpViewInfo, true);
          if (resultViewInfo.status) {
            _setModelViewInfo(resultViewInfo.modelViewInfo);
            setConfirmFlg(true);
            _gavadonRef.current.setCameraMode('camera');
            _gavadonRef.current.reloadGround();
          }
        }
        setIsCalcCancel(false);
      }
    };

    // 計算前の状態まで初期化
    function initializeSidebar() {
      setConfirmFlg(false);
      _setTrialCalcInfo(undefined);
      _setModelingResultList(undefined);
      _setIsModelingJudge(-1);
    }

    // 回転がかかったとき
    React.useMemo(() => {
      if (_modelViewInfo && !recommendCalcRunning) {
        if (JSON.stringify(modelViewInfo.rotate) === JSON.stringify(_modelViewInfo?.rotate)) {
          // 本体と同じ回転であれば、初期表示のためスキップ
          // 手動で回転を戻した時にも、ここに入ってくるため
          // 確定ボタンが押下できる状態であれば、初期表示ではないと判断
          if (confirmFlg) {
            initializeSidebar();
          }
        } else {
          initializeSidebar();
        }
      }
    }, [_modelViewInfo?.rotate]);

    const ResultConfirm = async () => {
      setIsModelingJudge(_isModelingJudge);
      setModelingResultList(_modelingResultList);
      setTrialCalcInfo(_trialCalcInfo);
      if (_modelViewInfo) {
        setModelViewInfo(_modelViewInfo);
        gavadonRef.current.setRotate(_modelViewInfo.rotate);
        gavadonRef.current.reloadGround();
      }
      await GetCalcResult(_modelViewInfo?.id);
      setCalcFlg(false);

      setIsModalOpen(false);
    };

    // モデルサイズチェック結果セッター
    function _SetCheckBoudingBoxSize() {
      const rtnMessage = CheckBoudingBoxSize(undefined, _gavadonRef);
      if (rtnMessage !== undefined) {
        _setModelSizeOverMessage(rtnMessage.message);
        _setModelSizeOverImg(rtnMessage.image);
      }
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const popoverHandleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const popoverOpen = Boolean(anchorEl);

    return (
      <Dialog open={show} maxWidth={'lg'} fullWidth={true}>
        <div className='modelModelingPostureEditWindow'>
          {/* 閉じるボタンは枠外右上固定 */}
          <div className='modelModelingPostureEditWindowCloseParent'>
            <button
              className='modelModelingPostureEditWindowClose'
              onClick={() => {
                setIsModalOpen(false);
              }}
              style={{ zIndex: '10' }}
            >
              閉じる
            </button>
          </div>

          <div style={{ width: '60%', minWidth: '720px' }}>
            {/* ヘッダーエリア */}
            <Accordion className='modalModelingPostureHeader' onChange={() => setAccordingExpanded(true)}>
              <AccordionSummary
                className={`modalModelingPostureHeaderExp ${accordingExpanded ? '' : 'recommendFlash'}`}
                aria-controls='panel-content'
              >
                ■　造形姿勢編集のコツ
                <ChatBubbleOutlineIcon
                  sx={{ marginLeft: '5px' }}
                  fontSize='small'
                ></ChatBubbleOutlineIcon>
              </AccordionSummary>
              <AccordionDetails className='modalModelingPostureHeaderExpDetail'>
                <p>{printerInfo?.basicModelingAttitude}</p>
                {printerInfo?.basicModelingAttitudeImageUrl && (
                  <img
                    src={`${process.env.PUBLIC_URL}/ModelingPostureImage/${printerInfo.basicModelingAttitudeImageUrl}`}
                    alt=''
                    className='modelingPostureImage'
                  ></img>
                )}
              </AccordionDetails>
            </Accordion>

            {/* ビューワーエリア */}
            <div className='modelModelingPostureImageBlock'>
              <div className='GavadonAreaModal'>
                <GavadonComponent
                  setRecommendCalcRunning={setRecommendCalcRunning}
                  handleCalcCancel={handleCalcCancel}
                  eulerFlg={true}
                  modelViewInfo={_modelViewInfo}
                  setModelViewInfo={_setModelViewInfo}
                  ref={_gavadonRef}
                  GetBasePlateSize={GetBasePlateSize}
                  SetCheckBoudingBoxSize={_SetCheckBoudingBoxSize}
                  RecommendCalcExecute={RecommendCalcExecute}
                  initializeSidebar={initializeSidebar}
                />
              </div>

              <div className='modelModelingPostureImageButtonBlock'>
                <button
                  className='btnOrangeCommitWhite'
                  type='button'
                  name='button'
                  onClick={() => {
                    CalcExecute();
                  }}
                  disabled={
                    _modelViewInfo?.id
                      ? confirmFlg
                        ? true
                        : false || _modelSizeOverMessage !== ''
                      : true
                  }
                >
                  造形可否判定
                </button>
                <button
                  style={{ marginLeft: '10px' }}
                  type='button'
                  name='button'
                  className='btnOrangeConfirm'
                  onClick={() => {
                    ResultConfirm();
                  }}
                  disabled={confirmFlg ? false : true}
                >
                  確定
                </button>
                <button
                  className='ModelingPostureResetLink button-style-trans'
                  onClick={() => {
                    if (_modelViewInfo) {
                      _setModelViewInfo((info) => ({ ...info!, rotate: { ...initialRotate } }));
                    }
                    _gavadonRef.current.setRotate(initialRotate);
                    setRecommendCalcRunning(false);
                    initializeSidebar();
                    _gavadonRef.current.switchToggle(false);
                  }}
                >
                  <img alt='' src={IconBackGray} />
                  初期姿勢に戻す
                </button>

                {_modelSizeOverMessage && (
                  <Alert severity='error' style={{ marginTop: '10px' }}>
                    <AlertTitle>Error</AlertTitle>
                    {_modelSizeOverMessage}
                    <span>
                      <IconButton onClick={popoverHandleClick}>
                        <QuestionMarkIcon className='questionIcon' fontSize='small' />
                      </IconButton>
                      <Popover
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        onClose={() => setAnchorEl(null)}
                        PaperProps={{
                          style: {
                            padding: '10px',
                          },
                        }}
                      >
                        <div style={{ display: 'flex', flexFlow: 'column' }}>
                          <img
                            style={{ maxWidth: '400px', margin: '0 auto' }}
                            src={_modelSizeOverImg}
                          ></img>
                          <div style={{ fontSize: '12px', whiteSpace: 'pre-line' }}>
                            {
                              '3Dモデルのサイズが造形可能範囲を超えています。\n図の外枠が3Dプリンターのカタログ表記にある造形チャンバーの大きさ、\n内枠が実際のプリントで使える範囲です。'
                            }
                          </div>
                        </div>
                      </Popover>
                    </span>
                  </Alert>
                )}
              </div>
            </div>
          </div>

          {/* 結果表示エリア */}
          <div className='modelModelingPostureInfoBlock'>
            <h2 className='modelNoSpecEditFormLabel'>
              造形可否判定結果
              <ModelingResultAreaTitle isModelingJudge={_isModelingJudge} />
            </h2>
            <ModelingResultAreaContent modelingResultList={_modelingResultList} />

            <h2 className='modelNoSpecEditFormLabel'>試算基本情報</h2>
            <TrialCalcInfoAreaContent trialCalcInfo={_trialCalcInfo} />
          </div>
        </div>

        <ModalCalcCancel
          show={isCalcCancel}
          handleCancel={handleCalcCancel}
          calcPredictTime={calcPredictTime}
        ></ModalCalcCancel>
      </Dialog>
    );
  },
);

export default ModalModelingPostureEdit;
