import axios from 'axios';

export const GetZipCodeAddress = async (zipCodeMain: string, zipCodeSub: string) => {
  if (zipCodeMain.length === 3 && zipCodeSub.length === 4) {
    try {
      const res = await axios.get('https://zipcloud.ibsnet.co.jp/api/search', {
        params: {
          zipcode: zipCodeMain + zipCodeSub,
        },
      });
      if (res.data.results) {
        const result = res.data.results[0];
        return {
          address1: result['address1'],
          address2: result['address2'],
          address3: result['address3'],
        };
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  return null;
};
